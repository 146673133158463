import { isObject } from 'lodash';
import React, { FunctionComponent } from 'react';
import { Route } from 'react-router';
import DashboardReport from "./DashboardReport";
import { scheduleReportRoute, sendReportRoute } from './reportPaths';

const DashboardReportRoute: FunctionComponent = () => {

  const fetchSendReportRoutePath = () => {
    return Object.values(sendReportRoute).map((path, index) => (
      <React.Fragment key={`send-report-${index}`}>
        <Route path={path} component={DashboardReport} />
      </React.Fragment>
    ));
  };

  const fetchScheduleReportRoutePath = () => {
    return Object.values(scheduleReportRoute).map((path, index) => (
      <React.Fragment key={`schedule-report-${index}`}>
        <Route path={path} component={DashboardReport} />
      </React.Fragment>
    ));
  };

  return (
      <>
        {isObject(sendReportRoute) && fetchSendReportRoutePath()}
        {isObject(scheduleReportRoute) && fetchScheduleReportRoutePath()}
      </>
  );
};

export default DashboardReportRoute;
