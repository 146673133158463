exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SsoCheck__loadingSpinner___3SFLS{height:100%}.SsoCheck__modal___4MUBe{top:30%}.SsoCheck__title___12fYY{margin-bottom:5px;font-size:1.2em;padding:15px;text-align:center}.SsoCheck__modal___4MUBe .modal-dialog{max-width:500px}.SsoCheck__modal___4MUBe .modal-footer{text-align:center}.SsoCheck__modal___4MUBe .modal-footer button{margin:5px 0 5px 5px}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/layouts/common/SsoCheck/SsoCheck.css"],"names":[],"mappings":"AAAA,kCACE,WAAa,CACd,AAED,yBACE,OAAS,CACV,AAED,yBACE,kBAAmB,AACnB,gBAAiB,AACjB,aAAc,AACd,iBAAmB,CACpB,AAED,uCACE,eAAiB,CAClB,AAED,uCACE,iBAAmB,CACpB,AAED,8CACE,oBAAsB,CACvB","file":"SsoCheck.css","sourcesContent":[".loadingSpinner {\n  height: 100%;\n}\n\n.modal {\n  top: 30%;\n}\n\n.title {\n  margin-bottom: 5px;\n  font-size: 1.2em;\n  padding: 15px;\n  text-align: center;\n}\n\n.modal :global(.modal-dialog) {\n  max-width: 500px;\n}\n\n.modal :global(.modal-footer) {\n  text-align: center;\n}\n\n.modal :global(.modal-footer) button {\n  margin: 5px 0 5px 5px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"loadingSpinner": "SsoCheck__loadingSpinner___3SFLS",
	"modal": "SsoCheck__modal___4MUBe",
	"title": "SsoCheck__title___12fYY"
};