exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SendEmailDialog__footer___1TpAP{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/layouts/common/Dashboard/components/DashboardUsers/SendEmailDialog/SendEmailDialog.css"],"names":[],"mappings":"AAAA,iCACE,oBAAqB,AACrB,aAAc,AACd,sBAAuB,AACnB,6BAA+B,CACpC","file":"SendEmailDialog.css","sourcesContent":[".footer{\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "SendEmailDialog__footer___1TpAP"
};