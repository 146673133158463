import React, { useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashboardReportLayout from "../Dashboard/components/Reports/components/DashboardReportLayout";
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/app/redux/store/actions';
import selectors from 'src/app/redux/selectors';
import { isCurrentUserBorF } from 'src/app/data/common/utils/userRoleUtils';
import { getDashboardFilterCache } from 'src/layouts/common/Dashboard/components/layout';

const DashboardReport = () => {
    const dispatch = useDispatch();
    const [clientName, setClientName] = useState<string>();
    const [clientID, setClientID] = useState<string>();
    const { pathname, search, state } = useLocation();

    const userProfile = useSelector(selectors.profile.getUserProfile);
    const userRole = useSelector(selectors.profile.getCurrentUserRole);

    const getPageId = useMemo(() => new URLSearchParams(search).get("pageId"), [search]);
    const userProfileId = useSelector(selectors.profile.getUserId) || 'UndefinedId';
    const storageKey = useMemo(() => `Filter[${getPageId}][${userRole?.name}][${userProfileId}]`, [userProfileId, userRole, getPageId]);

    const fetchClientName = async () => {
        if(isCurrentUserBorF(userRole) && userProfile?.clientId){
            const response = await dispatch(actions.clients.getClientDetail(userProfile?.clientId));
            setClientName(response?.name);
            setClientID(response?.id);
        }else{
            const cacheValue = getDashboardFilterCache(storageKey);
            setClientName(cacheValue?.client?.name);
            setClientID(cacheValue?.client?.id);
        }
    };

    useEffect(() => {
        fetchClientName();
    }, []);

    const checkReportType = useMemo(() => {
        if(pathname.includes('dashboard-report/schedule')) {
            return 'schedule';
        }
        if(pathname.includes('dashboard-report/send')){
            return 'send';
        }
        return null;
    }, [pathname]);

    const redirectDashboard = (): string => {
        if(pathname) {
            return pathname.replace(`dashboard-report/${checkReportType}`, 'dashboard');
        }
        return '/dashboard';
    };

    if(!userRole?.id){
        return null;
    }

    return (
        <DashboardReportLayout 
            reportType={checkReportType} 
            redirectDashboard={redirectDashboard()}
            isSendReport={checkReportType === 'send'}
            storageKey={storageKey}
            clientName={clientName}
            clientID={clientID}
            getPageId={getPageId}
            userId={userProfileId}
            userRoleId={userRole.id}
            query={state}
        />
    );
};

export default DashboardReport;
