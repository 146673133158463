import { CUSTOMIZATION_BTN_TEXT_MAX_LENGTH, LERARNER_INVITATIONS_SENDER_NAME_MAX_LENGTH, LERARNER_INVITATIONS_SUBJECT_MAX_LENGTH,  } from 'src/app/data/common/constants';
import { TListPageArchiveOption } from 'src/app/data/common/interfaces/IListPageData';
import { IClientUsersQuery } from 'src/app/redux/modules/clients/users/rest';
import { ButtonType } from 'src/components/Button';
import { ICustomizeEmailSettings, ICustomizeLearnerInvitationsMsg, ICustomizeLearnerState } from 'src/layouts/common/InviteToSchedulePage/components/CustomizeLearnerInvitations/CustomizeLearnerInvitations';

export function getButtonTheme(disableSaveButton: boolean) {
  return disableSaveButton ? ButtonType.LIGHT : ButtonType.BLUE;
}

export function getScenarioId(state: ICustomizeLearnerState) {
  return state?.requestTableFilters?.scenarioIds?.[0];
}

export function getButtonThemeByType (isCobalt: boolean, isDisabled: boolean) {
  return isCobalt ? getButtonTheme(isDisabled) : ButtonType.SPECIAL_RED;
}

export function getDisableStyle(isDisabled: boolean, disabledClass: string) {
  return isDisabled ? disabledClass : '';
}

export function getDisableButtonStyle(isCobalt: boolean, isDisabled: boolean, disabledClass: string) {
  return isCobalt ? getDisableStyle(isDisabled, disabledClass) : '';
}

export function getEmailCustomizedHistory(isCustomizeEmail: boolean | undefined, emailData: ICustomizeEmailSettings | null | undefined) {
  return isCustomizeEmail && emailData?.operatedOn && emailData?.operatedBy && emailData?.id;
}

export function getRequestTableFilters(state: ICustomizeLearnerState, selectedTeams: IClientUsersQuery | undefined) {
  return state?.requestTableFilters || selectedTeams;
}

export function getButtonDisabled(customizeLearnerInvitationsMsg: ICustomizeLearnerInvitationsMsg, isLimitExceeded: boolean, isCustomizeEmail: boolean | undefined, isInputValid: boolean) {
  const { senderName, subject, sendDate, customizeBtnText } = customizeLearnerInvitationsMsg;
  const isMsgIncomplete = !senderName.length || !subject.length || !sendDate?.valueOf();
  const isMsgLengthExceeded =
    subject.length > LERARNER_INVITATIONS_SUBJECT_MAX_LENGTH ||
    senderName.length > LERARNER_INVITATIONS_SENDER_NAME_MAX_LENGTH;

  if (isCustomizeEmail) {
    return isLimitExceeded || isMsgLengthExceeded;
  }

  const isCustomizeBtnLimitExceed = customizeBtnText.length > CUSTOMIZATION_BTN_TEXT_MAX_LENGTH;
  return isMsgIncomplete || !isInputValid || isLimitExceeded || isMsgLengthExceeded || !customizeBtnText || isCustomizeBtnLimitExceed;
}

export function generateConditionalClassForFooter(baseClass: any, condition:boolean | undefined, conditionalClass: any) {
  return condition ? `${baseClass} ${conditionalClass}` : baseClass;
}

export function getEmailTypeId(emailDataEmailTypeId: string | undefined, emailTypeId: string | null) {
  return (emailDataEmailTypeId ?? emailTypeId) || '';
}

export function getEmailEnabledStatus(emailEnabled: boolean | undefined) {
  return emailEnabled || false;
}

export function getEmailVersion(version: number | undefined) {
  return version || 0;
}

export function getArchiveStatusValue(isFromScenarioPage?: boolean, archive?: TListPageArchiveOption) {
  return isFromScenarioPage ? 'active' : archive;
}

export function isScenarioLevelInvitation(scenarioId: string | undefined, isFromScenarioPage?: boolean) {
  return scenarioId || isFromScenarioPage;
}
