import * as React from 'react';
import { FormControl, FormControlProps } from 'react-bootstrap';
import { connect } from 'react-redux';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import selectors from 'src/app/redux/selectors';
import actions from 'src/app/redux/store/actions';
import { IAppState } from 'src/app/redux/store/reducers';
import styles from '../IScenarioEditFormStyles.css';
import IRestError from 'src/app/data/common/interfaces/IRestError';
import cn from 'classnames';
import {injectIntl,WrappedComponentProps} from 'react-intl';
import getLabelForOption from 'src/app/data/common/utils/getLabelForOption';

export interface IIndustryFieldProps extends WrappedComponentProps {
  industryId: string;
  viewMode?: boolean;
  onChange: (industryId: string, needsRefresh?: boolean) => void;

  currentIndustry: INamedEntry | null;
  industries: INamedEntry[];
  fetched: boolean;
  fetching: boolean;
  fetchError: IRestError | null;
  fetchIndustry: (industryId: string) => any;
  fetchIndustriesList: () => any;
}

class IndustryField extends React.Component<IIndustryFieldProps> {

  public componentDidMount() {
    if (this.props.industryId) {
      this.props.fetchIndustry(this.props.industryId);
      return;
    }

    // Industries are the same across the application
    if (!this.props.industries.length) {
      this.props.fetchIndustriesList();
    }
  }

  public componentDidUpdate(prevProps: IIndustryFieldProps) {
    const setEditMode = prevProps.viewMode && !this.props.viewMode;
    const changedIndustryId = this.props.industryId && prevProps.industryId !== this.props.industryId;

    if (setEditMode && !this.props.industries.length) {
      this.props.fetchIndustriesList();
    }

    if (changedIndustryId) {
      this.props.fetchIndustry(this.props.industryId);
    }

  }

  public render() {
    const {
      viewMode,
      industryId,
      currentIndustry,
      intl
    } = this.props;

    const className = cn({
      [styles.propRowEdit]: viewMode,
      [styles.propRow]: !viewMode,
      [styles.formGroup]: true,
    });
    const props = {
      as: 'select' as React.ElementType,
      id: 'scenario-industry',
      onChange: this.onChange,
      disabled: viewMode,
      value: industryId,
    };

    const industryName = currentIndustry && currentIndustry.name || intl.formatMessage({ id: 'Projects.Message.UnnamedIndustry' });

    return (
      <div className={className}>
        {
          viewMode
            ? <span className={styles.propValue}>{industryName}</span>
            : (
              <FormControl {...props as FormControlProps}>
                {this.getIndustryOptions()}
              </FormControl>
            )
        }
      </div>
    );
  }

  private onChange = (event: any) => {
    const industryId = event.target.value || '';

    this.props.onChange(industryId, true);
  }

  private getIndustryOptions = () => {
    const { intl } = this.props;
    const options: any[] = [
      <option key={`industry-empty`} title={intl.formatMessage({ id: 'Projects.Placeholder.SelectAnIndustry' })} value=""
              disabled={true}>{getLabelForOption(intl.formatMessage({ id: 'Projects.Placeholder.SelectAnIndustry' }))}</option>,
    ];

    const items = this.props.industries
      .map((industry) => (
        <option
          key={`industry-${industry.id}`}
          title={industry.name}
          value={industry.id || ''}
        >
          {getLabelForOption(industry.name)}
        </option>
      ));

    return options.concat(items);
  }
}

export default injectIntl(connect(
  (state: IAppState) => ({
    currentIndustry: selectors.industries.getCurrentIndustry(state),
    industries: selectors.industries.getList(state),
    fetched: selectors.industries.isListFetched(state),
    fetching: selectors.industries.isRefreshing(state),
    fetchError: selectors.industries.getListRefreshError(state),
  }),
  {
    fetchIndustry: actions.industries.fetchIndustry,
    fetchIndustriesList: actions.industries.fetchIndustriesList,
  },
)(IndustryField));
