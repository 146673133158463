import { encode as base64encode } from 'base64-arraybuffer';

export async function isCodeVerifierValid(codeVerifier: string, codeChallenge: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  const base64Digest = base64encode(digest)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

  return base64Digest === codeChallenge;
}
