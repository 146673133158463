import * as React from 'react';
import _noop from 'lodash/noop';
import SessionWizardList from './SessionWizardList';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { SessionScenarioType } from 'src/app/data/session/interfaces/ISession';
import { IAggregatedPagedDataHookResult } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';

interface IScenarioTypeStepProps {
  companyName?: string | null;
  value: SessionScenarioType;

  onValueChange: (value: string, name: string) => void;
}

class ScenarioTypeStep extends React.Component<IScenarioTypeStepProps> {

  public componentDidMount() {
    const { value } = this.props;

    if (this.props.value) {
      this.props.onValueChange(value, this.getNameById(value));
    }
  }

  public render() {
    const items = this.getScenarioTypes();

    const { value } = this.props;
    const listProps: IAggregatedPagedDataHookResult<INamedEntry> = {
      items,
      loadMoreCallback: _noop,
      setPagedDataCallback: _noop,
      hasMore: false,
      refreshing: false,
      filter: '',
      total: items.length,
      currentPage: 0,
      error: null,
    };

    return (
      <SessionWizardList
        selectedItemId={value}
        onValueChange={this.props.onValueChange}
        nameGetter={this.nameGetter}
        hideSearch={true}
        emptyMessage={''}
        {...listProps}
      />
    );
  }

  private nameGetter = (entry: INamedEntry) => {
    return entry.name;
  }

  private getScenarioTypes = (): INamedEntry[] => {
    return [
      // MP-8016
      // {
      //   id: SessionScenarioType.TEMPLATE,
      //   name: `${this.props.companyName || ''} Scenario Bank`
      // },
      {
        id: SessionScenarioType.CLIENT,
        name: 'Client Scenario'
      }];
  }

  private getNameById = (id: SessionScenarioType): string => {
    const entry = this.getScenarioTypes().find(t => t.id === id);

    return entry ? this.nameGetter(entry) : '';
  }
}

export default ScenarioTypeStep;
