import actions from 'src/app/redux/store/actions';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { REPORT_PAGE_ID } from 'src/app/data/dashboardReport/interfaces/IReportPageId';
import moment from 'moment';
import { momentDateToTimestampMillis } from 'src/app/data/common/utils/dateUtil';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { IntlShape } from 'react-intl';
import { IDashboardReportQueryParams, IDashboardScheduleReports } from 'src/app/data/dashboardReport/interfaces/IDashboardReport';
import validateEmail from 'src/app/data/common/utils/validateEmail';
import { ReportSessionType } from 'src/app/data/dashboardReport/interfaces/ReportFiltersType';

export const requestUrl = (isSendReport:boolean)=>{
    return isSendReport ? actions.dashboardReport.createDashboardSendReport : actions.dashboardReport.createDashboardScheduleReport;
};

export const compareRecipientsArrays = (oldRecipientArray: string[] | undefined, newRecipientArray: string[])=> {
    return JSON.stringify(oldRecipientArray || []) === JSON.stringify(newRecipientArray);
};


export const commonPayload = (query:any, isSendReport?:boolean) => {
    const payloadObj = {
        archive: 'all',
        page: 0,
        size: 10,
        ...query
    };
    return {
        reportFilters: isSendReport ? JSON.stringify(payloadObj) : payloadObj
    };
};

export const ScheduleReportUpdatePayload = (scheduleReportId : string|undefined, clientID:string, recipients: INamedEntry[], selectedReportFrequencyValue:string|undefined, sessionValue:string|undefined, getReportType:string, reportStartDate:Date|undefined)=>{
    return {
        id: scheduleReportId,
        clientId: clientID,
        recipients: recipients.length ? recipients.map(v => v.id) : [],
        reportFrequency: selectedReportFrequencyValue,
        reportFrequencyType: sessionValue || null,
        reportType: REPORT_PAGE_ID[getReportType],
        startDate: momentDateToTimestampMillis(moment(reportStartDate)),
    };
};

export const dashboardReportQueryParams = (params : IDashboardReportQueryParams)=>{
    const {clientID, recipients, getReportType, reportStartDate, isSendReport, selectedReportFrequencyValue, sessionValue, scheduleName, scheduledBy, scheduledByRole} = params;
    return {
        clientId: clientID,
        recipients: recipients.length ? recipients.map(v => v.id) : [],
        reportType: REPORT_PAGE_ID[getReportType] ,
        startDate: momentDateToTimestampMillis(moment(reportStartDate || new Date())),
        scheduleName,
        scheduledBy,
        scheduledByRole,
        ...isSendReport && {reportScheduleType: "SEND_NOW"},
        ...!isSendReport && {reportFrequency : selectedReportFrequencyValue},
        ...!isSendReport && {reportFrequencyType: sessionValue || null},
    };
};

const checkIsSelectionChange = (data: IDashboardScheduleReports) => {

    const { recipients, fetchEditScheduleReport, selectedReportFrequencyVal, sessionVal, reportStartDate} = data;

    const newInputArray = JSON.stringify(recipients.map((val)=>val.id));
    const oldInputValue = JSON.stringify(fetchEditScheduleReport?.recipients);
    const reportFrequencyTypeData = selectedReportFrequencyVal || undefined;

    const getSessionValue = fetchEditScheduleReport?.reportFrequency;
    const getFrequencyValue = fetchEditScheduleReport?.reportFrequencyType;
    
    const getStartDate = fetchEditScheduleReport?.startDate;
    
    const momentReportStartDate = momentDateToTimestampMillis(moment(reportStartDate));
    const checkFrequencyType = reportFrequencyTypeData !== getFrequencyValue;
    const checkSession = sessionVal !== getSessionValue;
    const checkDate = momentReportStartDate !== getStartDate;

    return oldInputValue !== newInputArray || checkFrequencyType || checkSession || checkDate;

};

export const dashboardReportDialogText = (isSendReport:boolean, intl:IntlShape)=>{
    return{
      modalTitle : isSendReport ? intl.formatMessage({id: 'Dashboard.Report.SendReport'}) : intl.formatMessage({id: 'Dashboard.Report.ScheduleReport'}),
      modalBodyHeading : isSendReport ? '' : intl.formatMessage({id: 'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText'}),
      modalBody : isSendReport ? intl.formatMessage({id: 'Dashboard.Report.SendReport.ConfirmationModal.Text'}) : intl.formatMessage({id: 'Dashboard.Report.ScheduleReport.ConfirmationModal.Text'}),
      buttonLabel : intl.formatMessage({id: 'Dashboard.Report.ConfirmationModal.Button'})
    };
};

export const dashboardReportTitle = (isSendReport:boolean, isButtonText:boolean|undefined, fetchEditScheduleReport:boolean, showViewReport:boolean)=>{
    if(isSendReport){
        return TranslateMessage(`Dashboard.Report.SendReport`);
    }
    if(showViewReport){
        return TranslateMessage(`Dashboard.Report.ViewSchedule`);
    }
    if(fetchEditScheduleReport && !isButtonText){
        return TranslateMessage(`Dashboard.Report.EditSchedule`);
    }
    if(fetchEditScheduleReport && isButtonText){
        return TranslateMessage(`Dashboard.Report.EditSchedule.EditButton`);
    }
    return TranslateMessage('Dashboard.Report.ScheduleReport');
};

export const dashboardReportButtonConditions = (props:IDashboardScheduleReports)=>{
    const {isSendReport, recipients, fetchEditScheduleReport, selectedReportFrequencyVal, sessionVal, reportStartDate, isShowSelectSession } = props;
    if(isSendReport){
        return recipients.length<1;
    }
    const isChange = checkIsSelectionChange(props);
    const isDateChanged = !!reportStartDate;
    const isSession = !!sessionVal;
    const isFrequency = !!selectedReportFrequencyVal || !isShowSelectSession;
    if(fetchEditScheduleReport){
        return !(isChange && isFrequency && isSession && (isDateChanged || selectedReportFrequencyVal===ReportSessionType.ALL));
    }
    return !(recipients.length > 0 && isFrequency && isSession && (isDateChanged || selectedReportFrequencyVal===ReportSessionType.ALL));
};

export const isDashboardViewReportVisible = (response:any, profileId : string | undefined, userRoleId:string | undefined) =>{
    return (response.scheduledBy!==profileId || response.scheduledByRole!==userRoleId);
};

export const checkEmailValidation = (recipient:string, checkDuplicate:number,  intl:IntlShape)=>{
    if (checkDuplicate !== -1) {
        return intl.formatMessage({ id: 'Dashboard.Report.DuplicateEntry.ErrorMessage' });
    } 
    return validateEmail(recipient);
};
