export const scheduleReportRoute =  {
  DASHBOARD_CONTRACT_SUMMARIES_SCHEDULE_REPORT_ROUTE: '/dashboard-report/schedule/utilization/contract_summaries',
  DASHBOARD_CONTRACT_PROGRESS_ITEMS_SCHEDULE_REPORT_ROUTE: '/dashboard-report/schedule/utilization/contract_progress/items',
  DASHBOARD_CONTRACT_PROGRESS_PROJECTS_SCHEDULE_REPORT_ROUTE: '/dashboard-report/schedule/utilization/contract_progress/projects',
  DASHBOARD_CONTRACT_PROGRESS_SCENARIOS_SCHEDULE_REPORT_ROUTE: '/dashboard-report/schedule/utilization/contract_progress/scenarios',
  DASHBOARD_LEARNER_ACTIVITY_SCHEDULE_REPORT_ROUTE: '/dashboard-report/schedule/learners/learner_activity',
  DASHBOARD_LEARNER_SIMULATIONS_SCHEDULE_REPORT_ROUTE: '/dashboard-report/schedule/learners/learner_simulations',
  DASHBOARD_SIMULATION_SUMMARIES_SCHEDULE_REPORT_ROUTE: '/dashboard-report/schedule/simulations/summaries',
};

export const sendReportRoute = {
  DASHBOARD_CONTRACT_SUMMARIES_SEND_REPORT_ROUTE: '/dashboard-report/send/utilization/contract_summaries',
  DASHBOARD_CONTRACT_PROGRESS_ITEMS_SEND_REPORT_ROUTE: '/dashboard-report/send/utilization/contract_progress/items',
  DASHBOARD_CONTRACT_PROGRESS_PROJECTS_SEND_REPORT_ROUTE: '/dashboard-report/send/utilization/contract_progress/projects',
  DASHBOARD_CONTRACT_PROGRESS_SCENARIOS_SEND_REPORT_ROUTE: '/dashboard-report/send/utilization/contract_progress/scenarios',
  DASHBOARD_LEARNER_ACTIVITY_SEND_REPORT_ROUTE: '/dashboard-report/send/learners/learner_activity',
  DASHBOARD_LEARNER_SIMULATIONS_SEND_REPORT_ROUTE: '/dashboard-report/send/learners/learner_simulations',
  DASHBOARD_SIMULATION_SUMMARIES_SEND_REPORT_ROUTE: '/dashboard-report/send/simulations/summaries',
};

export const PROGRESS_SUBMENU = {
  ITEMS: 'items',
  PROJECTs: 'projects',
  SCENARIOS: 'scenarios',
};