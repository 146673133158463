import * as React from 'react';
import sessionWizardHooks
  from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { SessionWizardList } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/index';
import { ISessionWizardStepProps } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/SessionWizardList';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';

interface IClientStepProps extends ISessionWizardStepProps {
  scenarioVersion?: ScenarioVersion;
  training: boolean;
}

const ClientStep = (props: IClientStepProps) => {
  const { scenarioVersion, training } = props;
  const listProps = sessionWizardHooks.useActiveClients({ scenarioVersion, training })({ page: 0, size: 50 });

  return <SessionWizardList {...props} {...listProps} />;
};

export default ClientStep;
