import React, { FunctionComponent } from 'react';
import styles from 'src/layouts/common/SimAttendanceV2/SimAttendanceV2.css';
import { IntlShape, useIntl } from 'react-intl';
import { Card } from 'react-bootstrap';
import cn from 'classnames';
import { Radio, RadioGroup } from 'src/components/RadioGroup/RadioGroup';
import { getS3BucketURL } from 'src/s3bucketurls/S3BucketUrlUtils';
import { SessionUserStatus } from 'src/app/data/session/interfaces/ISession';
import getUserName from 'src/app/data/common/utils/getUserName';
import {
  getDisplayStatus,
  getSessionAttendanceDuration,
} from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/utils';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import { SESSION_STATUS_SHOW_USER_ATTENDANCE } from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/SessionEditForm';
import { ISessionAttendanceState } from 'src/layouts/common/SimAttendanceV2/SimAttendanceV2';
import { TLocaleId } from 'src/i18n';
import { SimSpecialistStatusType } from 'src/app/data/session/interfaces/SimSpecialistStatusType';

export const renderCardHeader = (labelId: TLocaleId, intl: IntlShape) => {
  return (
    <Card.Header bsPrefix={styles.simAttendanceCardHeader}>
      <img
        src={getS3BucketURL('images', 'app/supportIcon.svg')}
        alt={intl.formatMessage({ id: 'MursionPortal.Label.Learner' })}
      />
      {intl.formatMessage({ id: labelId })}
    </Card.Header>
  );
};

interface ISimAttendanceStatusCardProps {
  sessionAttendanceDetails: ISessionAttendanceState | null;
  userRole: IUserRoleExtended | null;
  onSimStatusChange: (status: SessionUserStatus) => void;
}

const SimAttendanceStatusCard: FunctionComponent<ISimAttendanceStatusCardProps> = ({
  sessionAttendanceDetails,
  userRole,
  onSimStatusChange,
}) => {
  const intl = useIntl();

  const displaySimSpecialistStatus = () => {
    const displayAttendance =
      userRole?.id !== RoleID.LEARNER &&
      sessionAttendanceDetails?.status &&
      SESSION_STATUS_SHOW_USER_ATTENDANCE.includes(sessionAttendanceDetails?.status);
    return !!displayAttendance;
  };

  const renderSimSessionStatus = () => {
    return sessionAttendanceDetails?.status === SessionStatusType.COMPLETED
      ? getSessionAttendanceDuration(sessionAttendanceDetails.simspecialist)
      : getDisplayStatus(displaySimSpecialistStatus(), sessionAttendanceDetails, sessionAttendanceDetails?.simspecialist?.status);
  };

  const showSimStatusToggle = () => {
    return (
      sessionAttendanceDetails?.simspecialist?.status === SimSpecialistStatusType.LEFT ||
      sessionAttendanceDetails?.simspecialist?.status === SimSpecialistStatusType.SIM_TECHNICAL_ERROR
    );
  };

  const renderSimStatusToggle = () => {
    return <RadioGroup
      name={'sim_status'}
      onChange={onSimStatusChange}
      selectedValue={sessionAttendanceDetails?.simStatus?.status}
    >
      <Radio
        value={SimSpecialistStatusType.LEFT}
        label={intl.formatMessage({ id: 'MursionPortal.SimAttendance.Left' })}
      />
      <Radio
        value={SimSpecialistStatusType.SIM_TECHNICAL_ERROR}
        label={intl.formatMessage({ id: 'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label' })}
      />
    </RadioGroup>;
  };

  const renderSimTechnicalError = () => {
    return <RadioGroup
      name={'sim_status'}
      onChange={onSimStatusChange}
      selectedValue={sessionAttendanceDetails?.simStatus?.status}
    >
      <Radio
        value={SimSpecialistStatusType.SIM_TECHNICAL_ERROR}
        label={intl.formatMessage({ id: 'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label' })}
      />
    </RadioGroup>;
  };

  const getCardClassName = () => {
    return showSimStatusToggle() ? styles.addAttendeesCard : styles.simCard;
  };

  const getSimStatusContent = () => {
    return showSimStatusToggle() ? (
      renderSimStatusToggle()
    ) : (
      <div className={styles.statusWrapper}>
        <div className={styles.status}>{renderSimSessionStatus()}</div>
        {(sessionAttendanceDetails?.simspecialist?.status === SimSpecialistStatusType.COMPLETED ||
          sessionAttendanceDetails?.simspecialist?.status === SimSpecialistStatusType.LEFT) && (
            <div>
              {renderSimTechnicalError()}
            </div>
          )}
      </div>
    );
  };

  return sessionAttendanceDetails?.simspecialist ? (
    <Card bsPrefix={cn(styles.simAttendanceCard, getCardClassName())}>
      {renderCardHeader('MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label', intl)}
      <Card.Body bsPrefix={styles.simAttendanceCardBody}>
        <div className={styles.simAttendanceBodyContent}>
          <div className={cn(styles.simAttendanceBodyContentCol, styles.simAttendanceBodyContentLeft)}>
            <div className={styles.label}>
              {intl.formatMessage({ id: 'MursionPortal.SessionAttendance.SimulationSpecialist' })}
            </div>
            <div className={styles.value}>{getUserName(sessionAttendanceDetails.simspecialist)}</div>
          </div>
          <div className={cn(styles.simAttendanceBodyContentCol, styles.simAttendanceBodyContentCenter)}>
            <div className={styles.label}>
              {intl.formatMessage({ id: 'MursionPortal.Dashboard.SimulationSpecialist.Status' })}
            </div>
            <div className={styles.value}>
              {getSimStatusContent()}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  ) : null;
};

export default SimAttendanceStatusCard;
