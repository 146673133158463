import React, { FunctionComponent } from 'react';
import styles from 'src/layouts/common/SimAttendanceV2/SimAttendanceV2.css';
import { Card } from 'react-bootstrap';
import cn from 'classnames';

export interface ISimAttendanceCardProps {
  content: string | JSX.Element;
  className?: string;
}

const SimAttendanceCard: FunctionComponent<ISimAttendanceCardProps> = (props) => {
  const { content, className } = props;

  return <Card bsPrefix={cn(styles.boxContent, className)}>{content}</Card>;
};

export default SimAttendanceCard;
