import moment from 'moment';
import { isCurrentUserProviderOrAdmin } from 'src/app/data/common/utils/userRoleUtils';
import { IUserRoleExtended } from 'src/app/data/profile/interfaces';
import Cookies from 'js-cookie';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';

const TOTP_EXPIRE_MINUTE = 5;
const TOTP_AUTHENTICATION_COOKIE_KEY = 'totp-authenticate';
export const TOTP_NUMBER_OF_DIGIT = 6;
export const ERROR_CODE_NOT_MATCH = 'NOT MATCHED';
export const ERROR_CODE_TIME_EXPIRED = 'OTP MATCH TIME EXPIRED';

interface ITOTPCookiesValue {
  totpVerificationRequired: boolean;
}

export function setTOTPAuthValue(totpVerificationRequired: boolean | undefined) {
  if (totpVerificationRequired !== undefined) {
    const authValue: ITOTPCookiesValue = { totpVerificationRequired };
    Cookies.set(TOTP_AUTHENTICATION_COOKIE_KEY, JSON.stringify(authValue));
  }
}

export function getTOTPAuthValue() {
  const totpAuthValue = Cookies.get(TOTP_AUTHENTICATION_COOKIE_KEY);
  if (totpAuthValue) {
    const parsedValue: ITOTPCookiesValue = JSON.parse(totpAuthValue);
    return parsedValue.totpVerificationRequired;
  }

  return false;
}

export function clearTOTPAuthValue() {
  Cookies.remove(TOTP_AUTHENTICATION_COOKIE_KEY);
}

export function isRidrectToTOTPStep(totpVerificationRequired: boolean, userRole: IUserRoleExtended | null) {
  return totpVerificationRequired && isCurrentUserProviderOrAdmin(userRole?.id);
}

export function getTime(userTimeZoneId: string) {
  return moment.tz(userTimeZoneId).add(TOTP_EXPIRE_MINUTE, 'minute').valueOf();
}

export function isValidationsError(error: IErrorState, errorMsgCode: string) {
  return error.code === 'validation' && error.validations[0].code === errorMsgCode;
}

export function hideEmail(email: string) {
  const parts = email.split('@');
  const hiddenUsername = '****' + parts[0].substring(parts[0].length - 3);
  const domainName = parts[1];
  return hiddenUsername + '@' + domainName;
}