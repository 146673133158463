import * as React from 'react';
import { useEffect, useState } from 'react';
import styles from './SessionWizardDropdownList.css';
import { IAggregatedPagedDataHookResult } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import useDebounce from 'src/hooks/useDebounce';
import { components, StylesConfig } from 'react-select';
import AsyncSelect  from 'react-select/async';
import { useIntl } from 'react-intl';
import greyTheme from 'src/components/Selector/themes/greyTheme';

export interface ISessionWizardStepProps {
  emptyMessage: string;
  selectedItemId: string;
  onValueChange: (selectedItemId: any, selectedItemName: any) => void;
  onUpdatedItems?: (items: any[]) => void;
  nameGetter: (item: any) => string;
}

interface IOptionProps {
  value: string | number | undefined;
  label: string;
}

export interface ISessionWizardList<T> extends ISessionWizardStepProps, IAggregatedPagedDataHookResult<T> {
  hideSearch?: boolean;
}

const SessionWizardDropdownList = <T extends { id?: string | number, name?: string }>(props: ISessionWizardList<T>) => {
  const {
    selectedItemId,
    onValueChange,
    items,
    loadMoreCallback,
    setPagedDataCallback,
    refreshing,
    nameGetter,
    total,
    hideSearch,
  } = props;
  const [filter, setFilter] = useState<string>('');
  const { value: debouncedFilter } = useDebounce(filter, 500);
  const intl = useIntl();

  useEffect(() => {
    if (props.onUpdatedItems) {
      props.onUpdatedItems(items);
    }
  }, [items]);

  useEffect(() => {
    if (items.length === 1 && !selectedItemId) {
      onValueChange(items[0].id, nameGetter(items[0]));
    }
  }, [selectedItemId, items, onValueChange]);

  useEffect(() => {
    setPagedDataCallback({ filter: debouncedFilter });
  }, [debouncedFilter]);

  const onChange = ({ value, label }: IOptionProps) => {
    onValueChange(value, label);
  };

  const [options, setOptions] = useState<IOptionProps[]>([]);
  const buildOptions = () => items.map((item: T) => ({ value: item.id, label: nameGetter(item) }));

  useEffect(() => {
    setOptions(buildOptions());
  }, [items]);

  const selectedValue = options.find((opt: IOptionProps) => opt.value === selectedItemId);

  const customStyles: StylesConfig<any, any> = {
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 1100,
    }),
    option: (provided: any) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px'
    }),
    control: (provided: any, state: any) => {
      return ({
        ...provided,
        boxShadow: 'inset 0 2px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        backgroundColor: '#FBF2E5',
        border: '1px solid #FFD09F',
        fontWeight: 600,
        color: state.menuIsOpen ? '#a4b0b9' : '#424242',
      });
    },
    menuList: (provided: any) => ({
      ...provided,
      zIndex: 10,
    }),
    container: (provided: any) => ({
      ...provided,
      width: '100%',
      flex: '0 1 120px',
      letterSpacing: '-1px',
      textAlign: 'center',
    }),
    indicatorSeparator: () => ({ display: 'inline-block' }),
    input: (provided: any) => ({
      ...provided,
      fontSize: '1rem',
      lineHeight: '1.6rem',
      letterSpacing: 'normal',
      display: 'flex',
      textAlign: 'center',
      margin: '0',
      padding: '0',
      overflow: 'visible',
      border: 'none',
      borderRadius: '0 !important',
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      lineHeight: '1.6rem',
      letterSpacing: '0px',
      whiteSpace: 'nowrap',
      fontSize: '1rem',
      padding: '0',
      textAlign: 'center',
      flex: 1,
      paddingLeft: '10px',
      display: 'inline-block',
      overflow: 'hidden',
      position: 'relative',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      width: '100%',
      textAlign: 'left',
      color: 'inherit',
    }),

  };

  useEffect(() => {
    setPagedDataCallback({ filter: debouncedFilter });
  }, [debouncedFilter]);

  const onScrollToBottom = () => {
    if (items.length < total) {
      loadMoreCallback();
    }
  };

  const handleInputChange = (newValue: string) => {
    setFilter(newValue);
    return newValue;
  };

  const onMenuClose = () => {
    setFilter('');
  };

  const customOption = (optionProps: any) => (
    <div title={optionProps?.data?.label}>
      <components.Option {...optionProps}/>
    </div>
  );

  const customValue = (innerProps: any) => (
    <div title={innerProps?.data?.label}>
      <components.SingleValue {...innerProps} />
    </div>
  );

  return (
    <div className={styles.dropdownContainer}>
      <AsyncSelect
        isSearchable={!hideSearch}
        cacheOptions={true}
        value={selectedValue}
        inputValue={filter}
        onChange={onChange}
        autoFocus={true}
        options={options}
        defaultOptions={options}
        isClearable={false}
        isDisabled={refreshing}
        placeholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.Dotted.Search' })}
        menuPlacement={'bottom'}
        onInputChange={handleInputChange}
        styles={customStyles}
        theme={greyTheme.customTheme}
        onMenuScrollToBottom={onScrollToBottom}
        isLoading={refreshing}
        onMenuClose={onMenuClose}
        maxMenuHeight={600}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        className={styles.innerContainer}
        components={{
          Option: customOption,
          SingleValue: customValue,
        }}
      />
    </div>
  );
};

export default SessionWizardDropdownList;
