import getUserName from 'src/app/data/common/utils/getUserName';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { ISession, ISessionLearner, ISessionUser, ISimSpecialist } from 'src/app/data/session/interfaces/ISession';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import useDebounce from 'src/hooks/useDebounce';
import SelectListItemPlain from 'src/components/SelectList/SelectListItemPlain';
import styles from './SessionSimSpecialistField.css';
import ErrorMessage from 'src/components/ErrorMessage';
import cn from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import IconButton from 'src/components/IconButton';
import Button from 'src/components/Button';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import actions from 'src/app/redux/store/actions';
import ScrollBar from 'src/components/ScrollBar';
import deleteIcon from './images/delete.png';
import { isCurrentUserClientUser } from 'src/app/data/common/utils/userRoleUtils';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import { getSessionAttendanceDuration, getDisplayStatus } from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/utils';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';

export interface ISessionSimSpecialistFieldProps {
  disabled?: boolean;
  training?: boolean;
  learners: ISessionLearner[];
  simSpecialist?: ISimSpecialist | null;
  onSimSpecChange: (item: ICompanyUser | null) => void;
  startDate: number;
  endDate: number;
  scenarioId: string;
  isScenarioTemplate: boolean;
  userRole: IUserRoleExtended | null;
  displaySimSpecialistStatus: boolean;
  sessionInfo: ISession | null;
}

export const SessionSimSpecialistPicker = (props: ISessionSimSpecialistFieldProps) => {
  const {
    scenarioId,
    training,
    startDate,
    endDate,
    disabled,
    onSimSpecChange,
    learners,
    simSpecialist,
    isScenarioTemplate,
    sessionInfo,
  } = props;

  const [initialSS] = useState(simSpecialist);

  const [nonSelectedSS, setNonSelectedSS] = useState<ICompanyUser[]>([]);
  const [filter, setFilter] = useState<string>('');
  const { value: debouncedFilter } = useDebounce(filter, 500);
  const [editing, setEditing] = useState(false);
  const [activeSS, setActiveSS] = useState<ICompanyUser[]>([]);
  const [refreshing, setRefreshing] = useState(true);
  const [error, setError] = useState<any>(null);
  const dispatch = useDispatch();
  const intl = useIntl();

  const fetchData = (filterString: string) => {
    setRefreshing(true);
    const pagedDate = {
      filter: filterString,
      page: 0,
      size: 50
    };
    isScenarioTemplate
      ?
      dispatch(actions.sessionWizard.fetchSimSpecsByScenarioTemplateId(pagedDate, scenarioId, startDate, endDate)).then((res) => {
        setActiveSS(res.content);
        setRefreshing(false);

      }).catch((e) => {
        setError(e);
        setRefreshing(false);

      })
      :
      dispatch(actions.sessionWizard.fetchSimSpecsByScenarioId(pagedDate, scenarioId, startDate, endDate, training)).then((res) => {
        setActiveSS(res.content);
        setRefreshing(false);

    
      }).catch((e) => {
        setError(e);
        setRefreshing(false);

      });
  };

  useEffect(() => {
    if (!!endDate && !!startDate) {
      fetchData('');
      }
  }, [endDate, startDate]);
  
  useEffect(() => {
      fetchData(debouncedFilter);
    }, [debouncedFilter]);
  
  const isInitialSimSpecFiltered = useMemo(() => {
    if (!initialSS) {
      return true;
    }

    const { firstName, lastName, email } = initialSS;

    return `${firstName} ${lastName} ${email}`.toLocaleLowerCase().includes(debouncedFilter.toLocaleLowerCase());
  }, [debouncedFilter, initialSS]);


  useEffect(() => {
    setNonSelectedSS(
      activeSS
        .concat(isInitialSimSpecFiltered && initialSS ? [initialSS] : [])
        // show only users which are not selected
        .filter(ss => ss.id !== simSpecialist?.id && !learners.some(l => l.id === ss.id))
    );
  }, [activeSS, simSpecialist?.id, isInitialSimSpecFiltered, initialSS, learners]);

  useEffect(() => {
    setFilter('');
  }, [editing]);

  const emptyMessage = training ? intl.formatMessage({ id: 'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers' })
    : intl.formatMessage({ id: 'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists' });
  const noSelectedMessage = training ? intl.formatMessage({ id: 'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers' })
    : intl.formatMessage({ id: 'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists' });

  const onChangeFilter = (e: any) => setFilter(e.target.value);

  const addSS = (ss: ICompanyUser) => () => {
    if (!ss || !ss.id) {
      return;
    }
    onSimSpecChange(ss);
  };

  const removeSS = () => {
    onSimSpecChange(null);
  };

  const NonSelectedSSRow = ({ index, style }: { index: number, style: any }) => {
    const ss = nonSelectedSS[index];
    const ssName = getUserName(ss);

    return (
      <div style={style} key={`ss-item-${ss.id}`} onClick={addSS(ss)} className={styles.selectListItem}>
        <div className={styles.listItemWrapper} title={`${ssName}`}>
          <span className={styles.listText}>{ssName}</span>
        </div>
        <span className={styles.addIcon}><i className={cn('fas', 'fa-plus', styles.icon)} /></span>
      </div>
    );
  };

  const toggleView = () => {
    setEditing(!editing);
  };

  const onAccept = () => {
    setEditing(false);
  };

  return (
    <>
      <div>
        {
          !editing &&
          <div className={styles.edit}>
            <IconButton
              label={intl.formatMessage({ id: 'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist' })}
              iconName='pencil-alt'
              onClick={toggleView}
            />
          </div>
        }
      </div>
      <ErrorMessage message={error?.message} className={styles.emptyMessage}/>
      {
        !disabled && editing &&
        <div className={cn(styles.listContainer, styles.learnersWrapper)} style={{ flex: '1' }}>
          <div className={styles.headingWrap}>
            <p>{sessionInfo?.training ?
               intl.formatMessage({ id: 'Session.Edit.Modal.Label.SelectTrainer' })
              : intl.formatMessage({ id: 'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist' })}</p>
            <div className={styles.acceptButtonWrapper}>
              <Button onClick={onAccept}>
                {TranslateMessage('MursionPortal.Close')}
              </Button>
            </div>
          </div>
          <div className={styles.learnerFilter}>
            <div className={styles.searchContainer}>
              <input
                className={styles.search}
                placeholder={intl.formatMessage({ id: 'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder' })}
                type="search"
                value={filter}
                onChange={onChangeFilter}
              />
            </div>
          </div>
          {
            !disabled && (
              nonSelectedSS.length
                ? (
                  <ScrollBar
                    scrollClass={styles.ScrollBarWrap}
                    contentClass={styles.scrollContent}
                    horizontal={false}>
                    <div style={{ flexGrow: 1, height: 42 * nonSelectedSS.length }}
                        className={styles.listWrapper}>
                      <AutoSizer>
                        {({ height, width }) => (
                          <List
                            itemCount={nonSelectedSS.length}
                            itemSize={42}
                            height={height}
                            width={width}
                          >
                            {NonSelectedSSRow}
                          </List>
                        )}
                      </AutoSizer>
                    </div>
                  </ScrollBar>
                )
                : refreshing
                ? (
                  <div>
                    <SelectListItemPlain refreshing={true}/>
                    <SelectListItemPlain refreshing={true}/>
                    <SelectListItemPlain refreshing={true}/>
                  </div>
                )
                : <div className={styles.emptyTimeBlock}>{emptyMessage}</div>
            )
          }
        </div>
      }
      {
        !!simSpecialist
          ? (
            <div className={styles.simSpecialisttableWrap}>
              <div className={styles.tableHeaderRow}>
                <div className={cn(styles.theadColumn, styles.width70)}>
                  {intl.formatMessage({ id: 'MursionPortal.Label.SIMSpecialist' })}
                </div>
                <div className={cn(styles.theadColumn, styles.width30)}>
                  {intl.formatMessage({ id: 'MursionPortal.Label.Status' })}
                </div>
              </div>
              <div className={styles.tableBodyRow}>
                <div className={cn(styles.tbodyColumn, styles.width70)}>
                  {getUserName(simSpecialist)}
                </div>
                <div className={cn(styles.tbodyColumn, styles.width30)}>
                  <div className={styles.statusRemoveBtnWrap}>
                    <span className={styles.status}>{`(${intl.formatMessage({ id: 'Mursion.Portal.Status.Pending' })})`}</span>
                    {
                      !disabled &&
                      <button onClick={removeSS} aria-label={intl.formatMessage({ id: 'MursionPortal.IconButton.Remove' })}><img src={deleteIcon} alt={intl.formatMessage({id:'MursionPortal.AriaImage.Attribute.Delete'})}/></button>
                    }
                  </div>
                </div>
              </div>
            </div>
          )
          : <span className={styles.emptyMessage}>{noSelectedMessage}</span>
      }
    </>
  );
};

const SessionSimSpecialistField = (props: ISessionSimSpecialistFieldProps) => {
  const {
    displaySimSpecialistStatus,
    userRole,
    simSpecialist,
    sessionInfo
  } = props;
  const intl = useIntl();

  const items: ISessionUser[] = [];
  if (!isCurrentUserClientUser(userRole) && !!simSpecialist) {
    items.push(simSpecialist);
  }
  const isSessionCompleted = (sessionInfo?.status === SessionStatusType.COMPLETED);

  if (props.disabled) {
    return props.simSpecialist
      ? 
        <div> {items.map((item, i) => {
          return (<div key={i} className={styles.simSpecialisttableWrap}>
            <div className={styles.tableHeaderRow}>
              <div className={cn(styles.theadColumn, styles.width70)}>
                {intl.formatMessage({ id: 'MursionPortal.Label.SIMSpecialist' })}
              </div>
              <div className={cn(styles.theadColumn, styles.width30)}>
                {isSessionCompleted ? intl.formatMessage({ id: 'Session.Attendance.Table.Column.Time' }) : intl.formatMessage({ id: 'MursionPortal.Label.Status' })}
              </div>
            </div>
            <div className={styles.tableBodyRow}>
              <div className={cn(styles.tbodyColumn, styles.width70)}>
                {getUserName(item)}
              </div>
              <div className={cn(styles.tbodyColumn, styles.width30)}>
                <div className={styles.statusRemoveBtnWrap}>
                  <div className={styles.status}>{isSessionCompleted ? getSessionAttendanceDuration(item) : getDisplayStatus(displaySimSpecialistStatus, sessionInfo, item.status)}</div>
                </div>
              </div>
            </div>
          </div>);
      })}
        </div> 
      : null;
  }

  return <SessionSimSpecialistPicker {...props}/>;
};

export default SessionSimSpecialistField;
