import React, { ComponentType, FunctionComponent, useMemo, useState, useCallback, useEffect } from 'react';
import styles from 'src/layouts/common/Dashboard/components/styles/Dashboard.css';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { TLocaleId } from 'src/i18n';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { IUserRoleExtended } from 'src/app/data/profile/interfaces';
import { CtaButton } from 'src/components';
import TranslateMessage from 'src/i18n/TranslateMessage';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { IPagedListControls } from 'src/components/PaginatedListWrapper/usePagedListControls';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import IRestCollectionInfo from 'src/app/data/common/interfaces/IRestCollectionInfo';
import { usePagedListFetchingWithOptions } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { TableTheme } from 'src/components/EntityTable/EntityTable';
import Pagination from 'src/components/Pagination/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import SendEmailDialog from 'src/layouts/common/Dashboard/components/DashboardUsers/SendEmailDialog/SendEmailDialog';
import { IDashboardChildData } from 'src/app/data/dashboard/IDashboardConfig';
import { IDashboardTableExtraData, IDashboardTableProps, IStickyColumns } from 'src/layouts/common/Dashboard/components/layout/DashboardLayout/DashboardTable';
import { ICompany } from 'src/app/data/licensee/interfaces/ICompany';
import { DASHBOARD_CHILD_PAGE } from 'src/layouts/common/Dashboard';
import DashboardHeaderPills from 'src/layouts/common/Dashboard/components/layout/DashboardLayout/DashboardHeaderPills';
import DashboardHeaderSearch from 'src/layouts/common/Dashboard/components/layout/DashboardLayout/DashboardHeaderSearch';
import { getDashbaordExportButtonTitle } from 'src/layouts/common/Dashboard/components/layout/DashboardLayout/DashboardHelperUtil';
import { RowSelectionType } from 'react-bootstrap-table-next';
import { ILearnerAndScenarioId, IReleaseLearnerQuery } from 'src/app/redux/modules/learner/rest';
import actions from 'src/app/redux/store/actions';
import { ILearnerLockedOut } from 'src/app/data/client/interfaces/learner';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import ClientUsersExportDialog from 'src/layouts/common/ClientUsers/components/ClientUsersExportDialog/ClientUsersExportDialog';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { IDashboardLearnersTableFilters } from 'src/layouts/common/Dashboard/components/DashboardLearners/filter/useLearnerActivityFilters';
import { isCurrentUserPS } from 'src/app/data/common/utils/userRoleUtils';
import { IClientContractItem } from 'src/app/data/client/interfaces/contract';

export interface ISearchParams {
  [key: string]: string | string[] | null | unknown;
  order: string;
  asc: boolean;
}

enum CONTRACT_PROGRESS_HEADER {
  'Dashboard.ContractProgress.Navigation.Scenarios' = 'Dashboard.ContractProgress.Navigation.Scenarios',
  'Dashboard.ContractProgress.Navigation.Projects' = 'Dashboard.ContractProgress.Navigation.Projects',
  'Dashboard.ContractProgress.Navigation.ContractItems' = 'Dashboard.ContractProgress.Navigation.ContractItems',
}
export interface IDashboardLayoutBaseProps extends IDashboardChildData {
  id: DASHBOARD_CHILD_PAGE;
  userRole: IUserRoleExtended;
  dialogTitle: TLocaleId;
  header?: TLocaleId;
  tableHeader?: TLocaleId;
  clientId: string;
  provider: ICompany | null;
  searchParams: ISearchParams;
}

export type RenderDetailDialogFn<Row = any> = (
  selectedRow: Row,
  onClose: () => void
) => JSX.Element | null;

interface ITableDataPlaceholder {
  tablePlaceholder: string;
  noDataTablePlaceholder: string;
}

type LearnerLockedOutRowType = ILearnerLockedOut & IDashboardTableExtraData<ILearnerLockedOut>;

interface IDashboardLayoutProps {
  // base
  header?: TLocaleId;
  userRole: IUserRoleExtended;
  searchParams: ISearchParams;
  timezoneId: string;
  hideExportButton?: boolean;

  // pills
  showPill?: boolean;
  pillSelectedAll?: boolean;
  pillValues?: INamedEntry[] | null;
  pillMaxWidth?: number;

  // search
  search?: string;
  onSearchChange: (val: string) => void;

  // charts
  renderChart?: () => JSX.Element | undefined;

  // filters
  filtersPanel?: JSX.Element | null;
  tableComponent: ComponentType<IDashboardTableProps<any, any>>;

  // table
  columns: any[];
  stickyColumns?: IStickyColumns;
  fetchAction: (query: any) => Promise<IListDataResponse<any>>;
  pagedListControls: IPagedListControls;
  /** API will not load if query is null/undefined, pass `{}` if you want to load an empty query */
  query: any;

  // dialog
  renderDetailDialog: RenderDetailDialogFn;

  // export
  exportAction: any;
  exportQuery: any;

  // Schedule Report 
  onRedirectScheduleReport?: () => void;
  onRedirectSendReport?: () => void;
  isExportEmail?: boolean;
  isEditScheduleReport?: boolean;
  isViewReportVisible?: boolean;

  // release learner
  isPageLearnerSchedulingLocked?: boolean;
  filterState?: IDashboardLearnersTableFilters;
  disableHeaderAction?: boolean;
  searchPlaceholder?: string;
  tablePlaceholder?: ITableDataPlaceholder;
  hasHourContractTypeItem?: boolean;
}

export const DashboardLayout: FunctionComponent<IDashboardLayoutProps> = (props) => {

  const {
    // base
    userRole,
    header,
    searchParams,
    timezoneId,

    // pills
    showPill = true,
    pillSelectedAll,
    pillValues,
    pillMaxWidth,

    // search
    search,
    onSearchChange,

    // chart
    renderChart,

    // filters
    filtersPanel,

    // table
    tableComponent: TableComponent,
    columns,
    stickyColumns,
    fetchAction,
    query,
    pagedListControls,

    // dialog
    renderDetailDialog,

    // export
    exportAction,
    exportQuery,
    hideExportButton,
    onRedirectScheduleReport,
    onRedirectSendReport,
    isExportEmail = false,
    isEditScheduleReport = false,
    isViewReportVisible = false,

    // release learner
    isPageLearnerSchedulingLocked,
    filterState,
    disableHeaderAction,
    searchPlaceholder,
    tablePlaceholder,
    hasHourContractTypeItem = false
  } = props;

  const { page, size, filter, onPagination, sizePerPageList } = pagedListControls;

  const intl = useIntl();
  const [showSendEmailDialog, setShowSendEmailDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  const [checkedLearners, setCheckedLearners] = useState<ILearnerAndScenarioId[]>([]);
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const [excludedLearners, setExcludedLearners] = useState<ILearnerAndScenarioId[]>([]);
  const [releaseAllLearners, setReleaseAllLearners] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ITEM_TYPE_TOTAL = 'Total';

  const { total, items, refreshing, setPagedDataCallback } = usePagedListFetchingWithOptions(fetchAction)({
    query
  })({
    page, size, filter,
    order: searchParams.order,
    asc: searchParams.asc,
    archive: 'all',
  }, 300, !!query);

  // region export ======================================================================================================
  let controller = new AbortController();
  const dispatch = useDispatch();
  const appConfig = useSelector(selectors.config.getConfig);
  const exportLimit = appConfig ? appConfig.dashboard_export_limit : null;

  const onSendEmail = useCallback(() => {
    setShowSendEmailDialog(!showSendEmailDialog);
    if (!exportAction || refreshing || !!exportLimit && total > exportLimit) {
      return;
    }

    controller.abort();
    controller = new AbortController();

    const defaultPayload = {
      page, size, filter,
      order: searchParams.order,
      asc: searchParams.asc,
      archive: 'all',
    };
    dispatch(exportAction({
      signal: controller.signal,
      query: { ...defaultPayload, ...exportQuery }
    }));
  }, [
    controller, exportAction, exportQuery,
    exportLimit, refreshing, total,
  ]);

  const isSendScheduleExportButtonDisable = useMemo(() => {
    return !exportAction || refreshing;
  }, [exportAction, refreshing]);

  const isHeaderSearchEnabled = useMemo(() => {
    if (header) {
      return !CONTRACT_PROGRESS_HEADER[header];
    }
    return false;
  }, [header]);

  const exportToEmailDialog = useMemo(() => {
    const hideEmailDialog = () => setShowSendEmailDialog(false);

    return (
      <SendEmailDialog
        modalProps={{
          onHide: hideEmailDialog,
          show: showSendEmailDialog,
          container: document.body,
          className: styles.modal,
          backdropClassName: styles.modalBackdrop
        }}
        limit={exportLimit}
        limitExceeded={!!exportLimit && total > exportLimit}
        onClose={hideEmailDialog}
      />
    );
  }, [showSendEmailDialog, total]);
  // endregion email

  // start region export locked learners
  const exportLockedLearnersDialog = useMemo(() => {

    return isCurrentUserPS(userRole) ? (
      <div className={styles.headerControls}>
        <ClientUsersExportDialog
          show={true}
          tableFilters={{
            clients: filterState?.clients || null,
            projects: filterState?.projects || null,
            scenarios: filterState?.scenarios || null,
            teams: filterState?.teams || null,
          }}
          query={query}
          exportAction={exportAction}
          themeType={SelectorTheme.Cobalt}
          isPageLearnerSchedulingLocked={isPageLearnerSchedulingLocked}
          searchParams={searchParams}
        />
      </div>
    ) : null;
  }, [query, total, exportAction, exportLimit, isPageLearnerSchedulingLocked, timezoneId, location]);
  // end region export loacked learners


  // region table & detail =============================================================================================
  const onSelectItem = (_: string, entity: any) => setSelectedRow(entity);

  const onUserDialogHide = () => setSelectedRow(null);

  const onForceOpenSessionInfoDialog = () => {
    const modalData = { simulationId: searchParams.simulation };
    setSelectedRow(modalData);
  };

  function onRowClick(row: LearnerLockedOutRowType, isSelect: boolean) {
    if (isSelect) {
      if (releaseAllLearners) {
        setExcludedLearners((prevExcludedLearners) =>
          prevExcludedLearners.filter(l => l.learnerId !== row.userRoleId)
        );
      } else {
        setCheckedLearners((prevLearners) =>
          [...prevLearners, { learnerId: row.userRoleId, scenarioId: row.scenarioId }]
        );
      }
      setCheckedRows((prevSelectedRows) => [...prevSelectedRows, row.fieldId]);
    } else {
      if (releaseAllLearners) {
        setExcludedLearners((prevExcludedLearners) =>
          [...prevExcludedLearners, { learnerId: row.userRoleId, scenarioId: row.scenarioId }]
        );
      }
      else {
        setCheckedLearners((prevLearners) =>
          prevLearners.filter(l => l.learnerId !== row.userRoleId)
        );
      }
      setCheckedRows((prevSelectedRows) => prevSelectedRows.filter(id => id !== row.fieldId));
    }
  }

  function getSelectRowProp() {
    if (isPageLearnerSchedulingLocked) {
      return {
        mode: 'checkbox' as RowSelectionType,
        selected: checkedRows,
        clickToSelect: true,
        onSelect: onRowClick,
        onSelectAll: (isSelect: boolean, rows: LearnerLockedOutRowType[]) => {
          if (isSelect) {
            setExcludedLearners([]);
            setReleaseAllLearners(true);
            setCheckedRows(rows.map((r: LearnerLockedOutRowType) => r.fieldId));
          } else {
            clearSelection();
          }
        }
      };
    }

    return undefined;
  }

  const getTablePlaceholder = () => {
    if (tablePlaceholder) {
      return !!query ? tablePlaceholder.noDataTablePlaceholder : tablePlaceholder.tablePlaceholder;
    }
    if (header) {
      return `${intl.formatMessage({ id: header })} ${intl.formatMessage({ id: 'MursionPortal.Dashboard.Table.Empty.Body.Message' })}`;
    }
    return null;
  };

  const table = useMemo(() => {
    if (!userRole) {
      return null;
    }

    const filterEntityInfo = (itemList: IClientContractItem[], hasContractTypeHours: boolean) => {
      return hasContractTypeHours ? itemList?.filter((data) => data.item !== ITEM_TYPE_TOTAL) : itemList;
    };

    const entitiesInfo: IRestCollectionInfo<any> = {
      items: !query ? [] : filterEntityInfo(items, hasHourContractTypeItem),
      fetched: !refreshing,
      fetchError: null,
      fetching: false,
    };

    const onRefreshing = () => {
      if (!query) {
        return false;
      }
      return refreshing || isLoading;
    };
    const headerMessage = getTablePlaceholder();

    return (
      <div className={styles.dialogContainer}>
        <LoadingOverlay active={onRefreshing()} spinner={!!query} isFallback={true}>
          <div className={styles.tableContainerWrapper}>
            <div className={styles.tableContainer}>
              <TableComponent
                entitiesInfo={entitiesInfo}
                userRole={userRole}
                onSelectItem={onSelectItem}
                columns={columns}
                stickyColumns={stickyColumns}
                timezoneId={timezoneId}
                header={headerMessage}
                query={query}
                selectRow={getSelectRowProp()}
              />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }, [items, total, refreshing, userRole, timezoneId, query, checkedLearners, checkedRows, isLoading]);
  // endregion table

  const countTotalRows = () => {
    return hasHourContractTypeItem ? total - 1 : total;
  };

  const rowCount: number = !query ? 0 : countTotalRows();
  // region pagination =================================================================================================
  const totalSize = `${intl.formatNumber(rowCount)} ${intl.formatMessage({ id: 'Dashboard.Total' })}`;
  // endregion pagination ==============================================================================================
  const scheduleButtonTitle = () => {
    if (isViewReportVisible) {
      return TranslateMessage('Dashboard.Report.ViewSchedule');
    }
    return isEditScheduleReport ? TranslateMessage('Dashboard.Report.EditSchedule') : TranslateMessage('Dashboard.Report.ScheduleReport');
  };

  const getExportCtaButton = () => {
    return (
      <div className={styles.headerControls}>
        {items.length ?
          <CtaButton
            disabled={isSendScheduleExportButtonDisable}
            title={getDashbaordExportButtonTitle(exportAction, intl)}
            onClick={onSendEmail}
          >
            {TranslateMessage('Dashboard.Button.ExportToEmail')}
          </CtaButton>
          :
          <CtaButton
            title={intl.formatMessage({ id: 'Dashboard.Button.ViewSession' })}
            onClick={onForceOpenSessionInfoDialog}
          >
            {TranslateMessage('Dashboard.Button.ViewSession')}
          </CtaButton>
        }
      </div>
    );
  };

  function clearSelection() {
    setCheckedLearners([]);
    setExcludedLearners([]);
    setCheckedRows([]);
    setReleaseAllLearners(false);
  }

  useEffect(() => {
    onPaginationChange(0, size);
  }, [query]);


  function onPaginationChange(pageNumber: number, pageSize: number) {
    clearSelection();
    onPagination(pageNumber, pageSize);
  }

  function closeConfirmationModal() {
    setShowConfirmationModal(false);
  }

  function releaseLearners() {
    closeConfirmationModal();
    setIsLoading(true);

    const { lockoutLevel, ...restQuery } = query;
    
    let releaseLearnerQuery: Partial<IReleaseLearnerQuery> = {};
    const releaseLevelQuery = {
      releaseLevel: lockoutLevel,
    };

    if (releaseAllLearners) {
      releaseLearnerQuery = {
        ...restQuery,
        ...releaseLevelQuery,
        excludeList: excludedLearners,
      };
    } else {
      releaseLearnerQuery = {
        ...releaseLevelQuery,
        lockedLearners: checkedLearners,
      };
    }

    dispatch(actions.learner.releaseLockedLearners(releaseLearnerQuery as IReleaseLearnerQuery)).then(() => {
      setShowSuccessModal(true);
      clearSelection();
      setPagedDataCallback();
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function onReleaseLearnerBtnClick() {
    setShowConfirmationModal(true);
  }

  function renderReleaseLearnerBtn() {
    return (
      <div className={styles.headerControls}>
        <CtaButton
          disabled={!(checkedLearners.length || (releaseAllLearners && (total - excludedLearners.length)))}
          onClick={onReleaseLearnerBtnClick}
          aria-label={intl.formatMessage({ id: 'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease' })}
        >
          {intl.formatMessage({ id: 'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease' })}
        </CtaButton>
      </div>
    );
  }

  function getNoOfLearnersToBeReleased() {
    return releaseAllLearners
      ? total - excludedLearners.length
      : checkedLearners.length;
  }

  const confirmationModals = useMemo(() => {
    const noOfLearnersToBeReleased = getNoOfLearnersToBeReleased();
    const bodyTextForConfirmationModal = intl.formatMessage(
      { id: 'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText' },
      { code: noOfLearnersToBeReleased }
    );
    const bodyTextForSuccessModal = intl.formatMessage(
      { id: 'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText' },
      { code: noOfLearnersToBeReleased }
    );

    function closeSuccessModal() {
      setShowSuccessModal(false);
    }

    return (
      <>
        {/* confirmation modal */}
        <ConfirmationModal
          show={showConfirmationModal}
          bodyText={bodyTextForConfirmationModal}
          onCancelModal={closeConfirmationModal}
          submitBtnTitle={intl.formatMessage({ id: 'MursionPortal.Message.Yes' })}
          onConfirm={releaseLearners}
          onOtherAction={closeConfirmationModal}
        />
        {/* success modal */}
        <ConfirmationModal
          show={showSuccessModal}
          bodyText={bodyTextForSuccessModal}
          onCancelModal={closeSuccessModal}
          confirmationTitle={intl.formatMessage({ id: 'MursionPortal.Button.Exclamation.GotIt' })}
        />
      </>
    );
  }, [showConfirmationModal, showSuccessModal]);

  function exportOrScheduleReport() {
    return isExportEmail && hideExportButton ? (
      getExportCtaButton()
    ) : !disableHeaderAction && (
      <>
        <div className={styles.headerControls}>
          <CtaButton disabled={isSendScheduleExportButtonDisable} onClick={onRedirectScheduleReport}>
            {scheduleButtonTitle()}
          </CtaButton>
        </div>
        {hideExportButton && (
          <div className={styles.headerControls}>
            <CtaButton disabled={isSendScheduleExportButtonDisable} onClick={onRedirectSendReport} onBlur={onSendEmail}>
              {TranslateMessage('Dashboard.Report.SendReport')}
            </CtaButton>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={cn(styles.container)}>
      <div className={styles.header}>
        <div className={styles.controlBar}>
          <h3 className={styles.title}>{header ? intl.formatMessage({ id: header }) : ''}</h3>
          {query && !disableHeaderAction &&
            <DashboardHeaderPills
              pillSelectedAll={pillSelectedAll}
              pillValues={pillValues}
              pillMaxWidth={pillMaxWidth}
              showPill={showPill}
              userRole={userRole}
            />}
          <div className={styles.search}>
            <DashboardHeaderSearch
              onSearchChange={onSearchChange}
              search={search}
              isHeaderSearchEnabled={isHeaderSearchEnabled}
              placeholder={searchPlaceholder}
            />
          </div>

          {/* Release Learner for Scheduling button */}
          {
            isPageLearnerSchedulingLocked ?
              <>
                {renderReleaseLearnerBtn()}
                {confirmationModals}
              </>
              : null
          }

          {isPageLearnerSchedulingLocked ? exportLockedLearnersDialog : exportOrScheduleReport()}
        </div>

        {(renderChart && query) ? renderChart() : null}

        <div className={styles.filtersPanel}>
          {filtersPanel}
        </div>
      </div>

      {table}

      {query ? (
        <LoadingOverlay active={refreshing}>
          <div className={styles.paginationContainer}>
            <div className={styles.total}>
              {totalSize}
            </div>
            {total > sizePerPageList[0] && (
              <Pagination
                compactView={true}
                disabled={refreshing}
                dropUp={'up'}
                page={page}
                sizePerPage={size}
                sizePerPageList={sizePerPageList}
                totalSize={total}
                refreshing={refreshing}
                onChange={onPaginationChange}
                themeType={TableTheme.Cobalt}
              />
            )}
          </div>
        </LoadingOverlay>
      ) : null}

      {exportToEmailDialog}

      {!!selectedRow && renderDetailDialog(selectedRow, onUserDialogHide)}
    </div>
  );
};
