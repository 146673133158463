import React, { FunctionComponent } from 'react';
import styles from 'src/layouts/common/SimAttendanceV2/SimAttendanceV2.css';
import { useIntl } from 'react-intl';
import { Card } from 'react-bootstrap';
import cn from 'classnames';
import { getS3BucketURL } from 'src/s3bucketurls/S3BucketUrlUtils';
import Button, { ButtonFont, ButtonSize, ButtonType } from 'src/components/Button';
import { ISessionWithHistoryInfo } from 'src/app/data/session/interfaces/ISession';
import moment from 'moment';
import { getDateAwareTimezoneTitle } from 'src/app/data/common/utils/dateUtil';
import { setSessionStatusLabelWithColorIcon } from 'src/app/data/session/interfaces/SessionStatusType';
import { LinkContainer } from 'react-router-bootstrap';
import { getScenarioRedirectionLinkForCompanyUser } from 'src/app/data/common/utils/getScenarioRedirectionLinkForClientUser';
import UseCopyToClipboard from 'src/hooks/useCopyToClipboard/useCopyToClipboard';

interface ISimAttendanceScenarioCardProps {
  sessionAttendanceDetails: ISessionWithHistoryInfo | null;
  simTimezoneId: string;
}

const SimAttendanceScenarioCard: FunctionComponent<ISimAttendanceScenarioCardProps> = ({
  sessionAttendanceDetails,
  simTimezoneId,
}) => {
  const intl = useIntl();
  const {
    statusLabel,
    startDate,
    endDate,
    clientId,
    clientName,
    projectId,
    projectName,
    scenarioId,
    scenarioName,
    generationType,
  } = sessionAttendanceDetails ?? {};

  const clientPrefix = clientId ? `/clients/${clientId}` : ``;
  const scenarioLink =
    projectId && scenarioId
      ? getScenarioRedirectionLinkForCompanyUser(generationType, clientPrefix, projectId, scenarioId)
      : null;
  const timeDifference = moment(endDate).diff(moment(startDate), 'minutes');

  const min = intl.formatMessage({ id: 'MursionPortal.Text.Min' });
  const mins = intl.formatMessage({ id: 'MursionPortal.Text.Mins' });
  const timeDifferenceLabel = timeDifference > 1 ? `${timeDifference} ${mins}` : `${timeDifference} ${min}`;
  const sessionStatus = setSessionStatusLabelWithColorIcon(statusLabel, intl);

  const getScenarioIcon = () => (
    <img
      src={getS3BucketURL('images', 'app/simAttendenceArrow.svg')}
      alt={intl.formatMessage({ id: 'MursionPortal.Label.Learner' })}
    />
  );

  return (
    <Card bsPrefix={cn(styles.simAttendanceCard, styles.scenarioCard)}>
      <Card.Header bsPrefix={styles.simAttendanceCardHeader}>
        <img
          src={getS3BucketURL('images', 'app/supportIcon.svg')}
          alt={intl.formatMessage({ id: 'MursionPortal.Label.Learner' })}
        />
        {intl.formatMessage({ id: 'MursionPortal.SessionAttendance.SessionStatus.Label' })}
      </Card.Header>
      <Card.Body bsPrefix={styles.simAttendanceCardBody}>
        <Card.Title bsPrefix={styles.simAttendanceCardTitle}>
          {clientId && clientPrefix && (
            <>
              <LinkContainer to={clientPrefix}>
                <a title={clientName}>{clientName}</a>
              </LinkContainer>
              {getScenarioIcon()}
            </>
          )}

          {projectId && (
            <>
              <LinkContainer to={`${clientPrefix}/projects/${projectId}`}>
                <a title={projectName}>{projectName}</a>
              </LinkContainer>
              {getScenarioIcon()}
            </>
          )}
          {scenarioId && scenarioLink && (
            <>
              <LinkContainer to={scenarioLink}>
                <a title={scenarioName}>{scenarioName}</a>
              </LinkContainer>
            </>
          )}
        </Card.Title>
        <div className={styles.simAttendanceBodyContent}>
          <div className={cn(styles.simAttendanceBodyContentCol, styles.simAttendanceBodyContentLeft)}>
            <div className={styles.label}>{intl.formatMessage({ id: 'Calendar.Button.Schedule.Session' })}</div>

            <div className={styles.value}>
              {`${moment(startDate).tz(simTimezoneId).format('dddd - MMMM DD, yyyy')} ${moment(startDate)
                .tz(simTimezoneId)
                .format('LT')} ${getDateAwareTimezoneTitle(simTimezoneId, startDate ?? 0)}- ${timeDifferenceLabel}`}
            </div>
          </div>
          <div className={cn(styles.simAttendanceBodyContentCol, styles.simAttendanceBodyContentCenter)}>
            <div className={styles.label}>
              {intl.formatMessage({ id: 'Dashboard.Learners.Column.SimulationStatus' })}
            </div>

            <div className={styles.value}>{sessionStatus.label}</div>
          </div>
          <div className={cn(styles.simAttendanceBodyContentCol, styles.simAttendanceBodyContentRight)}>
          {!!sessionAttendanceDetails?.id && (
              <UseCopyToClipboard
                value={sessionAttendanceDetails.id}
                messageTitle={intl.formatMessage({ id: 'Dashboard.Learners.Column.SimulationId' })}
                autoClose={3000}
              >
                {({ onClickToCopy }) => (
                  <Button
                    btnSize={ButtonSize.SMALL}
                    btnFont={ButtonFont.LIGHT}
                    onClick={onClickToCopy}
                    btnType={ButtonType.LIGHT_BLUE}
                    className={styles.copySessionBtn}
                    aria-label={intl.formatMessage({ id: 'Dashboard.Learners.Column.SimulationId.CopyHover' })}
                  >
                    {intl.formatMessage({ id: 'Dashboard.Learners.Column.SimulationId.CopyHover' })}
                  </Button>
                )}
              </UseCopyToClipboard>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SimAttendanceScenarioCard;
