import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isCurrentUserBorF, isCurrentUserClientUser, isCurrentUserLearner, isCurrentUserPSorOps } from 'src/app/data/common/utils/userRoleUtils';
import { selectors } from 'src/app/redux';
import BackButton from 'src/components/BackButton';
import styles from 'src/layouts/common/SimulationScheduling/SimulationScheduling.css';
import cn from 'classnames';
import ToggleButton from 'src/components/ToggleButton';
import CalendarInput from 'src/components/CalendarInput';
import { TimezoneDropdown } from 'src/components';
import {
  convertMomentToDate,
  getDateAwareTimezoneTitle,
} from 'src/app/data/common/utils/dateUtil';
import moment from 'moment';
import { ISelectorOption, SelectorTheme } from 'src/components/Selector';
import ErrorMessage from 'src/components/ErrorMessage';
import canCreateSessionWithoutLearners from 'src/app/data/session/utils/canCreateSessionWithoutLearners';
import { useHistory } from 'react-router';
import ROUTE_PATHS from 'src/routing/paths';
import {
  getTotalNoOfWeekends,
  getBufferTime,
  isClientGroupDelivery,
  isSchedulingTypeNotDemandBased,
  ITimeBlock,
  SchedulingType,
  getScenarioEndDateVal,
  showSelectionMessage,
  getName,
} from 'src/app/data/calendar/utils/simulationSchedulingUtil';
import ScenarioDetails from 'src/layouts/common/SimulationScheduling/components/ScenarioDetails/ScenarioDetails';
import DemandBasedTimeSlots from 'src/layouts/common/SimulationScheduling/components/DemandBasedSlots/DemandBasedTimeSlots';
import InstantSlotsInfo from 'src/layouts/common/SimulationScheduling/components/InstantSlotsInfo/InstantSlotsInfo';
import LearnerSimSelector from 'src/layouts/common/SimulationScheduling/components/LearnerSimSelector';
import SubmitSection from 'src/layouts/common/SimulationScheduling/components/SubmitSection/SubmitSection';
import SimulationSelectors from 'src/layouts/common/SimulationScheduling/components/SimulationSelectors';
import useFetchTimeBlocks from 'src/layouts/common/SimulationScheduling/useFetchTimeBlocks';
import IProject, { DemandWindowType } from 'src/app/data/projects/interfaces/IProject';
import IScenario from 'src/app/data/projects/interfaces/IScenario';
import { ISlot, SchedulingStepTab } from 'src/layouts/common/SimulationScheduling/SimulationScheduling';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import TimeRangeBlocks from 'src/layouts/common/SimulationScheduling/components/TimeRangeBlocks/TimeRangeBlocks';
import getUrlParamValue from 'src/app/data/common/utils/queryParamsAccessor';
import { parseInt } from 'lodash';
import errorsActions from 'src/app/redux/modules/errors/actions';
import RestError from 'src/app/services/rest/RestError';
import { isWeekEnd } from 'src/app/data/common/utils/calendarUtils';
import LearnerPersonalizationMessage from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/LearnerPersonalizationMessage/LearnerPersonalizationMessage';
import { PersonalizationMessageType } from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/PersonalizationCommunicationContainer';

export const maxDescriptionLength = 200;
export const MAX_TIMESTAMP = 8600000000000000;

interface ISchedulingFlowProps {
  redirectedFrom: string;
  onProjectSelect: (project: ISelectorOption<any>) => void;
  setLoading: (val: boolean) => void;
  isLoading: boolean;
  onScenarioSelect: (scenario: ISelectorOption<any>) => void;
  projectInfo: IProject | null;
  scenarioInfo: IScenario | null;
  refreshState: () => void;
  setClientName: (val: string) => void;
  getScenarioLink: () => string;
  timeSlots: ISlot[];
  selectedTime: ITimeBlock | null;
  setSelectedTime: (tb: ITimeBlock) => void;
  setTimeSlots: (slots: ISlot[]) => void;
  selectedDate: moment.Moment | null;
  codeOfConductSelected: boolean;
  isSimSpecialist: ICompanyUser | null;
  learners: ILearnerExtended[];
  setCurrentTab: (tab: SchedulingStepTab) => void;
  isCurrentTab: SchedulingStepTab;
  setErrorMessage: (msg: string) => void;
  onDateChange: (date: moment.Moment) => void;
  selectedTimezone: string;
  errorMessage: string;
  onSubmit: () => void;
  setCodeOfConductSelected: (val: boolean) => void;
  onTimeZoneChange: (tz: string) => void;
  isSecondPossibleDay: moment.Moment | null;
    setTime: (time: ITimeBlock | null) => void;
  setLearners: (learners: ILearnerExtended[]) => void;
  setSimSpecialist: (sim: ICompanyUser) => void;
  onlyUnauthenticatedLearners: boolean;
  setOnlyUnauthenticatedLearners: (val: boolean) => void;
  schedulingType: SchedulingType;
  setShowRequestSlots: (val: boolean) => void;
  showRequestSlots: boolean;
  setSecondPossibleDay: (date: moment.Moment) => void;
  dbsSlotsFilled: boolean;
  demandBasedSlotsCount: number;
  sessionID: string;
  disableDropDowns: boolean;
  setAreSlotsCalculated: (val: boolean) => void;
}

const SchedulingFlow: FunctionComponent<any> = (props: ISchedulingFlowProps) => {
  const intl = useIntl();
  const calendarDivRef = useRef<HTMLDivElement | null>(null);
  const [isSelectedMonth, setSelectedMonth] = useState<moment.Moment | null>(null);
  const [isScenarioResponse, setScenarioResponse] = useState<boolean>(false);
  const [finalShowAvailabilityEndDate, setFinalShowAvailabilityEndDate] = useState<moment.Moment | null>(null); // end date until which sim availability needs to be fetched after excluding weekends and restrictions and adding upcoming slots time
  const [areSlotsAvailable, setAreSlotsAvailable] = useState<boolean>(false);
  const [actualUpcomingAvailabilityPeriod, setActualUpcomingAvailabilityPeriod] = useState<number>(0);
  const dispatch = useDispatch();
  const userTimeZoneId = useSelector(selectors.profile.getUserTimeZoneId);

  const {
    redirectedFrom,
    onProjectSelect,
    setLoading,
    isLoading,
    onScenarioSelect,
    projectInfo,
    scenarioInfo,
    refreshState,
    setClientName,
    getScenarioLink,
    timeSlots,
    selectedTime,
    setSelectedTime,
    setTimeSlots,
    selectedDate,
    codeOfConductSelected,
    isSimSpecialist,
    learners,
    setCurrentTab,
    isCurrentTab,
    setErrorMessage,
    onDateChange,
    selectedTimezone,
    errorMessage,
    onSubmit,
    setCodeOfConductSelected,
    onTimeZoneChange,
    isSecondPossibleDay,
    setTime,
    setLearners,
    setSimSpecialist,
    onlyUnauthenticatedLearners,
    setOnlyUnauthenticatedLearners,
    schedulingType,
    setShowRequestSlots,
    showRequestSlots,
    setSecondPossibleDay,
    dbsSlotsFilled,
    demandBasedSlotsCount,
    sessionID,
    disableDropDowns,
    setAreSlotsCalculated,
  } = props;

  const setNextPossibleDate = (time: ITimeBlock) => {
    onDateChange(isSecondPossibleDay || moment());
    setTime(time);
  };

  const getMinDate = (): moment.Moment => {
    const demandWindow = moment().tz(selectedTimezone).add(bufferTime, 'ms');

    // earliest date for scheduling calculated on the basis of scheduling window should exclude weekends (only for client roles)
    if (isCurrentUserClientUser(userRole)) {
      const daysToExclude = getTotalNoOfWeekends(moment().tz(selectedTimezone), demandWindow.clone(), selectedTimezone);

      demandWindow.add(daysToExclude, 'd');
    }

    // following check is to handle scheduling requests for scenarios which are in finalized state and not yet started
    const scenarioStartDate = moment(scenarioInfo?.planning.startDate).tz(selectedTimezone);
    if (demandWindow.isBefore(scenarioStartDate)) {
      return scenarioStartDate;
    }
    return demandWindow;
  };

  const companyConfig = useSelector(selectors.companyConfig.getCompanyConfig);

  const userRole = useSelector(selectors.profile.getCurrentUserRole);

  const bufferTime = useMemo((): number => {
    return getBufferTime({
      schedulingType,
      userRole,
      companyConfigDisabledTime: companyConfig?.session.scheduleDisabledTime,
      sameDayRescheduling: !!projectInfo?.sameDayRescheduling,
      demandWindow: projectInfo?.demandWindow || 0,
      demandWindowType: projectInfo?.demandWindowType || DemandWindowType.DAYS,
    });
  }, [projectInfo, schedulingType, companyConfig, userRole, isSelectedMonth, selectedTimezone]);
  
  const verifySlotFallsOutOfDemandWindow = (startTime: number, demandWindowTime: number) => {
    if (startTime - moment().tz(selectedTimezone).valueOf() < demandWindowTime) {
      const error = new RestError(intl.formatMessage({ id: 'MursionPortal.Scheduling.TimeSlotsUnavailableMessage' }), 1); // passing status code as 1 just to have 'Refresh' button on Error modal
      dispatch(errorsActions.throwError(error));
      return false;
    }
    return true;
  };

  const submit = () => {
    const demandWindowTime = isCurrentUserPSorOps(userRole) ? 0 : bufferTime;

    if (schedulingType === SchedulingType.EMERGENCY || selectedTime?.isAvailable) {
      if (!verifySlotFallsOutOfDemandWindow(selectedTime?.startTime.valueOf() || 0, demandWindowTime)) {
        return;
      }
    } else {
        const slots:ISlot[]= timeSlots.slice(0, demandBasedSlotsCount);
        for (const slot of slots) {
            const slotStartTime:number = slot.startTime?.valueOf() || 0;
            if (!verifySlotFallsOutOfDemandWindow(slotStartTime, demandWindowTime)) {
                return;
            }
        }
    }
    onSubmit();
  };
  
  const history = useHistory();

  const onBackToDashboardHandler = () => {
    switch(redirectedFrom) {
      case 'scenarioCardPage':
        history.push(getScenarioLink());
        break;
      case 'dashboardPage':
        history.push(ROUTE_PATHS.DASHBOARD);
        break;
      default:
        history.push(ROUTE_PATHS.CALENDAR); 
        break;
    }
  };
  const onCodeOfConductChecked = (event: any) => {
    setCodeOfConductSelected(event.target.checked);
  };

  const isPsOrOps = isCurrentUserPSorOps(userRole);
  const showTzDropDown = isPsOrOps || isCurrentUserBorF(userRole);

  const showAllLearnersAreExternalCheckbox = useMemo(() => {
    if (scenarioInfo && userRole) {
      return canCreateSessionWithoutLearners(
        userRole,
        scenarioInfo.draft.deliveryMode,
        scenarioInfo.draft.scenarioVersion
      );
    } else {
      return false;
    }
  }, [scenarioInfo, userRole]);

  const enableSubmitButton = (): boolean => {
    const isLearnerOrBorFWithLearners = (isCurrentUserLearner(userRole)
      || (isCurrentUserBorF(userRole) && (showAllLearnersAreExternalCheckbox 
      || !!learners.length || isClientGroupDelivery(userRole, scenarioInfo?.draft.deliveryMode))));
    if (schedulingType === SchedulingType.RESCHEDULING || selectedTime?.isAvailable) {
      return isLearnerOrBorFWithLearners && !!selectedTime && codeOfConductSelected;
    }
    if (schedulingType === SchedulingType.EMERGENCY) {
      return !!isSimSpecialist
        && (showAllLearnersAreExternalCheckbox || !!learners.length)
        && codeOfConductSelected;
    }
    return (
      isLearnerOrBorFWithLearners &&
      dbsSlotsFilled &&
      codeOfConductSelected);
  };

  const enableLearnerStep = (): boolean => {
    if (schedulingType === SchedulingType.EMERGENCY || schedulingType === SchedulingType.INSTANT) {
      return !!selectedTime;
    } else if (schedulingType === SchedulingType.DEMAND_BASED) {
      return !!(dbsSlotsFilled || selectedTime?.isAvailable);
    }
    return false;
  };

  const schedulingSteps = useMemo(() => {
    const options = [
      {
        label: intl.formatMessage({ id: 'MursionPortal.Scheduling.Label.DateTimeTab' }),
        value: 'dateAndTimeStep',
        disabled: false,
        role: 'tab',
      },
      {
        label: intl.formatMessage({ id: 'MursionPortal.Label.Learner' }),
        value: 'learnerSelectionStep',
        disabled: !enableLearnerStep(),
        role: 'tab',
      },
    ];
    if (isCurrentUserPSorOps(userRole)) {
      options.push({
        label: intl.formatMessage({ id: 'MursionPortal.Label.SimSpecialist' }),
        value: 'simSelectionStep',
        disabled: !selectedTime,
        role: 'tab',
      });
    }
    return options;
  }, [selectedTime, showRequestSlots, dbsSlotsFilled, learners, isSimSpecialist]);

  const handleSchedulingStepChange = (value: 'dateAndTimeStep' | 'simSelectionStep' | 'learnerSelectionStep') => {
    setCurrentTab(value);
  };

  const monthStart = useMemo(() => {
    const selectedStartDateOfMonth = isSelectedMonth?.clone().startOf('month');
    const currentDateOfMonth = getMinDate();
    const sameDayReschedule = !!projectInfo?.sameDayRescheduling;
    if (selectedStartDateOfMonth?.isBefore(currentDateOfMonth)) {

    // Re calculate start date as 24hrs from current time if scheduling type is reschedule(when rescheduling session enabled) and same day is unchecked at project level
      if(schedulingType === SchedulingType.RESCHEDULING && !sameDayReschedule){
        return moment.tz(userTimeZoneId).add(1, 'd');
      }
      return currentDateOfMonth.clone().seconds(0).millisecond(0);
    } else {
      return selectedStartDateOfMonth?.clone().seconds(0).millisecond(0);
    }
  }, [isSelectedMonth, selectedTimezone, schedulingType, projectInfo?.sameDayRescheduling]);

  const scenarioEndDate = useMemo(() => {
    return moment(scenarioInfo?.planning.endDate).tz(selectedTimezone);
  }, [scenarioInfo?.planning.endDate, selectedTimezone]);

  const monthEnd = useMemo(() => {
    const selectedEndDateOfMonth = isSelectedMonth?.clone().endOf('month');
    if (scenarioEndDate?.isBefore(selectedEndDateOfMonth)) {
      return scenarioEndDate;
    } else {
      return selectedEndDateOfMonth;
    }
  }, [isSelectedMonth, scenarioEndDate, monthStart]);

  useEffect(() => {
    setSelectedMonth(null);
    if (projectInfo && scenarioInfo) {
      const dateInQueryParam = getUrlParamValue('date', location);
      if (dateInQueryParam) {
        setSelectedMonth(moment(parseInt(dateInQueryParam, 10)));
      } else {
        setSelectedMonth(getMinDate());
      }
    }
  }, [projectInfo, selectedTimezone, scenarioInfo]);

  const getMinStartDate = useMemo(() => {
    return getMinDate().clone().seconds(0).millisecond(0);
  }, [monthStart, scenarioInfo?.id]);

  const getShowAvailabilityEndDateExcludingWeekends = useMemo(() => {
    if (isCurrentUserPSorOps(userRole) || !projectInfo?.showAvailability) {
      return moment(MAX_TIMESTAMP);
    }

    let minStartDate = getMinStartDate.clone();

    if (isWeekEnd(minStartDate.valueOf(), selectedTimezone)) {
      minStartDate = minStartDate.startOf('d');
    }

    const daysToSkip = getTotalNoOfWeekends(minStartDate.clone(), minStartDate.clone().add(projectInfo?.showAvailability, 's'), selectedTimezone);

    return minStartDate.add(projectInfo?.showAvailability, 's').add(daysToSkip, 'd');
  }, [monthStart, scenarioInfo?.id]);

  const { timeBlocks, learnerAvailability, excludedDates, isResponse } = useFetchTimeBlocks({
    startDate: monthStart,
    endDate: monthEnd,
    timezone: selectedTimezone,
    scenario: scenarioInfo,
    project: projectInfo,
    setLoading,
    schedulingType,
    selectedDate,
    secondPossibleDay: isSecondPossibleDay,
    showAvailabilityEndDateExcludingWeekends: getShowAvailabilityEndDateExcludingWeekends,
    minStartDate: getMinStartDate,
    setActualUpcomingAvailabilityPeriod,
    finalShowAvailabilityEndDate,
    setFinalShowAvailabilityEndDate,
    scheduleType: schedulingType,
    setAreSlotsCalculated,
  });

  const isTodaySelected = useMemo(() => {
    return moment(selectedDate).tz(selectedTimezone).isSame(moment().tz(selectedTimezone), 'day');
  }, [selectedDate, selectedTimezone]);

  const isScenarioLoaded = useMemo(() => {
    return !!scenarioInfo?.draft;
  }, [scenarioInfo]);

  const renderNextDayTimeSlots =
    (schedulingType === SchedulingType.RESCHEDULING || schedulingType === SchedulingType.EMERGENCY) && isTodaySelected && isSecondPossibleDay;

  useEffect(() => {
      if (!selectedDate && timeBlocks.length && !isLoading) {
        const dateInQueryParam = getUrlParamValue('date', location);
        let dateToSelect = null;
        if (dateInQueryParam) {
          dateToSelect = timeBlocks.find((timeBlock) => {
            return timeBlock.date === parseInt(dateInQueryParam, 10);
          });
        } else {
          dateToSelect = timeBlocks.find((timeBlock) => {
            return timeBlock.timeSlots.length;
          });  
        }
        if (dateToSelect?.date) {
          const momentDateToSelect = moment(dateToSelect.date).tz(selectedTimezone);
          onDateChange(momentDateToSelect);
        }
      }
  }, [timeBlocks, isLoading]);
  
  useEffect(() => {
    if (!isSecondPossibleDay && selectedDate && (schedulingType === SchedulingType.RESCHEDULING || schedulingType === SchedulingType.EMERGENCY) && isTodaySelected) {
      const secondPossibleDay = timeBlocks.find((timeBlock) => {
        return timeBlock.date > selectedDate?.valueOf() && timeBlock.timeSlots.length;
      });  
      setSecondPossibleDay(moment(secondPossibleDay?.date).tz(selectedTimezone));
    }
  }, [isTodaySelected, schedulingType]);

  useEffect(() => {
    if(!!projectInfo){
      setScenarioResponse(true);
    }else{
      setScenarioResponse(false);
    }
  },[projectInfo, scenarioInfo]);
  
  const SelectFieldsJumbotron = () => {
    return isScenarioResponse ? <div/> : (
      <div className={styles.jumbotron}>
        <div>{intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.Jumbotron.Text' })} </div>
      </div>
    );
  };


  const backToPageLabel = (): string => {
    if(redirectedFrom === 'scenarioCardPage') {
      return intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario' });
    }
    else if(redirectedFrom === 'dashboardPage') {
      return intl.formatMessage({ id: 'MursionPortal.Dashboard.BackToDashboard' });
    }
    else {
      return intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar' });
    }
  };

  const getScenarioEndDate = useMemo(() => {
    if (!isCurrentUserClientUser(userRole) || !projectInfo?.showAvailability) {
      return scenarioEndDate;
    }

    return getScenarioEndDateVal(finalShowAvailabilityEndDate, scenarioEndDate);
  }, [finalShowAvailabilityEndDate, selectedTimezone, scenarioEndDate]);

  function getSelectionMessageOrTabs() {
    if (showSelectionMessage(isCurrentUserLearner(userRole), areSlotsAvailable)) {
      return (
        <div className={styles.description}>
          {schedulingType === SchedulingType.RESCHEDULING &&
            intl.formatMessage({ id: 'MursionPortal.Rescheduling.SlotSelectionMessage' })}
          {schedulingType === SchedulingType.INSTANT &&
            intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage' })}
          {schedulingType === SchedulingType.DEMAND_BASED &&
            intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.SlotSelectionMessage' })}
        </div>
      );
    }

    return (
      <div className={styles.description}>
        <ToggleButton
          options={schedulingSteps}
          themeType={SelectorTheme.Cobalt}
          className={styles.dateTimeLearnerSimTabWrap}
          onChange={handleSchedulingStepChange}
          value={isCurrentTab}
        />
      </div>
    );
  }

  function renderNextDayTimeRangeBlocks() {
    if (renderNextDayTimeSlots) {
      return (
        <div className={styles.timeRangeWrap}>
          <div className={styles.dayInfo}>
            {`${isSecondPossibleDay ? isSecondPossibleDay.clone().calendar().split(' at')[0] : ''
              }, ${isSecondPossibleDay?.format('dddd - MMMM D, YYYY')}`}{' '}
          </div>
          <TimeRangeBlocks
            scheduleType={schedulingType}
            timeBlocks={timeBlocks}
            learnerAvailability={learnerAvailability}
            selectedDate={isSecondPossibleDay}
            timeSlots={timeSlots}
            selectedTime={selectedTime}
            setTime={setNextPossibleDate}
            userTimezoneId={selectedTimezone}
            setShowRequestSlots={setShowRequestSlots}
            isResponse={isResponse}
          />
        </div>
      );
    }

    return null;
  }

  function renderBookingSlots() {
    if (schedulingType === SchedulingType.DEMAND_BASED) {
      if (showRequestSlots) {
        return (
          <DemandBasedTimeSlots
            demandBasedSlotsCount={demandBasedSlotsCount}
            timeSlots={timeSlots}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            setTimeSlots={setTimeSlots}
            selectedDate={selectedDate}
            setErrorMessage={setErrorMessage}
            calendarDivRef={calendarDivRef}
          />
        );
      }

      return <InstantSlotsInfo />;
    }

    return null;
  }

  function renderSimSelector() {
    if (isCurrentTab !== 'dateAndTimeStep') {
      return (
        <LearnerSimSelector
          selectedLearners={learners}
          setSelectedLearners={setLearners}
          selectedSimSpecialist={isSimSpecialist}
          setSimSpecialist={setSimSpecialist}
          onlyUnauthenticatedLearners={onlyUnauthenticatedLearners}
          setOnlyUnauthenticatedLearners={setOnlyUnauthenticatedLearners}
          sessionStartAndEndDate={{
            startDate: dbsSlotsFilled
              ? timeSlots[0].startTime?.valueOf() || 0
              : selectedTime?.startTime.valueOf() || 0,
            endDate: dbsSlotsFilled
              ? timeSlots[0].endTime?.valueOf() || 0
              : selectedTime?.endTime.valueOf() || 0,
          }}
          sessionSize={scenarioInfo?.draft.sessionSize || 1}
          showAllLearnersAreExternalCheckbox={showAllLearnersAreExternalCheckbox}
          currentTab={isCurrentTab}
          setLoading={setLoading}
          scenarioId={scenarioInfo?.id || ''}
          handleSchedulingStepChange={handleSchedulingStepChange}
          schedulingType={schedulingType}
          isClientGroupDelivery={isClientGroupDelivery(userRole, scenarioInfo?.draft.deliveryMode)}
        />
      );
    }

    return null;
  }

  function renderTzDropDownOrTzLabel() {
    return showTzDropDown
      ? (
        <TimezoneDropdown
          timezoneId={selectedTimezone}
          onChange={onTimeZoneChange}
          classNamePrefix={'schedulingTimeZoneDropdown'}
        />
      )
      : (
        <div
          className={styles.timezoneTooltipWrap}
          tabIndex={0}
          aria-describedby='demandBasedSessionTimezoneTooltipDescription'
        >
          {intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.Timezone' })}:{' '}
          {getDateAwareTimezoneTitle(selectedTimezone, new Date().getTime())}
          <div className={styles.tooltip}>
            <div id='demandBasedSessionTimezoneTooltipDescription'>
              {intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.Timezone.Tooltip' })}
            </div>
          </div>
        </div>
      );
  }

  function getPageTitle() {
    return schedulingType === SchedulingType.RESCHEDULING
      ? intl.formatMessage({ id: 'MursionPortal.Rescheduling.RescheduleSimulation' })
      : intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.ScheduleASession' });
  }

  function getTodayText() {
    return isTodaySelected
      ? selectedDate?.clone().calendar().split(' at')[0]
      : '';
  }

  function getDateOtherThanToday() {
    return !isTodaySelected
      ? selectedDate?.format('dddd - MMMM D, YYYY')
      : '';
  }


  return (
    <div>
      <div className={styles.headingWrap}>
        <BackButton
          onClick={onBackToDashboardHandler}
          label={ backToPageLabel() }
        />
      </div>
      <h1 className={styles.pageTitle}>
        {getPageTitle()}
      </h1>
      {isCurrentUserLearner(userRole) && <LearnerPersonalizationMessage personalizationMessageType={PersonalizationMessageType.SCHEDULING_PAGE_MESSAGE} className={styles.schedulePersonalizationMessage} cardVariant={'info'} />}
      <SimulationSelectors
        setLoading={setLoading}
        onProjectSelect={onProjectSelect}
        onScenarioSelect={onScenarioSelect}
        projectName={getName(projectInfo?.name)}
        scenarioName={getName(scenarioInfo?.name)}
        refreshState={refreshState}
        setClientName={setClientName}
        scheduleType={schedulingType}
        disableDropDowns={disableDropDowns}
      />
      {isScenarioLoaded ? (
        <div>
          <ScenarioDetails scenarioInfo={scenarioInfo} getScenarioLink={getScenarioLink} />
          <div style={{ textAlign: 'center' }} className={styles.desiredTimeSlotTextWrap}>
            {getSelectionMessageOrTabs()}
          </div>
          {isCurrentTab === 'dateAndTimeStep' && (
            <div className={cn(styles.learnerTimeSelection, styles.dFlex)}>
              <div ref={calendarDivRef} className={cn(styles.calendarWrap)}>
                <CalendarInput
                  selectedDate={selectedDate}
                  minDate={convertMomentToDate(getMinDate())}
                  maxDate={convertMomentToDate(getScenarioEndDate)}
                  onDateChange={onDateChange}
                  timezoneId={selectedTimezone}
                  disabledDates={excludedDates}
                  onMonthChange={setSelectedMonth}
                />
                <div className={cn(styles.posRel, styles.timezoneDropdownWrap)}>
                  {renderTzDropDownOrTzLabel()}
                </div>
              </div>
              <div
                className={cn(
                  styles.timeRangeWrap,
                  isSchedulingTypeNotDemandBased(schedulingType) &&
                    (isTodaySelected ? !isSecondPossibleDay : true ) &&
                    styles.timeRangeWrapMoreValues
                )}
              >
                {selectedDate && isSchedulingTypeNotDemandBased(schedulingType) && (
                  <div className={styles.dayInfo}>
                    {getTodayText()}
                    {getDateOtherThanToday()}{' '}
                  </div>
                )}
                <TimeRangeBlocks
                  scheduleType={schedulingType}
                  timeBlocks={timeBlocks}
                  learnerAvailability={learnerAvailability}
                  selectedDate={selectedDate}
                  timeSlots={timeSlots}
                  selectedTime={selectedTime}
                  setTime={setTime}
                  userTimezoneId={selectedTimezone}
                  setShowRequestSlots={setShowRequestSlots}
                  isResponse={isResponse}
                  scenarioInfo={scenarioInfo}
                  sessionID={sessionID}
                  showAvailability={projectInfo?.showAvailability}
                  finalShowAvailabilityEndDate={finalShowAvailabilityEndDate}
                  setAreSlotsAvailable={setAreSlotsAvailable}
                  actualUpcomingAvailabilityPeriod={actualUpcomingAvailabilityPeriod}
                />
              </div>
              {renderNextDayTimeRangeBlocks()}
              {renderBookingSlots()}
            </div>
          )}
          {renderSimSelector()}
          <ErrorMessage message={errorMessage} focusOnChange={true} />
          <SubmitSection
            onCodeOfConductChecked={onCodeOfConductChecked}
            codeOfConductSelected={codeOfConductSelected}
            enableSubmitButton={enableSubmitButton}
            onSubmit={submit}
          />
        </div>
      ) : (
        <SelectFieldsJumbotron />
      )}
    </div>
  );
};

export default SchedulingFlow;
