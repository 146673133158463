import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import styles from "../../SimulationScheduling.css";

const InstantSlotsInfo: FunctionComponent<any> = () => {
  const intl = useIntl();
  return (
    <div className={styles.selectOneSlotInfoWrap}>
      <div>
        <div className={styles.contentWrap}>
          <div>
            {intl.formatMessage({
              id: "MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking",
            })}
          </div>
          <div>{intl.formatMessage({ id: "MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork" })}</div>
        </div>
      </div>
    </div>
  );
};

export default InstantSlotsInfo;
