import React from 'react';
import Page from 'src/components/Page/Page';
import _noop from 'lodash/noop';
import EULA from 'src/layouts/common/EULA/EULA';

const EULAPage = () => {
  return (
    <Page bgColor={'white'} noBreadCrumbs={true}>
      <EULA disabled={false} onCancel={_noop} onApply={_noop} />
    </Page>
  );
};

export default EULAPage;