import React, { FunctionComponent } from 'react';
import styles from './TimeBlock.css';
import ITimeBlock from 'src/app/data/calendar/interfaces/ITimeBlock';
import cn from 'classnames';
import { getTimeBlockName } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/components/TimeBlock/utils';
import { getTitle } from 'src/app/data/calendar/utils/simulationSchedulingUtil';

export interface ITimeBlockProps {
  timeBlock: ITimeBlock;
  tzId: string;
  selected: boolean;
  available?: boolean;
  isDstChangeDate?: boolean;
  practiceSession?: boolean;
}

const TimeBlock: FunctionComponent<ITimeBlockProps> = (props) => {

  const { timeBlock, tzId, selected, available, isDstChangeDate, practiceSession } = props;

  let showOffsetInfo = false;
  if (isDstChangeDate && (timeBlock.start.isDST() !== timeBlock.start.clone().subtract(1, 'hour').isDST())
    || (timeBlock.start.isDST() !== timeBlock.start.clone().add(1, 'hour').isDST())
  ) {
    showOffsetInfo = true;
  }

  const name = getTimeBlockName(timeBlock, tzId, showOffsetInfo);

  return (
    <div className={cn(styles.container, selected && styles.selected, available && styles.available, practiceSession && styles.availableSlot)} tabIndex={0}>
      {practiceSession ? getTitle({ startTime: timeBlock.start, endTime: timeBlock.end }) : name}
    </div>
  );
};

export default TimeBlock;