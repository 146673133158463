
export enum LaunchDarklyType {
   MP_7169  = 'mp-7169-learner-feedback-report',
   MP_8281  = 'mp-8281-enhance-sim-feedback-survey-completion-flow',
   MP_8205  = 'mp-8205-mark-all-certificates-inactive-at-sim-level',
   MP_7199  = 'mp-7199-scenario-page-add-create-view-with-rich-text-formatting',
   MP_8108 = 'mp-8108-lti-integrations-only-allow-learner-to-schedule-for-specified-scenario',
   MP_8324 = 'mp-8324-miss-cancellation-rate',
   MP_8500 = 'mp-8500-scheduling-ui-changes-upcoming-avilability',
   MP_7608 = 'mp-7608-manage-availability',
   MP_8530 = 'mp-8530-mvp-learner-communication-personalization',
   MP_8522 = 'mp-8522-ability-for-si-ms-to-deny-swap',
   MP_8166 = 'mp-8166-scheduling-rate-similar-to-completion-rate',
   MP_8643 = 'mp-8643-on-demand-join-now-experience',
   MP_8135 = 'mp-8135-magic-open-new-tab',
   MP_9188 = 'mp-9188-learner-onboarding-page',
   MP_10275 = 'mp-10275-disable-utilization-learner-tabs',
   HAS_MP_10275 = 'MP-10275-Control-Dashboard-Via-LaunchDarkly',
   MP_12892_LICENSE_BREAKDOWN_CONTRACT_ITEM = 'mp-12892-license-breakdown-contract-item',
   MP_12892_SESSION_BREAKDOWN_QUICK_VIEW = 'mp-12892-session-breakdown-quick-view',
   MP_12892_SESSION_BREAKDOWN_CONTRACT_ITEM = 'mp-12892-session-breakdown-contract-item',
   MP_12892_HOUR_BREAKDOWN_CONTRACT_ITEM = 'mp-12892-session-breakdown-contract-item-hours',
   MP_12968_Froala_RTF = 'mp-12968-fe-old-portal-froala-editor-add-ld-flag-on-froala-rtf',
   MP_13385_AUTO_ASSIGN_TEAM = 'mp-13385-fe-auto-assign-team-ld-flag-to-control-update-scenario-team',
   MP_13388_SBUX_LEARNING_BUTTON = 'mp-13388-sbux-learning-button',
   MP_13397_LISTNEW_COUNT_REFRESH_CALL= 'mp-13397-list-new-and-count-api-requests-every-time-sbux-join-now-button-clicked',
   MUR_70_MAGIC_IVS = 'mur-70-magic-ivs-flag-for-switch-between-webrtc-and-ivs',
   BLUE_203_DEMAND_PREVIEW='blue-228-on-demand-preview'
}
