exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".OAuth__spinner___H8LZN{height:100%!important}.OAuth__modal___3HNHN{top:30%}.OAuth__title___UpgcT{margin-bottom:5px;font-size:1.2em;padding:15px;text-align:center}.OAuth__modal___3HNHN .modal-dialog{max-width:500px}.OAuth__modal___3HNHN .modal-footer{text-align:center;-ms-flex-pack:center;justify-content:center}.OAuth__modal___3HNHN .modal-footer button{margin:5px 0 5px 5px}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/layouts/common/OAuth/OAuth.css"],"names":[],"mappings":"AAAA,wBACE,qBAAuB,CACxB,AAED,sBACE,OAAS,CACV,AAED,sBACE,kBAAmB,AACnB,gBAAiB,AACjB,aAAc,AACd,iBAAmB,CACpB,AAED,oCACE,eAAiB,CAClB,AAED,oCACE,kBAAmB,AACnB,qBAAsB,AAClB,sBAAwB,CAC7B,AAED,2CACE,oBAAsB,CACvB","file":"OAuth.css","sourcesContent":[".spinner {\n  height: 100%!important;\n}\n\n.modal {\n  top: 30%;\n}\n\n.title {\n  margin-bottom: 5px;\n  font-size: 1.2em;\n  padding: 15px;\n  text-align: center;\n}\n\n.modal :global(.modal-dialog) {\n  max-width: 500px;\n}\n\n.modal :global(.modal-footer) {\n  text-align: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n}\n\n.modal :global(.modal-footer) button {\n  margin: 5px 0 5px 5px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"spinner": "OAuth__spinner___H8LZN",
	"modal": "OAuth__modal___3HNHN",
	"title": "OAuth__title___UpgcT"
};