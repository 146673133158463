import React, { FunctionComponent } from 'react';
import styles from 'src/layouts/common/SimAttendanceV2/SimAttendanceV2.css';
import { useIntl } from 'react-intl';
import { Card } from 'react-bootstrap';
import cn from 'classnames';
import { Radio, RadioGroup } from 'src/components/RadioGroup/RadioGroup';
import Button, { ButtonFont, ButtonSize, ButtonType } from 'src/components/Button';
import { ISessionAttendanceState } from 'src/layouts/common/SimAttendanceV2/SimAttendanceV2';
import { SessionUserStatus } from 'src/app/data/session/interfaces/ISession';
import { getLearnerAttendanceStatus } from 'src/app/data/session/utils/sessionActionUtils';
import getUserName from 'src/app/data/common/utils/getUserName';
import { TLocaleId } from 'src/i18n';
import FieldGroup from 'src/components/FieldGroup/FieldGroup';
import { renderCardHeader } from 'src/layouts/common/SimAttendanceV2/components/SimAttendanceStatusCard';
import { SimSpecialistStatusType } from 'src/app/data/session/interfaces/SimSpecialistStatusType';

interface ILearnerOptions {
  label: TLocaleId;
  value: SessionUserStatus;
}

const learnerStatusOptions: ILearnerOptions[] = [
  {
    label: 'MursionPortal.ProjectStatus.Completed',
    value: SessionUserStatus.COMPLETED,
  },
  {
    label: 'Mursion.Portal.Status.No.Show',
    value: SessionUserStatus.MISSED,
  },
  {
    label: 'Mursion.Portal.Status.ElectedToLeave',
    value: SessionUserStatus.LEFT,
  },
  {
    label: 'Mursion.Portal.Status.TooLateToComplete',
    value: SessionUserStatus.LATE,
  },
  {
    label: 'Mursion.Portal.Status.UnableToComplete',
    value: SessionUserStatus.UNABLE_TO_COMPLETE,
  },
  {
    label: 'Mursion.Portal.Status.TechnicalErrorLearner',
    value: SessionUserStatus.ERROR,
  },
];

interface IAddAttendeesCardProps {
  sessionAttendanceDetails: ISessionAttendanceState | null;
  onLearnerStatusChange: (index: number) => (value: SessionUserStatus) => void;
  showAddAttendeesForm: boolean;
  onAddAttendeeClick: () => void;
  attendeeFirstName: string;
  onFirstNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  attendeeLastName: string;
  onLastNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addAttendee: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const AddAttendeesCard: FunctionComponent<IAddAttendeesCardProps> = ({
  sessionAttendanceDetails,
  onLearnerStatusChange,
  showAddAttendeesForm,
  onAddAttendeeClick,
  attendeeFirstName,
  onFirstNameChange,
  attendeeLastName,
  onLastNameChange,
  addAttendee,
}) => {
  const intl = useIntl();

  const { learnerAttendeeStatus } = sessionAttendanceDetails ?? {};
  
  const getAttendeesFooter = () => {
    return (
      <div className={styles.simAttendanceCardFooter}>
        <Button
          btnSize={ButtonSize.SMALL}
          btnFont={ButtonFont.LIGHT}
          onClick={onAddAttendeeClick}
          btnType={ButtonType.LIGHT_BLUE}
          className={styles.addAttendeesBtn}
          aria-label={intl.formatMessage({ id: 'MursionPortal.SessionAttendance.AddAttendees' })}
        >
          {intl.formatMessage({ id: 'MursionPortal.SessionAttendance.AddAttendees' })}
        </Button>
      </div>
    );
  };

  const getAddAttendeesForm = () => {
    return (
      <div className={styles.nameInputContentWrap}>
        <form className={styles.nameInputWrap}>
          <label htmlFor='first-name'>{intl.formatMessage({ id: 'MursionPortal.SimAttendance.Add.Attendee' })}</label>
          <FieldGroup
            id='first-name'
            type='text'
            placeholder={intl.formatMessage({ id: 'MursionPortal.FirstName' })}
            value={attendeeFirstName}
            name={'attendeeFirstName'}
            csvProtected={true}
            onChange={onFirstNameChange}
          />
          <FieldGroup
            id='last-name'
            type='text'
            value={attendeeLastName}
            placeholder={intl.formatMessage({ id: 'MursionPortal.LastName' })}
            name={'attendeeLastName'}
            csvProtected={true}
            onChange={onLastNameChange}
          />
          <Button
            btnSize={ButtonSize.SMALL}
            btnFont={ButtonFont.LIGHT}
            onClick={addAttendee}
            disabled={!attendeeFirstName || !attendeeLastName}
            btnType={ButtonType.BLUE}
            className={styles.addAttendeesUserBtn}
            aria-label={intl.formatMessage({ id: 'MursionPortal.Add' })}
          >
            {intl.formatMessage({ id: 'MursionPortal.Add' })}
          </Button>
        </form>
      </div>
    );
  };

  return (
    <>
      <Card bsPrefix={cn(styles.simAttendanceCard, styles.addAttendeesCard)}>
      {renderCardHeader('MursionPortal.SessionAttendance.SessionStatus.Learner.Label', intl)}
        <Card.Body bsPrefix={styles.simAttendanceCardBody}>
          <div className={styles.simAttendanceBodyContent}>
            <div className={cn(styles.simAttendanceBodyContentCol, styles.simAttendanceBodyContentLeft)}>
              <div className={styles.label}>{intl.formatMessage({ id: 'MursionPortal.Label.Learner' })}</div>
            </div>
            <div className={cn(styles.simAttendanceBodyContentCol, styles.simAttendanceBodyContentCenter)}>
              <div className={styles.label}>{intl.formatMessage({ id: 'Filters.LearnerStatus' })}</div>
            </div>
          </div>
           
          {learnerAttendeeStatus?.length &&
            learnerAttendeeStatus.length > 0 &&
            learnerAttendeeStatus.map((learnerInfo, index) => (
              <div className={cn(styles.simAttendanceBodyContent, styles.simAttendanceBodyContentValue)} key={index}>
                <div className={cn(styles.simAttendanceBodyContentCol, styles.simAttendanceBodyContentLeft)}>
                  <div className={styles.value}>{getUserName(learnerInfo)}</div>
                </div>
                <div className={cn(styles.simAttendanceBodyContentCol, styles.simAttendanceBodyContentCenter)}>
                  <div className={styles.value}>
                    <RadioGroup
                      name={index.toString()}
                      onChange={onLearnerStatusChange(index)}
                      selectedValue={sessionAttendanceDetails?.simStatus?.status === SimSpecialistStatusType.SIM_TECHNICAL_ERROR?SessionUserStatus.UNABLE_TO_COMPLETE:getLearnerAttendanceStatus(learnerAttendeeStatus[index].status)}
                    >
                      {learnerStatusOptions.map((option: ILearnerOptions) => (
                        <Radio
                          value={sessionAttendanceDetails?.simStatus?.status === SessionUserStatus.UNABLE_TO_COMPLETE?SessionUserStatus.UNABLE_TO_COMPLETE:option.value}
                          label={intl.formatMessage({ id: option.label })}
                          key={option.value}
                          disabled={sessionAttendanceDetails?.simStatus?.status === SessionUserStatus.UNABLE_TO_COMPLETE}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </div>
              </div>
            ))}
        </Card.Body>
      </Card>
      {showAddAttendeesForm ? getAddAttendeesForm() : getAttendeesFooter()}
    </>
  );
};

export default AddAttendeesCard;
