import React, { FunctionComponent } from 'react';
import styles from './ShortScenarioInfo.css';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { DELIVERY_MODE } from 'src/app/data/common/DeliveryMode';
import moment from 'moment';
import { useIntl } from 'react-intl';

interface IShortScenarioInfo {
  deliveryMode: SessionType;
  sessionLength: number;
}

const ShortScenarioInfo: FunctionComponent<IShortScenarioInfo> = (props) => {
  const intl = useIntl();
  const deliveryMode = intl.formatMessage({ id: DELIVERY_MODE[props.deliveryMode] });
  const sessionLength = moment.duration(props.sessionLength, 'ms').format({ template: 'm _', trim: 'all', trunc: true });
  const block = (label: string, value: string) => (

    <tr className={styles.block}>
      <th className={styles.label}>{label}:</th>
      <td className={styles.value}>
        <div><b>{value}</b></div>
      </td>
    </tr>
  );

  return (
    <div className={styles.container}>
      <table>
        <tbody>
        {block(intl.formatMessage({ id: 'MursionPortal.Label.DeliveryMode' }), deliveryMode)}
        {block(intl.formatMessage({ id: 'MursionPortal.Label.SessionLength' }), sessionLength)}
        </tbody>
      </table>
    </div>
  );
};

export default ShortScenarioInfo;
