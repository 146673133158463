import _findIndex from 'lodash/findIndex';
import React, { ChangeEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import IRestCollectionInfo from 'src/app/data/common/interfaces/IRestCollectionInfo';
import getUserName from 'src/app/data/common/utils/getUserName';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import IProject, { DemandWindowType, ScenarioVersion, SoftwareType, IProjectSurveyConfig, ShowAvailabilityType, getShowAvailabilityOptions } from 'src/app/data/projects/interfaces/IProject';
import Button, { ButtonFont, ButtonSize } from 'src/components/Button';
import FieldGroup from 'src/components/FieldGroup/FieldGroup';
import ItemPicker from 'src/components/ItemPicker';
import { IPickerItem } from 'src/components/ItemPicker/ItemPicker';
import Tags from 'src/layouts/common/Tags';
import { INewProjectFormStepProps, INewProjectFormStepState } from 'src/layouts/common/Projects/components/ProjectCard/components/NewProjectForm/components/common/INewProjectFormStepProps';
import UsersPickerComponent from 'src/layouts/common/Projects/components/ProjectCard/components/NewProjectForm/components/common/UsersPickerComponent';
import styles from 'src/layouts/common/Projects/components/ProjectCard/components/NewProjectForm/components/ProjectCreateStep/ProjectCreateStep.css';
import { connect } from 'react-redux';
import { IAppState } from 'src/app/redux/store/reducers';
import actions from 'src/app/redux/store/actions';
import selectors from 'src/app/redux/selectors';
import { IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import {
  getScenarioSettingCheck,
  getValidationError,
  getValidationErrorByCode,
  getValidationErrorMessage,
  getValidationErrorMessageForCancellationWindow,
  getValidationErrorMessageForShowAvailability,
  missOrCancelRateAndSchedulingLockoutNullCheck,
  schedulingLockoutNullCheck
} from 'src/app/data/common/utils/getValidationErrorMessage';
import generateNewProjectData, { defaultProjectSettingsForAutoSim } from 'src/app/data/projects/utils/generateNewProjectData';
import {
  convertTimestampMillisToMoment,
  getDateFormat,
  getTimeFormat,
  momentDateToTimestampMillis,
  minutesToMillisConverter,
  millisToMinutesConverter,
  getDemandBasedSchedulingTime,
  daysHoursMinutesToSecondsConverter,
  getCancellationWindowValue,
  getTypeFromSecondsToDaysHoursMinutesConverter,
  getValueAccordingToType,
  getTypeForShowAvailability,
  getSessionMissedTime,
  getStartDateFormat,
  getEndDateFormat,
  getEndDateFromStartDateFormat,
  copyDate,
  getDeliveryHours,
  DeliveryHoursTimeType,
  DeliveryHoursType,
  DeliveryWeekDaysField,
  getTimezoneId,
  getMaxTime,
  getMinTime,
  getTimeUsingTZ
} from 'src/app/data/common/utils/dateUtil';
import ConfirmButton from 'src/components/ConfirmButton';
import { ConfirmButtonType } from 'src/components/ConfirmButton/ConfirmButton';
import { ERROR_CODE_TIMELINE } from 'src/app/data/common/utils/errorUtils';
import ErrorMessage from 'src/components/ErrorMessage';
import IScenarioConfig from 'src/app/data/companyConfig/interfaces/IScenarioConfig';
import ScenarioVersionSelect from 'src/components/ScenarioVersionSelect';
import setInitialScenarioVersionFromConfig from 'src/app/data/projects/utils/setInitialScenarioVersionFromConfig';
import moment from 'moment-timezone';
import Checkbox from 'src/components/Checkbox/Checkbox';
import { CLIENTS_ROLES } from 'src/app/data/common/roles';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import cn from 'classnames';
import Separator from 'src/components/Separator';
import hasPermission from 'src/app/data/profile/utils/hasPermission';
import Permission from 'src/app/data/profile/interfaces/Permission';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import { MAX_FIELD_LENGTH, MAX_UPCOMING_AVAILABILITY_IN_HOURS, MIN_SESSION_MISSED_TIME, MIN_UPCOMING_AVAILABILITY } from 'src/app/data/common/constants';
import IClientConfig from 'src/app/data/clientConfig/interfaces/IClientConfig';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import A11yDateRangeSelector from 'src/components/EntitySelectors/A11yDateRangeSelector/A11yDateRangeSelector';
import TimePicker from 'src/components/TimePicker';
import { TooltipPlacement } from 'src/components/Tooltip/Tooltip';
import InfoTooltip from 'src/components/InfoTooltip/InfoTooltip';
import TimezoneDropdown from 'src/components/TimezoneDropdown';
import NumericField from 'src/components/NumericField';
import SoftwareTypeSelect from 'src/components/SoftwareTypeSelect/SoftwareTypeSelect';
import _range from 'lodash/range'; 
import A11ySelector from 'src/components/A11ySelector';
import { ISelectorOption } from 'src/components/Selector';
import ProjectSurveyControl from 'src/layouts/common/Projects/components/ProjectCard/components/ProjectInfo/ProjectSurveyControl';
import { IUserProfile } from 'src/app/data/profile/interfaces';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import IUserRole from 'src/app/data/common/interfaces/IUserRole';
import ScenarioSelection from 'src/components/ScenarioSelection/ScenarioSelection';
import ItemStyles from 'src/components/ItemPicker/ItemPicker.css';
import { isCurrentUserPS } from 'src/app/data/common/utils/userRoleUtils';
import { Radio, RadioGroup } from 'src/components/RadioGroup/RadioGroup';
import { cloneDeep } from 'lodash';
import { getDeliveryHoursDefaultTime, getDeliveryWeekDaysCheckboxesDetails, getProjectSettigsValue, TIMEZONE_ID_EST } from 'src/layouts/common/Projects/components/ProjectCard/components/NewProjectForm/newProjectFormUtils';
import { toggleAutoSimCheckboxVisibility } from 'src/layouts/mursion/ScenarioBankContainer/utils/isAutoSimEditEnabled';

export interface IProjectCreateStepProps extends WrappedComponentProps {
  accManagers: ICompanyUser[];
  projectInfo: IProject;
  scenarioConfig: IScenarioConfig | null;
  onCancel: () => void;
  usersInfo: IRestCollectionInfo<ICompanyUser>;
  clientConfig: IClientConfig | null;
  clientTzId: string | undefined;

  errorMessage: string;
  clearValidationErrors: () => void;
  validationErrors: IErrorValidation[];
  userRole: IUserRoleExtended | null;
  userProfile: IUserProfile | null;
  companyConfig: ICompanyConfig;
  ldClient?: any;
}

export enum ProjectCreateStepField {
  UPCOMING_AVAILABILITY = 'upcomingAvailability',
}

export const defaultDemandBasedTimeSlots = 0;
class ProjectCreateStep extends React.Component<INewProjectFormStepProps & IProjectCreateStepProps, INewProjectFormStepState> {

  public static getDerivedStateFromProps(
    props: INewProjectFormStepProps & IProjectCreateStepProps,
    state: INewProjectFormStepState): INewProjectFormStepState {

    let { projectInfo } = state;
    if (props.projectInfo.id !== projectInfo.id || props.projectInfo.version !== projectInfo.version) {
      projectInfo = props.projectInfo;
    }

    return {
      ...state,
      projectInfo,
    };
  }

  public state: INewProjectFormStepState = {
    projectInfo: generateNewProjectData(),
    dateFocusedInput: null,
    needReason: false,
    isChanged: false,
    sessionTimeStepFlag:false,
    isTimeStepInvalid:false,
    sessionTimeStepVal: 0,
    scenarioSettingCheckMessage: '',
  };

  public componentDidMount() {
    moment.tz.setDefault(this.props.clientTzId);
    const {companyConfig} = this.props;
    const defaultTimeStep = millisToMinutesConverter(companyConfig?.session.timeStep);
    this.props.clearValidationErrors();
    this.setState({
      sessionTimeStepVal: defaultTimeStep,
      projectInfo: this.props.projectInfo
    }, () => {
      setInitialScenarioVersionFromConfig(this.state.projectInfo.scenarioVersion, this.props.scenarioConfig, this.onProjectScenarioVersionChange);
    });

    const { ldClient, projectInfo } = this.props;
    const { client } = projectInfo;
    const launchDarklyUser = {
      key: client.id /// clientId
    };
    ldClient.identify(launchDarklyUser);

    this.setState({
      projectInfo: {
        ...projectInfo,
        timezoneId: this.props.clientTzId,
        simPostSimulationSurveyOneToOne: true,
        enabledReportOneToOneDeliveries: true,
        deliveryHoursStartTime: generateNewProjectData().deliveryHoursStartTime,
        deliveryHoursEndTime: generateNewProjectData().deliveryHoursEndTime,
      },
    });
  }

  public componentDidUpdate(_: Readonly<INewProjectFormStepProps & IProjectCreateStepProps>, prevState: Readonly<INewProjectFormStepState & IProject>): void {
    const { projectInfo } = this.state;
    const { showAvailability, showAvailabilityType, virtualSimOnly } = projectInfo;

    if (this.state.projectInfo.timezoneId && this.state.projectInfo.timezoneId !== prevState.projectInfo.timezoneId) {
      moment.tz.setDefault(this.state.projectInfo.timezoneId);
    }

    if (!this.state.projectInfo.cancellationWindowType) {
      this.setState({
        projectInfo: {
          ...projectInfo,
          cancellationWindowType: getTypeFromSecondsToDaysHoursMinutesConverter(this.state.projectInfo.cancellationWindow),
        },
      });
    }

    if (!showAvailabilityType) {
      this.setState({
        projectInfo: {
          ...projectInfo,
          showAvailabilityType: getTypeForShowAvailability(showAvailability),
        },
      });
    }

    const isProjectVersionWebOnly = toggleAutoSimCheckboxVisibility(projectInfo);

    if (virtualSimOnly && !isProjectVersionWebOnly) {
      this.setState({
        projectInfo: {
          ...projectInfo,
          // set virtualSim to false when projectversion is not ML3 and webonly 
          virtualSimOnly: false,
          // set initial settings of survey
          simPostSimulationSurveyOneToOne: true, 
          enabledReportOneToOneDeliveries: true,
        },
      });
    }

    setInitialScenarioVersionFromConfig(this.state.projectInfo.scenarioVersion, this.props.scenarioConfig, this.onProjectScenarioVersionChange);

  }

  public componentWillUnmount() {
    moment.tz.setDefault(moment.tz.guess());
  }

  public render() {
    return this.props.collapsed
      ? this.renderCollapsed()
      : this.renderExpanded();
  }

  private renderCollapsed() {
    return (
      <div className={styles.collapsedContainer}>
        <span className={styles.projectNameTitle}>{this.state.projectInfo.name}</span>
      </div>
    );
  }

  private renderExpanded() {// nosonar
    const {
      errorMessage,
      scenarioConfig,
      validationErrors,
      userRole,
      clientConfig,
      intl,
      userProfile,
      ldClient,
      companyConfig,
    } = this.props;
    const { projectInfo, needReason } = this.state;
    const { startDate, endDate, archived, client, showAvailability, showAvailabilityType, upcomingAvailability, timezoneId } = projectInfo;
    const {
      'mp-9341-delivery-hours-setting-for-sim-availability' : featureFlagDeliveryHours,
    } = ldClient.allFlags();
 
    let startDateMoment = startDate ? convertTimestampMillisToMoment(startDate) : null;
    let endDateMoment = endDate ? convertTimestampMillisToMoment(endDate) : null;

    if (timezoneId && startDate && endDate) {
      startDateMoment = moment.tz(startDate, timezoneId);
      endDateMoment = moment.tz(endDate, timezoneId).endOf('day');
    }

    const submitDisable = !!this.props.submitEnableChecker && !this.props.submitEnableChecker(this.state.projectInfo)
      || this.props.finished && !this.state.isChanged;
    const allowedScenarioVersions: ScenarioVersion[] = scenarioConfig ? scenarioConfig.allowedScenarioVersions : [];
    const renderScenarioVersionSelect = allowedScenarioVersions.length > 1;
    const showRecordingOptions =
      (
        projectInfo.scenarioVersion === ScenarioVersion.V3 ||
        projectInfo.scenarioVersion === ScenarioVersion.V3z ||
        projectInfo.scenarioVersion === ScenarioVersion.V3meet
      )
      && hasPermission(userRole, Permission.SESSIONS_RECORDING_OPTIONS_WRITE);

    const showSmartMetricsOptions = clientConfig?.sessionSmartMetrics
      && projectInfo.scenarioVersion === ScenarioVersion.V3
      && projectInfo.recordingAllowed;

    const demandWindowDropdownOptions: Array<ISelectorOption<DemandWindowType>> = [
      {
        value: DemandWindowType.DAYS,
        label: intl.formatMessage({ id: 'MursionPortal.Project.Days' }),
      },
      {
        value: DemandWindowType.HOURS,
        label: intl.formatMessage({ id: 'MursionPortal.Title.Hours' }),
      },
      {
        value: DemandWindowType.MINUTES,
        label: intl.formatMessage({ id: 'MursionPortal.Title.Minutes' }),
      },
    ];

    const saveSurveyConfig = (sConfig: IProjectSurveyConfig) => {
      this.setState({
        preSimulationSurvey: sConfig?.enablePreSimulation
      });
    };

    const getCancellationWindowValueWithType = getCancellationWindowValue(projectInfo.cancellationWindow, projectInfo.cancellationWindowType);
    const cancellationWindowValue = Number.isInteger(getCancellationWindowValueWithType) ? getCancellationWindowValueWithType : 0;
    const showJoinNow = companyConfig.joinNow;
    const showDeliveryHoursField = isCurrentUserPS(userRole) && featureFlagDeliveryHours;

    const clientConfigScenarioSelection = { completionRate: projectInfo.completionRate, schedulingLockoutTime: projectInfo.schedulingLockoutTime, schedulingRate: projectInfo.schedulingRate, missOrCancelationRate: projectInfo.missOrCancelationRate };
    const clientConfigValues = { completionRate: clientConfig?.completionRate, schedulingLockoutTime: clientConfig?.schedulingLockoutTime, schedulingRate: clientConfig?.schedulingRate, missOrCancelationRate: clientConfig?.missOrCancelationRate };

    const timezoneID = getTimezoneId(projectInfo.timezoneId);
    const demandBasedStartTimeWithTZ = getTimeUsingTZ(timezoneID, projectInfo.demandBasedSchedulingStartTime);
    const demandBasedEndTimeWithTZ = getTimeUsingTZ(timezoneID, projectInfo.demandBasedSchedulingEndTime);
    const deliveryHoursStartTimeWithTZ = getTimeUsingTZ(timezoneID, projectInfo.deliveryHoursStartTime);
    const deliveryHoursEndTimeWithTZ = getTimeUsingTZ(timezoneID, projectInfo.deliveryHoursEndTime);
    const isVirtualSimOnlyEnable = projectInfo.virtualSimOnly;

    const getAutoSimContent = () => {
      return(
        <div  className={cn(styles.field, 'mb-2')}>
          <Checkbox
            checked={projectInfo.virtualSimOnly}
            id={'auto-sim-checkbox'}
            onChange={this.onAutoSimChange}
          >
            {intl.formatMessage({ id: 'MursionPortal.ScenarioInfo.Label.AutoSIM' })}
          </Checkbox>
        </div>
      );
    };
    
    return (
      <>
        <div className={styles.projectFormContainer}>
          <div className={styles.topContainer}>
            <div className={styles.itemBox}>
              <h2 className={styles.boxTitle}>{TranslateMessage('MursionPortal.ProjectName')} <span
                aria-hidden="true">*</span></h2>
              <label htmlFor="project-name"
                     className="visibilityHidden">{TranslateMessage('MursionPortal.ProjectName')}</label>
              <div className={styles.field}>
                <FieldGroup
                  id="project-name"
                  label=""
                  required={true}
                  min={0}
                  type="text"
                  csvProtected={true}
                  value={projectInfo.name}
                  error={getValidationErrorMessage('name', validationErrors)}
                  validationState={this.getValidationState('name')}
                  onChange={this.onPropChange('name', false)}
                  autoFocus={true}
                  maxLength={MAX_FIELD_LENGTH}
                  tabIndex={0}
                />
              </div>
            </div>
          </div>

          <Separator/>
          <div className={styles.body}>
            <div className={styles.halfContainer}>

              <div className={cn(styles.itemBox, styles.whiteBox)}>
                <h2 className={styles.boxTitle}>{TranslateMessage('MursionPortal.Label.Timeline')}
                <InfoTooltip
                  text={intl.formatMessage({ id: 'Projects.ProjectInfo.Label.Timeline.Tooltip' })}
                  placement={TooltipPlacement.TOP} /></h2>
                <label htmlFor="project_start_date_id"
                       className="visibilityHidden">{TranslateMessage('MursionPortal.Project.TimeZoneStartDate')}</label>
                <label htmlFor="project_end_date_id"
                       className="visibilityHidden">{TranslateMessage('MursionPortal.Project.TimeZoneEndDate')}</label>
                <div className={cn(styles.field, styles.DateRangePicker)}>
                  <A11yDateRangeSelector
                    startDate={startDateMoment}
                    startDateId="project_start_date_id"
                    endDate={endDateMoment}
                    endDateId="project_end_date_id"
                    minimumNights={0}
                    startDatePlaceholderText={getDateFormat()}
                    endDatePlaceholderText={getDateFormat()}
                    focusedInput={this.state.dateFocusedInput}
                    small={true}
                    onDatesChange={this.onProjectDatesChange}
                    onFocusChange={this.onProjectDatesFocusChange}
                    noBorder={true}
                    required={true}
                    disabled={archived}
                  />
                  <ErrorMessage message={getValidationErrorByCode(ERROR_CODE_TIMELINE, validationErrors)}/>
                </div>
              </div>
              <div className={cn(styles.itemBox, styles.whiteBox)}>
                <h2
                  className={styles.boxTitle}>{intl.formatMessage({ id: 'MursionPortal.CheckboxLabel.ProjectTimezone' })}
                </h2>
                <div className={cn(styles.value, styles.field)} id={'timepicker-id'}>
                  <TimezoneDropdown
                    dropUp={false}
                    nullable={false}
                    timezoneId={projectInfo.timezoneId || moment.tz.guess()}
                    onChange={this.handleProjectTimezoneChange}
                    disabled={false}
                  />
                </div>
              </div>
              <div className={cn(styles.itemBox, styles.whiteBox)}>
                <h2 className={styles.boxTitle}>{TranslateMessage('MursionPortal.Contracted')}</h2>
                <label htmlFor="project-hours"
                       className="visibilityHidden">{TranslateMessage('MursionPortal.Contracted')}</label>
                <div className={styles.field}>
                  <FieldGroup
                    id="project-hours"
                    label=""
                    min={0}
                    type="number"
                    value={projectInfo.hours}
                    onChange={this.onPropChange('hours', true)}
                    onKeyDown={this.checkNumericInput}
                    className={styles.hourInput}
                  />
                </div>
              </div>

            </div>
            <div className={styles.halfContainer}>

              {
                renderScenarioVersionSelect &&
                <>
                  <div className={cn(styles.itemBox, styles.whiteBox)}>
                    <h2 className={styles.boxTitle}>{TranslateMessage('MursionPortal.DeliveryMode')}
                    <InfoTooltip
                            text={intl.formatMessage({ id: 'Projects.ProjectInfo.DeliveryMode.tooltip' })}
                            placement={TooltipPlacement.TOP} /> 
                    </h2>
                    <div className={styles.field}>
                      <ScenarioVersionSelect
                        scenarioConfig={this.props.scenarioConfig}
                        editMode={true}
                        value={this.state.projectInfo.scenarioVersion}
                        onChange={this.onProjectScenarioVersionChange}
                      />
                    </div>
                  </div>
                  {
                    this.state.projectInfo.scenarioVersion === ScenarioVersion.V3 && scenarioConfig?.allowMursionMagic &&
                    <div className={cn(styles.itemBox, styles.whiteBox)}>
                      <h2 className={styles.boxTitle}>{TranslateMessage('MursionPortal.Label.SoftwareType')}</h2>
                      <div className={styles.field}>
                        <SoftwareTypeSelect
                          labelId={'project-version-type'}
                          editMode={true}
                          selectedValue={this.state.projectInfo.softwareType}
                          onChange={this.onProjectSoftwareTypeChange}
                        />
                      </div>
                    </div>
                  }      
                </>
              }

              <div className={cn(styles.itemBox, styles.whiteBox)}>
                {
                  toggleAutoSimCheckboxVisibility(projectInfo) &&
                  getAutoSimContent()
                }
                <h2 className={styles.boxTitle}>{TranslateMessage('MursionPortal.Billing')}</h2>
                <label htmlFor="non-billable-checkbox-1"
                       className="visibilityHidden">{TranslateMessage('MursionPortal.Billing')}</label>
                <div className={styles.field}>
                  <Checkbox
                    checked={!projectInfo.billable}
                    id={'non-billable-checkbox-1'}
                    onChange={this.onChangeBillable}
                  >
                    {TranslateMessage('Clients.Modal.Projects.Checkbox.NonBillable')}
                  </Checkbox>
                </div>
              </div>

            </div>
          </div>

          {
            showRecordingOptions &&
            <>
              <Separator/>
              <div className={styles.body}>
                <div className={styles.halfContainer}>
                  <div className={cn(styles.itemBox, styles.whiteBox)}>
                    <h2 className={styles.boxTitle}>{TranslateMessage('MursionPortal.Recording')}</h2>
                    <div className={styles.field}>
                      <Checkbox checked={projectInfo.recordingAllowed} id={'recording-checkbox-1'}
                                onChange={this.onRecordingAllowedChange}
                                tooltipText={intl.formatMessage({ id: 'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip' })}>
                        {TranslateMessage('Projects.ProjectInfo.Checkbox.AllowSessionRecording')}
                      </Checkbox>
                      { projectInfo.recordingAllowed? <div className={styles.field}>
                                      <div>
                                        <Checkbox
                                          checked={projectInfo.recordingDoNotShare}  
                                          id={'do-not-allow-sharing-or-downloading'}
                                          onChange={this.onSharingOrDownloadingChange}
                                        >
                                          {intl.formatMessage({ id: 'MursionPortal.Label.SharingOrDownloading' })}
                                        </Checkbox>
                                      </div>
                                    </div>

                                    : null
                                  }
                      {
                        projectInfo.recordingAllowed &&
                        <>
                          <div
                            className={styles.label}>{TranslateMessage('MursionPortal.Label.SendRecordedVideosTo')}</div>
                          <div className={styles.recordingRoles}>
                            {Object.values(CLIENTS_ROLES).map((role: IUserRole) =>
                              <Checkbox
                                checked={projectInfo.recordingRecipients?.includes(role.id)}
                                id={`recording-recipients-${role.id}`}
                                key={`role_checkbox_${role.id}`}
                                onChange={this.onRecipientChange(role.id)}
                              >
                                {role.name}
                              </Checkbox>
                            )}
                          </div>
                        </>
                      }
                      {
                        projectInfo.recordingRecipients?.includes(RoleID.LEARNER) &&
                        <div className={styles.propsKey}>
                          <Checkbox
                            checked={projectInfo.recordingLearnerChoice}
                            id={'recording-checkbox-2'}
                            onChange={this.onRecordingLearnerChoiceChange}
                          >
                            {TranslateMessage('MursionPortal.Checkbox.AllowLearnerToRefuseRecording')}
                              <InfoTooltip
                                text={intl.formatMessage({ id: 'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip' })}
                                placement={TooltipPlacement.TOP} />
                          </Checkbox>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          }

          {
            showSmartMetricsOptions &&
            <>
              <Separator/>
              <div className={styles.body}>
                <div className={styles.halfContainer}>
                  <div className={cn(styles.itemBox, styles.whiteBox)}>
                    <h2
                      className={styles.boxTitle}>{TranslateMessage('Clients.Modal.Projects.Header.SessionSmartMetrics')}</h2>
                    <div className={styles.field}>
                      <Checkbox checked={projectInfo.sessionSmartMetrics} id={'smart-metrics-checkbox-1'}
                                onChange={this.onSmartMetricsAllowedChange}>
                        {TranslateMessage('MursionPortal.Checkbox.EnableSmartMetrics')}
                      </Checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }

          <Separator/>
          <div className={styles.body}>

            <div className={styles.halfContainer}>
              <div className={cn(styles.itemBox, styles.whiteBox)}>
                {this.renderProjectManagerSelect()}
              </div>
            </div>

            <div className={cn(styles.halfContainer, styles.halfContainerRight)}>
              <div className={cn(styles.itemBox, styles.whiteBox)}>
                <h2 className={styles.boxTitle}>{TranslateMessage('MursionPortal.Header.Tags')}
                <InfoTooltip
                    text={intl.formatMessage({ id: 'Projects.ProjectInfo.label.Tags.Tooltip' })}
                    placement={TooltipPlacement.TOP}/>
                </h2>
                <div className={styles.field}>
                  <Tags
                    tags={projectInfo.tags}
                    onAddNewTag={this.addNewProjectTag}
                    onRemoveTag={this.removeProjectTag}
                    error={getValidationErrorMessage('tags', validationErrors)}
                  />
                </div>
              </div>
            </div>
          </div>
          <Separator/>
          {!isVirtualSimOnlyEnable && <>
           <div className={styles.halfContainer}>
              <div className={cn(styles.itemBox, styles.whiteBox)}>
                <h2 className={styles.boxTitle}>{intl.formatMessage({ id: 'MursionPortal.CheckboxLabel.sessionMissedTime' })}
                  <InfoTooltip
                    text={intl.formatMessage({ id: 'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text' })}
                    placement={TooltipPlacement.TOP} />
                </h2>
                <label htmlFor="sessionMissedTime"
                  className="visibilityHidden">{intl.formatMessage({ id: 'MursionPortal.CheckboxLabel.sessionMissedTime' })}
                </label>
                <div className={cn(styles.value, styles.field, styles.demandWindowInputwrap, styles.upcomingAvailability)}>
                  <NumericField
                    disabled={false}
                    id='sessionMissedTime'
                    min={MIN_SESSION_MISSED_TIME}
                    validationState={getValidationErrorMessage('sessionMissedTime', validationErrors) ? 'error' : undefined}
                    value={null}
                    onChange={this.onSessionMissedTimePropChange('sessionMissedTime', true)} />
                  <div className={styles.hours}>
                    {intl.formatMessage({ id: 'MursionPortal.Title.Minutes' })}
                  </div>
                </div>
              </div>
            </div><div className={styles.body}>
                <div className={styles.halfContainer}>
                  <div className={cn(styles.itemBox, styles.whiteBox)}>
                    <h2
                      className={styles.boxTitle}>{intl.formatMessage({ id: 'MursionPortal.Title.DemandBasedSessionScheduling' })}</h2>
                    <label htmlFor="schedule-demand-checkbox"
                      className="visibilityHidden">{TranslateMessage('MursionPortal.Project.TimeZoneStartDate')}</label>
                    <div className={styles.field}>
                      <Checkbox
                        className={styles.checkbox}
                        checked={projectInfo.demandBasedScheduling}
                        id={'schedule-demand-checkbox'}
                        onChange={this.demandBasedSchedulingChange}
                      >
                        {intl.formatMessage({ id: 'MursionPortal.CheckboxLabel.SchedulingByBookIt' })}
                      </Checkbox>
                    </div>
                    {showJoinNow &&
                      <div className={styles.field}>
                        <Checkbox
                          className={styles.checkbox}
                          checked={projectInfo.joinNow}
                          id={'join-now-checkbox'}
                          onChange={this.joinNowChange}
                          tooltipText={`${intl.formatMessage({ id: 'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text' })}`}
                        >
                          {intl.formatMessage({ id: 'MursionPortal.CheckboxLabel.JoinNow' })}
                        </Checkbox>
                      </div>}

                    {<div className={styles.field}>
                      <Checkbox
                        className={styles.checkbox}
                        checked={this.state.sessionTimeStepFlag}
                        id={'session-timestep-checkbox'}
                        onChange={this.onSessiontimeStepChange}
                        tooltipText={intl.formatMessage({ id: 'Projects.ProjectInfo.SchedulingInterval.Tooltip' })}
                      >
                        {intl.formatMessage({ id: 'Projects.ProjectInfo.SchedulingInterval' })}
                      </Checkbox>
                    </div>}
                    {this.state.sessionTimeStepFlag &&
                      <>
                        <h2 className={styles.boxTitle}>
                          {TranslateMessage('Projects.ProjectInfo.SchedulingIntervalLength')}
                          <InfoTooltip text={intl.formatMessage({ id: 'Projects.ProjectInfo.SchedulingIntervalLength.tooltip' })} placement={TooltipPlacement.TOP} />
                        </h2>
                        <div className={styles.field}>
                          <NumericField
                            id="project-session-timestep"
                            min={1}
                            value={this.state.sessionTimeStepVal}
                            onChange={this.sessionTimeStepChange}
                            onKeyDown={this.checkNumericInput} />
                          {this.state.isTimeStepInvalid && <ErrorMessage className={styles.timeStepError} message={intl.formatMessage({ id: 'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast' }, { minutes: 1 })} />}
                        </div>
                      </>}
                  </div>
                </div>
              </div>        
          <Separator />
          </>
          }
          {!isVirtualSimOnlyEnable && projectInfo.demandBasedScheduling && <>
          <div className={styles.body}>
            <div className={styles.halfContainer}>
              <div className={cn(styles.itemBox, styles.whiteBox)}>
                <h2
                  className={styles.boxTitle}>{intl.formatMessage({ id: 'MursionPortal.CheckboxLabel.DemandTime' })}
                    <InfoTooltip
                      text={intl.formatMessage({ id: 'Projects.ProjectInfo.label.DemandTime.Tooltip' })}
                      placement={TooltipPlacement.TOP} />
                  </h2>
                <label htmlFor="timepicker-id-one"
                       className="visibilityHidden">{TranslateMessage('MursionPortal.Label.DemandTimeStart')}</label>
                <label htmlFor="timepicker-id-two"
                       className="visibilityHidden">{TranslateMessage('MursionPortal.Label.DemandTimeEnd')}</label>
                <div className={cn(styles.value, styles.field)} id={'timepicker-id'}>
                  <div className={cn(styles.value, styles.field)}>
                    <TimePicker
                      id={'timepicker-id-one'}
                      date={demandBasedStartTimeWithTZ || moment.tz(timezoneID)}
                      format={getTimeFormat()}
                      maxTime={getMaxTime(timezoneID)}
                      minTime={getMinTime(timezoneID)}
                      stepMinutes={30}
                      time={demandBasedStartTimeWithTZ || moment.tz(timezoneID).startOf('d')}
                      tzId={timezoneID}
                      onChange={this.handledemandBasedSchedulingTime('startTime')}
                    />
                    -
                    <TimePicker
                      id={'timepicker-id-two'}
                      date={demandBasedEndTimeWithTZ || moment.tz(timezoneID)}
                      format={getTimeFormat()}
                      maxTime={getMaxTime(timezoneID)}
                      minTime={moment(demandBasedStartTimeWithTZ, 'HH:mm').add('30', 'minutes') ||  moment.tz(timezoneID).startOf('d')}
                      stepMinutes={30}
                      time={demandBasedEndTimeWithTZ || moment.tz(timezoneID).endOf('d')}
                      tzId={timezoneID}
                      onChange={this.handledemandBasedSchedulingTime('endTime')}
                    /></div>
                </div>
              </div>
            </div>
            <div className={cn(styles.halfContainer, styles.halfContainerRight)}>
              <div className={cn(styles.itemBox, styles.whiteBox)}>
                <h2 className={styles.boxTitle}>{intl.formatMessage({ id: 'MursionPortal.Table.ColumnHeader.SchedulingWindow' })}
                  <InfoTooltip
                    text={intl.formatMessage({ id: 'MursionPortal.Tooltip.SchedulingWindow' })}
                    placement={TooltipPlacement.TOP}/>
                </h2>
                <label htmlFor="demand-window-input"
                       className="visibilityHidden">{TranslateMessage('MursionPortal.Project.TimeZoneStartDate')}</label>
                <div className={cn(styles.value, styles.field, styles.demandWindowInputwrap)}>
                  <NumericField
                    id="demand-window-input"
                    min={1}
                    maxLength={15}
                    className={styles.dayInput}
                    value={projectInfo.demandWindow || 0}
                    onChange={this.onDemandWindowChange}
                    error={getValidationErrorMessage('demandWindow', validationErrors)}
                    validationState={getValidationErrorMessage('demandWindow', validationErrors) ? 'error' : undefined}
                  />
                    <A11ySelector
                      options={demandWindowDropdownOptions}
                      defaultValue={this.state.projectInfo.demandWindowType}
                      onChange={this.onDemandWindowTypeChange}
                      className={styles.demandWindowDropdown}
                    />
                </div>
              </div>
            </div>
          </div>
            {
              showDeliveryHoursField &&
              <div className={styles.body}>
                <div className={styles.halfContainer}>
                  <div className={cn(styles.itemBox, styles.whiteBox, styles.deliveryHoursContent)}>
                    <h2
                      className={styles.boxTitle}>{intl.formatMessage({ id: 'MursionPortal.ProjectCreate.DeliveryHours' })}
                      <InfoTooltip
                        text={intl.formatMessage({ id: 'MursionPortal.ProjectCreate.DeliveryHours.Tooltip' })}
                        placement={TooltipPlacement.TOP} />
                    </h2>
                    <div className={styles.deliveryHoursRadioGroup}>
                      <RadioGroup
                        name={intl.formatMessage({ id: 'MursionPortal.ProjectCreate.DeliveryHours' })}
                        selectedValue={projectInfo.deliveryHoursType}
                        onChange={this.onDeliveryHoursTypeChange}
                      >
                        <Radio value={DeliveryHoursType.STANDARD} label={intl.formatMessage({ id: 'MursionPortal.Project.DeliveryHours.Standard' })} />
                        <Radio value={DeliveryHoursType.GLOBAL} label={intl.formatMessage({ id: 'MursionPortal.Project.DeliveryHours.Global' })} />
                      </RadioGroup>
                    </div>
                    {
                      projectInfo.deliveryHoursType === DeliveryHoursType.STANDARD &&
                      <>
                        <label htmlFor='project-create-delivery-start'
                          className='visibilityHidden'>{intl.formatMessage({ id: 'MursionPortal.ProjectCreate.DeliveryHours.Start' })}</label>
                        <label htmlFor='project-create-delivery-end'
                          className='visibilityHidden'>{intl.formatMessage({ id: 'MursionPortal.ProjectCreate.DeliveryHours.End' })}</label>

                        <div className={cn(styles.value, styles.field)} id={'timepicker-id'}>
                          <div className={cn(styles.value, styles.field)}>
                            <TimePicker
                              id={'project-create-delivery-start'}
                              date={deliveryHoursStartTimeWithTZ || moment.tz(timezoneID)}
                              format={getTimeFormat()}
                              maxTime={getMaxTime(timezoneID)}
                              minTime={getMinTime(timezoneID)}
                              stepMinutes={30}
                              time={deliveryHoursStartTimeWithTZ || moment.tz(timezoneID).startOf('d')}
                              tzId={getTimezoneId(projectInfo.timezoneId)}
                              onChange={this.handleDeliveryHoursChange(DeliveryHoursTimeType.START_TIME)}
                            />
                            -
                            <TimePicker
                              id={'project-create-delivery-end'}
                              date={deliveryHoursEndTimeWithTZ || moment.tz(timezoneID)}
                              format={getTimeFormat()}
                              maxTime={getMaxTime(timezoneID)}
                              minTime={getMinTime(timezoneID)}
                              stepMinutes={30}
                              time={deliveryHoursEndTimeWithTZ || moment.tz(timezoneID).endOf('d')}
                              tzId={getTimezoneId(projectInfo.timezoneId)}
                              onChange={this.handleDeliveryHoursChange(DeliveryHoursTimeType.END_TIME)}
                            />
                          </div>
                        </div>
                      </>
                    }

                    <div className={styles.deliveryHoursDays}>
                      {
                        getDeliveryWeekDaysCheckboxesDetails(projectInfo, intl).map((checkbox) => {
                          return (
                            <Checkbox
                              onChange={this.onDeliveryWeekDaysCheckboxChange(checkbox.field)}
                              checked={checkbox.value}
                              key={checkbox.label}
                            >
                              {checkbox.label}
                            </Checkbox>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          <div className={styles.body}>
                    <div className={styles.halfContainer}>
                    <div className={cn(styles.itemBox, styles.whiteBox)}>
                      <h2 className={styles.boxTitle}>{`${intl.formatMessage({ id: 'MursionPortal.Project.DemandSlots' })}:`} 
                      <InfoTooltip
                       text={intl.formatMessage({ id: 'Projects.ProjectInfo.label.DemandSlots.Tooltip' })}
                       placement={TooltipPlacement.TOP} />
                      </h2>
                      <label htmlFor="demand-based-slots-count"
                        className="visibilityHidden">{intl.formatMessage({ id: 'MursionPortal.Project.DemandSlots' })}</label>
                      <div className={cn(styles.value, styles.field)}>
                        <FieldGroup
                          id="demand-based-time-slots-count"
                          as={'select'}
                          placeholder={`${intl.formatMessage({ id: 'MursionPortal.Project.DemandSlots' })}:`}
                          defaultValue={projectInfo.demandBasedSlotsCount}
                          style={{ width: '120px' }}
                          onChange={this.onDemandSlotsChange}
                        >
                          {
                            _range(0, 4).map(key => {
                              return (
                                <option
                                  key={'demand-based-time-slots-'+key}
                                  value={key}>
                                  {key}
                                </option>
                              );
                            })
                          }
                        </FieldGroup>
                      </div>
                    </div>
                  </div>
                  <div className={cn(styles.halfContainer, styles.halfContainerRight)}>
                    <div className={cn(styles.itemBox, styles.whiteBox)}>
                      <h2 className={styles.boxTitle}>{TranslateMessage('Projects.ProjectInfo.Label.CancellationDeadline')} <span
                        aria-hidden="true">*</span> <InfoTooltip
                        text={intl.formatMessage({ id: 'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip' })}
                        placement={TooltipPlacement.TOP}/></h2>
                      <label htmlFor="cancellation-window"
                       className="visibilityHidden">{TranslateMessage('Projects.ProjectInfo.Label.CancellationDeadline')}</label>
                      <div  className={cn(styles.value, styles.field, styles.demandWindowInputwrap)}>
                        <FieldGroup
                          id="cancellation-window"
                          label=""
                          min={1}
                          type="number"
                          error={getValidationErrorMessageForCancellationWindow(validationErrors, intl, cancellationWindowValue, projectInfo.cancellationWindow)}
                          validationState={getValidationErrorMessage('cancellationWindow', validationErrors) ? 'error' : undefined}
                          value={cancellationWindowValue}
                          onChange={this.onCancellationWindowPropChange('cancellationWindow', false)}
                          onKeyDown={this.checkNumericInput}
                          className={styles.dayInput}
                          required={true}
                        />
                        <A11ySelector
                            options={demandWindowDropdownOptions}
                            value={projectInfo.cancellationWindowType}
                            onChange={this.onCancellationWindowTypeChange}
                            className={styles.demandWindowDropdown}
                          />
                      </div>
                    </div>
                   </div>
                  </div>
                  <Separator/>
            </>
          }
          { !isVirtualSimOnlyEnable &&
            <><div className={styles.body}>
              {<div className={styles.halfContainer}>
                <div className={cn(styles.itemBox, styles.whiteBox)}>
                  <h2 className={styles.boxTitle}>{intl.formatMessage({ id: 'Projects.ProjectInfo.Label.ShowSIMAvailaiblity' })}
                    <InfoTooltip
                      text={intl.formatMessage({ id: 'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip' })}
                      placement={TooltipPlacement.TOP} />
                  </h2>
                  <label htmlFor="show-availability"
                    className="visibilityHidden">{intl.formatMessage({ id: 'Projects.ProjectInfo.Label.ShowSIMAvailaiblity' })}
                  </label>
                  <div className={cn(styles.value, styles.field, styles.demandWindowInputwrap)}>
                    {projectInfo.showAvailabilityType !== ShowAvailabilityType.ALL &&
                      <FieldGroup
                        id="show-availability"
                        min={1}
                        type="number"
                        error={getValidationErrorMessageForShowAvailability(validationErrors, intl, showAvailability, showAvailabilityType)}
                        value={getValueAccordingToType(showAvailability, showAvailabilityType)}
                        onChange={this.onShowAvailabilityChange}
                        onKeyDown={this.checkNumericInput}
                        className={styles.dayInput}
                        required={true} />}
                    <A11ySelector
                      options={getShowAvailabilityOptions(intl)}
                      value={showAvailabilityType}
                      onChange={this.onShowAvailabilityTypeChange}
                      className={styles.demandWindowDropdown} />
                  </div>
                </div>
              </div>}
              {<div className={styles.halfContainer}>
                <div className={cn(styles.itemBox, styles.whiteBox)}>
                  <h2 className={styles.boxTitle}>{intl.formatMessage({ id: 'MursionPortal.ProjectCreateStep.UpcomingAvailability' })}
                    <InfoTooltip
                      text={intl.formatMessage({ id: 'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip' })}
                      placement={TooltipPlacement.TOP} />
                  </h2>
                  <label htmlFor="upcoming-availability"
                    className="visibilityHidden">{intl.formatMessage({ id: 'MursionPortal.ProjectCreateStep.UpcomingAvailability' })}
                  </label>
                  <div className={cn(styles.value, styles.field, styles.demandWindowInputwrap, styles.upcomingAvailability)}>
                    {<FieldGroup
                      id="upcoming-availability"
                      min={MIN_UPCOMING_AVAILABILITY}
                      max={MAX_UPCOMING_AVAILABILITY_IN_HOURS}
                      type="number"
                      error={getValidationError(validationErrors, ProjectCreateStepField.UPCOMING_AVAILABILITY, upcomingAvailability, intl)}
                      value={moment.duration(upcomingAvailability, 'seconds').asHours()}
                      onChange={this.onPropChange(ProjectCreateStepField.UPCOMING_AVAILABILITY, true)}
                      onKeyDown={this.checkNumericInput}
                      className={styles.dayInput}
                      required={true} />}
                    <div className={styles.hours}>
                      {intl.formatMessage({ id: 'MursionPortal.Project.Hours' })}
                    </div>
                  </div>
                </div>
              </div>}
            </div><Separator />
            </>
          }
         {userProfile?.licenseeId?.toLowerCase() === 'mursion' && 
          <div className={styles.body}>
            <div className={styles.halfContainer}>
              <div className={cn(styles.itemBox, styles.whiteBox)}>
                <h2
                  className={styles.boxTitle}>{TranslateMessage('Projects.ProjectInfo.Label.SurveyControl')}</h2>
                <div className={styles.field}>
                  <ProjectSurveyControl
                    editMode={true}
                    projectId={null}
                    clientId={client.id}
                    licenseeId={userProfile?.licenseeId}
                    intl={intl}
                    setSurveyConfig={saveSurveyConfig}
                    isVirtualSimOnlyEnable={isVirtualSimOnlyEnable}
                  />
                </div>
                <div className={styles.field}>
                  <Checkbox
                    className={styles.checkbox}
                    disabled={isVirtualSimOnlyEnable}
                    checked={projectInfo.surveyEnabled}
                    id={'survey-demand-checkbox'}
                    onChange={this.onSurveyChange}>
                    {intl.formatMessage({ id: 'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey' })}
                  </Checkbox>
                </div>
                <div className={styles.field}>
                  <Checkbox
                    className={styles.checkbox}
                    disabled={isVirtualSimOnlyEnable}
                    checked={projectInfo.simPostSimulationSurveyOneToOne}
                    id={'survey-demand-checkbox'}
                    onChange={this.onOneToOneSurveyChange}>
                    {intl.formatMessage({ id: 'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne' })}
                  </Checkbox>
                </div>
                <div className={styles.field}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={projectInfo.simPostSimulationSurveyWorkshop}
                    disabled={isVirtualSimOnlyEnable}
                    id={'survey-demand-checkbox'}
                    onChange={this.simPostSimulationSurveyWorkshop}>
                    {intl.formatMessage({ id: 'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop' })}
                  </Checkbox>
                </div>
                
              </div>
            </div>
            {projectInfo.simPostSimulationSurveyOneToOne ? (
                  <div className={styles.field}>
                    <h4 className={cn(styles.boxTitle, 'mb-3')}> {intl.formatMessage({ id: 'MursionPortal.Learner.Session.Report' })}</h4>
                    <div>
                        <Checkbox
                            className={styles.checkbox}
                            checked={projectInfo.enabledReportOneToOneDeliveries}
                            id={'survey-checkbox'}
                            onChange={this.enabledReportOneToOneDeliveriesChange}
                            >
                            {intl.formatMessage({ id: 'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne' })}:
                        </Checkbox>
                    </div>
                </div>
                ): null}
          </div>

        }
        { !isVirtualSimOnlyEnable && 
          <>
           <Separator />
            <div className={styles.body}>
              <div className={styles.halfContainer}>
                <div className={cn(styles.itemBox, styles.whiteBox)}>
                  <h2 className={styles.boxTitle}>{intl.formatMessage({ id: 'MursionPortal.Project.Rescheduling' })}</h2>
                  <label htmlFor="reschedule-demand-checkbox"
                    className="visibilityHidden">{intl.formatMessage({ id: 'MursionPortal.Project.Rescheduling' })}</label>
                  <div className={styles.field}>
                    <Checkbox
                      className={styles.checkbox}
                      checked={projectInfo.reschedulingEnabled}
                      id={'reschedule-demand-checkbox'}
                      onChange={this.onReschedulingChange}
                      tooltipText={intl.formatMessage({ id: 'Projects.ProjectInfo.label.Rescheduling.Tooltip' })}>
                      {intl.formatMessage({ id: 'MursionPortal.Project.Rescheduling' })}
                    </Checkbox>
                  </div>

                  {projectInfo.reschedulingEnabled &&
                    <>
                      <label htmlFor="sameDay-checkbox"
                        className="visibilityHidden">{intl.formatMessage({ id: 'MursionPortal.Project.SameDay' })}</label>
                      <div className={styles.field}>
                        <Checkbox
                          className={styles.checkbox}
                          checked={projectInfo.sameDayRescheduling}
                          id={'sameDay-checkbox'}
                          onChange={this.onSameDayChange}
                          tooltipText={intl.formatMessage({ id: 'MursionPortal.Project.SameDay.Tooltip' })}>
                          {intl.formatMessage({ id: 'MursionPortal.Project.SameDay' })}
                        </Checkbox>
                      </div>
                    </>}
                </div>
              </div>
            </div>
          </>
          }
          <Separator />
          <div className={styles.body}>
            <div className={styles.halfContainer}>
              <div className={cn(styles.itemBox, styles.whiteBox)}>
                <h2 className={styles.boxTitle}>{intl.formatMessage({ id: 'MursionPortal.Project.Sequencing' })}</h2>
                <label htmlFor="sequencing-checkbox"
                  className="visibilityHidden">{intl.formatMessage({ id: 'MursionPortal.Project.Sequencing' })}</label>
                <div className={styles.field}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={projectInfo?.sequencing}
                    id={'sequencing-checkbox'}
                    onChange={this.onSequencingChange}>
                    {intl.formatMessage({ id: 'MursionPortal.Project.Checkbox.Sequencing' })}
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
          {
            <>
              <Separator />
              <div className={styles.topContainer}>
                <div className={cn(styles.scenarioSelectionWwrap, 'px-3')}>
                  <ScenarioSelection editMode={true} className={'projectScenarioSelection'} scenarioSettings={clientConfigScenarioSelection} onChange={this.onChangeScenarioSetting} higerLevelSettings={clientConfigValues} scenarioSettingErrorMessage={this.state.scenarioSettingCheckMessage} onErrorChange={this.onChangeSettingErrorMessage} />
                </div>
              </div>
            </>
          }
        </div>
        <Row>
          <Col>
            {!validationErrors.length && <ErrorMessage message={errorMessage}/>}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.scenarioSettingCheckMessage && <ErrorMessage message={this.state.scenarioSettingCheckMessage} />}
          </Col>
        </Row>
        <Row>
          <Col className={styles.submitButtonContainer} xs={12}>
            {
              !this.props.finished && (
                <Button
                  btnSize={ButtonSize.MEDIUM}
                  onClick={this.onCancel}
                  aria-label={intl.formatMessage({ id: 'MursionPortal.Cancel' })}
                >
                  {TranslateMessage('MursionPortal.Cancel')}
                </Button>
              )
            }
            {
              (needReason && this.props.finished)
                ? <ConfirmButton
                  type={ConfirmButtonType.PROJECT_CHANGE}
                  buttonStyle={true}
                  confirmationText={intl.formatMessage({ id: 'Clients.Modal.Projects.ConfirmationText.EditProject' })}
                  buttonTitle={intl.formatMessage({ id: 'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo' })}
                  disabled={this.state.isTimeStepInvalid || submitDisable}
                  onConfirm={this.submitStep}
                  addReason={needReason}
                />
                : <Button
                  btnSize={ButtonSize.MEDIUM}
                  btnFont={ButtonFont.LIGHT}
                  disabled={this.state.isTimeStepInvalid || submitDisable}
                  onClick={this.submitStepWithoutReason}
                  aria-label={intl.formatMessage({ id: 'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo' })}
                >
                  {TranslateMessage('Clients.Modal.Projects.ButtonTitle.SaveProjectInfo')}
                </Button>
            }
          </Col>
        </Row>
      </>
    );
  }

  private renderProjectManagerSelect() {
    const { usersInfo, intl } = this.props;

    return (
      <>
        <h2 className={styles.boxTitle}>{TranslateMessage('Clients.Modal.Projects.Header.ProjectManager')}</h2>
        <div className={styles.field}>
          <ItemPicker
            listClassName={styles.accManagersList}
            ItemComponent={UsersPickerComponent}
            items={this.wrapAccManagers()}
            itemsFetchError={usersInfo.fetchError}
            itemsFetched={usersInfo.fetched}
            itemsFetching={usersInfo.fetching}
            emptyListMessage={intl.formatMessage({ id: 'Clients.Modal.Projects.ProjectsEmptyListMessage' })}
            keyExtractor={this.extractKey}
            selectedItemTitleExtractor={this.extractAccManagerTitle}
            onChange={this.onAccManagersChange}
            className={ItemStyles.itemPickerContainer}
          />
        </div>
      </>
    );
  }

  private onPropChange = (propName: keyof IProject, numeric: boolean) => (event: any) => {
    const { projectInfo } = this.state;
    let { value } = event.target;

    if (numeric && /^0\d+$/.test(value)) { // remove the leading 0 in numeric values
      value = value.substring(1, value.length);
    }

    if(propName === ProjectCreateStepField.UPCOMING_AVAILABILITY) {
      value = moment.duration(value, 'hours').asSeconds();
    }

    this.props.clearValidationErrors();

    this.setState({
      projectInfo: {
        ...projectInfo,
        [propName]: value,
      },
      needReason: true,
      isChanged: true,
    });
  };

  
  private onCancellationWindowPropChange = (propName: keyof IProject, numeric: boolean) => (event: any) => {
    const { projectInfo } = this.state;
    let { value } = event.target;

    if (numeric && /^0\d+$/.test(value)) { // remove the leading 0 in numeric values
      value = value.substring(1, value.length);
    }

    const cancellationWindowVal = daysHoursMinutesToSecondsConverter(value, projectInfo.cancellationWindowType);

    this.props.clearValidationErrors();

    this.setState({
      projectInfo: {
        ...projectInfo,
        [propName]: cancellationWindowVal,
      },
      needReason: true,
      isChanged: true,
    });
  };

  private demandBasedSchedulingChange = (e: any) => {
    const { projectInfo } = this.state;
    const demandBasedScheduling = e.target.checked;
    const demandWindow = demandBasedScheduling ? 2 : null;
    const timezoneId = demandBasedScheduling ? this.props.clientTzId : null;
    const demandBasedSchedulingStartTime = demandBasedScheduling ? moment().startOf('d').add(9, 'h').format('HH:mm') : null;
    const demandBasedSchedulingEndTime = demandBasedScheduling ? moment().startOf('d').add(17, 'h').format('HH:mm') : null;
    const deliveryHoursDefaultTime = getDeliveryHoursDefaultTime(TIMEZONE_ID_EST, this.props.clientTzId || moment.tz.guess());
    const deliveryHoursStartTime = demandBasedScheduling ? deliveryHoursDefaultTime.startTime : null;
    const deliveryHoursEndTime = demandBasedScheduling ? deliveryHoursDefaultTime.endTime : null;
    const demandWindowType = DemandWindowType.DAYS;
    let joinNow = projectInfo.joinNow;
    if (!demandBasedScheduling) {
      joinNow = demandBasedScheduling;
    }
    this.setState({
      projectInfo: {
        ...projectInfo,
        timezoneId,
        demandBasedScheduling,
        demandWindow,
        demandBasedSchedulingStartTime,
        demandBasedSchedulingEndTime,
        deliveryHoursStartTime,
        deliveryHoursEndTime,
        deliveryHoursType: DeliveryHoursType.STANDARD,
        deliveryWeekDays: {
          deliveryOnMonday: true,
          deliveryOnTuesday: true,
          deliveryOnWednesday: true,
          deliveryOnThursday: true,
          deliveryOnFriday: true,
          deliveryOnSaturday: false,
          deliveryOnSunday: false
        },
        demandWindowType,
        joinNow,
      },
      needReason: true,
      isChanged: true,
    });
  };

  private joinNowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { projectInfo } = this.state;

    const joinNow = event.target.checked;
    if (joinNow) {
      const demandBasedScheduling = joinNow;
      const demandWindow = demandBasedScheduling ? 2 : null;
      const timezoneId = demandBasedScheduling ? this.props.clientTzId : null;
      const demandBasedSchedulingStartTime = demandBasedScheduling ? moment().startOf('d').add(9, 'h').format('HH:mm') : null;
      const demandBasedSchedulingEndTime = demandBasedScheduling ? moment().startOf('d').add(17, 'h').format('HH:mm') : null;
      const demandWindowType = DemandWindowType.DAYS;
      this.setState({
        projectInfo: {
          ...projectInfo,
          timezoneId,
          demandBasedScheduling,
          demandWindow,
          demandBasedSchedulingStartTime,
          demandBasedSchedulingEndTime,
          demandWindowType,
          joinNow,
        },
        needReason: true,
        isChanged: true,
      });
    } else {
      this.setState({
        projectInfo: {
          ...projectInfo,
          joinNow,
        },
        needReason: true,
        isChanged: true,
      });
    }
  };

  private onSessionMissedTimePropChange = (propName: keyof IProject, numeric: boolean) => (event: any) => {
    const { projectInfo } = this.state;
    let { value } = event.target;

    if (numeric && /^0\d+$/.test(value)) { // remove the leading 0 in numeric values
      value = value.substring(1, value.length);
    }

    if (value === 0){
      value = MIN_SESSION_MISSED_TIME;
    }

    const sessionMissedTime = getSessionMissedTime(value);

    this.props.clearValidationErrors();

    this.setState({
      projectInfo: {
        ...projectInfo,
        [propName]: sessionMissedTime,
      },
      isChanged: true,
    });
  };

  private handledemandBasedSchedulingTime = (state: 'startTime' | 'endTime') => (momentDate: moment.Moment) => {
    const { projectInfo } = this.state;
    const project: IProject = getDemandBasedSchedulingTime(projectInfo.demandBasedSchedulingEndTime, state, momentDate);
    this.setState({
      projectInfo: {
        ...projectInfo,
        ...project,
      },
      needReason: true,
      isChanged: true,
    });
  };

  private handleDeliveryHoursChange = (deliveryHoursType: DeliveryHoursTimeType) => (momentDate: moment.Moment) => {
    const { projectInfo } = this.state;
    const deliveryHours = getDeliveryHours(deliveryHoursType, momentDate);

    this.setState({
      projectInfo: {
        ...projectInfo,
        deliveryHoursStartTime: deliveryHours.startTime || projectInfo.deliveryHoursStartTime,
        deliveryHoursEndTime: deliveryHours.endTime || projectInfo.deliveryHoursEndTime,
      },
      needReason: true,
      isChanged: true,
    });
  };

  private handleProjectTimezoneChange = (newTzId: string) => {
    const { projectInfo } = this.state;
    const { startDate, endDate, timezoneId } = projectInfo;

    if (timezoneId && startDate && endDate) {
      const oldStartDate = moment.tz(projectInfo.startDate, timezoneId).startOf('day');
      const oldEndate = moment.tz(projectInfo.endDate, timezoneId).endOf('day');
      const newStartDate = moment.tz(startDate, newTzId).startOf('day');
      const newEndDate = moment.tz(endDate, newTzId).endOf('day');

      // year, month, date of new dates are same as old dates, only timezone is changed
      copyDate(oldStartDate, newStartDate);
      copyDate(oldEndate, newEndDate);

      this.setState({
        projectInfo: {
          ...projectInfo,
          startDate: momentDateToTimestampMillis(newStartDate),
          endDate: momentDateToTimestampMillis(newEndDate),
        },
      });
    }

    this.setState(prevState => ({
      projectInfo: {
        ...prevState.projectInfo,
        timezoneId: newTzId,
      },
      needReason: true,
      isChanged: true,
    }));
  };

  private onDemandWindowChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { projectInfo } = this.state;
    const demandWindow = parseInt(e.target.value, 10);

    this.setState({
      projectInfo: {
        ...projectInfo,
        demandWindow,
      },
      needReason: true,
      isChanged: true,
    });
  };

  private onShowAvailabilityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { projectInfo } = this.state;
    const value = parseInt(e.target.value, 10);
    const showAvailability = daysHoursMinutesToSecondsConverter(value, projectInfo.showAvailabilityType);

    this.setState({
      projectInfo: {
        ...projectInfo,
        showAvailability,
      },
      needReason: true,
      isChanged: true,
    });
  };

  private onShowAvailabilityTypeChange = (value: ShowAvailabilityType) => {
    const { projectInfo } = this.state;

    this.setState({
      projectInfo: {
        ...projectInfo,
        showAvailability: value === ShowAvailabilityType.ALL ? 0 : projectInfo.showAvailability,
        showAvailabilityType: value,
      },
      needReason: true,
      isChanged: true,
    });
  };

  
  private onCancellationWindowTypeChange = (value: DemandWindowType) => {
    this.props.clearValidationErrors();
    const { projectInfo } = this.state;
   
    this.setState({
      projectInfo: {
        ...projectInfo,
        cancellationWindowType:value,
        cancellationWindow:projectInfo.cancellationWindow,
      },
      needReason: true,
      isChanged: true,
    });
};

  private onDemandWindowTypeChange = (value: DemandWindowType) => {
    const { projectInfo } = this.state;
   
    this.setState({
      projectInfo: {
        ...projectInfo,
        demandWindowType:value,
      },
      needReason: true,
      isChanged: true,
    });
   
};

  private onDeliveryHoursTypeChange = (value: DeliveryHoursType) => {
    const { projectInfo } = this.state;
    const newProjectInfo = cloneDeep(projectInfo);

    newProjectInfo.deliveryHoursType = value;

    if (value === DeliveryHoursType.GLOBAL) {
      newProjectInfo.deliveryHoursEndTime = null;
      newProjectInfo.deliveryHoursStartTime = null;
    } else {
      newProjectInfo.deliveryHoursEndTime = generateNewProjectData().deliveryHoursEndTime;
      newProjectInfo.deliveryHoursStartTime = generateNewProjectData().deliveryHoursStartTime;
    }

    this.setState({
      projectInfo: newProjectInfo,
      needReason: true,
      isChanged: true,
    });
  };

  private onDeliveryWeekDaysCheckboxChange = (field: DeliveryWeekDaysField) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { projectInfo } = this.state;

    this.setState({
      projectInfo: {
        ...projectInfo,
        deliveryWeekDays: {
          ...projectInfo.deliveryWeekDays,
          [field]: event.target.checked,
        }
      },
      needReason: true,
      isChanged: true,
    });
  }

  private onDemandSlotsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { projectInfo } = this.state;
    const demandBasedSlotsCount = parseInt(e.target.value, 10);
    this.setState({
      projectInfo: {
        ...projectInfo,
        demandBasedSlotsCount,
      },
      needReason: true,
      isChanged: true,
    });
  };

  private onChangeBillable = (e: any) => {
    const { projectInfo } = this.state;

    this.props.clearValidationErrors();

    this.setState({
      projectInfo: {
        ...projectInfo,
        billable: !e.target.checked,
      },
      needReason: true,
      isChanged: true,
    });
  };

  private onProjectDatesFocusChange = (dateFocusedInput: 'startDate' | 'endDate' | null) => {
    this.setState({
      dateFocusedInput,
    });
  };

  private onRecordingAllowedChange = () => {
    const projectInfo: IProject = {
      ...this.state.projectInfo,
      recordingAllowed: !this.state.projectInfo.recordingAllowed,
      recordingRecipients: [],
      recordingLearnerChoice: true,
      sessionSmartMetrics: false,
    };

    this.setState({
      projectInfo,
      needReason: true,
      isChanged: true,
    });
  };

  private onSharingOrDownloadingChange = () => {
    const projectInfo: IProject = {
      ...this.state.projectInfo,
      recordingDoNotShare: !this.state.projectInfo.recordingDoNotShare,
    };

    this.setState({
      projectInfo,
      needReason: true,
      isChanged: true,
    });
    
  };

  private onRecipientChange = (roleId: RoleID) => (e: any) => {

    const recordingRecipients = this.state.projectInfo.recordingRecipients || [];

    const projectInfo: IProject = {
      ...this.state.projectInfo,
      recordingRecipients: !!e.target.checked
        ? recordingRecipients.concat(roleId)
        : recordingRecipients.filter((r: RoleID) => (r !== roleId)),
      recordingLearnerChoice: roleId === RoleID.LEARNER ? true : this.state.projectInfo.recordingLearnerChoice,
    };

    this.setState({
      projectInfo,
      needReason: true,
      isChanged: true,
    });
  };

  private onRecordingLearnerChoiceChange = () => {
    const projectInfo: IProject = {
      ...this.state.projectInfo,
      recordingLearnerChoice: !this.state.projectInfo.recordingLearnerChoice,
    };

    this.setState({
      projectInfo,
      needReason: true,
      isChanged: true,
    });
  };

  private onSmartMetricsAllowedChange = () => {
    const projectInfo: IProject = {
      ...this.state.projectInfo,
      sessionSmartMetrics: !this.state.projectInfo.sessionSmartMetrics,
    };

    this.setState({
      projectInfo,
      needReason: true,
      isChanged: true,
    });
  };

  private onProjectDatesChange = (datesObj: any) => {
    const { timezoneId } = this.state.projectInfo;
    let startDate = datesObj.startDate ? datesObj.startDate.clone() : null;
    let endDate = datesObj.endDate ? datesObj.endDate.clone() : null;

    if (startDate) {
      startDate = getStartDateFormat(timezoneId, startDate);
    } else {
      startDate = timezoneId ? moment.tz(startDate, timezoneId) : moment().utc();
    }
    
    if (endDate) {
      // end dates are considered by the server as the start of the next day (exclusively)
      endDate = getEndDateFormat(timezoneId, endDate);
    } else {
      endDate = getEndDateFromStartDateFormat(timezoneId, startDate);
    }

    this.setState({
      projectInfo: {
        ...this.state.projectInfo,
        endDate: momentDateToTimestampMillis(endDate),
        startDate: momentDateToTimestampMillis(startDate),
      },
      needReason: true,
      isChanged: true,
    });
  };

  private onCancel = () => {
    this.props.onCancel();
  };

  private getValidationState(fieldName: string) {
    return getValidationErrorMessage(fieldName, this.props.validationErrors) ? 'error' : undefined;
  }

  private addNewProjectTag = (tag: string) => {
    const { projectInfo } = this.state;
    projectInfo.tags.push(tag);

    this.setState({
      projectInfo,
      isChanged: true,
    });
  };

  private removeProjectTag = (tagIndex: number) => {
    const { projectInfo } = this.state;

    projectInfo.tags.splice(tagIndex, 1);

    this.setState({
      projectInfo,
      isChanged: true,
    });
  };

  private checkNumericInput = (event: any) => {
    const { key } = event;

    if (key.length === 1 && !/\d+/.test(key)) {
      event.preventDefault();
    }
  };

  private submitStepWithoutReason = () => {
    this.submitStep();
  };

  private submitStep = (reason?: string) => {
    const { intl } = this.props;
    this.onChangeSettingErrorMessage('');
    const timezoneID = getTimezoneId(this.state.projectInfo.timezoneId);
    if (getTimeUsingTZ(timezoneID, this.state.projectInfo.deliveryHoursStartTime) > getTimeUsingTZ(timezoneID, this.state.projectInfo.deliveryHoursEndTime)) {
      this.onChangeSettingErrorMessage(intl.formatMessage({ id: 'MursionPortal.ProjectCreate.DeliveryHours.Validation' }));
      return;
    }
    const configSurveyProject = { preSimulationSurvey: this.state.preSimulationSurvey};
    if (this.scenarioSettingsValidation()) {
      this.validateInitialScenarioSetting(this.props.clientConfig, this.state.projectInfo);
      return;
    }

    let updatedProjectInfo = this.state.projectInfo;

    if (this.state.projectInfo.virtualSimOnly) {
      updatedProjectInfo = { ...updatedProjectInfo, ...defaultProjectSettingsForAutoSim };
    }

    if (this.props.onSubmit) {
      this.props.onSubmit(updatedProjectInfo, reason, configSurveyProject);
    }
    this.setState({ needReason: false, isChanged: false });
  };

  private wrapAccManagers(): IPickerItem[] {
    return this.props.accManagers.map((manager: ICompanyUser) => ({
      data: manager,
      id: manager.id || '',
      selected: _findIndex(this.state.projectInfo.accManagers, (mngr: ICompanyUser) => mngr.id === manager.id) > -1,
    }));
  }

  private extractAccManagerTitle = (item: IPickerItem) => {
    return getUserName(item.data);
  };

  private extractKey = (item: IPickerItem, index: number) => {
    return `acc-manager-${item.id}`;
  };

  private onChangeScenarioSetting = (value: number, fieldName: string) => {
    this.setState({ projectInfo: { ...this.state.projectInfo, [fieldName]: value } });
  };

  private onChangeSettingErrorMessage = (message: string) => {
    this.setState({
      scenarioSettingCheckMessage: message,
      isChanged: true,
    });
  };

  private scenarioSettingsValidation = () => {
    const { clientConfig } = this.props;
    const { completionRate, schedulingRate, schedulingLockoutTime, missOrCancelationRate } = this.state.projectInfo;
    switch (true) {
      case clientConfig?.completionRate && completionRate && completionRate > clientConfig?.completionRate:
      case clientConfig?.schedulingRate && schedulingRate && schedulingRate > clientConfig?.schedulingRate:
      case clientConfig?.schedulingLockoutTime && schedulingLockoutTime && schedulingLockoutTime > clientConfig?.schedulingLockoutTime:
      case clientConfig?.missOrCancelationRate && missOrCancelationRate && missOrCancelationRate > clientConfig?.missOrCancelationRate:
      case schedulingLockoutNullCheck(missOrCancelationRate, schedulingLockoutTime):
        return true;
      case missOrCancelRateAndSchedulingLockoutNullCheck(missOrCancelationRate, schedulingLockoutTime):
        return false;
      default:
        return false;
    }
  };

  private onAccManagersChange = (item: IPickerItem) => {
    const { projectInfo } = this.state;
    const { accManagers } = projectInfo;
    const accManagerIndex = _findIndex(accManagers, { id: item.id });

    if (item.selected && accManagerIndex === -1) {
      accManagers.push(item.data);
    } else if (!item.selected && accManagerIndex > -1) {
      accManagers.splice(accManagerIndex, 1);
    }

    this.setState({
      projectInfo: {
        ...projectInfo,
        accManagers,
      },
      isChanged: true,
    });
  };

  private onProjectScenarioVersionChange = (scenarioVersion: ScenarioVersion) => {

    this.props.clearValidationErrors();

    this.setState({
      projectInfo: {
        ...this.state.projectInfo,
        scenarioVersion,
        sessionSmartMetrics: false,
        softwareType: scenarioVersion === ScenarioVersion.V3 ? SoftwareType.WEB : undefined,
      },
      needReason: false,
      isChanged: true,
    });
  };

  private onProjectSoftwareTypeChange = (softwareType: SoftwareType) => {

    this.props.clearValidationErrors();

    this.setState({
      projectInfo: {
        ...this.state.projectInfo,
        softwareType,
      },
      needReason: false,
      isChanged: true,
    });
  };

  private onReschedulingChange = (event : any)=>{
    const reschedulingEnabled = event.target.checked;
    const sameDayRescheduling = reschedulingEnabled? this.state.projectInfo.reschedulingEnabled : false;
    this.setState({projectInfo:{...this.state.projectInfo,reschedulingEnabled, sameDayRescheduling}});
  };

  private onAutoSimChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const virtualSimOnly = event.target.checked;
    const { projectInfo, preSimulationSurvey } = this.state;
    const { surveyEnabled, simPostSimulationSurveyWorkshop } = projectInfo;

    this.props.clearValidationErrors();

    this.setState({
      projectInfo: {
        ...projectInfo,
        virtualSimOnly,
        simPostSimulationSurveyOneToOne: !virtualSimOnly,
        enabledReportOneToOneDeliveries: !virtualSimOnly,
        simPostSimulationSurveyWorkshop: getProjectSettigsValue(virtualSimOnly, simPostSimulationSurveyWorkshop),
        surveyEnabled: getProjectSettigsValue(virtualSimOnly, surveyEnabled),
      },
      preSimulationSurvey: getProjectSettigsValue(virtualSimOnly, preSimulationSurvey),
      needReason: true,
      isChanged: true,
    });
  };

  private validateInitialScenarioSetting = (clientConfig: IClientConfig | null, projectInfo: IProject) => {
    const { intl } = this.props;
    const { completionRate, schedulingRate, missOrCancelationRate, schedulingLockoutTime } = projectInfo;
    const errorMessages = [];
    if (getScenarioSettingCheck(clientConfig?.completionRate, completionRate)) {
      errorMessages.push(
        `${intl.formatMessage({ id: 'MursionPortal.Label.Completion' })} ${intl.formatMessage({ id: 'MursionPortal.ScenarioSetting.ValidationMessage' })}`
      );
    }

    if (getScenarioSettingCheck(clientConfig?.schedulingRate, schedulingRate)) {
      errorMessages.push(
        `${intl.formatMessage({ id: 'MursionPortal.Label.SchedulingRate' })} ${intl.formatMessage({ id: 'MursionPortal.ScenarioSetting.ValidationMessage' })}`
      );
    }

    if (getScenarioSettingCheck(clientConfig?.schedulingLockoutTime, schedulingLockoutTime)) {
      errorMessages.push(
        `${intl.formatMessage({ id: 'MursionPortal.ScenarioInfo.SchedulingLockout.Time' })} ${intl.formatMessage({ id: 'MursionPortal.ScenarioSetting.ValidationMessage' })}`
      );
    }

    if (getScenarioSettingCheck(clientConfig?.missOrCancelationRate, missOrCancelationRate)) {
      errorMessages.push(
        `${intl.formatMessage({ id: 'MursionPortal.Label.MissCancellationRate' })} ${intl.formatMessage({ id: 'MursionPortal.ScenarioSetting.ValidationMessage' })}`
      );
    }

    if (schedulingLockoutNullCheck(missOrCancelationRate, schedulingLockoutTime)) {
      errorMessages.push(
        `${intl.formatMessage({ id: 'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage' })}`
      );
    }

    this.setState({
      scenarioSettingCheckMessage:  errorMessages.join('\n')
    });
  };

  private onSequencingChange = (event: any) => {
    const sequencing = event.target.checked;
    this.setState({ projectInfo: { ...this.state.projectInfo, sequencing } });
  };

  private onSurveyChange = (event : any)=>{
    this.setState({
      projectInfo: {
        ...this.state.projectInfo,
        surveyEnabled: event.target.checked,
      },
    });
  };

  private enabledReportOneToOneDeliveriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      projectInfo: {
        ...this.state.projectInfo,
        enabledReportOneToOneDeliveries: event.target.checked,
      },
    });
  }

  private onOneToOneSurveyChange = (event : any)=>{
    this.setState({
      projectInfo: {
        ...this.state.projectInfo,
        simPostSimulationSurveyOneToOne: event.target.checked,
        enabledReportOneToOneDeliveries: event.target.checked,
      },
    });
  };

  private simPostSimulationSurveyWorkshop = (event : any)=>{
    this.setState({
      projectInfo: {
        ...this.state.projectInfo,
        simPostSimulationSurveyWorkshop: event.target.checked,
      },
    });
  };

  private onSameDayChange = (event:any)=>{
    const sameDayRescheduling = event.target.checked;
    this.setState({projectInfo:{...this.state.projectInfo,sameDayRescheduling}});
  };

  private onSessiontimeStepChange = (event : any)=>{
    const timeStepEnabled = event.target.checked;
    const {companyConfig} = this.props;
    const defaultTimeStep = millisToMinutesConverter(companyConfig?.session.timeStep);
    {timeStepEnabled?
      this.setState({
        sessionTimeStepVal: defaultTimeStep,
        projectInfo: {
          ...this.state.projectInfo,
          sessionTimeStep: this.state.sessionTimeStepVal?minutesToMillisConverter(this.state.sessionTimeStepVal):null
        },
        sessionTimeStepFlag:true
      }):
      this.setState({
        sessionTimeStepVal:this.state.sessionTimeStepVal,
        projectInfo: {
          ...this.state.projectInfo,
          sessionTimeStep: null
        },
        sessionTimeStepFlag:false,
        isTimeStepInvalid: false
      });
    }
  };

  private sessionTimeStepChange = (event: any) => {
    const timeStepMinutes = event.target.value;
    const timestepValueForAPi= timeStepMinutes&&timeStepMinutes>0?minutesToMillisConverter(timeStepMinutes):null;
    if(timeStepMinutes<1){
      this.setState({
        sessionTimeStepVal: timeStepMinutes,
        isTimeStepInvalid: true
      });
    } else {
      this.setState({
        isTimeStepInvalid: false,
        sessionTimeStepVal:timeStepMinutes,
        projectInfo: {
          ...this.state.projectInfo,
          sessionTimeStep: timestepValueForAPi
        },
      });
    }
  };
}
export default withLDConsumer() (injectIntl(connect(
  (state: IAppState) => ({
    errorMessage: selectors.projects.getErrorMessage(state),
    scenarioConfig: selectors.companyConfig.getScenarioConfig(state),
    validationErrors: selectors.projects.getProjectValidationErrors(state),
    userRole: selectors.profile.getCurrentUserRole(state),
    userProfile: selectors.profile.getUserProfile(state),
    companyConfig: selectors.companyConfig.getCompanyConfig(state)
  }), {
    clearValidationErrors: actions.projects.clearValidationErrors,
  },
)(ProjectCreateStep)));
