import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import actions from 'src/app/redux/store/actions';
import { isGlobalUserRole } from 'src/app/data/profile';
import { useRawDataFetching } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { IDashboardChildConfigItem, IDashboardParentData } from 'src/app/data/dashboard';
import DataRequestNotImplemented from '../DataRequestNotImplemented/DataRequestNotImplemented';
import { ISearchParams } from 'src/layouts/common/Dashboard/components/layout';
import queryString from 'query-string';
import { isCurrentUserFacilitator } from 'src/app/data/common/utils/userRoleUtils';

export interface IDashboardPageContainerProps {
  data: IDashboardParentData;
  items: IDashboardChildConfigItem[];
  redirect: string;
}

const DashboardRouter: FunctionComponent<IDashboardPageContainerProps> = (props) => {

  const { items, redirect, data } = props;
  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  const clientId = useSelector(selectors.profile.getClientId);
  const licenseeId = useSelector(selectors.profile.getLicenseeId);
  const isGlobalUser = isGlobalUserRole(userRole);
  const controller = new AbortController();
  const history = useHistory();
  const redirection = sessionStorage.getItem('link');
  if (redirection && isCurrentUserFacilitator(userRole)) {
    history.push(redirection);
  }

  useEffect(() => {
    return () => {
      if (redirection) {
        sessionStorage.removeItem('link');
      }
    };
  }, []);

  const { item: companyData, refreshing } = useRawDataFetching(actions.company.fetchCompany)(licenseeId)(!!licenseeId);

  useEffect(() => () => controller.abort(), []);

  const queries = useMemo(() => queryString.parse(location.search), [location.search]);

  if (!isGlobalUser && (refreshing || !companyData)) {
    return null; // non-global user must be assigned to a company
  }

  const renderRoute = (item: IDashboardChildConfigItem) => {
    const renderComponent = () => {
      if (!item.component  || !userRole) {
        return <DataRequestNotImplemented />;
      }

      const Component = item.component;

      const defaultSorting =
        item.data?.getDefaultSorting?.(userRole.id) ||
        data?.getDefaultSorting?.(userRole.id) ||
        'id';

      const defaultSortOrder =
        item.data?.getDefaultSortByOrder?.(userRole.id) ||
        data?.getDefaultSortByOrder?.(userRole.id) ||
        'asc';

        const searchParams: ISearchParams = {
          ...queries,
          order: queries.sortby as string || defaultSorting,
          asc: (queries.sortbyorder || defaultSortOrder) !== 'desc',
        };

      return (
        <Component
          {...item.data}
          id={item.id}
          userRole={userRole}
          clientId={clientId}
          provider={companyData}
          dialogTitle={item.label}
          header={item.header}
          tableHeader={item.tableHeader}
          searchParams={searchParams}
        />
      );
    };

    return (
      <Route
        key={item.path}
        path={item.path}
        render={renderComponent}
      />
    );
  };

  return (
    <Switch>
      {items.map(renderRoute)}
      <Redirect to={redirect} />
    </Switch>
  );
};

export default DashboardRouter;
