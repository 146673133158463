import { useMemo } from 'react';
import { IStickyColumns } from './DashboardTable';
import { IDashboardColumn } from 'src/layouts/common/Dashboard';

type IUseFilteredStickyColumns = (filteredColumns: IDashboardColumn[], stickyColumns?: IStickyColumns) => number[] | undefined;

export const useFilteredStickyColumns: IUseFilteredStickyColumns = (filteredColumns, stickyColumns) => {
  return useMemo(() =>
      stickyColumns && filteredColumns
        .map(({ id }) => stickyColumns[id])
        .filter(v => v),
    [stickyColumns, filteredColumns]
  );
};
