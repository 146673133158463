import * as React from 'react';
import Button, { ButtonSize } from 'src/components/Button';
import IconButton from 'src/components/IconButton';
import cn from 'classnames';
import styles from './Notes.css';
import truncate from 'src/app/data/common/utils/truncateText';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import TranslateMessage from 'src/i18n/TranslateMessage';
import FieldGroup from 'src/components/FieldGroup/FieldGroup';
import fieldGroupStyles from 'src/components/FieldGroup/FieldGroup.css';

export interface INotesProps extends WrappedComponentProps {
  emptyMessage: string;
  notes: string[];
  viewMode: boolean;
  onChange: (notes: string[]) => void;
  maxLength: number;
  maxNoteLengthDisplay?: number; // show only that number of characters and add trailing ...
}

interface INotesMetaData {
  note: string;
  empty: boolean;
  limitExceeded: boolean;
}

interface INotesState {
  collapsed: boolean;
  notesMetaData: INotesMetaData[];
}

class Notes extends React.Component<INotesProps, INotesState> {

  public state: INotesState = {
    collapsed: true,
    notesMetaData: []
  };

  private checkIfAcceptButtonDisabled = () => {
    const { notes, maxLength } = this.props;
    return notes.some((note) => note.length > maxLength);
  }

  public render() {
    const { emptyMessage, notes, intl } = this.props;
    const { collapsed } = this.state;

    const hasNotes = !!notes && notes.length > 0;

    return (
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.notesBody}>
            {
              hasNotes
                ? notes?.map(this.renderNote)
                : collapsed ? emptyMessage : ''
            }
            {
              !collapsed && (
                <div>
                  <Button btnSize={ButtonSize.SMALL} onClick={this.addNewEmptyNote}>
                    {TranslateMessage('MursionPortal.Button.AddNew')}
                  </Button>
                </div>
              )
            }
          </div>
          {
            !collapsed && (
              <div className={styles.buttonRow}>
                <Button btnSize={ButtonSize.MEDIUM} onClick={this.acceptNotes} disabled={this.checkIfAcceptButtonDisabled()}>
                  {TranslateMessage('MursionPortal.Button.Accept')}
                </Button>
              </div>
            )
          }
        </div>
        {
          !this.props.viewMode && collapsed && (
            <div className={styles.editButton}>
              <IconButton
                iconName={'pencil-alt'}
                title={intl.formatMessage({ id: 'MursionPortal.Title.EditNotes' })}
                label={intl.formatMessage({ id: 'MursionPortal.IconButton.Edit' })}
                onClick={this.toggleMode}
              />
            </div>
          )
        }
      </div>
    );
  }

  private toggleMode = () => {
    const { collapsed } = this.state;

    if (collapsed && this.props.notes.length === 0) {
      this.addNewEmptyNote();
    }

    this.setState({
      collapsed: !collapsed
    });
  }

  private acceptNotes = () => {
    const notesArray = this.props.notes?.map((note) => {
      return {
        note,
        empty: note === '',
        limitExceeded: note.length > this.props.maxLength
      };
    });
    const ifAnyNoteIsEmpty = notesArray.some((note) => note.empty === true);
    this.setState({
      notesMetaData: notesArray,
      collapsed: !ifAnyNoteIsEmpty,
    });
  };

  private showNoteValidationError = (showError: boolean, error: string) => {
    return showError ? (
      <div className={cn(fieldGroupStyles.errorMessage, 'errorMsg pt-1 px-0')}>
        <i className={cn('fas fa-info-circle', fieldGroupStyles.icon)} />
        {error}
      </div>
    ) : null;
  };

  private getValidationErrorClass = (showLimitExceedError: boolean, showRequiredError: boolean) => {
    return showLimitExceedError || showRequiredError ? fieldGroupStyles.formControlLimited : '';
  };
  
  private renderNote = (note: string, index: number) => {
    const { collapsed, notesMetaData } = this.state;
    const { maxNoteLengthDisplay } = this.props;
    const removeNote = () => {
      const { notes } = this.props;

      notes.splice(index, 1);

      this.props.onChange(notes);

      this.setState((prevState) => {
        const notesMetaArray = [...prevState.notesMetaData];
        notesMetaArray.splice(index, 1);

        return {
          ...prevState,
          notesMetaData: notesMetaArray,
        };
      });
    };
    
    const onNoteChange = (event: any) => {
      const { notes, maxLength } = this.props;
      const noteValue = event.target.value;
      notes[index] = noteValue;

      this.props.onChange(notes);
      this.setState((prevState) => {
        const updatedArray = [...prevState.notesMetaData];
        updatedArray[index] = { note: noteValue, empty: noteValue === '', limitExceeded: noteValue.length > maxLength };
        return {
          ...prevState,
          notesMetaData: updatedArray,
        };
      });
    };
    const noteProps = maxNoteLengthDisplay && note.length > maxNoteLengthDisplay ? { title: note } : {};
    const { intl } = this.props;
    return (
      <div key={`note-${index}`} className={styles.notesField}>
        <div className={styles.noteIndex}>
          {index + 1}.
        </div>
        <div className={styles.noteContainer}>
          {
            !collapsed ? (
                <>
                    <FieldGroup
                      id={'note'}
                      type={'textarea'}
                      value={note}
                      as={'textarea'}
                      csvProtected={true}
                      onChange={onNoteChange}
                      tabIndex={1}
                      className={this.getValidationErrorClass(
                        notesMetaData[index]?.note === note && notesMetaData[index]?.limitExceeded === true,
                        notesMetaData[index]?.note === note && notesMetaData[index]?.empty === true
                      )}
                    />
                    {this.showNoteValidationError(
                      notesMetaData[index]?.note === note && notesMetaData[index]?.limitExceeded === true,
                      intl.formatMessage(
                        { id: 'MursionPortal.ErrorMessage.CharacterLimitExceeded' },
                        { maximumLength: this.props.maxLength }
                      )
                    )}
                    {this.showNoteValidationError(
                      notesMetaData[index]?.note === note && notesMetaData[index]?.empty === true,
                      intl.formatMessage({ id: 'Session.Edit.Modal.Notes.BlankNoteValidation' })
                    )}
                </>
          ) : (
            <p {...noteProps}>{truncate(note, maxNoteLengthDisplay)}</p>
            )
          }
        </div>
        <div className={styles.noteRemove}>
          {
            !collapsed && (
              <IconButton
                iconName={'times'}
                title={intl.formatMessage({ id: 'MursionPortal.IconButton.Remove' })}
                onClick={removeNote}
              />
            )
          }
        </div>
      </div>
    );
  }

  private addNewEmptyNote = () => {
    const { notes, maxLength } = this.props;
    const existingNoteMetaData = notes?.map(note => {
      return {
        note,
        empty: note === '',
        limitExceeded: note.length > maxLength
      };
    });

    this.props.onChange([
      ...notes,
      ''
    ]);

    this.setState((prevState) => {
     return {
      ...prevState,
      notesMetaData: [
        ...existingNoteMetaData,
        ...[{ note: '', empty: false, limitExceeded: false }],
      ]
     };
    });
  }
}

export default injectIntl(Notes);
