import React, { FunctionComponent, useMemo, useEffect, useState }from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { IDashboardReportIds, IFilterStore } from 'src/app/data/dashboardReport/interfaces/IDashboardReport';
import styles from './DashboardReportFilter.css';
import actions from 'src/app/redux/store/actions';
import { DefaultFilterIdPayloadType } from 'src/app/data/dashboardReport/interfaces/ReportFiltersType';
import { useDashboardReportIdsDataFetching } from 'src/layouts/common/DashboardReport/useDashboardReportHooks';
import ButtonSpinner from 'src/components/Button/ButtonSpinner/ButtonSpinner';

interface IFilterTabProps {
    setActiveHandler: (event: any) => void;
    activeKey: string;
    selectedFilterStore: IFilterStore[];
    isEditOrView: boolean;
    isLoading: boolean;
    isSendReport: boolean;
}

const DashboardReportTabs: FunctionComponent<IFilterTabProps> = ({ setActiveHandler, activeKey, selectedFilterStore, isEditOrView, isLoading, isSendReport }) => {
    const [data, setData] = useState<IFilterStore[]>([]);

    const payloadOfIds: IDashboardReportIds = useMemo(() => {
        const payload = {};
        if(isEditOrView && selectedFilterStore.length){
            selectedFilterStore.forEach((item: IFilterStore) => {
                if(!!DefaultFilterIdPayloadType[item.accessKey]){
                    payload[item.accessKey] = !!item.filters && typeof item.filters === 'string' ? item.filters.replace(/\s/g, "").split(",") : null;
                }
            });
        }
        setData(selectedFilterStore);
        return payload;
    }, [selectedFilterStore]);
    
    const checkIsApiCall = !isSendReport && isEditOrView && payloadOfIds && Object.keys(payloadOfIds).length > 0 && Object.values(payloadOfIds).some(v => Array.isArray(v));

    const { items, refreshing } = useDashboardReportIdsDataFetching(actions.dashboardReport.scheduleReportIdConversion)(
        {...payloadOfIds}
    )({}, 300, checkIsApiCall);

    useEffect(() => {
        try {
            if(!!items && isEditOrView && Object.keys(items).length){
                const response = data.map(val => {
                    if(items[val.accessKey]){
                        const getValue = Object.values(items[val.accessKey]);
                        val.filters = Array.isArray(getValue) ? getValue.join(", ") : val.filters;
                    }
                    return val;
                });
                setData(response);
            }
        }catch{
            setData(selectedFilterStore);
        }
    }, [items]);

    if(isLoading || checkIsApiCall && refreshing && isEditOrView && !isSendReport){
        return <ButtonSpinner />;
    }

    return (
        <Tabs
            onSelect={setActiveHandler}
            className={styles.filterTab}
            activeKey={activeKey}
            id={'filterTabs'}
        >
            {data.map((tab: IFilterStore) => (
                <Tab 
                    eventKey={tab.key} 
                    title={tab.name} 
                    key={tab.key}
                > 
                    {tab.filters} 
                </Tab>
            ))}
        </Tabs>
    );
};

export default DashboardReportTabs;
