import React, { useState, useEffect } from 'react';
import styles from './SessionWizard.css';
import ErrorMessage from 'src/components/ErrorMessage';
import IUser from 'src/app/data/common/interfaces/IUser';
import getUserName from 'src/app/data/common/utils/getUserName';
import SelectListItemPlain from 'src/components/SelectList/SelectListItemPlain';
import { DEFAULT_USER_ICON_PATH } from 'src/app/data/client/data';
import sessionWizardHooks, { IAggregatedPagedDataHookResult } from './sessionWizardHooks';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import useDebounce from 'src/hooks/useDebounce';
import { useIntl } from 'react-intl';

interface ISsLearnerStepProps {
  selectedSimSpecialistId: string;
  selectedLearners: IUser[];
  onValueChange: (selectedLearners: IUser[], selectedItemName: string) => void;

  userProfile: IUserProfile;
  maxLearners: number;
  startDate: number;
  endDate: number;
}

const SsLearnersStep = (props: ISsLearnerStepProps) => {
  const { selectedSimSpecialistId, selectedLearners, onValueChange, maxLearners, startDate, endDate, userProfile } = props;
    const intl = useIntl();
  const { items: activeLearners, refreshing, setPagedDataCallback }: IAggregatedPagedDataHookResult<IUser> =
    sessionWizardHooks.useActiveSsLearners(userProfile.licenseeId || '', startDate, endDate)({ page: 0, size: 9999 });

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [nonSelectedLearners, setNonSelectedLearners] = useState<IUser[]>([]);

  const [filter, setFilter] = useState<string>('');
  const {value: debouncedFilter} = useDebounce(filter, 500);

  useEffect(() => {
    setPagedDataCallback({filter: debouncedFilter});
  }, [debouncedFilter]);

  const onChangeFilter = (e: any) => setFilter(e.target.value);

  useEffect(() => {
    const availableLearners = activeLearners.filter(l => l.id !== selectedSimSpecialistId);

    setNonSelectedLearners(
      availableLearners
      // show only users which are not selected
        .filter(al => al.id && !selectedLearners.some(l => l.id === al.id))
    );
  }, [activeLearners, selectedLearners]);

  const updateSelectedLearners = (updatedLearners: IUser[]) => {
    const learnerIds = updatedLearners;
    const names = updatedLearners.map(l => {
      const learner = [
        ...activeLearners,
        ...selectedLearners,
      ].find(activeLearner => activeLearner.id === l.id);
      if (!learner) {
        return '';
      }

      return getUserName(learner);
    }).join(', ');
    onValueChange(learnerIds, names);
  };

  const addLearner = (learner: IUser) => () => {
    if (learner && !learner.id) {
      return;
    }

    if (maxLearners && selectedLearners.length >= maxLearners) {
      setErrorMessage(intl.formatMessage({ id: maxLearners > 1 ? 'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees' : 'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee' }, { maxLearners }));
      return;
    }

    updateSelectedLearners([...selectedLearners, learner]);
  };

  const removeLearner = (learner: IUser) => () => {
    const updatedLearnersIds = selectedLearners.filter(l => l.id !== learner.id);

    setErrorMessage('');
    updateSelectedLearners(updatedLearnersIds);
  };

  const renderSelectedLearner = (user: IUser) => {
    const selectedLearnerName = getUserName(user);

    return (
      <SelectListItemPlain
        title={selectedLearnerName}
        onClick={removeLearner(user)}
        key={`selected-items-${user.id}`}
        selected={true}
        imageSrc={user.picture || ''}
        fallback={DEFAULT_USER_ICON_PATH}
        circle={true}
        imageClassName={styles.userImageContainer}
      >
        <div className={styles.selectedLearnerWrapper} title={selectedLearnerName}>
          <span className={styles.listText}>{selectedLearnerName}</span>
          {
            <button className={styles.learnerRemoveButton} onClick={removeLearner(user)}>
              <i className='fas fa-times-circle'/>
            </button>
          }
        </div>
      </SelectListItemPlain>
    );
  };

  const NonSelectedLearnerRow = ({ index, style }: { index: number, style: any }) => {
    const learner = nonSelectedLearners[index];
    const learnerName = getUserName(learner);

    return (
      <div style={style}>
        <SelectListItemPlain
          title={learnerName}
          onClick={addLearner(learner)}
          key={`ss-item-${learner.id}`}
          selected={false}
          imageSrc={learner.picture || ''}
          fallback={DEFAULT_USER_ICON_PATH}
          circle={true}
          imageClassName={styles.userImageContainer}
        >
          <div className={styles.learnerWrapper} title={`${learnerName}`}>
            <span className={styles.listText}>{learnerName}</span>
          </div>
        </SelectListItemPlain>
      </div>
    );
  };

  return (
    <>
      <div style={{
        minHeight: '50px',
        maxHeight: '200px',
        overflow: 'auto'
      }}>
        {
          !!selectedLearners.length
            ? selectedLearners
              .map(
                (learner: IUser) => {
                  return renderSelectedLearner(learner);
                }
              )
            : <span
              className={styles.emptyMessage}> {intl.formatMessage({ id: 'MursionPortal.EmptyMessage.NoUsersSelected' })} </span>
        }
      </div>
      <ErrorMessage message={errorMessage} className={styles.emptyMessage}/>

      <div className={styles.searchContainer}>
        <input
          className={styles.search}
          placeholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.Search' })}
          type="search"
          value={filter}
          onChange={onChangeFilter}
        />
      </div>

      <div style={{ minHeight: 200, flexGrow: 1 }}>
        {
          nonSelectedLearners.length
            ? (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    itemCount={nonSelectedLearners.length}
                    itemSize={45}
                    height={height}
                    width={width}
                  >
                    {NonSelectedLearnerRow}
                  </List>
                )}
              </AutoSizer>
            )
            : refreshing
            ? (
              <div>
                <SelectListItemPlain refreshing={true}/>
                <SelectListItemPlain refreshing={true}/>
                <SelectListItemPlain refreshing={true}/>
              </div>
            )
            : <div
              className={styles.emptyTimeBlock}>{intl.formatMessage({ id: 'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect' })}</div>
        }
      </div>
    </>
  );
};

export default SsLearnersStep;
