import * as React from 'react';
import _noop from 'lodash/noop';
import LoadingOverlayWrapper from 'react-loading-overlay';
import { Modal } from 'react-bootstrap';
import styles from 'src/layouts/common/SsoLink/SsoLink.css';
import Cookies from 'js-cookie';
import { PARAM_LINK_EXTERNAL } from 'src/app/redux/modules/auth/interfaces/IAuthData';

class SsoLink extends React.Component {

  public componentDidMount() {
    const currentWindow = window;

    setTimeout(() => {
      Cookies.remove(PARAM_LINK_EXTERNAL);
      currentWindow.close();
    }, 2000);

    Cookies.set(PARAM_LINK_EXTERNAL, "true");
  }

  public render() {
    return (
      <LoadingOverlayWrapper active={true} spinner={true}>
        <Modal className={styles.modal} onHide={_noop} show={true}>
          <Modal.Header>
            <h5 className={styles.title}>Congratulations!</h5>
          </Modal.Header>
          <Modal.Body>
            <div>You have successfully linked your profile</div>
          </Modal.Body>
        </Modal>
      </LoadingOverlayWrapper>
    );
  }
}

export default SsoLink;
