import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import styles from 'src/layouts/common/SimulationScheduling/components/SimulationSelectors/SimulationSelectors.css';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import Selector, { ISelectorOption } from 'src/components/Selector/Selector';
import getUrlParamValue, { updateUrlParamsValue } from 'src/app/data/common/utils/queryParamsAccessor';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/app/redux/store/actions';
import selectors from 'src/app/redux/selectors';
import { isCurrentUserPSorOps } from 'src/app/data/common/utils/userRoleUtils';
import { useHistory } from 'react-router';
import { SchedulingType, isCurrentUserPSorOpsAndScheduleType, addDisabledClass } from 'src/app/data/calendar/utils/simulationSchedulingUtil';
import { SelectorTheme } from 'src/components/Selector';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { ISessionWizardTinyItem } from 'src/app/redux/modules/sessionWizard/rest';
import { IClientExt } from 'src/app/data/client/interfaces/IClient';
import { getScenarioNameForClientUsers } from 'src/app/data/common/utils/scenarioTemplateUtils';

export interface ISimulationSelectorsProps {
  setLoading: (value: boolean) => void;
  onProjectSelect: (project: ISelectorOption) => void;
  onScenarioSelect: (scenario: ISelectorOption) => void;
  projectName: string;
  scenarioName: string;
  refreshState: () => void;
  setClientName: (value: string) => void;
  disableDropDowns: boolean;
  scheduleType?: SchedulingType;
  practiceSession?: boolean;
}

const projectScenarioPagination = {
  page: 0,
  size: 9999,
};

const commonProjectsScenariosActionInfo = {
  training: false,
  joinNow: true
};

const SimulationSelectors: FunctionComponent<ISimulationSelectorsProps> = (props) => {
  // nosonar

  const {
    projectName,
    setLoading,
    scenarioName,
    onProjectSelect,
    onScenarioSelect,
    refreshState,
    setClientName,
    scheduleType,
    disableDropDowns,
    practiceSession = false,
  } = props;
  const clientId = useSelector(selectors.profile.getClientId);
  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = history;
  const [projects, setProjects] = useState<[] | ISelectorOption[]>([]);
  const [scenarios, setScenarios] = useState<[] | ISelectorOption[]>([]);
  const [clients, setClients] = useState<[] | ISelectorOption[]>([]);

  function setProjectsState(response: IListDataResponse<ISessionWizardTinyItem>) {
    const options: ISelectorOption[] = response.content.map((project) => ({
      value: project.id,
      label: project.name,
    }));
    setProjects(options);
    setLoading(false);
  }

  const getProjectsScenariosAction = () => {
    return practiceSession
      ? actions.practiceSession.fetchPracticeSessionProjectsWithActiveScenarios
      : actions.sessionWizard.fetchProjectsWithActiveScenarios;
  };

  useEffect(() => {
    if (scheduleType !== SchedulingType.RESCHEDULING) {
      if (scheduleType === SchedulingType.DEMAND_BASED) {
        setLoading(true);
        dispatch(actions.demandBasedSession.fetchDemandBasedProjects(null, clientId, false)).then((res) => {
          setProjectsState(res);
        });
      } else if (isCurrentUserPSorOpsAndScheduleType(isCurrentUserPSorOps(userRole),scheduleType)) {
        setLoading(true);
        dispatch(
          actions.clients.fetchClientShortList({
            pageData: { page: 0, size: 9999, archive: "active" },
            signal: null,
          })
        ).then((res) => {
          setClientResponse(res);
        });
      }
      else if (practiceSession) { 
        setLoading(true);       
        dispatch(actions.sessionWizard.fetchClientsWithActiveScenarios({
          page: 0,
          size: 9999,
        }, { training: false, practiceSession })).then((res) => {
          setClientResponse(res);
        });
      }
    }
  }, [scheduleType]);

  const setClientResponse = (res: IListDataResponse<ISessionWizardTinyItem | IClientExt>) => {
    const options: ISelectorOption[] = res.content.map((client) => ({
      value: client.id,
      label: client.name,
    }));

    setClients(options);
    setLoading(false);
  };

  const handleClientSelection = useCallback(
    async (client: ISelectorOption) => {
      setClientName(client.label);
      setProjects([]);
      setScenarios([]);
      refreshState();
      updateUrlParamsValue(
        {
          clientId: client.value,
        },
        location,
        history
      );
      setLoading(true);
      const fetchProjectInfo = getProjectsScenariosAction()(projectScenarioPagination, client.value, commonProjectsScenariosActionInfo);
      dispatch(fetchProjectInfo).then((res) => {
        setProjectsState(res);
      });
    },
    [refreshState, dispatch]
  );

  const handleProjectSelection = useCallback(
    (project: ISelectorOption) => {
      setScenarios([]);
      onProjectSelect(project);
      updateUrlParamsValue(
        {
          projectId: project.value,
        },
        location,
        history
      );
      const fetchAllActiveScenarios = practiceSession ? actions.practiceSession.fetchAllActivePracticeScenariosList(
        projectScenarioPagination,
        project.value,
      )
      : actions.sessionWizard.fetchSessionWizardActiveScenarios(
        projectScenarioPagination,
        project.value,
        false,
        false,
        null,
        true,
      );
      const fetchDemandBasedOnlyActiveScenarios = actions.demandBasedSession.fetchDemandBasedActiveScenarios(
        project.value,
        true,
      );
      dispatch((isCurrentUserPSorOps(userRole) || practiceSession) ? fetchAllActiveScenarios : fetchDemandBasedOnlyActiveScenarios).then(
        (res) => {
          const options: ISelectorOption[] = res.content.map((scenario) => ({
            value: scenario.id,
            label: getScenarioNameForClientUsers(userRole, scenario?.nameCustomized, scenario.name) ?? '',
          }));
          setScenarios(options);
        }
      );
    },
    [onProjectSelect, dispatch]
  );

  const selectedValue = (
    urlParam: "projectId" | "scenarioId" | "clientId",
    options: [] | ISelectorOption[]
  ): any => {
    const paramId = getUrlParamValue(urlParam, location);
    if (options.length === 1) {
      return options[0];
    }
    if (options && paramId) {
      return options.find((e: any) => e.value === paramId);
    }
  };

  return (
    <div className={styles.dropDownContainer}>
      {(isCurrentUserPSorOps(userRole) || practiceSession) && (
        <div className={styles.dropDown}>
          <span className={styles.title}>{intl.formatMessage({ id: "Clients.Modal.Header.Client" })}</span>
          <Selector
            value={selectedValue("clientId", clients)}
            className={cn(styles.selector, addDisabledClass(clients.length,styles.disabled))}
            disabled={!clients.length}
            options={clients}
            onChange={handleClientSelection}
            placeholder={intl.formatMessage({ id: "MursionPortal.PlaceHolder.SelectAClient" })}
            ariaLabel={intl.formatMessage({ id: "Clients.Modal.Header.Client" })}
            themeType={SelectorTheme.Blue}
          />
        </div>
      )}
      <div className={styles.dropDown}>
        <span className={styles.title}>{intl.formatMessage({ id: "MursionPortal.Capitalized.Project" })}</span>
        {scheduleType === SchedulingType.RESCHEDULING || disableDropDowns ? (
          <div className={styles.projectInfoName}>{projectName}</div>
        ) : (
          <Selector
            value={selectedValue("projectId", projects)}
            className={cn(styles.selector, addDisabledClass(projects.length,styles.disabled))}
            disabled={!projects.length}
            options={projects}
            onChange={handleProjectSelection}
            placeholder={intl.formatMessage({ id: "MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder" })}
            ariaLabel={intl.formatMessage({ id: "MursionPortal.Capitalized.Project" })}
            themeType={SelectorTheme.Blue}
          />
        )}
      </div>
      <div className={styles.dropDown}>
        <span className={styles.title}>{intl.formatMessage({ id: "MursionPortal.ScenarioCard.Header.Scenario" })}</span>
        {(scheduleType === SchedulingType.RESCHEDULING) || disableDropDowns ? (
          <div className={styles.projectInfoName}>{scenarioName}</div>
        ) : (
          <Selector
            value={selectedValue("scenarioId", scenarios)}
            className={cn(styles.selector, addDisabledClass(scenarios.length,styles.disabled))}
            disabled={!scenarios.length}
            options={scenarios}
            onChange={onScenarioSelect}
            placeholder={intl.formatMessage({ id: "MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder" })}
            themeType={SelectorTheme.Blue}
            ariaLabel={intl.formatMessage({ id: "MursionPortal.ScenarioCard.Header.Scenario" })}
          />
        )}
      </div>
    </div>
  );
};

export default SimulationSelectors;
