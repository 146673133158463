import * as React from 'react';
import _noop from 'lodash/noop';
import queryString from 'query-string';
import { connect } from 'react-redux';
import actions from 'src/app/redux/store/actions';
import IOAuthData from 'src/app/redux/modules/auth/interfaces/IOAuthData';
import { RouteComponentProps, withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import Button, { ButtonSize, ButtonType } from 'src/components/Button';
import { IAppState } from 'src/app/redux/store/reducers';
import selectors from 'src/app/redux/selectors';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import getUserName from 'src/app/data/common/utils/getUserName';
import styles from './OAuth.css';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import ROUTE_PATHS from 'src/routing/paths';
import * as H from 'history';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { isCodeVerifierValid } from 'src/layouts/common/LaunchSimulation/MursionAppOpen/oauth-tools';

interface IOAuthProps extends RouteComponentProps, WrappedComponentProps {
  userProfile: IUserProfile;
  fetchOAuthData: (state: string) => Promise<IOAuthData>;
  logOut: () => void;
  location: H.Location<{ from: H.Location }>;
}

interface IOAuthState {
  showModal: boolean;
}

class OAuth extends React.Component<IOAuthProps & RouteComponentProps<any>, IOAuthState> {

  public state: IOAuthState = {
    showModal: false,
  };

  public componentDidMount() {
    const { location } = this.props;
    const { from } = location.state || { from: location };

    // ask the user for confirmation only if the user was already logged in to the system
    if (from && from.pathname === ROUTE_PATHS.LOGIN) { // redirected from the login page
      this.processAuth();
    } else {
      const stateParams = queryString.parse(decodeURIComponent(decodeURIComponent(decodeURIComponent(location.search))));
      const savedCodeVerifier = localStorage.getItem('code_verifier');
      // show 'Continue As'/'Switch' dialog only for external applications
      if (stateParams?.code_challenge && savedCodeVerifier && !Array.isArray(stateParams?.code_challenge)) {

        isCodeVerifierValid(savedCodeVerifier, stateParams?.code_challenge)
          .then(isValid => {
            // compare saved code challenge with code challenge from uri
            if (isValid) {
              this.processAuth();
            } else {
              this.setState({
                showModal: true,
              });
            }
          });
      } else {
        this.setState({
          showModal: true,
        });
      }
    }
  }

  public render() {
    const { intl } = this.props;

    return this.state.showModal ? (
      <Modal className={styles.modal} show={true} onHide={_noop}>
        <Modal.Body>
          <h5 className={styles.title}>{TranslateMessage('MursionPortal.Header.HowWouldYouProceed')}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button
            btnSize={ButtonSize.MEDIUM}
            onClick={this.props.logOut}
          >
            {TranslateMessage('MursionPoral.Button.SwitchUser')}
          </Button>
          <Button
            btnSize={ButtonSize.MEDIUM}
            btnType={ButtonType.SPECIAL_PURPLE}
            title={intl.formatMessage({ id: 'MursionPortal.Title.ContinueAs' }, { userName: getUserName(this.props.userProfile) })}
            onClick={this.processAuth}
          >
            {TranslateMessage('MursionPortal.Button.ContinueAs',
              {
                code: (word: string) => <b> {word} </b>,
                userName: getUserName(this.props.userProfile, true)
              }
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    ) : (
      <LoadingOverlay active={true} spinner={true}/>
    );
  }

  private processAuth = async () => {
    const searchParams: any = queryString.parse(this.props.location.search);
    const { code, error, redirectUri, state } = await this.props.fetchOAuthData(encodeURIComponent(searchParams.state));
    let url = `${redirectUri}?code=${code}&state=${state}`;

    if (error) {
      url += `&error=${error}`;
    }

    this.setState({
      showModal: false,
    });

    window.location.replace(url);
  };
}

export default injectIntl(withRouter(connect(
  (state: IAppState) => ({
    userProfile: selectors.profile.getUserProfile(state),
  }),
  {
    fetchOAuthData: actions.auth.fetchOAuthData,
    logOut: actions.auth.logOut,
  }
)(OAuth)));
