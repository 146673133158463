import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { getDateAwareTimezoneTitle } from 'src/app/data/common/utils/dateUtil';
import moment from 'moment-timezone';
import { DELIVERY_MODE } from 'src/app/data/common/DeliveryMode';
import styles from '../SessionEditForm.css';
import getTimeToEvent from 'src/app/data/common/utils/getTimeToEvent';
import { ISession, ISessionWithHistoryInfo } from 'src/app/data/session/interfaces/ISession';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import SessionStatusType, { SessionStatusLabelType, getSessionStatusLabelTypeI18nId } from 'src/app/data/session/interfaces/SessionStatusType';
import { DateStep } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard';
import { IScenarioData } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/SessionWizard';
import { getAvailablePeriods } from 'src/app/data/calendar/utils/calendarEventUtils';
import ITimeBlock from 'src/app/data/calendar/interfaces/ITimeBlock';
import IClientConfig from 'src/app/data/clientConfig/interfaces/IClientConfig';
import ITimeblocks from 'src/app/data/common/interfaces/ITimeblocks';
import SessionTimeStep
  from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/SessionTimeStep';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { useIntl } from 'react-intl';
import cn from "classnames";
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { isCurrentUserPSorOps } from 'src/app/data/common/utils/userRoleUtils';
import { useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import { isPracticeSession } from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/utils';

export interface ISessionInfoEditConfig {
  selectedScenarioData: IScenarioData;
  companyConfig: ICompanyConfig;
  clientConfig: IClientConfig;
  sessionLength: number;
}

export interface ISessionInfoProps {
  calendarTimezoneId: string;
  editingConfig: ISessionInfoEditConfig | null;
  session: ISession;
  onChange: (session: ISession) => void;
  hideDateTime?: boolean;
}

const SessionInfo = (props: ISessionInfoProps) => {// nosonar

  const {
    session,
    editingConfig,
    calendarTimezoneId,
    onChange,
  } = props;

  const [selectedDate, setSelectedDate] = useState(session.startDate);
  const [timeBlocks, setTimeBlocks] = useState<ITimeBlock[]>([]);
  const [dailyTimeBlocksData, setDailyBlocksData] = useState<ITimeblocks | null>(null);
  const [selectedTimeBlock, setSelectedTimeBlock] = useState<ITimeBlock | null>({
    index: 0,
    start: moment.tz(session.startDate, calendarTimezoneId),
    end: moment.tz(session.endDate, calendarTimezoneId),
  });
  const intl = useIntl();

  const [timeInfo, setTimeInfo] = useState(`${intl.formatTime(session.startDate,{
        hour: '2-digit',
        minute: '2-digit',
        timeZone: calendarTimezoneId,
      })} ${getDateAwareTimezoneTitle(calendarTimezoneId, session.startDate)}`);
  const [timeToEvent, setTimeToEvent] = useState(getTimeToEvent(moment(session.startDate), intl,'', false, true));

  const initialSessionStart = useMemo(() => session.startDate, [session.id]);
  const userRole = useSelector(selectors.profile.getCurrentUserRole);

  useEffect(() => {
    const sessionLength = dailyTimeBlocksData?.sessionLength;
    const step = dailyTimeBlocksData?.timeStep;
    const startDates = dailyTimeBlocksData?.startDates;

    if (!sessionLength || !step || !startDates) {
      return;
    }

    const dailyTimeBlocks = getAvailablePeriods(
      moment.tz(selectedDate, calendarTimezoneId),
      [
        ...startDates,
        {
          startDate: initialSessionStart,
          endDate: initialSessionStart + 1,
        }
      ],
      sessionLength,
      step,
      calendarTimezoneId,
    );

    let updatedTimeBlock = null;

    if (dailyTimeBlocks.length) {

      // select time block from the previous day
      if (selectedTimeBlock) {
        updatedTimeBlock = dailyTimeBlocks.find(
          tb =>
            tb.start.format('HH:mm') === selectedTimeBlock.start.format('HH:mm')
            && tb.end.format('HH:mm') === selectedTimeBlock.end.format('HH:mm')
        ) || null;
      }

      // select first available time block
      if (!updatedTimeBlock) {
        updatedTimeBlock = dailyTimeBlocks[0];
      }
    }

    setTimeBlocks(dailyTimeBlocks);

    onChangeSelectedTimeBlock(updatedTimeBlock);
  }, [selectedDate, JSON.stringify(dailyTimeBlocksData)]);

  const onChangeSelectedTimeBlock = (timeBlock: ITimeBlock | null) => {

    if (!timeBlock) {
      setTimeInfo('-');
      setTimeToEvent('');

      onChange({
        ...session,
        startDate: 0,
        endDate: 0,
      });
      return;
    }

    const startTime = intl.formatTime(timeBlock.start.toDate(),{
      hour: '2-digit',
      minute: '2-digit',
      timeZone: calendarTimezoneId,
    });
    const tzInfo = `${getDateAwareTimezoneTitle(calendarTimezoneId, timeBlock.start.valueOf())}`;

    setTimeInfo(`${startTime} ${tzInfo}`);
    setTimeToEvent(getTimeToEvent(timeBlock.start, intl,'', false, true)); // TODO: do we need to use calendarTimezoneId here?
    setSelectedTimeBlock(timeBlock);

    onChange({
      ...session,
      startDate: timeBlock.start.valueOf(),
      endDate: timeBlock.end.valueOf(),
    });
  };

  const renderTimeSelect = () => {
    const onValueChange = (data: any) => {
      onChangeSelectedTimeBlock(data);
    };

    return (
      <div className={styles.timeStepWrapper}>
        <SessionTimeStep
          timeBlocks={timeBlocks}
          selectedTimeBlock={selectedTimeBlock}
          onValueChange={onValueChange}
          calendarTimezoneId={calendarTimezoneId}
        />
      </div>
    );
  };

  const onDateChange = (date: moment.Moment, _: string, timeBlocksData: ITimeblocks) => {
    if (!date.isSame(selectedDate) || timeBlocksData?.startDates.length) {
      setDailyBlocksData(timeBlocksData);
    }

    setSelectedDate(date.valueOf());
  };

  const renderSessionField = (_: string | JSX.Element, element: React.ReactElement) => {

    return (
      <div className={styles.sessionField}>
        <div className={styles.sessionFieldText}>
          {element}
        </div>
      </div>
    );
  };

  const timeDifference = moment(session.endDate).diff(moment(session.startDate), 'minutes');
  const min = intl.formatMessage({ id: 'MursionPortal.Text.Min' });
  const mins = intl.formatMessage({ id: 'MursionPortal.Text.Mins' });
  return (
    <div className={styles.infoWrapper}>
      {!props.hideDateTime && <>
        <div className={styles.sessionDate}>
          {renderSessionField(TranslateMessage('Session.Edit.Modal.Date'), (
            <>
              <div>
                <strong>
                  {moment(selectedDate).tz(calendarTimezoneId).format('dddd - MMMM DD, yyyy')}
                </strong>
              </div>
              {
                editingConfig
                && (
                  <div className={styles.dateStepWrapper}>
                    <DateStep
                      inline={true}
                      showNonRecommendedBlocks={false}
                      alwaysEnabledDates={[moment.tz(initialSessionStart, calendarTimezoneId)]}
                      isTraining={!!session.training}
                      calendarTimezoneId={calendarTimezoneId}
                      onValueChange={onDateChange}
                      selectedDate={moment.tz(selectedDate, calendarTimezoneId)}
                      selectedScenarioData={editingConfig.selectedScenarioData}
                    />
                  </div>
                )
              }
            </>
          ))}
        </div>
        <div>
          {renderSessionField(TranslateMessage('Session.Edit.Modal.Time'), (
            <>
              <div>
                <strong>{timeInfo}</strong>
                <strong>- {timeDifference > 1 ? timeDifference + ' ' + mins : timeDifference + ' ' + min}</strong>
                <strong>{timeToEvent ? ` (${intl.formatMessage({ id:'Session.Table.Column.TimeUntil'})} -${timeToEvent})` : ''}</strong>
              </div>
              {editingConfig && renderTimeSelect()}
            </>
          ))}
        </div>
      </>}
        <div>
          <div>
            {session.type && renderSessionField(TranslateMessage('Session.Edit.Modal.DeliveryMode'), (
              <strong>{intl.formatMessage({ id:DELIVERY_MODE[session.type]})}{` ${session.type===SessionType.ONE_TO_ONE?intl.formatMessage({ id:'MursionPortal.Label.Delivery'}):''}`}</strong>
            ))}
          </div>
          <div>
            {!isPracticeSession(session) && session.statusLabel && renderSessionField(TranslateMessage('Session.Edit.Modal.Status'), (
              <strong className={cn((session.statusLabel === SessionStatusLabelType.COMPLETED) && styles.completed,(session.statusLabel === SessionStatusLabelType.MISSED) && styles.missed)}>{intl.formatMessage({id:getSessionStatusLabelTypeI18nId(session.statusLabel)})}</strong>
            ))}
          </div>
          <div>
            {session.attendanceCount && renderSessionField(TranslateMessage('Session.Edit.Modal.LearnersAttended'), (
              <strong>{session.attendanceCount}</strong>
            ))}
          </div>
          {isCurrentUserPSorOps(userRole) && session.rescheduleEmailDate && session.rescheduleEmailBy ? <div>
              <div className={styles.sessionField}>
                <div className={styles.sessionFieldText}>
              <strong>{intl.formatMessage({ id: 'Session.Edit.Modal.LastEmailTrigger' },
                {
                  sentDate: moment(session.rescheduleEmailDate).tz(calendarTimezoneId).format('L')
                }
              )}</strong>
                </div>
              </div>
              <div className={styles.sessionField}>
                <div className={styles.sessionFieldText}>
              <strong>{intl.formatMessage({ id: 'Session.Edit.Modal.LastEmailTrigger.Sender.UserName' },
                {
                  userName: session.rescheduleEmailBy,
                  sentTime: moment(session.rescheduleEmailDate).tz(calendarTimezoneId).format('LT')
                }
              )}</strong>
                </div>
              </div>
            </div> : null
          }
          {
            session.status === (SessionStatusType.CANCELLED || SessionStatusType.LATE_CANCELLED ||SessionStatusType.LICENSEE_CANCELLED ||SessionStatusType.EARLY_CANCELLED) && (
              <div>
                {session.statusLabel && renderSessionField(TranslateMessage('Session.Edit.Modal.ReasonForCancellation'), (
                  <strong>{(session as ISessionWithHistoryInfo).reasonForModification}</strong>
                ))}
              </div>
            )
          }
        </div>
   </div>
  );
};

export default SessionInfo;
