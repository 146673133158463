import * as React from 'react';
import { useState, useEffect } from 'react';
import sessionWizardHooks, { useRawDataFetching }
  from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { ISessionWizardStepProps } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/SessionWizardList';
import { SessionWizardDropdownList, ScenarioStepInfo } from './components';
import actions from 'src/app/redux/store/actions';

interface ISessionWizardScenarioStepProps extends ISessionWizardStepProps {
  projectId: number | string;
  training: boolean;
}

const ScenarioStep = (props: ISessionWizardScenarioStepProps) => {
  const { selectedItemId, training} = props;
  const listProps = sessionWizardHooks.useActiveScenarios(props.projectId, training)({ page: 0, size: 50 });

  const [active, setHookStatus] = useState(false);
  const { item: scenario } = useRawDataFetching(actions.scenarios.fetchScenarioById)(selectedItemId)(active);

  useEffect(() => {
    if (!!selectedItemId) {
      setHookStatus(true);
    }
  }, [selectedItemId]);

  const info = scenario && {
    name: scenario.name,
    vignette: scenario.draft.vignette,
    goals: scenario.draft.goals,
    deliveryMode: scenario.draft.deliveryMode,
    sessionLength: scenario.draft.sessionLength,
  };

  return (
    <>
      <SessionWizardDropdownList {...props} {...listProps}/>
      {!!info && <ScenarioStepInfo info={info} />
      }
    </>
  );
};

export default ScenarioStep;
