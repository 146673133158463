import React, { ElementType, FunctionComponent, useEffect } from 'react';
import styles from 'src/layouts/common/SimAttendanceV2/SimAttendanceV2.css';
import { useIntl } from 'react-intl';
import { Card, Col, Row } from 'react-bootstrap';
import Checkbox from 'src/components/Checkbox/Checkbox';
import { SelectorTheme } from 'src/components/Selector';

export interface IErrorState {
    isCheckedLearnerTechnicalIssuesAudio: boolean;
    isCheckedLearnerTechnicalIssuesVideo: boolean;
    isCheckedLearnerTechnicalIssuesConnectionInternet: boolean;
    ischeckedMursionTechnicalIssuesAudio: boolean;
    ischeckedMursionTechnicalIssuesVideo: boolean;
    ischeckedMursionTechnicalIssuesConnectionInternet: boolean;
    ischeckedMursionTechnicalIssuesMomentSoftware: boolean;
    ischeckedMursionTechnicalIssuesAvatarOrEnvironment: boolean;
    ischeckedMursionTechnicalIssuesHardware: boolean;
    ischeckedOtherTechnicalIssuesLearnerSimulationSpecialistBothJoinedNoConnection: boolean;
    ischeckedOtherTechnicalIssuesOtherPleaseExplainBelow: boolean;
    ischeckedNonTechnicalIssueLearnerMaterialsOrPreparation: boolean;
    ischeckedNonTechnicalIssueLearnerLanguageFluency: boolean;
    ischeckedNonTechnicalIssueLearnerElectedToLeave: boolean;
    ischeckedNonTechnicalIssueOtherPleaseExplainBelow: boolean;
}

export enum ErrorTracking {
    SIM_LATE_OR_NO_SHOW = 1,
    SESSION_STARTED_TOO_EARLY = 2,
    MOUTH_LIP_SYNC_ISSUE_DURING_SESSION = 3,
    AUDIO_VIDEO_ISSUE_FOR_SIM = 4,
    AUDIO_VIDEO_ISSUE_FOR_LEARNER = 5,
    OTHER_SIM_HARDWARE_ISSUES = 6,
    LEARNER_AND_SIM_BOTH_JOINED = 7,
    SIM_MOMENT_ISSUE = 8,
    LEARNER_WIFI = 9,
    SIM_WIFI = 10,
    OTHER_ISSUES = 11,
    LEARNER_TECHNICAL_ISSUES_AUDIO = 12,
    LEARNER_TECHNICAL_ISSUES_VIDEO = 13,
    LEARNER_TECHNICAL_ISSUES_CONNECTION_INTERNET = 14,
    MURSION_TECHNICAL_ISSUES_AUDIO = 15,
    MURSION_TECHNICAL_ISSUES_VIDEO = 16,
    MURSION_TECHNICAL_ISSUES_CONNECTION_INTERNET = 17,
    MURSION_TECHNICAL_ISSUES_MOMENT_SOFTWARE = 18,
    MURSION_TECHNICAL_ISSUES_AVATAR_OR_ENVIRONMENT = 19,
    MURSION_TECHNICAL_ISSUES_HARDWARE = 20,
    OTHER_TECHNICAL_ISSUES_LEARNER_SIMULATION_SPECIALIST_BOTH_JOINED_NO_CONNECTION = 21,
    OTHER_TECHNICAL_ISSUES_OTHER_PLEASE_EXPLAIN_BELOW = 22,
    NON_TECHNICAL_ISSUE_LEARNER_MATERIALS_OR_PREPARATION = 23,
    NON_TECHNICAL_ISSUE_LEARNER_LANGUAGE_FLUENCY = 24,
    NON_TECHNICAL_ISSUE_LEARNER_ELECTED_TO_LEAVE = 25,
    NON_TECHNICAL_ISSUE_OTHER_PLEASE_EXPLAIN_BELOW = 26,
}

interface ISimAttendanceErrorProps {
    onCheckedItem: (id: ErrorTracking, checked: boolean) => void;
    setCheckboxErrorState: (key: string, value: boolean) => void;
    selectedItems: ErrorTracking[];
    errorState: IErrorState;
}

export function getOtherIssues(otherIssues: string | undefined) {
    return otherIssues ?? '';
}

export function getSimReportError(simReportError: ErrorTracking[] | undefined) {
    return simReportError ?? [];
}

export function checkSimReportError(otherIssuesInitialValue: string, simReportErrorInitialValue: ErrorTracking[]) {
    return otherIssuesInitialValue || simReportErrorInitialValue;
}

const SimAttendanceErrorSection: FunctionComponent<ISimAttendanceErrorProps> = ({
    onCheckedItem,
    setCheckboxErrorState,
    selectedItems,
    errorState,
}) => {
    const intl = useIntl();

    useEffect(() => {
        selectedItems.forEach(errorItem => {
            setCheckboxState(errorItem, true);
        });
    }, [selectedItems]);

    const getErrorRow = (
        labelId: ErrorTracking,
        optionText: string,
        labelId2?: ErrorTracking,
        optionText2?: string,
        labelId3?: ErrorTracking,
        optionText3?: string,
    ) => {
        return (
            <>
                {getErrorCheckboxWithLabelAndEvent(labelId, optionText)}
                {optionText2 && labelId2 && getErrorCheckboxWithLabelAndEvent(labelId2, optionText2)}
                {optionText3 && labelId3 && getErrorCheckboxWithLabelAndEvent(labelId3, optionText3)}
            </>
        );
    };

    const getErrorCheckboxWithLabelAndEvent = (labelId: ErrorTracking, text: string) => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setCheckboxState(labelId, e.target.checked);
            onCheckedItem(labelId, e.target.checked);
        };

        return (
            <div className={styles.errorCheckboxWithLabelItem}>
                <Checkbox
                    checked={getCheckboxState(labelId)}
                    themeType={SelectorTheme.Cobalt}
                    className={styles.simReportCheckbox}
                    onChange={handleChange}
                >
                    {text}
                </Checkbox>
            </div>
        );
    };

    const setCheckboxState = (id: ErrorTracking, checked: boolean) => {
        switch (id) {
            case ErrorTracking.LEARNER_TECHNICAL_ISSUES_AUDIO:
                setCheckboxErrorState('isCheckedLearnerTechnicalIssuesAudio', checked);
                break;
            case ErrorTracking.LEARNER_TECHNICAL_ISSUES_VIDEO:
                setCheckboxErrorState('isCheckedLearnerTechnicalIssuesVideo', checked);
                break;
            case ErrorTracking.LEARNER_TECHNICAL_ISSUES_CONNECTION_INTERNET:
                setCheckboxErrorState('isCheckedLearnerTechnicalIssuesConnectionInternet', checked);
                break;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_AUDIO:
                setCheckboxErrorState('ischeckedMursionTechnicalIssuesAudio', checked);
                break;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_VIDEO:
                setCheckboxErrorState('ischeckedMursionTechnicalIssuesVideo', checked);
                break;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_CONNECTION_INTERNET:
                setCheckboxErrorState('ischeckedMursionTechnicalIssuesConnectionInternet', checked);
                break;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_MOMENT_SOFTWARE:
                setCheckboxErrorState('ischeckedMursionTechnicalIssuesMomentSoftware', checked);
                break;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_AVATAR_OR_ENVIRONMENT:
                setCheckboxErrorState('ischeckedMursionTechnicalIssuesAvatarOrEnvironment', checked);
                break;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_HARDWARE:
                setCheckboxErrorState('ischeckedMursionTechnicalIssuesHardware', checked);
                break;
            case ErrorTracking.OTHER_TECHNICAL_ISSUES_LEARNER_SIMULATION_SPECIALIST_BOTH_JOINED_NO_CONNECTION:
                setCheckboxErrorState('ischeckedOtherTechnicalIssuesLearnerSimulationSpecialistBothJoinedNoConnection', checked);
                break;
            case ErrorTracking.OTHER_TECHNICAL_ISSUES_OTHER_PLEASE_EXPLAIN_BELOW:
                setCheckboxErrorState('ischeckedOtherTechnicalIssuesOtherPleaseExplainBelow', checked);
                break;
            case ErrorTracking.NON_TECHNICAL_ISSUE_LEARNER_MATERIALS_OR_PREPARATION:
                setCheckboxErrorState('ischeckedNonTechnicalIssueLearnerMaterialsOrPreparation', checked);
                break;
            case ErrorTracking.NON_TECHNICAL_ISSUE_LEARNER_LANGUAGE_FLUENCY:
                setCheckboxErrorState('ischeckedNonTechnicalIssueLearnerLanguageFluency', checked);
                break;
            case ErrorTracking.NON_TECHNICAL_ISSUE_LEARNER_ELECTED_TO_LEAVE:
                setCheckboxErrorState('ischeckedNonTechnicalIssueLearnerElectedToLeave', checked);
                break;
            case ErrorTracking.NON_TECHNICAL_ISSUE_OTHER_PLEASE_EXPLAIN_BELOW:
                setCheckboxErrorState('ischeckedNonTechnicalIssueOtherPleaseExplainBelow', checked);
                break;
            default:
                break;
        }
    };

    const getCheckboxState = (id: ErrorTracking) => {
        const isSelectedItems = selectedItems.includes(id);
        if (isSelectedItems) {
            return true;
        }
        switch (id) {
            case ErrorTracking.LEARNER_TECHNICAL_ISSUES_AUDIO:
                return errorState.isCheckedLearnerTechnicalIssuesAudio;
            case ErrorTracking.LEARNER_TECHNICAL_ISSUES_VIDEO:
                return errorState.isCheckedLearnerTechnicalIssuesVideo;
            case ErrorTracking.LEARNER_TECHNICAL_ISSUES_CONNECTION_INTERNET:
                return errorState.isCheckedLearnerTechnicalIssuesConnectionInternet;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_AUDIO:
                return errorState.ischeckedMursionTechnicalIssuesAudio;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_VIDEO:
                return errorState.ischeckedMursionTechnicalIssuesVideo;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_CONNECTION_INTERNET:
                return errorState.ischeckedMursionTechnicalIssuesConnectionInternet;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_MOMENT_SOFTWARE:
                return errorState.ischeckedMursionTechnicalIssuesMomentSoftware;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_AVATAR_OR_ENVIRONMENT:
                return errorState.ischeckedMursionTechnicalIssuesAvatarOrEnvironment;
            case ErrorTracking.MURSION_TECHNICAL_ISSUES_HARDWARE:
                return errorState.ischeckedMursionTechnicalIssuesHardware;
            case ErrorTracking.OTHER_TECHNICAL_ISSUES_LEARNER_SIMULATION_SPECIALIST_BOTH_JOINED_NO_CONNECTION:
                return errorState.ischeckedOtherTechnicalIssuesLearnerSimulationSpecialistBothJoinedNoConnection;
            case ErrorTracking.OTHER_TECHNICAL_ISSUES_OTHER_PLEASE_EXPLAIN_BELOW:
                return errorState.ischeckedOtherTechnicalIssuesOtherPleaseExplainBelow;
            case ErrorTracking.NON_TECHNICAL_ISSUE_LEARNER_MATERIALS_OR_PREPARATION:
                return errorState.ischeckedNonTechnicalIssueLearnerMaterialsOrPreparation;
            case ErrorTracking.NON_TECHNICAL_ISSUE_LEARNER_LANGUAGE_FLUENCY:
                return errorState.ischeckedNonTechnicalIssueLearnerLanguageFluency;
            case ErrorTracking.NON_TECHNICAL_ISSUE_LEARNER_ELECTED_TO_LEAVE:
                return errorState.ischeckedNonTechnicalIssueLearnerElectedToLeave;
            case ErrorTracking.NON_TECHNICAL_ISSUE_OTHER_PLEASE_EXPLAIN_BELOW:
                return errorState.ischeckedNonTechnicalIssueOtherPleaseExplainBelow;
            default:
                return false;
        }
    };

    const getTitle = (titleType: ElementType | undefined, text: string) => <Card.Title as={titleType}>{text}</Card.Title>;

    return (
        <>
            <Row className={styles.optionRow}>
                <Col className={styles.titleCol}>
                    <div className={styles.title}>{intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues' })}</div>
                </Col>
                <Col className={styles.content}>
                    {getErrorRow(
                        ErrorTracking.LEARNER_TECHNICAL_ISSUES_AUDIO,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.Audio' }),
                        ErrorTracking.LEARNER_TECHNICAL_ISSUES_VIDEO,
                        intl.formatMessage({ id: 'MursionPortal.AriaImage.Attribute.Video' }),
                        ErrorTracking.LEARNER_TECHNICAL_ISSUES_CONNECTION_INTERNET,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.ConnectionInternet' })
                    )}
                </Col>
            </Row>
            <Row className={styles.optionRow}>
                <Col className={styles.titleCol}>
                    <div className={styles.title}>{intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues' })}</div>
                </Col>
                <Col className={styles.content}>
                    {getErrorRow(
                        ErrorTracking.MURSION_TECHNICAL_ISSUES_AUDIO,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.Audio' }),
                        ErrorTracking.MURSION_TECHNICAL_ISSUES_VIDEO,
                        intl.formatMessage({ id: 'MursionPortal.AriaImage.Attribute.Video' }),
                        ErrorTracking.MURSION_TECHNICAL_ISSUES_CONNECTION_INTERNET,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.ConnectionInternet' }),
                    )}
                    {getErrorRow(
                        ErrorTracking.MURSION_TECHNICAL_ISSUES_MOMENT_SOFTWARE,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.MomentSoftware' }),
                        ErrorTracking.MURSION_TECHNICAL_ISSUES_AVATAR_OR_ENVIRONMENT,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc' }),
                        ErrorTracking.MURSION_TECHNICAL_ISSUES_HARDWARE,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc' }),
                    )}
                </Col>
            </Row>
            <Row className={styles.optionRow}>
                <Col className={styles.titleCol}>
                    <div className={styles.title}>{intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues' })}</div>
                </Col>
                <Col className={styles.content}>
                    {getErrorRow(
                        ErrorTracking.OTHER_TECHNICAL_ISSUES_LEARNER_SIMULATION_SPECIALIST_BOTH_JOINED_NO_CONNECTION,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined' }),
                        ErrorTracking.OTHER_TECHNICAL_ISSUES_OTHER_PLEASE_EXPLAIN_BELOW,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow' }),
                    )}
                </Col>
            </Row>
            {getTitle('h3', intl.formatMessage({ id: 'MursionPortal.Dashboard.NonTechnical.Issue.Summary' }))}
            <Row className={styles.optionRow}>
                <Col className={styles.titleCol}>
                    <div className={styles.title}>{intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.NonTechnicalIssues' })}</div>
                </Col>
                <Col className={styles.content}>
                    {getErrorRow(
                        ErrorTracking.NON_TECHNICAL_ISSUE_LEARNER_MATERIALS_OR_PREPARATION,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation' }),
                        ErrorTracking.NON_TECHNICAL_ISSUE_LEARNER_LANGUAGE_FLUENCY,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency' }),
                        ErrorTracking.NON_TECHNICAL_ISSUE_LEARNER_ELECTED_TO_LEAVE,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave' }),
                    )}
                    {getErrorRow(
                        ErrorTracking.NON_TECHNICAL_ISSUE_OTHER_PLEASE_EXPLAIN_BELOW,
                        intl.formatMessage({ id: 'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow' }),
                    )}
                </Col>
            </Row>
        </>
    );
};

export default SimAttendanceErrorSection;
