import * as React from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from './SessionWizard.css';
import cn from 'classnames';
import { SESSION_CLIENT_NOTE_MAX_LENGTH } from 'src/app/data/common/constants';
import { useIntl } from 'react-intl';

interface INotesStepProps {
  clientNote: string;
  onValueChange: (data: any, names: any) => void;
}

const NotesStep = (props: INotesStepProps) => {
  const [isLimitExceeded, changeLimitState] = useState(false);
  const intl = useIntl();
  function changeValue(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { value } = event.target as HTMLTextAreaElement;
    changeLimitState(value.length > SESSION_CLIENT_NOTE_MAX_LENGTH);
    props.onValueChange(value, value);
  }

  function handleKey(event: any) {
    event.stopPropagation();
  }

  return (
    <div className={styles.notesContainer}>
      <Form.Control
        className={cn(styles.textarea, isLimitExceeded && styles.errorText)}
        as="textarea"
        id={`notes-step-textarea`}
        title={intl.formatMessage({ id: 'MursionPortal.NotesStep.SessionNotes' })}
        required={false}
        rows={10}
        type="textarea"
        value={props.clientNote}
        placeholder={intl.formatMessage({ id: 'MursionPortal.NotesStep.SessionNotes' })}
        onChange={changeValue}
        onKeyDown={handleKey}
      />
      {
        isLimitExceeded &&
        <span className={styles.errorMessage}>
          <i className={cn('fas fa-info-circle', styles.icon)}/>
          {intl.formatMessage({ id: 'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded' }, { limit: SESSION_CLIENT_NOTE_MAX_LENGTH })}
        </span>
      }
    </div>
  );
};

export default NotesStep;
