import dashboardIcon from './images/icon/dashboard.png';
import calendarIcon from './images/icon/calendar.png';
import projectsIcon from './images/icon/projects.png';
import teamsIcon from './images/icon/teams.png';
import sessionsIcon from './images/icon/sessions.png';
import usersIcon from './images/icon/users.png';
import companyIcon from './images/icon/company.png';
import clientsIcon from './images/icon/clients.png';
import ssoIcon from './images/icon/sso.svg';

export default {
  dashboardIcon,
  calendarIcon,
  projectsIcon,
  teamsIcon,
  sessionsIcon,
  usersIcon,
  companyIcon,
  clientsIcon,
  ssoIcon,
};