import * as React from 'react';
import { useEffect } from 'react';
import sessionWizardHooks from './sessionWizardHooks';
import SessionWizardList, { ISessionWizardStepProps } from './SessionWizardList';
import { useIntl } from 'react-intl';

interface ISimSpecialistStepProps extends ISessionWizardStepProps {
  selectedScenarioId: string;
  excludeSimspecialistIds?: string[];
  startDate: number;
  endDate: number;
  isBankScenario: boolean;
  hideSearch?: boolean;
  training: boolean;
}

const SimSpecialistStep = (props: ISimSpecialistStepProps) => {
  const { selectedScenarioId, excludeSimspecialistIds, startDate, endDate, isBankScenario, training } = props;
  const intl = useIntl();
  const listProps =
    isBankScenario
      ? sessionWizardHooks.useActiveSimSpecialistsForScenarioTemplate(selectedScenarioId, startDate, endDate)({
        page: 0,
        size: 50
      })
      : sessionWizardHooks.useActiveSimSpecialistsForScenario(selectedScenarioId, startDate, endDate, training)({
        page: 0,
        size: 50
      });

  if (excludeSimspecialistIds) {
    listProps.items = (listProps.items || []).filter(ss => !excludeSimspecialistIds.includes(ss.id || ''));
  }

  const noAvailableSimSpec = !listProps.filter && !listProps.total && !listProps.refreshing;

  useEffect(() => {
    if (noAvailableSimSpec) {
      props.onValueChange('auto', 'Auto-assigned');
    }
  }, [noAvailableSimSpec]);

  if (noAvailableSimSpec) {
    return (
      <div style={{ padding: 20, textAlign: 'center' }}>
        {intl.formatMessage({ id: 'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable' })}
      </div>
    );
  }

  return <SessionWizardList {...props} {...listProps}/>;
};

export default SimSpecialistStep;
