import moment, { Moment } from 'moment-timezone';
import * as React from 'react';
import { connect } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import actions from 'src/app/redux/store/actions';
import { IAppState } from 'src/app/redux/store/reducers';
import calendarEventActions from 'src/app/redux/modules/calendarEvents/actions';
import Button, { ButtonFont, ButtonSize, ButtonType } from 'src/components/Button';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import { hasPermissionToCreateEmergencySession } from 'src/app/data/permissions/sessionPermissionUtils';
import { Modal } from 'react-bootstrap';
import TimezoneDropdown from 'src/components/TimezoneDropdown';
import { getDateFormat } from 'src/app/data/common/utils/dateUtil';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import styles from './SessionWizard.css';
import _noop from 'lodash/noop';
import IScenario, { IScenarioTemplate } from 'src/app/data/projects/interfaces/IScenario';
import cn from 'classnames';
import {
  IEmergencySessionCreate,
  ISession,
  ISessionCreate,
  ITrainingSessionCreate,
  SessionScenarioType
} from 'src/app/data/session/interfaces/ISession';
import { ICreateEventInfo } from 'src/app/redux/modules/createEventDialog/reducer';
import IClientConfig from 'src/app/data/clientConfig/interfaces/IClientConfig';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import ErrorMessage from 'src/components/ErrorMessage';
import { isCurrentUserBorF, isCurrentUserClientUser, isCurrentUserLearner, isCurrentUserSimSpec } from 'src/app/data/common/utils/userRoleUtils';
import ConfirmButton from 'src/components/ConfirmButton';
import { ConfirmButtonType } from 'src/components/ConfirmButton/ConfirmButton';
import {
  AssetForBankScenarioStep,
  AssetStep,
  ClientStep,
  DateStep,
  LearnersStep,
  NotesStep,
  ScenarioBankStep,
  ScenarioStep,
  ScenarioTypeStep,
  SimSpecialistStep,
  SsLearnersStep,
  TimeStep,
} from './';
import {
  IAllocateTimeRequest,
  ISessionWizardTinyItem,
  ITimeAllocationResponse,
  ITimeBlockOptions
} from 'src/app/redux/modules/sessionWizard/rest';
import ProjectStep from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/ProjectStep';
import getUserName from 'src/app/data/common/utils/getUserName';
import { getAvailablePeriods } from 'src/app/data/calendar/utils/calendarEventUtils';
import ITimeBlock from 'src/app/data/calendar/interfaces/ITimeBlock';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { IClientShort } from 'src/app/data/client/interfaces/IClient';
import { IProjectShort, ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import { getTimeline } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/DateStep';
import { SESSION_CLIENT_NOTE_MAX_LENGTH } from 'src/app/data/common/constants';
import { ICompany } from 'src/app/data/licensee/interfaces/ICompany';
import IUser from 'src/app/data/common/interfaces/IUser';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import ITimeblocks from 'src/app/data/common/interfaces/ITimeblocks';
import { IAllocatedTimeBlock } from 'src/app/redux/modules/sessionWizard/reducer';
import { getTimeBlockName } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/components/TimeBlock/utils';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import AllocationCounter
  from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/components/AllocationCounter/AllocationCounter';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { TLocaleId } from 'src/i18n';
import A11yModal from 'src/components/A11yModal';
import ITimeInterval from 'src/app/data/common/interfaces/ITimeInterval';

export enum STAGE {
  SCENARIO_TYPE = 'Scenario type',
  CLIENT = 'Client',
  PROJECT = 'Project',
  SCENARIO = 'Scenario',
  ASSET = 'Simulation Content',
  DATE = 'Date',
  TIME = 'Time',
  SIMSPECIALIST = 'Sim Specialist',
  LEARNERS = 'Learners',
  NOTES = 'Notes',
}

export interface IScenarioData {
  id: string;
  isBankScenario: boolean;
  startDate?: number;
  endDate?: number;
  sessionLength?: number;
}

export interface IProjectShortData extends IProjectShort {
  clientId: string;
}

export interface IClientShortData extends IClientShort {
  name: string;
}

export interface ISessionWizardProps extends WrappedComponentProps {
  onClose: (needRefresh?: boolean) => void;

  // defaults
  eventDateInfo: ICreateEventInfo;

  // redux
  calendarTimezoneId: string;
  clientConfig: IClientConfig | null;
  company: ICompany | null;
  companyConfig: ICompanyConfig | null;
  userProfile: IUserProfile | null;
  userRole: IUserRoleExtended | null;
  clientId: string;
  showScheduleSessionDialog: boolean;
  isTimeBlockExpired: boolean;

  fetchClientName: (clientId: string) => Promise<string>;
  fetchProjectName: (projectId: string) => Promise<string>;
  fetchScenarioName: (scenarioId: string) => Promise<string>;

  isEmergency: boolean;
  isTraining: boolean;

  clearError: () => any;
  createSession: (session: ISessionCreate) => Promise<ISession>;
  createEmergencySession: (session: IEmergencySessionCreate) => Promise<ISession>;
  createTrainingSession: (session: ITrainingSessionCreate) => Promise<ISession>;

  fetchClientsWithActiveScenarios: (pageData: IListPageData, filterParams: any) => Promise<IListDataResponse<ISessionWizardTinyItem>>;
  fetchProjectsWithActiveScenarios: (pageData: IListPageData, clientId: number | string, filterParams: any) => Promise<IListDataResponse<ISessionWizardTinyItem>>;
  fetchSessionWizardActiveScenarios: (pageData: IListPageData, projectId: number | string, training: boolean, includeDraft?: boolean) => Promise<IListDataResponse<ISessionWizardTinyItem>>;
  fetchScenarioAvailableTimeBlocks: (timeBlockOptions: ITimeBlockOptions) => Promise<ITimeblocks>;
  fetchScenarioTemplateAvailableTimeBlocks: (scenarioTemplateId: number | string, startTime: number, endTime: number, timezone: string) => Promise<ITimeblocks>;
  fetchScenario: (scenarioId: number | string) => Promise<IScenario>;
  fetchScenarioTemplate: (scenarioId: number | string) => Promise<IScenarioTemplate>;
  allocateAvailability: (allocateTimeRequest: IAllocateTimeRequest) => Promise<ITimeAllocationResponse>;
  releaseAvailability: () => Promise<void>;
  allocateTimeBlock: (timeBlock: IAllocatedTimeBlock | null) => any;
  getRestrictions: (providerId: string, startDate: number, endDate: number) => Promise<ITimeInterval[]>;
}

interface IStep {
  stage: STAGE;
  label?: string;
  valid: 'success' | 'error' | null;
  error: string | null;
  component: any;
  value: string;
  icon: string;
  formattedLabel: string;
  depends?: any;
  set: (val: any, data?: any) => void;
  reset: () => void;
  hide?: (state: ISessionWizardState) => boolean;
}

interface ISessionWizardState {
  schema: IStep[];
  activeStageIndex: number;

  loadingDefaults: boolean;
  refreshing: boolean;
  allocating: boolean;
  error: string;

  // selected items
  selectedScenarioType: SessionScenarioType;
  selectedClientId: string;
  selectedProjectId: string;
  selectedScenarioId: string;
  selectedScenario: IScenario | null;
  selectedScenarioTemplate: IScenarioTemplate | null;
  selectedDate: moment.Moment | null;
  defaultSelectedDate: moment.Moment | null;
  timeBlocksData: ITimeblocks | null;
  timeStepData: {
    showOtherBlocks: boolean,
    rangeStart: Moment | null,
    rangeEnd: Moment | null,
  };
  selectedTimeBlock: ITimeBlock | null;
  assetSettingsId: string;
  selectedSimSpecialistId: string;
  selectedLearners: ILearnerExtended[];
  showAllLearnersAreExternalCheckbox: boolean;
  allLearnersAreExternal: boolean;
  selectedTrainees: IUser[];
  clientNote: string;
  warningDialog: {
    show: boolean,
    failedParam: string,
  };
  allocateBlockWarning: boolean;
  allocationPeriod: number;
  restrictionSlots: ITimeInterval[];
}

const getInitialState = (): ISessionWizardState => ({
  schema: [],
  activeStageIndex: 0,

  loadingDefaults: false,
  refreshing: false,
  allocating: false,
  error: '',

  timeBlocksData: null,
  selectedScenarioType: SessionScenarioType.CLIENT,
  selectedClientId: '',
  selectedProjectId: '',
  selectedScenarioId: '',
  selectedScenario: null,
  selectedScenarioTemplate: null,
  selectedDate: null,
  defaultSelectedDate: null,
  selectedTimeBlock: null,
  timeStepData: {
    showOtherBlocks: false,
    rangeStart: null,
    rangeEnd: null,
  },
  assetSettingsId: '',
  selectedSimSpecialistId: '',
  selectedLearners: [],
  showAllLearnersAreExternalCheckbox: false,
  allLearnersAreExternal: false,
  selectedTrainees: [],
  clientNote: '',
  warningDialog: {
    show: false,
    failedParam: '',
  },
  allocateBlockWarning: false,
  allocationPeriod: 0,
  restrictionSlots: [],
});

class SessionWizard extends React.PureComponent<ISessionWizardProps, ISessionWizardState> {
  public state: ISessionWizardState = getInitialState();

  private rightPanelFooterRef: HTMLElement | null;
  private rightPanelFooterTopRef: HTMLElement | null;
  private applyRightPanel: React.RefObject<HTMLDivElement> | null = React.createRef();
  private selectedStep: React.RefObject<HTMLDivElement> | null = React.createRef();
  private stepFooter: React.RefObject<HTMLDivElement> | null = React.createRef();

  public async componentDidMount() {
    this.init();
    this.setState({
      defaultSelectedDate: this.getPredefinedDate(),
    });
    const { isEmergency, isTraining, intl } = this.props;

    const { eventDateInfo } = this.props;
    let { currentClientId, currentProjectId, currentScenarioId } = eventDateInfo;

    const failedToSync = (failedParam: string) => {
      if (this.state.warningDialog.show) {
        return;
      }

      this.setState({
        warningDialog: {
          show: true,
          failedParam,
        },
      });
    };

    if (currentScenarioId || currentProjectId || currentClientId) {
      this.setState({
        refreshing: true,
      });

      try {
        // loading scenario from calendar
        // TODO: update API to check if client/project/scenario is active
        // TODO: remove all unnecessary fetches
        // 1) fetch items by name
        // 2) check if there are items with selected id
        let clientName = '';

        if (isEmergency || isTraining) {
          clientName = currentClientId ? await this.props.fetchClientName(currentClientId) : ''; // only for emergency and training sessions
          const clientsByName = clientName ? await this.props.fetchClientsWithActiveScenarios({
            page: 0,
            size: 9999,
            filter: clientName,
          }, { training: isTraining }) : null;

          if (!clientsByName || !clientsByName.content.length || !clientsByName.content.some(x => x.id === currentClientId)) {
            if (currentProjectId) {
              failedToSync(intl.formatMessage({ id: 'MursionPortal.Label.LowerCase.Client' }));
            }
            currentClientId = '';
          }
        } else {
          currentClientId = this.props.userProfile && this.props.userProfile.clientId || '';
        }

        const projectName = currentProjectId ? await this.props.fetchProjectName(currentProjectId) : '';
        const projectsByName = projectName && currentClientId ? await this.props.fetchProjectsWithActiveScenarios({
          page: 0,
          size: 9999,
          filter: projectName
        }, currentClientId, { training: isTraining }) : null;

        if (!projectsByName || !projectsByName.content.length || !projectsByName.content.some(x => x.id === currentProjectId)) {
          if (currentProjectId) {
            failedToSync(intl.formatMessage({ id: 'MursionPortal.Label.Project' }));
          }
          currentProjectId = '';
          // if project is not in the active projects list -> there are no active scenarios for it
          currentScenarioId = '';
        }

        const scenarioName = currentScenarioId ? await this.props.fetchScenarioName(currentScenarioId) : '';
        const scenariosByName = scenarioName ? await this.props.fetchSessionWizardActiveScenarios({
          page: 0,
          size: 9999,
          filter: scenarioName,
        }, currentProjectId || '', isTraining) : null;

        if (!scenariosByName || !scenariosByName.content.length || !scenariosByName.content.some(x => x.id === currentScenarioId)) {
          if (currentScenarioId) {
            failedToSync(intl.formatMessage({ id: 'MursionPortal.Label.Scenario' }));
          }
          currentScenarioId = '';
        }

        let selectedDate = currentScenarioId ? this.getPredefinedDate() : null;

        const timeBlocksData: ITimeblocks | null = await this.getDateTimeBlocks(selectedDate, currentScenarioId || '');

        // TODO: hotfix - change to more clear solution

        if (isEmergency || isTraining) {
          selectedDate = timeBlocksData?.startDates.length ? selectedDate : null;
        } else {
          selectedDate = [
            ...(timeBlocksData?.startDates || []),
            ...(timeBlocksData?.startDatesRequest || [])
          ].length ? selectedDate : null;
        }

        const formattedDate = this.getFormattedDate(selectedDate, this.props.calendarTimezoneId);
        const schema = this.state.schema.map(step => {
          switch (step.stage) {
            case STAGE.CLIENT: // only for emergency session
              if (currentClientId) {
                step.set(currentClientId);
                return { ...step, value: clientName };
              }
              return step;
            case STAGE.PROJECT:
              if (currentProjectId) {
                step.set(currentProjectId);
                return { ...step, value: projectName };
              }
              return step;
            case STAGE.SCENARIO:
              if (currentScenarioId) {
                step.set(currentScenarioId);
                return { ...step, value: scenarioName };
              }
              return step;
            case STAGE.DATE:
              if (currentScenarioId && selectedDate) {
                step.set(selectedDate);
                return { ...step, value: formattedDate };
              }
              return step;
          }
          return step;
        });

        this.setState({
          loadingDefaults: true,
          refreshing: false,
          timeBlocksData,
          schema,
        }, () => {
          const index = this.state.schema.findIndex(step => !step.value);

          this.goToStageByIndex(index)();
        });
      } catch (e) {
        this.setState({
          refreshing: false
        });
      }
    }
  }

  public componentWillUnmount() {
    this.props.releaseAvailability();
  }

  public componentDidUpdate = async (prevProps: ISessionWizardProps, prevState: ISessionWizardState) => {

    if (prevProps.isTimeBlockExpired !== this.props.isTimeBlockExpired && this.props.isTimeBlockExpired) {
      this.setState({
        allocateBlockWarning: true,
      });
    }

    if (this.state.selectedScenarioId && this.state.selectedScenarioId !== prevState.selectedScenarioId) {
      try {
        this.setState({ refreshing: true });
        if (this.state.selectedScenarioType === SessionScenarioType.TEMPLATE) {
          const scenarioTemplate = await this.props.fetchScenarioTemplate(this.state.selectedScenarioId);
          this.setState({ selectedScenarioTemplate: scenarioTemplate || null });
        } else {
          const scenario = await this.props.fetchScenario(this.state.selectedScenarioId);
          this.setState({ selectedScenario: scenario || null });
        }

        const { isEmergency, isTraining } = this.props;
        const { defaultSelectedDate, selectedScenarioId } = this.state;
        if (defaultSelectedDate) {
          const timeBlocksData: ITimeblocks | null = await this.getDateTimeBlocks(defaultSelectedDate, selectedScenarioId);
          let hasTimeBlocks;

          if (isEmergency || isTraining) {
            hasTimeBlocks = !!timeBlocksData?.startDates.length;
          } else {
            hasTimeBlocks = !![
              ...(timeBlocksData?.startDates || []),
              ...(timeBlocksData?.startDatesRequest || [])
            ].length;
          }

          if (hasTimeBlocks) {
            const schema = this.state.schema.map(step => {
              if (step.stage === STAGE.DATE) {
                if (selectedScenarioId && defaultSelectedDate) {
                  step.set(defaultSelectedDate);
                  return {
                    ...step,
                    value: this.getFormattedDate(defaultSelectedDate, this.props.calendarTimezoneId)
                  };
                }
              }
              return step;
            });

            this.setState({
              selectedDate: hasTimeBlocks ? defaultSelectedDate : null,
              timeBlocksData,
              schema
            });
          }

        }

        this.setState({ refreshing: false });
      } catch (e) {
        this.setState({ refreshing: false });
      }
    }
  };

  public render() {
    const {
      schema,
      activeStageIndex,
      refreshing,
      selectedTimeBlock,
      selectedDate,
      allocating,
      allocationPeriod,
      allocateBlockWarning
    } = this.state;
    const { isEmergency, isTraining, isTimeBlockExpired, intl } = this.props;

    if (!schema.length) {
      return null;
    }

    const scenarioStepIndex = schema.findIndex(step => step.stage === STAGE.SCENARIO);
    const nextButtonIsDisabled = this.hasVisibleStepsWithoutValue(schema, activeStageIndex + 1) || refreshing || allocating;
    const isLastVisibleStep = schema.findIndex((step, index) => index > activeStageIndex && (!step.hide || !step.hide(this.state))) === -1;
    const showAllocationCounter = !!selectedTimeBlock
      && !!selectedTimeBlock.recommended
      && !isTimeBlockExpired
      && !!allocationPeriod
      && !allocateBlockWarning;


    return (
      <>
      <div onKeyDown={this.handleKey}>
        <A11yModal
          show={this.props.showScheduleSessionDialog}
          onHide={this.onClose}
          size={"lg"}
          className={cn(styles.scheduleSessionModal)}
        >
          <Modal.Body  className={styles.modalBody}>
            <div className={styles.container}>
              <div className={styles.leftContainer} tabIndex={0}>
                <div className={styles.stepHeader}>
                  <div
                    className={styles.stepTitle}>{intl.formatMessage({ id: isTraining ? 'MursionPortal.SessionWizard.Label.ScheduleTraining' : 'MursionPortal.SessionWizard.Label.ScheduleSession' })}</div>
                    <div className={styles.timeZoneWrap}>
                      <TimezoneDropdown
                        nullable={false}
                        timezoneId={this.props.calendarTimezoneId}
                        onChange={_noop}
                        disabled={true}
                        shiftDate={selectedTimeBlock && selectedTimeBlock.start || this.state.selectedDate || this.getPredefinedDate()}
                      />
                    </div>
                </div>

                <div className={styles.stepContainer}>
                  {
                    schema.map((step: IStep, index) => {
                      if (step.hide && step.hide(this.state)) {
                        return null;
                      }

                      const isActive = (index === activeStageIndex);
                      const isFilled = (!!step.value);
                      const isError = (!!step.error);

                      const isClickable = !allocating && !refreshing && (step.value
                        || (index > 0) && !this.hasVisibleStepsWithoutValue(schema, index)
                        || (step.stage === STAGE.NOTES || step.stage === STAGE.ASSET) && (!!this.state.selectedScenario || this.isScenarioBankType(this.state) && this.state.selectedScenarioTemplate));

                      let defaultValue: string='';

                      switch (step.stage) {
                        case (STAGE.NOTES):
                          defaultValue = intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.AddNotes' });
                          break;
                        case (STAGE.ASSET):
                          defaultValue = intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.SelectArtContentForUse' });
                          break;
                        default:
                          defaultValue = intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.SelectStep' }, { step: step.formattedLabel });
                      }

                      return (
                        <React.Fragment key={`wizard-stage-${step.stage}`}>
                          <div
                            tabIndex={0}
                            onClick={isClickable ? this.goToStageByIndex(index) : _noop}
                            onKeyDown={this.handleStepOnKeyPress(index)}
                            className={cn(styles.stepTab,
                              isActive && styles.active,
                              isFilled && styles.filled,
                              isError && styles.error,
                              isClickable && styles.clickable,
                            )}
                            ref={isActive ? this.selectedStep : ''}
                          >
                            <span className={styles.leftIcon}>
                              <i className={`fas ${step.icon}`}/>
                            </span>
                            <div className={styles.text}>
                              <h4> {step.formattedLabel} </h4>
                              <p title={step.value || defaultValue}>
                                {step.value || defaultValue}
                                {
                                  step.stage === STAGE.TIME && this.props.isTimeBlockExpired &&
                                  <span>({intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.Expired' })})</span>
                                }
                              </p>
                            </div>
                            <span className={styles.rightIcon}>
                              <i className={'fas fa-chevron-right'}/>
                            </span>
                          </div>
                          <hr/>
                        </React.Fragment>
                      );
                    })
                  }
                </div>
                <div className={styles.stepFooter} ref={this.stepFooter} tabIndex={0}>
                  <Button
                    btnFont={ButtonFont.LIGHT}
                    btnSize={ButtonSize.MEDIUM}
                    onClick={this.onClose}
                    onKeyDown={this.handleStepOnEnterKeyPress}>
                    {intl.formatMessage({ id: 'MursionPortal.Button.Cancel' })}
                  </Button>
                  {
                    isEmergency
                      ? <ConfirmButton
                        type={ConfirmButtonType.CREATE_EMERGENCY_SESSION}
                        buttonStyle={true}
                        confirmationText={intl.formatMessage({ id: 'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession' })}
                        buttonTitle={intl.formatMessage({ id: 'MursionPortal.SessionWizard.Button.Create' })}
                        disabled={this.state.refreshing || this.props.isTimeBlockExpired || !this.isDataValid()}
                        onConfirm={this.submitEmergency}
                        addReason={true}
                        onDialogHidden={this.handleModalWindowOnKeyPress}
                      />
                      : <Button
                        btnSize={ButtonSize.MEDIUM}
                        btnType={ButtonType.SPECIAL_PURPLE}
                        disabled={this.state.refreshing || this.props.isTimeBlockExpired || !this.isDataValid()}
                        onClick={this.submit}
                        onKeyDown={this.handleStepOnSubmitButton}
                      >
                        {intl.formatMessage({ id: 'MursionPortal.SessionWizard.Button.Create' })}
                      </Button>
                  }
                </div>
              </div>
              <LoadingOverlay active={this.state.refreshing} spinner={true} className={styles.rightContainer}>
                <div className={styles.formContainer} tabIndex={0} ref={this.applyRightPanel}>
                  {
                    activeStageIndex > scenarioStepIndex
                      ? !(this.state.refreshing) && schema[activeStageIndex].component()
                      : schema[activeStageIndex].component()
                  }
                  <div className={styles.nextFormContainer} tabIndex={0}>
                    <div className={styles.rightPanelFooterTop} ref={this.applyRightPanelFooterTopRef}/>
                    <div>
                      <ErrorMessage message={this.state.error}/>
                      {
                        isLastVisibleStep
                          ?
                          < div className={styles.rightPanelFooterContainer} onKeyDown={this.goToSubmitButtons}
                                ref={this.applyRightPanelFooterRef}/>
                          :
                          < div className={styles.rightPanelFooterContainer} ref={this.applyRightPanelFooterRef}/>
                      }
                    </div>
                    {
                      !isLastVisibleStep
                      && <Button
                        className={styles.nextButton}
                        btnSize={ButtonSize.MEDIUM}
                        btnType={ButtonType.SPECIAL_PURPLE}
                        disabled={nextButtonIsDisabled}
                        onClick={this.goToNextStage}
                        onKeyDown={this.goToNextStage}
                      >
                        {intl.formatMessage({ id: 'MursionPortal.Button.Next' })}
                      </Button>
                    }
                  </div>
                </div>
              </LoadingOverlay>
            </div>
            {
              showAllocationCounter &&
              <div className={styles.subFooter}>
                <AllocationCounter
                  show={!!this.state.selectedTimeBlock && !this.props.isTimeBlockExpired}
                  duration={this.state.allocationPeriod}
                  message={intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.RemainingTimeToBook' })}
                  expiredMessage={intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired' })}
                  onExpire={this.onAllocationCounterExpired}
                />
              </div>
            }
          </Modal.Body>
        </A11yModal>
        </div>
        <Modal
          show={this.state.warningDialog.show}
          onHide={this.onClose}
        >
          <Modal.Header>
            <Modal.Title>{intl.formatMessage({ id: 'MursionPortal.Label.Warning' })}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.UnableToCreateSession' }, { failedParam: this.state.warningDialog.failedParam })}
            </p>
            <p>{TranslateMessage('MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother', {
              code: (failedParam: TLocaleId) =>
                <span style={{ textTransform: 'capitalize' }}>{intl.formatMessage({ id: failedParam })}</span>,
              failedParam: this.state.warningDialog.failedParam
            })}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className={styles.confirmationBtns}>
              <Button
                btnSize={ButtonSize.MEDIUM}
                btnFont={ButtonFont.LIGHT}
                onClick={this.onClose}
                aria-label={intl.formatMessage({ id: 'MursionPortal.Button.Cancel' })}
                >
                {intl.formatMessage({ id: 'MursionPortal.Button.Cancel' })}
              </Button>
              <Button
                btnSize={ButtonSize.MEDIUM}
                btnFont={ButtonFont.LIGHT}
                btnType={ButtonType.SPECIAL_PURPLE}
                onClick={this.onConfirmWarning}
                aria-label={intl.formatMessage({ id: 'MursionPortal.Button.Continue' })}
                >
                {intl.formatMessage({ id: 'MursionPortal.Button.Continue' })}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.allocateBlockWarning}
          onHide={this.onGoBackToTimeStep}
        >
          <Modal.Header>
            <Modal.Title>{intl.formatMessage({ id: 'MursionPortal.Label.Warning' })}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LoadingOverlay active={allocating} spinner={true}>
              {
                !!selectedTimeBlock && !!selectedDate &&
                <>
                  <p>{intl.formatMessage({ id: 'MursionPortal.Label.Date' })}: <strong>{selectedDate.format(getDateFormat())}</strong>
                  </p>
                  <p>{intl.formatMessage({ id: 'MursionPortal.Label.Time' })}: <strong>{getTimeBlockName(selectedTimeBlock, this.props.calendarTimezoneId, false)}</strong>
                  </p>
                </>
              }
              {
                this.props.clientConfig?.schedulingRequest ? (
                  <p>
                    {intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit' })}
                  </p>
                ) : (
                  <p>
                    {intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable' })}
                  </p>
                )
              }
            </LoadingOverlay>
          </Modal.Body>
          <Modal.Footer>
            <div className={styles.confirmationBtns}>
              <Button
                btnSize={ButtonSize.MEDIUM}
                btnFont={ButtonFont.LIGHT}
                onClick={this.onGoBackToTimeStep}
                aria-label={intl.formatMessage({ id: 'MursionPortal.SessionWizard.Button.GoBack' })}
                >
                {intl.formatMessage({ id: 'MursionPortal.SessionWizard.Button.GoBack' })}
              </Button>
              {
                this.props.clientConfig?.schedulingRequest && (
                  <Button
                    btnSize={ButtonSize.MEDIUM}
                    btnFont={ButtonFont.LIGHT}
                    btnType={ButtonType.SPECIAL_PURPLE}
                    onClick={this.onResubmitTimeStep}
                    aria-label={intl.formatMessage({ id: 'MursionPortal.Button.Submit' })}
                    >
                    {intl.formatMessage({ id: 'MursionPortal.Button.Submit' })}
                  </Button>
                )
              }
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  private applyRightPanelFooterRef = (ref: HTMLElement | null) => this.rightPanelFooterRef = ref;
  private applyRightPanelFooterTopRef = (ref: HTMLElement | null) => this.rightPanelFooterTopRef = ref;

  private onAllocationCounterExpired = () => {
    this.props.releaseAvailability();

    this.setState({
      allocateBlockWarning: true,
    });
  };

  private onConfirmWarning = () => {
    this.setState({
      warningDialog: {
        show: false,
        failedParam: '',
      }
    });
  };

  private onGoBackToTimeStep = async () => {

    const newSchema = this.state.schema.map((step, index) => {
      if (step.stage === STAGE.TIME) {
        step.value = '';
        step.valid = null;
        step.error = null;
      }

      return step;
    });


    this.setState({
      activeStageIndex: this.getSchemaByStageName(STAGE.TIME),
      allocateBlockWarning: false,
      selectedTimeBlock: null,
      schema: newSchema,
      timeStepData: {
        showOtherBlocks: false,
        rangeStart: null,
        rangeEnd: null
      },
    });

    await this.refreshTimeBlocks();
  };

  private onResubmitTimeStep = async () => {
    const { selectedTimeBlock } = this.state;

    if (!selectedTimeBlock) {
      return;
    }

    this.setState({
      allocateBlockWarning: false,
    });

    await this.refreshTimeBlocks();
  };

  private hasVisibleStepsWithoutValue = (schema: IStep[], index: number) => schema.slice(0, index).some(s => !s.value && (!s.hide || !s.hide(this.state)));

  private renderScenarioTypeSelect = () => {
    const { company } = this.props;
    const { selectedScenarioType } = this.state;

    return (
      <ScenarioTypeStep
        companyName={company ? company.name : null}
        value={selectedScenarioType}
        onValueChange={this.onValueChange}
      />
    );
  };

  private renderClientSelect = () => {
    const { selectedClientId } = this.state;
    const { isTraining, intl } = this.props;
    const nameGetter = (client: any) => client.name;

    return (
      <ClientStep
        training={isTraining}
        emptyMessage={intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.NoAvailableClients' })}
        scenarioVersion={this.props.isTraining ? ScenarioVersion.V3 : undefined}
        selectedItemId={selectedClientId}
        onValueChange={this.onValueChange}
        nameGetter={nameGetter}
      />
    );
  };

  private renderProjectSelect = () => {
    const { selectedProjectId, selectedClientId } = this.state;
    const { userProfile, isTraining, intl } = this.props;
    const nameGetter = (project: any) => project.name;

    if (!userProfile) {
      return null;
    }

    const clientId = selectedClientId || userProfile.clientId || '';

    return (
      <ProjectStep
        training={isTraining}
        emptyMessage={intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.NoAvailableProjects' })}
        scenarioVersion={this.props.isTraining ? ScenarioVersion.V3 : undefined}
        selectedItemId={selectedProjectId}
        clientId={clientId}
        onValueChange={this.onValueChange}
        nameGetter={nameGetter}
      />
    );
  };

  private renderScenarioSelect = () => {
    const { selectedScenarioId, selectedProjectId, selectedScenarioType } = this.state;
    const { isTraining, userProfile, intl } = this.props;
    const nameGetter = (scenario: any) => scenario.name;
    const isBankScenario = selectedScenarioType === SessionScenarioType.TEMPLATE;

    if (!userProfile) {
      return null;
    }

    if (isBankScenario) {
      return (
        <ScenarioBankStep
          userProfile={userProfile}
          emptyMessage={intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.NoAvailableScenarios' })}
          selectedItemId={selectedScenarioId}
          projectId={selectedProjectId}
          onValueChange={this.onValueChange}
          nameGetter={nameGetter}
        />
      );
    }

    return (
      <ScenarioStep
        training={isTraining}
        emptyMessage={intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.NoAvailableScenarios' })}
        selectedItemId={selectedScenarioId}
        projectId={selectedProjectId}
        onValueChange={this.onValueChange}
        nameGetter={nameGetter}
      />
    );
  };

  private renderDateSelect = () => {
    const { calendarTimezoneId, isTraining, clientConfig } = this.props;
    const { selectedScenario, selectedScenarioId, selectedScenarioTemplate } = this.state;
    const useScenarioTemplate = this.isScenarioBankType(this.state);

    if (
      !useScenarioTemplate && (!selectedScenario || selectedScenario.id !== selectedScenarioId)
      || (useScenarioTemplate && (!selectedScenarioTemplate || selectedScenarioTemplate.id !== selectedScenarioId))
    ) {
      return null;
    }

    const scenarioData: IScenarioData = {
      id: selectedScenarioId,
      startDate: !useScenarioTemplate
      && selectedScenario
        ? Math.max(
          selectedScenario.planning.startDate,
          moment.tz(calendarTimezoneId).add(clientConfig?.daysBeforeSessionStart || 0, 'd').valueOf()
        )
        : undefined,
      endDate: !useScenarioTemplate && selectedScenario ? selectedScenario.planning.endDate : undefined,
      sessionLength: selectedScenario?.draft.sessionLength,
      isBankScenario: useScenarioTemplate,
    };

    return (
      <div className={styles.listContainer} style={{ textAlign: 'center' }}>
        <div className={styles.scrollWrap}>
          <DateStep
            showNonRecommendedBlocks={clientConfig?.schedulingRequest}
            selectedScenarioData={scenarioData}
            isTraining={isTraining}
            selectedDate={this.state.selectedDate}
            onValueChange={this.onValueChange}
            calendarTimezoneId={calendarTimezoneId}
            inline={true}
          />
        </div>
      </div>
    );
  };

  private renderTimeSelect = () => {
    const timeBlocks = this.getTimeBlocksByDay(this.state.selectedDate);
    const deliveryMode = this.currentDeliveryMode();
    const { timeBlocksData, timeStepData, selectedDate } = this.state;
    const sessionLength = timeBlocksData?.sessionLength;
    const step = timeBlocksData?.timeStep;

    const canShowAll = (maxSelectedTime: number) => {
      return timeBlocks.some(tb => !tb.recommended && tb.end.valueOf() < maxSelectedTime);
    };

    if (!deliveryMode || !sessionLength || !selectedDate || !step) {
      return null;
    }

    const onValueChange = async (timeBlock: ITimeBlock | null, name: string, defaultData: any) => {

      if (!timeBlock || !timeBlock.recommended || this.props.isEmergency || this.props.isTraining) {
        this.onValueChange(timeBlock, name, defaultData);

        this.props.allocateTimeBlock(null);
        await this.props.releaseAvailability();
        return;
      }

      this.onValueChange(null, '', defaultData);

      const allocateResult: ITimeAllocationResponse | null = await this.allocateTimeBlock(timeBlock);

      if (allocateResult?.success) {
        this.onValueChange(
          timeBlock,
          name,
          { ...defaultData, allocationPeriod: allocateResult.allocationPeriod }
        );
      } else {
        this.onValueChange(timeBlock, name, defaultData);
      }
    };

    return (
      <TimeStep
        allocating={this.state.allocating}
        notificationContainer={this.rightPanelFooterTopRef}
        showAllControlContainer={this.rightPanelFooterRef}
        date={selectedDate}
        timeBlocks={timeBlocks}
        deliveryMode={deliveryMode}
        sessionLength={sessionLength}
        defaultData={timeStepData}
        canShowAll={canShowAll}
        selectedTimeBlock={this.state.selectedTimeBlock}
        onValueChange={onValueChange}
        calendarTimezoneId={this.props.calendarTimezoneId}
        restrictions={this.state.restrictionSlots}
      />
    );
  };

  private renderAssetSelect = () => this.isScenarioBankType(this.state) ? (
    <AssetForBankScenarioStep
      selectedScenarioTemplate={this.state.selectedScenarioTemplate}
      assetSettingsId={this.state.assetSettingsId}
      onValueChange={this.onValueChange}
    />
  ) : (
    <AssetStep
      selectedScenario={this.state.selectedScenario}
      assetSettingsId={this.state.assetSettingsId}
      onValueChange={this.onValueChange}
    />
  );

  private renderSimspecialistSelect = () => {
    const { isTraining, userRole, intl } = this.props;
    const { selectedSimSpecialistId, selectedScenarioId, selectedTimeBlock, selectedTrainees } = this.state;

    if (!selectedTimeBlock) {
      return null;
    }

    const { start, end } = selectedTimeBlock;
    const hideSearch = isTraining && isCurrentUserSimSpec(userRole);

    return (
      <SimSpecialistStep
        training={isTraining}
        hideSearch={hideSearch}
        emptyMessage={intl.formatMessage({ id: 'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists' })}
        selectedItemId={selectedSimSpecialistId}
        onValueChange={this.onValueChange}
        nameGetter={getUserName}
        selectedScenarioId={selectedScenarioId}
        excludeSimspecialistIds={selectedTrainees.map(t => t.id || '')}
        startDate={start.valueOf()}
        endDate={end.valueOf()}
        isBankScenario={this.isScenarioBankType(this.state)}
      />
    );
  };

  private renderLearnersSelect = () => {
    const {
      selectedSimSpecialistId,
      selectedLearners,
      selectedTrainees,
      selectedTimeBlock,
      selectedScenario,
      selectedScenarioTemplate
    } = this.state;
    const { isTraining, userRole, userProfile } = this.props;
    const useScenarioTemplate = this.isScenarioBankType(this.state);

    if (!userRole || !selectedTimeBlock || !userProfile) {
      return null;
    }
    const { start, end } = selectedTimeBlock;

    const maxLearners = useScenarioTemplate
      ? selectedScenarioTemplate && selectedScenarioTemplate.sessionSize
      : selectedScenario && selectedScenario.draft.sessionSize;

    if (!maxLearners) {
      return null;
    }

    return isTraining ? (
      <SsLearnersStep
        userProfile={userProfile}
        selectedSimSpecialistId={selectedSimSpecialistId}
        selectedLearners={selectedTrainees}
        onValueChange={this.onValueChange}

        maxLearners={maxLearners}
        startDate={start.valueOf()}
        endDate={end.valueOf()}
      />
    ) : selectedScenario && (
      <LearnersStep
        footerContainer={this.rightPanelFooterRef}
        userRole={userRole}
        userProfile={userProfile}
        selectedLearners={selectedLearners}
        onValueChange={this.onValueChange}
        selectedScenario={selectedScenario}
        startDate={start.valueOf()}
        endDate={end.valueOf()}
        handleFocus={this.goToSubmitButtons}
      />
    );
  };

  private renderNotesSelect = () => {
    const { selectedScenario } = this.state;
    const { userRole, userProfile } = this.props;

    if (!userRole || !selectedScenario || !userProfile) {
      return null;
    }

    return (
      <NotesStep
        clientNote={this.state.clientNote}
        onValueChange={this.onValueChange}
      />
    );
  };

  private onValueChange = (selectedItemId: any, selectedItemName: string, data?: any) => {
    const { activeStageIndex, schema } = this.state;

    if (schema[activeStageIndex].value === selectedItemName) {
      return;
    }

    const scenarioStepIndex = schema.findIndex(step => step.stage === STAGE.SCENARIO);
    const timeBlockStepIndex = schema.findIndex(step => step.stage === STAGE.TIME);

    const newSchema = schema.map((step, index) => {
      if (index === activeStageIndex) {
        step.set(selectedItemId, data);

        return {
          ...step,
          value: selectedItemName
        };
      }

      // reset all next steps
      if (index > activeStageIndex) {

        // do NOT reset asset settings and notes if scenario is selected
        if (activeStageIndex > scenarioStepIndex && (step.stage === STAGE.ASSET || step.stage === STAGE.NOTES)) {
          return step;
        }

        // do NOT reset sim specialist and learners if time block is selected
        if (activeStageIndex > timeBlockStepIndex && (step.stage === STAGE.SIMSPECIALIST || step.stage === STAGE.LEARNERS)) {
          return step;
        }

        step.reset();

        return ({
          ...step,
          value: '',
        });
      }

      return step;
    });

    this.setState({
      schema: newSchema,
      error: '',
    });
  };

  private isScenarioBankType = (state: ISessionWizardState): boolean => {
    return state.selectedScenarioType === SessionScenarioType.TEMPLATE;
  };

  /*------------------- Component Management ------------------*/
  private init = () => {
    const { isEmergency, isTraining, intl } = this.props;
    const { selectedScenarioType } = this.state;
    const clientScenario = selectedScenarioType === SessionScenarioType.CLIENT;
    const schema = [];

    if (isTraining) {
      schema.push({
        stage: STAGE.SCENARIO_TYPE,
        formattedLabel: intl.formatMessage({ id: 'MursionPortal.Projects.Header.ScenarioType' }),
        value: '',
        valid: null,
        error: null,
        icon: 'fa-clipboard',
        reset: _noop,
        set: (val: SessionScenarioType) => this.setState({ selectedScenarioType: val }),
        component: this.renderScenarioTypeSelect
      });
    }

    if (isEmergency || isTraining && clientScenario) {
      schema.push({
        stage: STAGE.CLIENT,
        formattedLabel: intl.formatMessage({ id: 'MursionPortal.Projects.Header.Client' }),
        value: '',
        valid: null,
        error: null,
        icon: 'fa-star-of-life',
        reset: () => this.setState({ selectedClientId: '' }),
        set: (val: string) => this.setState({ selectedClientId: val }),
        hide: this.isScenarioBankType,
        component: this.renderClientSelect
      });
    }

    schema.push({
      stage: STAGE.PROJECT,
      formattedLabel: intl.formatMessage({ id: 'MursionPortal.Projects.Header.Project' }),
      value: '',
      valid: null,
      error: null,
      icon: 'fa-chart-pie',
      reset: () => this.setState({ selectedProjectId: '' }),
      set: (val: string) => this.setState({ selectedProjectId: val }),
      hide: this.isScenarioBankType,
      component: this.renderProjectSelect
    });

    schema.push({
      stage: STAGE.SCENARIO,
      formattedLabel: intl.formatMessage({ id: 'MursionPortal.Projects.Header.Scenario' }),
      value: '',
      valid: null,
      error: null,
      icon: 'fa-clipboard-list',
      reset: () => this.setState({ selectedScenarioId: '' }),
      set: (val: string) => this.setState({ selectedScenarioId: val }),
      component: this.renderScenarioSelect
    });

    schema.push({
      stage: STAGE.DATE,
      formattedLabel: intl.formatMessage({ id: 'MursionPortal.Projects.Header.Date' }),
      value: '',
      valid: null,
      error: null,
      icon: 'fa-calendar-alt',
      reset: () => this.setState({ selectedDate: null, timeBlocksData: null }),
      set: (val: moment.Moment | null, timeBlocksData: ITimeblocks | null) => this.setState({
        selectedDate: val,
        timeBlocksData,
        defaultSelectedDate: null,
      }),
      component: this.renderDateSelect
    });

    schema.push({
      stage: STAGE.TIME,
      formattedLabel: intl.formatMessage({ id: 'MursionPortal.Projects.Header.Time' }),
      value: '',
      valid: null,
      error: null,
      icon: 'fa-history',
      reset: () => {
        this.props.releaseAvailability();

        this.setState({
          selectedTimeBlock: null,
          timeStepData: {
            showOtherBlocks: false,
            rangeStart: null,
            rangeEnd: null
          }
        });
      },
      set: (val: ITimeBlock | null, timeStepData: any) => {
        this.setState({
          selectedTimeBlock: val,
          timeStepData
        });
      },
      component: this.renderTimeSelect
    });

    schema.push({
      stage: STAGE.ASSET,
      formattedLabel: intl.formatMessage({ id: 'MursionPortal.Label.ArtProject' }),
      value: '',
      valid: null,
      error: null,
      icon: 'fa-cog',
      reset: () => this.setState({ assetSettingsId: '' }),
      set: (val: string) => this.setState({ assetSettingsId: val }),
      component: this.renderAssetSelect
    });

    if (isEmergency || isTraining) {
      schema.push({
        stage: STAGE.SIMSPECIALIST,
        label: isTraining ? 'Trainer' : STAGE.SIMSPECIALIST,
        formattedLabel: isTraining ? intl.formatMessage({ id: 'MursionPortal.Label.Trainer' }) : STAGE.SIMSPECIALIST,
        value: '',
        valid: null,
        error: null,
        icon: 'fa-user-cog',
        reset: () => this.setState({ selectedSimSpecialistId: '' }),
        set: (val: string) => this.setState({ selectedSimSpecialistId: val }),
        component: this.renderSimspecialistSelect
      });
    }

    if (isTraining) {
      schema.push({
        stage: STAGE.LEARNERS,
        label: 'Trainees',
        formattedLabel: intl.formatMessage({ id: 'MursionPortal.Projects.Header.Trainees' }),
        value: '',
        valid: null,
        error: null,
        icon: 'fa-users',
        reset: () => this.setState({ selectedTrainees: [] }),
        set: (val: IUser[]) => this.setState({ selectedTrainees: val }),
        component: this.renderLearnersSelect
      });
    } else {
      schema.push({
        stage: STAGE.LEARNERS,
        formattedLabel: STAGE.LEARNERS,
        value: '',
        valid: null,
        error: null,
        icon: 'fa-users',
        reset: () => this.setState({ selectedLearners: [], allLearnersAreExternal: false }),
        set: (val: ILearnerExtended[], allLearnersAreExternal: boolean) => this.setState({
          selectedLearners: val,
          allLearnersAreExternal
        }),
        component: this.renderLearnersSelect
      });
    }

    if (!isEmergency && !isTraining) {
      schema.push({
        stage: STAGE.NOTES,
        formattedLabel: intl.formatMessage({ id: 'MursionPortal.Projects.Header.Notes' }),
        value: '',
        valid: null,
        error: null,
        icon: 'fa-sticky-note',
        reset: () => this.setState({ clientNote: '' }),
        set: (val: string,) => this.setState({ clientNote: val, }),
        hide: this.isScenarioBankType,
        component: this.renderNotesSelect,
      });
    }

    this.setState({
      schema,
      activeStageIndex: 0,
    });
  };

  private isGroupType = () => {
    const { selectedScenario } = this.state;
    return !!selectedScenario && selectedScenario.draft.deliveryMode === SessionType.GROUP;
  };

  private submit = async () => {
    const {
      selectedTimeBlock,
      selectedScenarioId,
      selectedScenario,
      selectedScenarioTemplate,
      selectedLearners,
      selectedTrainees,
      selectedSimSpecialistId,
      assetSettingsId,
      allLearnersAreExternal,
      clientNote,
    } = this.state;
    const useScenarioTemplate = this.isScenarioBankType(this.state);

    if (useScenarioTemplate && !selectedScenarioTemplate
      || !useScenarioTemplate && !selectedScenario
      || !this.isDataValid() || !selectedTimeBlock) {
      return;
    }

    const sessionAssetSettingsId =
      useScenarioTemplate
      || selectedScenario &&
      (!!selectedScenario.draft.scenarioVersion &&
        [
          ScenarioVersion.V3,
          ScenarioVersion.V3z,
          ScenarioVersion.V3meet
        ].includes(selectedScenario.draft.scenarioVersion))
        ? assetSettingsId
        : '';
    this.setState({
      refreshing: true,
    });

    try {
      const session: ISessionCreate = {
        endDate: selectedTimeBlock.end.valueOf(),
        learners: this.isGroupType() ? [] : selectedLearners.map(l => l.user.id || ''),
        notes: [],
        clientNote,
        scenarioId: selectedScenarioId,
        startDate: selectedTimeBlock.start.valueOf(),
        assetSettingsId: sessionAssetSettingsId,
        externalLearnersOnly: allLearnersAreExternal,
      };

      this.props.allocateTimeBlock(null);

      if (this.props.isTraining) {
        await this.props.createTrainingSession({
          ...session,
          learners: selectedTrainees.map(t => t.id || ''),
          simspecialistId: selectedSimSpecialistId === 'auto' ? undefined : selectedSimSpecialistId,
          scenarioTemplate: useScenarioTemplate,
        });
      } else {
        await this.props.createSession(session);
      }

      this.onCloseAndUpdate();
    } catch (error) {

      this.props.allocateTimeBlock({
        startDate: selectedTimeBlock.start.valueOf(),
        endDate: selectedTimeBlock.end.valueOf(),
        expired: false,
      });

      this.handleRestError(error);
      this.setState({
        refreshing: false,
      });
    }
  };

  private handleStepOnSubmitButton = async (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    if (keyboardEvent.key === 'Enter') {
      await this.submit();
    }
  };

  private submitEmergency = async (reason: string) => {
    const {
      selectedScenario,
      selectedTimeBlock,
      selectedScenarioId,
      selectedLearners,
      assetSettingsId,
      selectedSimSpecialistId,
      allLearnersAreExternal,
    } = this.state;
    const { userRole } = this.props;

    if (!selectedScenario
      || !this.isDataValid()
      || !selectedTimeBlock
      || !hasPermissionToCreateEmergencySession(userRole)
    ) {
      return;
    }

    this.setState({
      refreshing: true,
    });

    this.props.allocateTimeBlock(null);

    try {
      const session: IEmergencySessionCreate = {
        endDate: selectedTimeBlock.end.valueOf(),
        learners: selectedLearners.map(l => l.user.id || ''),
        notes: [],
        scenarioId: selectedScenarioId,
        startDate: selectedTimeBlock.start.valueOf(),
        assetSettingsId:
          (!!selectedScenario.draft.scenarioVersion &&
            [
              ScenarioVersion.V3,
              ScenarioVersion.V3z,
              ScenarioVersion.V3meet
            ].includes(selectedScenario.draft.scenarioVersion))
            ? assetSettingsId : '',
        reason,
        simspecialistId: selectedSimSpecialistId === 'auto' ? undefined : selectedSimSpecialistId,
        externalLearnersOnly: allLearnersAreExternal,
      };

      await this.props.createEmergencySession(session);

      this.onCloseAndUpdate();
    } catch (error) {

      this.props.allocateTimeBlock({
        startDate: selectedTimeBlock.start.valueOf(),
        endDate: selectedTimeBlock.end.valueOf(),
        expired: false,
      });

      this.handleRestError(error);
      this.setState({
        refreshing: false,
      });
    }
  };

  private currentDeliveryMode = (): SessionType | null => {
    const {
      selectedScenario,
      selectedScenarioTemplate,
    } = this.state;
    const useScenarioTemplate = this.isScenarioBankType(this.state);

    if (!useScenarioTemplate && selectedScenario) {
      return selectedScenario.draft.deliveryMode;
    }

    if (useScenarioTemplate && selectedScenarioTemplate) {
      return selectedScenarioTemplate.deliveryMode;
    }

    return null;
  };

  private isDataValid(): boolean {
    const { isEmergency, isTraining, userRole, userProfile } = this.props;
    const {
      selectedScenario,
      selectedScenarioId,
      assetSettingsId,
      selectedLearners,
      selectedTrainees,
      selectedTimeBlock,
      selectedSimSpecialistId,
      clientNote,
    } = this.state;

    // common validations
    if (!userRole
      || !userProfile
      || !selectedScenarioId
      || !selectedTimeBlock
      || !(selectedScenario && selectedScenario.draft.scenarioVersion !== ScenarioVersion.V3 || !!assetSettingsId)
      || (!!selectedScenario?.draft.scenarioVersion && [ScenarioVersion.V3z, ScenarioVersion.V3meet].includes(selectedScenario.draft.scenarioVersion) && !assetSettingsId)
      || clientNote.length > SESSION_CLIENT_NOTE_MAX_LENGTH
    ) {
      return false;
    }

    const deliveryMode = this.currentDeliveryMode();

    if (!deliveryMode) {
      return false;
    }

    const isLearner = isCurrentUserLearner(userRole);
    const isGroupType = deliveryMode === SessionType.GROUP;

    if (isEmergency) {
      if (!selectedSimSpecialistId) {
        return false;
      }

      return !isGroupType || !!selectedLearners.length;
    }

    if (isTraining) {
      return !!selectedSimSpecialistId && !!selectedTrainees.length;
    }

    const isOneToOne = deliveryMode === SessionType.ONE_TO_ONE;
    const isAOorF = isCurrentUserBorF(userRole);
    const hasLearnerRole = userProfile.roles.some(role => role.id === RoleID.LEARNER);


    const isInTeam = selectedScenario && selectedScenario.planning.teams.some(team => team.learners.some(teamLearner => teamLearner.id === userProfile.id));
    const autoSelectLearner =
      (isGroupType && isAOorF && hasLearnerRole // AO or F with L role creating group type session
        || isOneToOne && isLearner && isInTeam);   // L in the team creating 1 to 1 type session

    return autoSelectLearner ? !!selectedLearners.length : true;
  }

  private goToNextStage = () => {
    const { activeStageIndex, schema } = this.state;
    const nextVisibleStageIndex = schema
      .findIndex((s, i) => i > activeStageIndex && (!s.hide || !s.hide(this.state)));

    this.goToStageByIndex(nextVisibleStageIndex > 0 ? nextVisibleStageIndex : activeStageIndex)();
  };

  private goToStageByIndex = (stageIndex: number) => () => {
    const { schema } = this.state;
    const timeBlockStepIndex = schema.findIndex((step) => step.stage === STAGE.TIME);

    if (stageIndex === timeBlockStepIndex) {
      this.fetchRestrictions();
    }
    this.setState({ activeStageIndex: stageIndex }, () => this.selectedStep?.current?.focus());
  };

  private fetchRestrictions = () => {
    const { userRole, company, getRestrictions } = this.props;
    const selectedDate = this.state.selectedDate;

    if (isCurrentUserClientUser(userRole) && selectedDate && company) {
      getRestrictions(company?.id, selectedDate?.startOf("d").valueOf(), selectedDate?.endOf("d").valueOf()).then(
        (res) => {
          this.setState({ restrictionSlots: res });
        }
      );
    }
  }

  private goToSubmitButtons = (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    if (keyboardEvent.key === 'Tab') {
      this.stepFooter?.current?.focus();
    }
  };

  private handleStepOnKeyPress = (stageIndex: number) => (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    if (keyboardEvent.key === 'Enter') {
      keyboardEvent.preventDefault();
      this.goToStageByIndex(stageIndex)();
    }
    if (keyboardEvent.key === 'ArrowRight') {
      this.applyRightPanel?.current?.focus();
    }
  };

  private handleModalWindowOnKeyPress = (e: React.KeyboardEvent) => {
    this.selectedStep?.current?.focus();
  };

  private getSchemaByStageName = (stageName: STAGE) => {
    return this.state.schema.findIndex(item => item.stage === stageName);
  };

  private handleKey = (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    const { activeStageIndex, schema } = this.state;
    const { isEmergency } = this.props;

    const nextIndexWithoutValue = schema.findIndex(step => !step.value);

    if (keyboardEvent.key === 'Enter') {
      keyboardEvent.preventDefault();
      if (nextIndexWithoutValue !== -1) {
        // go to next unfilled step
        // this.goToStageByIndex(nextIndexWithoutValue)();
      } else if (schema.length - activeStageIndex === 1) {
        // if all fields is filled and wizard on the last step try to submit
        if (!isEmergency) {
          this.submit();
        }
      }
    }
  };

  private handleRestError = (error: IErrorState) => {
    this.setState({
      error: error.message
    });
  };

  private onClose = () => {
    this.props.onClose();
  };

  private handleStepOnEnterKeyPress = (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    if (keyboardEvent.key === 'Enter') {
      this.onClose();
    }
  };

  private onCloseAndUpdate = () => {
    this.props.onClose(true);
  };

  private getTimeBlocksByDay = (date: moment.Moment | null) => {
    const { timeBlocksData } = this.state;
    const sessionLength = timeBlocksData?.sessionLength;
    const step = timeBlocksData?.timeStep;
    const startDates = timeBlocksData?.startDates;
    const startDatesRequest = timeBlocksData?.startDatesRequest;

    if (!date || !sessionLength || !step) {
      return [];
    }

    const recommendedTimeBlocks = startDates ? getAvailablePeriods(
      date,
      startDates,
      sessionLength,
      step,
      this.props.calendarTimezoneId,
    ) : [];

    const notRecommendedTimeBlocks = startDatesRequest ? getAvailablePeriods(
      date,
      startDatesRequest,
      sessionLength,
      step,
      this.props.calendarTimezoneId,
    ).filter(tb => !recommendedTimeBlocks.some(rtb => rtb.start.isSame(tb.start))) : [];

    return recommendedTimeBlocks
      .map(tb => ({ ...tb, recommended: true }))
      .concat(notRecommendedTimeBlocks.map(tb => ({ ...tb, recommended: false })))
      .sort((tbA, tbB) => tbA.start.valueOf() - tbB.start.valueOf());
  };

  private getPredefinedDate = () => this.props.eventDateInfo && this.props.eventDateInfo.date;

  private getDateTimeBlocks = async (selectedDate: Moment | null, currentScenarioId: string) => {
    if (!selectedDate || !currentScenarioId) {
      return null;
    }

    const { isTraining, calendarTimezoneId } = this.props;
    const isBankScenario = this.isScenarioBankType(this.state);
    const scenarioData: IScenarioData = {
      id: currentScenarioId,
      isBankScenario,
    };

    let timeBlocksData: ITimeblocks | null = null;

    if (!isBankScenario) {
      const scenario = await this.props.fetchScenario(currentScenarioId);

      scenarioData.startDate = scenario.planning.startDate;
      scenarioData.endDate = scenario.planning.endDate;
      scenarioData.sessionLength = scenario.draft.sessionLength;
    }

    const timeline = getTimeline(selectedDate, 'day', scenarioData, this.props.calendarTimezoneId);

    if (timeline && !timeline.isOutOfRange) {

      if (!isBankScenario) {
        const { clientConfig } = this.props;
        const { selectedScenario } = this.state;

        const configMinTime = moment.tz(calendarTimezoneId).add(clientConfig?.daysBeforeSessionStart || 0, 'd');
        const planningStartTimeDay = moment.tz(selectedScenario?.planning.startDate, calendarTimezoneId).startOf('d').valueOf();

        if (timeline.startTime < configMinTime.clone().startOf('d').valueOf() || timeline.startTime < planningStartTimeDay) {
          return null;
        }

        timeline.startTime = Math.max(
          configMinTime.valueOf(),
          timeline.startTime,
          selectedScenario?.planning.startDate || 0,
        );

        timeline.endTime = Math.max(
          timeline.endTime,
          moment.tz(timeline.startTime + (scenarioData?.sessionLength || 0), this.props.calendarTimezoneId).endOf('day').valueOf(),
        );
      }
      
      const timeBlockValues = {
        scenarioId: currentScenarioId,
        isTraining,
        startTime: timeline.startTime,
        endTime: timeline.endTime,
        timezone: calendarTimezoneId,
      };
      
      timeBlocksData = isBankScenario
        ? await this.props.fetchScenarioTemplateAvailableTimeBlocks(currentScenarioId, timeline.startTime, timeline.endTime, calendarTimezoneId)
        : await this.props.fetchScenarioAvailableTimeBlocks(timeBlockValues);
    }

    return timeBlocksData;
  };

  private getFormattedDate = (selectedDate: Moment | null, tzId: string) =>
    selectedDate ? selectedDate.tz(tzId || '').format(getDateFormat()) : '';

  private refreshTimeBlocks = async () => {
    this.setState({ refreshing: true });
    this.props.allocateTimeBlock(null);

    const timeBlocksData: ITimeblocks | null =
      await this.getDateTimeBlocks(this.state.selectedDate, this.state.selectedScenarioId);

    this.setState({
      timeBlocksData,
      refreshing: false,
    });
  };

  private allocateTimeBlock = async (timeBlock: ITimeBlock) => {
    const {
      start,
      end,
    } = timeBlock;

    this.setState({
      allocating: true,
    });

    try {
      this.props.allocateTimeBlock(null);

      const allocateResult: ITimeAllocationResponse = await this.props.allocateAvailability({
        scenarioId: this.state.selectedScenarioId,
        interval: {
          startDate: start.valueOf(),
          endDate: end.valueOf(),
        }
      });

      if (allocateResult.success) {
        this.props.allocateTimeBlock({ startDate: start.valueOf(), endDate: end.valueOf(), expired: false });
      }

      this.setState({
        allocating: false,
        allocationPeriod: allocateResult.allocationPeriod,
        allocateBlockWarning: !allocateResult.success,
      });

      return allocateResult;
    } catch (e) {
      // do something
      this.setState({
        allocating: false,
      });

      return null;
    }

  };
}

export default injectIntl(connect(
  (state: IAppState) => {
    const userRole = selectors.profile.getCurrentUserRole(state);
    const userProfile = selectors.profile.getUserProfile(state);
    const eventDateInfo = selectors.createEventDialog.getCreateEventDialogInfo(state);
    const isTraining = !!eventDateInfo && !!eventDateInfo.training;

    return {
      userProfile,
      userRole,
      eventDateInfo,
      isTraining,
      isEmergency: !isTraining && hasPermissionToCreateEmergencySession(userRole),
      clientId: userProfile ? userProfile.clientId || '' : '',

      company: selectors.company.getCompanyData(state),
      companyConfig: selectors.companyConfig.getCompanyConfig(state),
      configIsLoading: selectors.companyConfig.isCompanyConfigFetching(state),
      clientConfig: selectors.clientsConfig.getClientConfig(state),

      // defaults from redux calendar & createEventDialog
      calendarTimezoneId: selectors.calendar.getCalendarTimezoneId(state),
      showScheduleSessionDialog: selectors.createEventDialog.isScheduleSessionDialogShown(state),
      isTimeBlockExpired: selectors.sessionWizard.isTimeBlockExpired(state),
    };
  }, {
    clearError: calendarEventActions.clearError,
    createSession: actions.session.createSession,
    createTrainingSession: actions.session.createTrainingSession,
    createEmergencySession: actions.session.createEmergencySession,

    // for defaults
    fetchClientName: actions.clients.fetchClientName,
    fetchProjectName: actions.projects.fetchProjectName,
    fetchScenarioName: actions.scenarios.fetchScenarioName,

    // for wizard
    fetchClientsWithActiveScenarios: actions.sessionWizard.fetchClientsWithActiveScenarios,
    fetchProjectsWithActiveScenarios: actions.sessionWizard.fetchProjectsWithActiveScenarios,
    fetchSessionWizardActiveScenarios: actions.sessionWizard.fetchSessionWizardActiveScenarios,
    fetchScenarioAvailableTimeBlocks: actions.sessionWizard.fetchScenarioAvailableTimeBlocks,
    fetchScenarioTemplateAvailableTimeBlocks: actions.sessionWizard.fetchScenarioTemplateAvailableTimeBlocks,
    fetchScenario: actions.sessionWizard.fetchScenario,
    fetchScenarioTemplate: actions.sessionWizard.fetchScenarioTemplate,
    allocateAvailability: actions.sessionWizard.allocateAvailability,
    releaseAvailability: actions.sessionWizard.releaseAvailability,
    allocateTimeBlock: actions.sessionWizard.allocateTimeBlock,
    releaseTimeBlock: actions.sessionWizard.releaseTimeBlock,
    getRestrictions: actions.session.getRestrictions,
  }
)(SessionWizard));
