import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import { convertDateToMoment, convertMomentToDate, getDateFormat } from 'src/app/data/common/utils/dateUtil';
import sessionWizardHooks from './sessionWizardHooks';
import ReactDatePicker from 'react-datepicker';
import { getCalendarMonthRange } from 'src/app/data/common/utils/calendarUtils';
import { IScenarioData } from './SessionWizard';
import ITimeblocks from 'src/app/data/common/interfaces/ITimeblocks';
import { useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import styles from './SessionWizard.css';
import calendarInput from 'src/components/CalendarInput/CalendarInput.css';
import LoadingOverlay from 'src/components/LoadingOverlay';
export interface IDateStepProps {
  selectedScenarioData: IScenarioData;
  isTraining: boolean;
  showNonRecommendedBlocks?: boolean;
  inline?: boolean;
  alwaysEnabledDates?: Moment[];

  selectedDate: Moment | null;
  calendarTimezoneId: string;
  onValueChange: (date: any, names: any, data?: ITimeblocks | null) => void;
  practiceSession?: boolean;
}

export const getTimeline = (date: moment.Moment | null, period: moment.unitOfTime.Base, scenarioData: IScenarioData, tzId: string) => {
  if (!date) {
    return;
  }

  const now = moment.tz(tzId);
  let startOfPeriod;
  let endOfPeriod;

  if (period === 'month') {
    const monthRange = getCalendarMonthRange(date);
    startOfPeriod = monthRange.start;
    endOfPeriod = monthRange.end;
  } else {
    startOfPeriod = date.clone().startOf(period).valueOf();
    endOfPeriod = date.clone()
      // end of a period is start of the next period
      .add(1, period)
      .startOf(period)
      .valueOf();
  }


  const startDate = startOfPeriod < now.valueOf() ? now.valueOf() : startOfPeriod;
  let isOutOfRange = false;

  if (scenarioData.isBankScenario) {
    return {
      startTime: startOfPeriod,
      endTime: endOfPeriod,
      isOutOfRange
    };
  }

  if (!scenarioData.startDate || !scenarioData.endDate) {
    return;
  }

  if (scenarioData.endDate < startOfPeriod) {
    return;
  }

  if (scenarioData.startDate > endOfPeriod) {
    isOutOfRange = true;
  }

  const newStartTime =
    scenarioData.startDate > startDate
      ? scenarioData.startDate < now.valueOf() ? now.valueOf() : scenarioData.startDate
      : startDate;


  if (period === 'month') {
    const monthRange = getCalendarMonthRange(moment.tz(newStartTime, tzId));
    endOfPeriod = monthRange.end;
  }

  const newEndTime =
    scenarioData.endDate > endOfPeriod
      ? endOfPeriod
      : scenarioData.endDate;

  if (newStartTime >= newEndTime) {
    isOutOfRange = true;
  }

  return {
    startTime: newStartTime,
    endTime: newEndTime,
    isOutOfRange
  };
};

const DateStep = (props: IDateStepProps) => {
  const {
    selectedDate,
    alwaysEnabledDates,
    calendarTimezoneId,
    onValueChange,
    isTraining,
    showNonRecommendedBlocks,
    selectedScenarioData,
    inline,
    practiceSession,
  } = props;

  const currentTime = moment.tz(calendarTimezoneId);

  const getMonthTimeline = (date: moment.Moment | null) =>
    getTimeline(date, 'month', selectedScenarioData, calendarTimezoneId);

  const timeline = getMonthTimeline(selectedDate || currentTime);

  if (!timeline) {
    return null;
  }

  const [startTime, setStartTime] = useState<number>(timeline.startTime);
  const [endTime, setEndTime] = useState<number>(timeline.endTime);

  const timeBlockValues = {
    scenarioId: selectedScenarioData.id,
    isTraining,
    startTime,
    endTime,
    timezone: calendarTimezoneId,
    signal: null,
    simAvailabilityOnly: false,
  };

  const getTimeBlockForProjectLevelScenario = () => {
    return practiceSession ? sessionWizardHooks.usePracticeScenarioAvailableTimeBlocks(timeBlockValues)() : sessionWizardHooks.useScenarioAvailableTimeBlocks(timeBlockValues)();
  };

  const { item: timeBlocksData, refreshing } = selectedScenarioData.isBankScenario
    ? sessionWizardHooks.useScenarioTemplateAvailableTimeBlocks(selectedScenarioData.id, startTime, endTime, calendarTimezoneId)()
    : getTimeBlockForProjectLevelScenario();

  const timeBlocks = useMemo(() =>
    timeBlocksData?.startDates
      .concat(showNonRecommendedBlocks ? timeBlocksData?.startDatesRequest || [] : []) || []
    , [JSON.stringify(timeBlocksData)]);


  useEffect(() => {
    if (!selectedDate) {
      return;
    }

    const name = selectedDate.format(getDateFormat());

    onValueChange(selectedDate, name, timeBlocksData);
  }, [JSON.stringify(timeBlocks), selectedDate]);
 const locale = useSelector(selectors.profile.getUserLanguage);

  const filterDate = (dateToCheck: Date): boolean => {
    const date = convertDateToMoment(dateToCheck, calendarTimezoneId);
    const dateStart = date.clone().startOf('day').valueOf();
    const dateEnd = date.clone().endOf('day').valueOf();

    return alwaysEnabledDates?.some(aed => aed.isSame(date, 'd')) ||
      timeBlocks
        .some(tb => {
          const tbs = tb.startDate;
          const tbe = tb.endDate;
          // TODO: add session length check or make additional daily timeblocks fetch

          return tbs < dateStart ? tbe > dateStart : tbs < dateEnd;
        });
  };

  const selectedDateLocal = selectedDate ? convertMomentToDate(selectedDate) : null;

  const onMonthChanged = (newDate: Date) => {
    const date = convertDateToMoment(newDate, calendarTimezoneId).startOf('month');
    const newTimeline = getMonthTimeline(moment.tz(date, calendarTimezoneId));

    if (newTimeline) {
      setStartTime(newTimeline.startTime);
      setEndTime(newTimeline.endTime);
    }

  };

  const onDateChange = (newDate: Date) => {
    // date returns from calendar as start of the day in the timezone of browser
    // convert it to calendar timezone
    const date = convertDateToMoment(newDate, calendarTimezoneId);
    const name = date.format(getDateFormat());

    if (!selectedDate || !selectedDate.isSame(date, 'month')) {
      const newTimeline = getMonthTimeline(moment.tz(date, calendarTimezoneId));
      if (newTimeline && (newTimeline.startTime !== startTime || newTimeline.endTime !== endTime)) {
        setStartTime(newTimeline.startTime);
        setEndTime(newTimeline.endTime);
      }
    }

    onValueChange(date, name, timeBlocksData);
  };

  const now = convertMomentToDate(moment.tz(calendarTimezoneId));
  const start = selectedScenarioData.isBankScenario ? now : selectedScenarioData.startDate;
  const end = selectedScenarioData.isBankScenario ? null : selectedScenarioData.endDate;
  const planningStartDate = convertMomentToDate(moment.tz(start, calendarTimezoneId));
  const planningEndDate = end ? convertMomentToDate(moment.tz(end, calendarTimezoneId)) : null;

  return (
    <div className={practiceSession ? calendarInput.reactCalendar : styles.datePicker}>
    <LoadingOverlay active={practiceSession ? refreshing : false} spinner={true}>
    <ReactDatePicker
      locale={locale}
      onMonthChange={onMonthChanged}
      selected={selectedDateLocal || null}
      onChange={onDateChange}
      inline={inline}
      minDate={now > planningStartDate ? now : planningStartDate}
      maxDate={planningEndDate}
      filterDate={filterDate}
    />
    </LoadingOverlay>
    </div>
  );
};

export default DateStep;
