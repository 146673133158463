import * as React from 'react';
import { useEffect, useState } from 'react';
import styles from './SessionWizard.css';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import Button, { ButtonSize, ButtonType } from 'src/components/Button';
import SelectListItemPlain from 'src/components/SelectList/SelectListItemPlain';
import { IAggregatedPagedDataHookResult } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import useDebounce from 'src/hooks/useDebounce';
import Skeleton from 'react-loading-skeleton';
import { useIntl } from 'react-intl';

export interface ISessionWizardStepProps {
  emptyMessage: string;
  selectedItemId: string;
  onValueChange: (selectedItemId: any, selectedItemName: any) => void;
  onUpdatedItems?: (items: any[]) => void;
  nameGetter: (item: any) => string;
}

export interface ISessionWizardList<T> extends ISessionWizardStepProps, IAggregatedPagedDataHookResult<T> {
  hideSearch?: boolean;
}

const SessionWizardList = <T extends { id?: string | number, name?: string }>(props: ISessionWizardList<T>) => {
  const {
    selectedItemId,
    onValueChange,
    emptyMessage,
    items,
    loadMoreCallback,
    setPagedDataCallback,
    hasMore,
    refreshing,
    nameGetter,
    hideSearch,
  } = props;
  const [filter, setFilter] = useState<string>('');
  const {value: debouncedFilter} = useDebounce(filter, 500);
  const intl = useIntl();
  useEffect(() => {
    if (props.onUpdatedItems) {
      props.onUpdatedItems(items);
    }
  }, [items]);

  useEffect(() => {
    if (items.length === 1 && !selectedItemId) {
      onValueChange(items[0].id, nameGetter(items[0]));
    }
  }, [selectedItemId, items, onValueChange]);

  useEffect(() => {
    setPagedDataCallback({filter: debouncedFilter});
  }, [debouncedFilter]);

  const onSelect = (itemId: number | string) => () => {
    const selectedItem = items.find(item => item.id === itemId);

    onValueChange(itemId, nameGetter(selectedItem));
  };

  const onLoadMore = () => loadMoreCallback();
  const onChangeFilter = (e: any) => setFilter(e.target.value);

  const Row = ({ index, style }: { index: number, style: any }) => {
    const item = items[index];

    if (refreshing && !item) {
      return (
        <div style={style}>
          <SelectListItemPlain key={`item-${index}`} className={styles.listItem}>
              <Skeleton/>
          </SelectListItemPlain>
        </div>
      );
    }

    if (!refreshing && !item) {
      return (
        <div style={style} className={styles.loadMoreBtnWrapper}>
          <Button
            btnType={ButtonType.GHOST}
            btnSize={ButtonSize.MEDIUM}
            onClick={onLoadMore}
          >
            {intl.formatMessage({ id: 'MursionPortal.Button.LoadMore' })}
          </Button>
        </div>
      );
    }

    const itemName = nameGetter(item);

    return (
      <div style={style}>
        <SelectListItemPlain
          title={itemName}
          onClick={onSelect(item.id || '')}
          selected={item.id === selectedItemId}
          key={`item-${item.id}`}
          className={styles.listItem}
        >
          {itemName}
        </SelectListItemPlain>
      </div>
    );
  };

  // @ts-ignore
  // TODO: add search if needed
  const renderSessionWizardSearch = () => (
    <div className={styles.searchContainer}>
      <input
        className={styles.search}
        placeholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.Search' })}
        type="search"
        value={filter}
        onChange={onChangeFilter}
      />
    </div>
  );

  const itemCount = (hasMore ? items.length + 1 : items.length);

  return (
    <>
      {
        !hideSearch && renderSessionWizardSearch()
      }
      <div className={styles.listContainer}>
        {
          itemCount
            ? (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    itemCount={itemCount}
                    itemSize={30}
                    height={height}
                    width={width}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            )
            : <div className={styles.emptyTimeBlock}>{refreshing ? <Skeleton count={3}/> : emptyMessage}</div>
        }
      </div>

    </>
  );
};

export default SessionWizardList;
