import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import useDebounce from 'src/hooks/useDebounce';
import _isEqual from 'lodash/isEqual';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { IDashboardReportIds } from 'src/app/data/dashboardReport/interfaces/IDashboardReport';

interface IDashboardReportIdsHookResult<U> {
    items: IDashboardReportIds;
    filter: string;
    setPagedDataCallback: (pagedData?: Partial<IListPageData>) => void;
    refreshing: boolean;
    error: any;
  }

export const useDashboardReportIdsDataFetching = <T, U extends any>(
    action: (params: IDashboardReportIds & T) => Promise<IListDataResponse<U>>,
  ) =>
    (params?: Omit<IDashboardReportIds & T, keyof IDashboardReportIds>) =>
      (defaultPagedData: IDashboardReportIds, debounceDelay: number = 300, isActive: boolean = true): IDashboardReportIdsHookResult<U> => {
        const [items, setItems] = useState<IDashboardReportIds>({});
        const [refreshing, setRefreshing] = useState<boolean>(true);
        const [error, setError] = useState<any>(null);
        let controller = new AbortController();
        const searchParams = params || {};
        const { value: debounceParams } = useDebounce({ ...defaultPagedData, ...searchParams }, debounceDelay);
  
        const dispatch = useDispatch();
  
        const fetchItems = async () => {
          if (refreshing) {
            controller.abort();
            controller = new AbortController();
          }
  
          setRefreshing(true);
  
          const actionParams: any = {
            ...debounceParams
          };
  
          try {
            const loadedItems = await dispatch(action(actionParams)) as any;
            setItems(loadedItems);
          } catch (e) {
            setError(e);
          }
          setRefreshing(false);
        };
  
        const paramsReady = useMemo(() =>
            _isEqual(debounceParams, { ...defaultPagedData, ...searchParams }) && isActive,
          [debounceParams, defaultPagedData, searchParams, isActive]
        );
  
        useEffect(() => {
          return () => controller.abort();
        }, []);
  
        useEffect(() => {
          setRefreshing(true);
          if (paramsReady) {
            fetchItems();
          }
        }, [paramsReady]);
  
        const setPagedDataCallback = useCallback(fetchItems, [dispatch, debounceParams, defaultPagedData, controller]);
  
        return {
          items,
          setPagedDataCallback,
          filter: defaultPagedData.filter || '',
          refreshing,
          error,
        };
      };