import * as React from 'react';
import AssetSettingField from 'src/components/AssetSettingField/AssetSettingField';
import { IScenarioTemplate } from 'src/app/data/projects/interfaces/IScenario';
import styles from './SessionWizard.css';
import { injectIntl, WrappedComponentProps } from 'react-intl';

export interface IAssetForBankScenarioStepProps extends WrappedComponentProps {
  selectedScenarioTemplate: IScenarioTemplate | null;
  assetSettingsId: string;
  onValueChange: (data: any, names: any) => void;
}

class AssetForBankScenarioStep extends React.Component<IAssetForBankScenarioStepProps> {

  public render() {
    const { selectedScenarioTemplate, assetSettingsId, intl } = this.props;

    if (!selectedScenarioTemplate) {
      return <span
        className={styles.emptyTimeBlock}> {intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.PleaseSelectScenario' })} </span>;
    }

    return (
      <div style={{ overflow: 'auto' }}>
        <AssetSettingField
          aliasNames={{}}
          required={true}
          singleColumn={false}
          label={intl.formatMessage({ id: 'MursionPortal.Label.ArtBundle' })}
          scenarioId={selectedScenarioTemplate.id}
          isScenarioTemplate={true}
          viewMode={false}
          selectedAssetSettingId={assetSettingsId}
          onChange={this.onAssetSettingsChange}
          listView={true}
          // onImageLoad={this.scrollToTheBottom}
        />
      </div>
    );
  }

  private onAssetSettingsChange = (assetSettingsId: string, assetSettingName: string) => {
    // TODO fetch asset name
    this.props.onValueChange(assetSettingsId, assetSettingName);
  }
}

export default injectIntl(AssetForBankScenarioStep);
