import React, { ElementType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Page from 'src/components/Page';
import styles from 'src/layouts/common/SimAttendanceV2/SimAttendanceV2.css';
import { useIntl } from 'react-intl';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import { Card, Row, Col } from 'react-bootstrap';
import SimAttendanceCard from 'src/layouts/common/SimAttendanceV2/components/SimAttendanceCard';
import SimAttendanceScenarioCard from 'src/layouts/common/SimAttendanceV2/components/SimAttendanceScenarioCard';
import SimAttendanceStatusCard from 'src/layouts/common/SimAttendanceV2/components/SimAttendanceStatusCard';
import AddAttendeesCard from 'src/layouts/common/SimAttendanceV2/components/AddAttendeesCard';
import FieldGroup from 'src/components/FieldGroup/FieldGroup';
import Button, { ButtonSize } from 'src/components/Button';
import { useHistory } from 'react-router-dom';
import getUrlParamValue from 'src/app/data/common/utils/queryParamsAccessor';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import { isCurrentUserSimSpec, isCurrentUserSupportAdmin } from 'src/app/data/common/utils/userRoleUtils';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import ROUTE_PATHS from 'src/routing/paths';
import actions from 'src/app/redux/store/actions';
import {
  ILearnerAttendanceStatus,
  ISessionAttendanceUpdate,
  ISessionRegisteredUser,
  ISessionWithHistoryInfo,
  SessionUserStatus,
} from 'src/app/data/session/interfaces/ISession';
import {
  getLearnerAttendanceStatus,
  isBeforeSimsSessionNextDayMidnight,
} from 'src/app/data/session/utils/sessionActionUtils';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { TLocaleId } from 'src/i18n';
import ErrorMessage from 'src/components/ErrorMessage/ErrorMessage';
import { getButtonTheme } from 'src/layouts/common/InviteToSchedulePage/InviteToScheduleUtils';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { PAGE, PARAM } from 'src/layouts/common/SimAttendance/SimAttendance';
import { LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH } from 'src/app/data/common/constants';
import SimAttendanceErrorSection, { ErrorTracking, IErrorState, checkSimReportError, getOtherIssues, getSimReportError } from 'src/layouts/common/SimAttendanceV2/components/SimAttendanceErrorSection';

export interface ISessionAttendanceState extends ISessionWithHistoryInfo {
  learnerAttendeeStatus?: ILearnerAttendanceStatus[];
  simStatus?: ISessionRegisteredUser | any;
}

export const allAcceptedStatus = [
  SessionUserStatus.COMPLETED,
  SessionUserStatus.MISSED,
  SessionUserStatus.ERROR,
  SessionUserStatus.LATE,
  SessionUserStatus.LEFT,
  SessionUserStatus.UNABLE_TO_COMPLETE
];

const SimAttendanceV2: FunctionComponent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = history;

  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  const userProfile = useSelector(selectors.profile.getUserProfile);
  const simTimezoneId = useSelector(selectors.profile.getUserTimeZoneId);

  const [hasSimSpecRole, setSimSpecRole] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sessionAndSimStatusMissed, setSessionAndSimStatusMissed] = useState<boolean>(false);
  const [sessionBeforeNextDayMidnight, setSessionBeforeNextDayMidnight] = useState<boolean>(false);
  const [showAddAttendeesForm, setAddAttendeesFormVisiblity] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [attendeeFirstName, setFirstName] = useState<string>('');
  const [attendeeLastName, setLastName] = useState<string>('');
  const [submittingAttendanceForm, setSubmittingAttendanceForm] = useState<boolean>(false);
  const [sessionAttendanceDetails, setSessionAttendanceDetails] = useState<ISessionAttendanceState | null>(null);

  const [errorState, setErrorState] = useState<IErrorState>({
    isCheckedLearnerTechnicalIssuesAudio: false,
    isCheckedLearnerTechnicalIssuesVideo: false,
    isCheckedLearnerTechnicalIssuesConnectionInternet: false,
    ischeckedMursionTechnicalIssuesAudio: false,
    ischeckedMursionTechnicalIssuesVideo: false,
    ischeckedMursionTechnicalIssuesConnectionInternet: false,
    ischeckedMursionTechnicalIssuesMomentSoftware: false,
    ischeckedMursionTechnicalIssuesAvatarOrEnvironment: false,
    ischeckedMursionTechnicalIssuesHardware: false,
    ischeckedOtherTechnicalIssuesLearnerSimulationSpecialistBothJoinedNoConnection: false,
    ischeckedOtherTechnicalIssuesOtherPleaseExplainBelow: false,
    ischeckedNonTechnicalIssueLearnerMaterialsOrPreparation: false,
    ischeckedNonTechnicalIssueLearnerLanguageFluency: false,
    ischeckedNonTechnicalIssueLearnerElectedToLeave: false,
    ischeckedNonTechnicalIssueOtherPleaseExplainBelow: false,
  });
  const [selectedItems, setSelectedItems] = useState<ErrorTracking[]>([]);
  const [otherIssues, setOtherIssues] = useState<string>('');

  const paramSessionId = useMemo(() => {
    return getUrlParamValue('sessionId', location);
  }, [location]);

  const redirectToHome = () => {
    history.push(ROUTE_PATHS.HOME);
  };

  const redirectToDashboard = () => {
    history.push(ROUTE_PATHS.DASHBOARD);
  };

  const updateSessionState = (sessionInfo: ISessionWithHistoryInfo) => {
    const { status, simspecialist } = sessionInfo;
    const isSessionAndSimStatusMissed = status === SessionStatusType.MISSED && !simspecialist?.status;
    if (isSessionAndSimStatusMissed) {
      setSessionAndSimStatusMissed(true);
    }
  };

  const fetchSessionDetails = () => {
    if (paramSessionId) {
      setLoading(true);
      dispatch(actions.session.fetchSession(paramSessionId))
        .then((sessionAttendance) => {
          const learnersData: ILearnerAttendanceStatus[] = sessionAttendance.learners.map((learner) => ({
            userId: learner.id,
            firstName: learner.firstName,
            lastName: learner.lastName,
            email: learner.email,
            isRegisteredUser: true,
            status: getLearnerAttendanceStatus(learner.status),
          }));

          const sessionAttendanceData = {
            ...sessionAttendance,
            learnerAttendeeStatus: learnersData,
            simStatus: {
              status: sessionAttendance.simspecialist?.status,
              userId: sessionAttendance.simspecialist?.id,
            },
          };

          updateSessionState(sessionAttendance);
          const otherIssuesInitialValue = getOtherIssues(sessionAttendance.otherIssues);
          const simReportErrorInitialValue = getSimReportError(sessionAttendance.simReportError);

          if (checkSimReportError(otherIssuesInitialValue, simReportErrorInitialValue)) {
            setOtherIssues(otherIssuesInitialValue);
            setSelectedItems(simReportErrorInitialValue);
          }
          if (isCurrentUserSupportAdmin(userRole)) {
            setSessionAttendanceDetails(sessionAttendanceData);
          } else if (sessionAttendance.simspecialist?.id !== userProfile?.id) {
            redirectToHome();
          } else {
            if (!isBeforeSimsSessionNextDayMidnight(simTimezoneId, sessionAttendance.endDate)) {
              setSessionBeforeNextDayMidnight(true);
            }
            setSessionAttendanceDetails(sessionAttendanceData);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const authorizeUserAndFetchDetails = useCallback(() => {
    const isAuthorizedRole = isCurrentUserSimSpec(userRole) || isCurrentUserSupportAdmin(userRole);
    if (!isAuthorizedRole || !paramSessionId) {
      const simSpecRoleExist = userProfile?.roles.some((role) => role.id === RoleID.SIM_SPECIALIST);
      if (simSpecRoleExist) {
        setSimSpecRole(true);
      } else {
        redirectToHome();
      }
    } else {
      fetchSessionDetails();
    }
  }, [userRole, paramSessionId]);

  useEffect(() => {
    authorizeUserAndFetchDetails();
  }, [authorizeUserAndFetchDetails]);

  const getCardText = (text: string | JSX.Element) => <Card.Text>{text}</Card.Text>;

  const getSimAttendanceCardText = () => {
    return (
      <>
        {getCardText(<>{getSimAttendanceText('MursionPortal.SessionAttendance.SessionDetails.Instructions')}</>)}
        {getCardText(<>{intl.formatMessage({ id: 'MursionPortal.SessionAttendance.SessionDetails.Text' })}</>)}
      </>
    );
  };

  const getSimAttendanceText = (text: TLocaleId) => {
    return getCardText(
      <>
        {TranslateMessage(text, {
          code: (word: string) => <strong>{word}</strong>,
        })}
      </>
    );
  };

  const getTitle = (titleType: ElementType | undefined, text: string) => <Card.Title as={titleType}>{text}</Card.Title>;

  const isAdditionalDetailsEnabled = Object.values(errorState).some((checked) => checked);

  const getAdditionalDetails = () => {
    return (
      <Row className={styles.optionRow}>
        <Col className={styles.titleCol}>
          <div className={styles.title}>
            {intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.AdditionalDetails' })}
          </div>
        </Col>
        <Col className={styles.content}>
          <div className={styles.contentDetail}>
            <FieldGroup
              id={`other-issue`}
              as={'textarea'}
              value={otherIssues}
              csvProtected={true}
              placeholder={intl.formatMessage({ id: 'MursionPortal.Dashboard.Provide.Details' })}
              isCobalt={true}
              maxLength={LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH}
              onChange={handleAdditionalDetailsText}
              disabled={!isAdditionalDetailsEnabled}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const onSubmit = async () => {
    const { learnerAttendeeStatus, simStatus } = sessionAttendanceDetails ?? {};
    setErrorMessage(null);
    setSubmittingAttendanceForm(true);
    setLoading(true);
    const registeredUsers = learnerAttendeeStatus
      ?.filter((user) => user.isRegisteredUser)
      .map((regUser) => ({ status: regUser.status, userId: regUser.userId }));
    const unregisteredUsers = learnerAttendeeStatus
      ?.filter((user) => !user.isRegisteredUser)
      .map((unRegUser) => ({ status: unRegUser.status, firstName: unRegUser.firstName, lastName: unRegUser.lastName }));
    const attendeeData: ISessionAttendanceUpdate = { attendeeStatus: registeredUsers ?? [], simStatus };
    if (unregisteredUsers?.length) {
      attendeeData.addAttendees = unregisteredUsers;
    }
    attendeeData.simReportError = selectedItems;
    attendeeData.otherIssues = otherIssues;
    const submitAttendance = await dispatch(actions.session.updateSessionAttendance(attendeeData, paramSessionId));
    setLoading(false);
    if (submitAttendance) {
      if (getUrlParamValue(PARAM.REDIRECTED_FROM, location) === PAGE.DASHBOARD) {
        redirectToDashboard();
      } else {
        redirectToHome();
      }
    } else {
      setErrorMessage(intl.formatMessage({ id: 'MursionPortal.Error.SomethingWentWrong' }));
    }
  };

  const isSubmitButtonDisabled = () => {
    const { learnerAttendeeStatus } = sessionAttendanceDetails ?? {};
    if (otherIssues.length > LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH) {
      return !otherIssues ||
        otherIssues.length > LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH;
    }
    return (
      (learnerAttendeeStatus && learnerAttendeeStatus.length < 1) ||
      submittingAttendanceForm ||
      !!learnerAttendeeStatus?.some((user) => !allAcceptedStatus.includes(user.status ?? SessionUserStatus.MISSED))
    );
  };

  const getSubmitOption = () => {
    return (
      <div className={styles.submitOption}>
        <Button
          onClick={onSubmit}
          className={styles.submitBtn}
          aria-label={intl.formatMessage({ id: 'MursionPortal.Button.Save' })}
          disabled={isSubmitButtonDisabled()}
          btnType={getButtonTheme(isSubmitButtonDisabled())}
        >
          {intl.formatMessage({ id: 'MursionPortal.Button.Save' })}
        </Button>
      </div>
    );
  };

  const onSimStatusChangeHandler = (status: SessionUserStatus) => {
    setSessionAttendanceDetails((prevState) => {
      return prevState
        ? {
            ...prevState,
            simStatus: {
              ...prevState?.simStatus,
              status,
            },
          }
        : null;
    });
  };

  const onLearnerStatusChangeHandler = (index: number) => (value: SessionUserStatus) => {
    const { learnerAttendeeStatus } = sessionAttendanceDetails ?? {};
    if (learnerAttendeeStatus) {
      const newStatus = [...learnerAttendeeStatus];
      newStatus[index].status = value;
      setSessionAttendanceDetails((prevState) => {
        return prevState
          ? {
              ...prevState,
              learnerAttendeeStatus: newStatus,
            }
          : null;
      });
    }
  };

  const onAddAttendeeClickHandler = () => {
    setErrorMessage(null);
    setAddAttendeesFormVisiblity(true);
  };

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const addAttendeeHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setErrorMessage(null);
    if (attendeeFirstName && attendeeLastName) {
      const { learnerAttendeeStatus } = sessionAttendanceDetails ?? {};
      if (learnerAttendeeStatus) {
        setSessionAttendanceDetails((prevState) => {
          return prevState
            ? {
                ...prevState,
                learnerAttendeeStatus: [
                  {
                    status: SessionUserStatus.COMPLETED,
                    firstName: attendeeFirstName,
                    userId: null,
                    isRegisteredUser: false,
                    lastName: attendeeLastName,
                  },
                  ...learnerAttendeeStatus,
                ],
              }
            : null;
        });
      }
      setAddAttendeesFormVisiblity(false);
      setFirstName('');
      setLastName('');
    }
  };
 
  const onCheckedItem = (id: ErrorTracking, checked: boolean) => {
    setSelectedItems(prevCheckedItems => {
      if (checked && !prevCheckedItems.includes(id)) {
        return [...prevCheckedItems, id];
      } else if (!checked && prevCheckedItems.includes(id)) {
        return prevCheckedItems.filter(item => item !== id);
      }
      return prevCheckedItems;
    });

    sortCheckedItems();
  };

  const sortCheckedItems = () => {
    setSelectedItems(prevCheckedItems => {
      return [...prevCheckedItems].map(item => item).sort((a, b) => a - b);
    });
  };

  const setCheckboxErrorState = (key: string, value: boolean) => {
    setErrorState({
      ...errorState,
      [key]: value,
    });
  };

  const handleAdditionalDetailsText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setOtherIssues(value);
    if (value.trim() !== '' || value.length > LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH) {
      setSelectedItems(prevCheckedItems => {
        if (!prevCheckedItems.includes(ErrorTracking.OTHER_ISSUES)) {
          return [...prevCheckedItems, ErrorTracking.OTHER_ISSUES];
        }
        return prevCheckedItems;
      });
    } else {
      setSelectedItems(prevCheckedItems => prevCheckedItems.filter(item => item !== ErrorTracking.OTHER_ISSUES));
    }
    sortCheckedItems();
  };

  const getModalText = () => {
    if (sessionAndSimStatusMissed) {
      return intl.formatMessage({ id: 'MursionPortal.SimAttendance.MissedSimulation.Message' });
    }

    return `${intl.formatMessage({ id: 'MursionPortal.SimAttendance.Midnight' })}${' '}`;
  };

  const getModalBody = () => {
    return (
      <>
        {sessionAndSimStatusMissed ? null : (
          <div className={styles.textRed}>
            {intl.formatMessage({ id: 'MursionPortal.SimAttendance.NoLongerAvailable' })}
          </div>
        )}
        <div>{getModalText()}</div>
      </>
    );
  };

  return (
    <Page bgColor={'white'} className={styles.simAttendanceV2Wrap}>
      <ConfirmationModal
        show={sessionAndSimStatusMissed || sessionBeforeNextDayMidnight}
        headerTitle={`${intl.formatMessage({ id: 'Mursion.Portal.Status.Error' })}!`}
        bodyText={getModalBody()}
        onCancelModal={redirectToHome}
        confirmationTitle={intl.formatMessage({ id: 'MursionPortal.Button.GotIt' })}
        buttonSize={ButtonSize.MEDIUM}
        className={styles.errorModal}
      />

      {hasSimSpecRole ? (
        <div className={styles.switchRole}>
          {intl.formatMessage({ id: 'MursionPortal.SessionAttendance.SwitchToSimRole.Message' })}
        </div>
      ) : (
        <LoadingOverlay active={isLoading} spinner={true}>
          {getTitle('h1', intl.formatMessage({ id: 'MursionPortal.Label.SessionAttendance' }))}
          {getTitle('h2', intl.formatMessage({ id: 'MursionPortal.Label.Status' }))}
          <SimAttendanceCard content={getSimAttendanceText('MursionPortal.SessionAttendance.Heading')} />
          <SimAttendanceScenarioCard
            sessionAttendanceDetails={sessionAttendanceDetails}
            simTimezoneId={simTimezoneId}
          />
          <SimAttendanceStatusCard
            sessionAttendanceDetails={sessionAttendanceDetails}
            userRole={userRole}
            onSimStatusChange={onSimStatusChangeHandler}
          />
          <AddAttendeesCard
            sessionAttendanceDetails={sessionAttendanceDetails}
            onLearnerStatusChange={onLearnerStatusChangeHandler}
            showAddAttendeesForm={showAddAttendeesForm}
            onAddAttendeeClick={onAddAttendeeClickHandler}
            attendeeFirstName={attendeeFirstName}
            onFirstNameChange={onFirstNameChange}
            attendeeLastName={attendeeLastName}
            onLastNameChange={onLastNameChange}
            addAttendee={addAttendeeHandler}
          />
          <Card.Title as={'h2'}>
            {intl.formatMessage({ id: 'MursionPortal.Session.LearnerSession.SessionDetails' })}{' '}
            <div className={styles.smallText}>
              {intl.formatMessage({ id: 'MursionPortal.SessionAttendance.SessionDetails.Heading' })}
            </div>
          </Card.Title>
          <SimAttendanceCard content={getSimAttendanceCardText()} className={styles.summaryBoxContent} />
          {getTitle('h3', intl.formatMessage({ id: 'MursionPortal.Dashboard.Technical.Issue.Summary' }))}
          <SimAttendanceErrorSection
            onCheckedItem={onCheckedItem}
            setCheckboxErrorState={setCheckboxErrorState}
            selectedItems={selectedItems}
            errorState={errorState}
          />
          {getAdditionalDetails()}
          <ErrorMessage message={errorMessage} focusOnChange={true} />
          {getSubmitOption()}
        </LoadingOverlay>
      )}
    </Page>
  );
};

export default SimAttendanceV2;
