import * as React from 'react';
import AssetSettingField from 'src/components/AssetSettingField/AssetSettingField';
import AssetViewV2 from 'src/components/ScenarioInfo/AssetViewV2/AssetViewV2';
import { convertScenarioDraftToUpdate } from 'src/app/data/projects/utils/scenarioConverter';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import IScenario from 'src/app/data/projects/interfaces/IScenario';
import styles from './SessionWizard.css';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { injectIntl, WrappedComponentProps } from 'react-intl';

export interface IAssetStepProps extends WrappedComponentProps {
  selectedScenario: IScenario | null;
  assetSettingsId: string;
  onValueChange: (data: any, names: any) => void;
}

class AssetStep extends React.Component<IAssetStepProps> {
  public componentDidMount() {
    const { selectedScenario, assetSettingsId, intl } = this.props;

    // autofill ml2 asset settings
    if (!!selectedScenario && selectedScenario.draft.scenarioVersion === ScenarioVersion.V2 && !assetSettingsId) {
      const assetName = selectedScenario.draft.assetProject && selectedScenario.draft.assetProject.name || intl.formatMessage({ id: 'MursionPortal.Text.Asset' });
      const assetId = selectedScenario.draft.assetProject && selectedScenario.draft.assetProject.id;
      this.props.onValueChange(assetId, assetName);
    }
  }

  public render() {
    const { selectedScenario, assetSettingsId, intl } = this.props;

    if (!selectedScenario) {
      return <span
        className={styles.emptyTimeBlock}> {TranslateMessage('Projects.Message.SelectScenarioFirst')} </span>;
    }

    const scenarioVersion = selectedScenario ? selectedScenario.draft.scenarioVersion : '';

    return (
      <div style={{ overflow: 'auto' }}>
        {
          (
            !!scenarioVersion &&
            [
              ScenarioVersion.V3,
              ScenarioVersion.V3z,
              ScenarioVersion.V3meet
            ].includes(scenarioVersion)
          )
          && !!selectedScenario.id &&
          <AssetSettingField
            aliasNames={selectedScenario ? selectedScenario.draft.assetSettingsAliases : null}
            required={true}
            singleColumn={false}
            label={intl.formatMessage({ id: 'MursionPortal.Label.ArtBundle' })}
            scenarioId={selectedScenario.id}
            viewMode={false}
            selectedAssetSettingId={assetSettingsId}
            onChange={this.onAssetSettingsChange}
            listView={true}
          // onImageLoad={this.scrollToTheBottom}
          />
        }
        {
          scenarioVersion === ScenarioVersion.V2 && selectedScenario &&
          <div className={styles.fieldContainer}>
            <AssetViewV2
              industryId={''}
              scenarioDraft={convertScenarioDraftToUpdate(selectedScenario.draft)}
              viewMode={true}
            />
          </div>
        }
      </div>
    );
  }

  private onAssetSettingsChange = (assetSettingsId: string, assetSettingName: string) => {
    // TODO fetch asset name
    this.props.onValueChange(assetSettingsId, assetSettingName);
  };
}

export default injectIntl(AssetStep);
