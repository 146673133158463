import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { isCurrentUserBorF, isCurrentUserBorForL, isCurrentUserLearner, isCurrentUserPSorOps } from 'src/app/data/common/utils/userRoleUtils';
import { selectors } from 'src/app/redux';
import ROUTE_PATHS from 'src/routing/paths';
import styles from 'src/layouts/common/SimulationScheduling/SimulationScheduling.css';
import getUrlParamValue from 'src/app/data/common/utils/queryParamsAccessor';
import { getTitle, ITimeBlock, SchedulingType } from 'src/app/data/calendar/utils/simulationSchedulingUtil';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import getUserName from 'src/app/data/common/utils/getUserName';
import { getS3BucketURL } from 'src/s3bucketurls/S3BucketUrlUtils';
import cn from 'classnames';
import calendar from 'src/images/calendar.svg';
import IVSBrowserWarning from 'src/components/IVSBrowserWarning/IVSBrowserWarning';

interface ISubmitPageProps {
  schedulingType: SchedulingType;
  emergencySessionId: string;
  selectedDate: moment.Moment | null;
  learners: ILearnerExtended[];
  onlyUnauthenticatedLearners: boolean;
  isSimSpecialist: ICompanyUser | null;
  scenarioName: string;
  projectName: string;
  clientName: string;
  onSchedule: () => void;
  getScenarioLink: () => string;
  selectedTime: ITimeBlock | null;
  scheduledSessionId: string;
}

const SubmitPage: FunctionComponent<any> = (props: ISubmitPageProps) => {
  const intl = useIntl();
  const {
    schedulingType,
    emergencySessionId,
    selectedDate,
    learners,
    onlyUnauthenticatedLearners,
    isSimSpecialist,
    scenarioName,
    projectName,
    clientName,
    onSchedule,
    getScenarioLink,
    selectedTime,
    scheduledSessionId,
  } = props;
  const userRole = useSelector(selectors.profile.getCurrentUserRole);

  const getProjectLink = (): string => {
    const paramClientId = getUrlParamValue('clientId', location);
    const paramProjectId = getUrlParamValue('projectId', location);
    return isCurrentUserBorF(userRole) ? `/projects/${paramProjectId}` :
     `/clients/${paramClientId}/projects/${paramProjectId}`;
  };

  const getClientLink = (): string => {
    const paramClientId = getUrlParamValue('clientId', location);
    return `/clients/${paramClientId}`;
  };

  const getSessionLink = (): string => {
    return isCurrentUserBorF(userRole) ? `/calendar/event/session/${scheduledSessionId}` : `/calendar/event/session/${emergencySessionId}`;
  };

  const getSubmitMessage = (): string => {
    return schedulingType === SchedulingType.RESCHEDULING
      ? intl.formatMessage({ id: 'MursionPortal.Rescheduling.SubmitPage.Message' })
      : intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.SubmitPage.Message' });
  };

  const getFormattedLearnerContent = (learnerToDisplay: JSX.Element) => {
    return intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed' }, { learners: learnerToDisplay });
  };

  const renderLearners = () => {
    if (isCurrentUserBorF(userRole)) {
      return getFormattedLearnerContent(<div className={styles.learnerName}>{learners.map((learner) => getUserName(learner.user)).join(', ')}</div>);
    }
  
    return learners.map((learner: ILearnerExtended, i: number) => {
      return (
        <div key={'learner' + i} className={styles.learnerName}>
          {getUserName(learner.user)}
        </div>
      );
    });
  };
  
  const renderLearnerContent = () => {
    return learners.length
      ? renderLearners()
      : onlyUnauthenticatedLearners && (
        <div className={styles.learnerName}>
          {intl.formatMessage({ id: 'Session.Edit.Modal.Learners.UnauthenticatedCheckbox' })}
        </div>
      );
  };

  const getImagePath = () => {
    switch (true) {
      case isCurrentUserLearner(userRole):
        return getS3BucketURL('images','app/learner.gif');
      case isCurrentUserBorF(userRole):
        return getS3BucketURL('images','app/buyerAndFacilitator.gif');
      case isCurrentUserPSorOps(userRole):
        return getS3BucketURL('images','app/professionalService.gif');
      default:
        return null;
    }
  };

  const renderConfirmationImages = () => {
    const imageUrl = getImagePath();
    return imageUrl ? (
      <img src={imageUrl} alt={intl.formatMessage({ id: "MursionPortal.UnavailableSlotsError.SorryText" })} />
    ) : null;
  };

  const getTimeTitle = () => {
    return selectedTime ? getTitle(selectedTime) : '';
  };

  const getDateValue = () => {
    return moment(selectedDate).format(`LL [${intl.formatMessage({ id: 'MursionPortal.At' })} ]`);
  };

  const renderDateTime = () => {
    return `${moment(selectedDate).format('dddd [ ]')} ${getDateValue()} ${getTimeTitle()}`;
  };

  return (
    <div className={cn(styles.thanyouBodyWrapper, isCurrentUserPSorOps(userRole) && styles.psRolePage)}>
    <div className={styles.thanyouWrapper}>
      {renderConfirmationImages()}
    </div>
    <div className={styles.thankyouMessageWrap}>
      {isCurrentUserBorForL(userRole) && (
            <div className={styles.pageTitle}>
              {intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou' })}
            </div>
        )}
      {schedulingType === SchedulingType.EMERGENCY || (selectedTime?.isAvailable && schedulingType !== SchedulingType.RESCHEDULING) || isCurrentUserBorF(userRole) ? (
        <div>
          {isCurrentUserLearner(userRole) &&
          <div className={styles.descriptionText}>
            {intl.formatMessage({ id: 'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation' })}
          </div>
          }
          {isCurrentUserBorF(userRole) &&
          <div className={styles.descriptionText}>
          {intl.formatMessage({ id: 'ClientUserTable.AddNewUsers.Learners' })}: {renderLearnerContent()}
        </div>
          }
          {(schedulingType === SchedulingType.EMERGENCY ||  isCurrentUserBorF(userRole)) && (
            <div>
              {isCurrentUserPSorOps(userRole) && <>
                <div className={styles.selectedScenario}>
                  <a href={getSessionLink()} className={styles.scenarioName}>
                    {scenarioName}
                  </a>
                  <div className={styles.scheduledDate}>
                      <img src={calendar} alt={moment(selectedDate).format('dddd')} />
                      {renderDateTime()}
                  </div>
                </div>
                <div className={cn(styles.learnerSimSpecialistWrap, styles.scheduleDetails)}>
                  <div className={cn(styles.learnerWrap, styles.scheduleDetailsCol)}>
                    <div className={styles.learnerTitle}>{intl.formatMessage({ id: 'ClientUserTable.AddNewUsers.Learners' })}:</div>
                    {renderLearnerContent()}
                  </div>
                  <div className={cn(styles.simSpecialistWrap, styles.learnerWrap, styles.scheduleDetailsCol)}>
                    <div className={styles.learnerTitle}>SIM Specialist:</div>
                    <div className={styles.simSpecialistName}>
                      {isSimSpecialist?.firstName + ' ' + isSimSpecialist?.lastName}
                    </div>
                  </div>
                </div>
              </>
              }
              <div className={styles.linksWrap}>
                {isCurrentUserPSorOps(userRole) && <>
                  <div className={cn(styles.link, styles.clientName)}>
                  <div className={styles.linkTitle}>{intl.formatMessage({ id: 'Session.Table.Column.Client' })}</div>
                  <a href={getClientLink()} className={styles.links}>
                    {clientName}
                  </a>
                </div>
                </>}
                <div className={styles.link}>
                  <div className={styles.linkTitle}>{intl.formatMessage({ id: 'Session.Table.Column.Project' })}</div>
                  <a href={getProjectLink()} className={styles.links}>
                    {projectName}
                  </a>
                </div>
                <div className={styles.link}>
                  <div className={styles.linkTitle}>{intl.formatMessage({ id: 'Session.Table.Column.Scenario' })}</div>
                  <a href={getScenarioLink()} className={styles.links}>
                    {scenarioName}
                  </a>
                </div>
                {isCurrentUserBorF(userRole) && <>
                  <div className={cn(styles.link, styles.scheduleDate)}>
                    <div className={styles.linkTitle}>{intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime' })}</div>
                      <div className={styles.linksDetail}>
                      <div>
                      {renderDateTime()}
                      </div>
                    </div>
                </div>
                </>}
              </div>
              <div className={styles.submitBtnWrap}>
                <button className={styles.scheduleAnotherBtn} onClick={onSchedule} aria-label={intl.formatMessage({ id: 'MursionPortal.Button.Schedule.Another' })}>
                  {intl.formatMessage({ id: 'MursionPortal.Button.Schedule.Another' })}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
          <div className={styles.description}>
            {getSubmitMessage()}
          </div>
      )}
      <IVSBrowserWarning />
      {isCurrentUserLearner(userRole) && (
        <div className={styles.learnerScenDetails}>
          <div className={styles.mainTitle}>
            <a href={getSessionLink()} className={styles.scenarioName}>
              {scenarioName}
            </a>
          </div>
          <div className={styles.time}>
            {moment(selectedDate).format('dddd [ ]')}
            {moment(selectedDate).format('MMMM D [| ]')}
            {getTimeTitle()}
          </div>
          <LinkContainer to={ROUTE_PATHS.DASHBOARD}>
            <a className={styles.dashboardLink}>
              {intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard' })}
            </a>
          </LinkContainer>
        </div>
      )}
    </div>
    </div>
  );
};
export default SubmitPage;
