import SessionType from 'src/app/data/session/interfaces/SessionType';
import BooleanType from 'src/app/data/session/interfaces/iBooleanType';
import { DELIVERY_MODE } from 'src/app/data/common/DeliveryMode';
import { intl } from 'src/i18n/createIntl';

export const SIMULATION_RECORDED = {
    [BooleanType.TRUE]: 'Filters.Boolean.Yes',
    [BooleanType.FALSE]: 'Filters.Boolean.No',
};

export const STANDARDIZED = {
    [BooleanType.TRUE]: 'Filters.Boolean.Yes',
    [BooleanType.FALSE]: 'Filters.Boolean.No',
};

export const REGISTRATION_STATUS = {
    [BooleanType.TRUE]: 'MursionPortal.Status.RegistrationAccepted',
    [BooleanType.FALSE]: 'MursionPortal.Status.RegistrationNotAccepted',
};

export const RECORDED = {
    [BooleanType.TRUE]: 'Filters.Boolean.Yes',
    [BooleanType.FALSE]: 'Filters.Boolean.No',
};

export const BOOLEAN_VALUE = {
    [BooleanType.TRUE]: 'Filters.Boolean.Yes',
    [BooleanType.FALSE]: 'Filters.Boolean.No',
};

export const getBooleanLabel = (key: boolean) => {
    return intl().formatMessage({id: BOOLEAN_VALUE[String(key).toLocaleUpperCase()]});
};

export const getRegistrationStatusLabel = (key: boolean) => {
    return intl().formatMessage({id: REGISTRATION_STATUS[String(key).toLocaleUpperCase()]});
};

export const getDeliveryModeLabel = (key: SessionType) => {
    return intl().formatMessage({id: DELIVERY_MODE[key]});
};
