import React, { FunctionComponent } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import Button, { ButtonFont, ButtonSize } from 'src/components/Button';
import styles from './SendEmailDialog.css';
import ErrorMessage from 'src/components/ErrorMessage';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';

export interface IUsersActivityDialogProps {
  modalProps: ModalProps;
  container?: HTMLElement;
  limit: number | null;
  limitExceeded: boolean;
  onClose: () => void;
}

const SendEmailDialog: FunctionComponent<IUsersActivityDialogProps> = (props) => {

  const { modalProps, onClose, limit, limitExceeded } = props;
  const intl = useIntl();
  const userProfile = useSelector(selectors.profile.getUserProfile);

  if (!userProfile?.email) {
    return null;
  }

  return (
    <Modal {...modalProps} >
      <Modal.Header>
        <Modal.Title>{TranslateMessage('Dashboard.Button.ExportToEmail')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          limit && limitExceeded ? (
            <ErrorMessage message={intl.formatMessage({ id: 'Session.Export.ErrMsg' }, { limit })} />
          ) : (
            <span>{TranslateMessage('Dashboard.Button.ExportToEmailSentMsg', { email: userProfile.email })}</span>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <div className={styles.footer}>
          <Button
            btnFont={ButtonFont.LIGHT}
            btnSize={ButtonSize.MEDIUM}
            disabled={false}
            onClick={onClose}
            aria-label={intl.formatMessage({ id: "MursionPortal.AriaLabel.Close" })}
          >
            {TranslateMessage('MursionPortal.Close')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SendEmailDialog;
