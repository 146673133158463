exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ViewMore__viewMore___3VGEc{color:#2949bc;font-weight:600;font-size:13px;line-height:18px}.ViewMore__viewMore___3VGEc img{margin-right:5px}.ViewMore__storyHeading___ed2gh{text-align:center;padding-top:10px}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/layouts/common/Projects/components/ProjectCard/components/common/ScenarioEditForm/ViewMore/ViewMore.css"],"names":[],"mappings":"AAAA,4BACI,cAAe,AACf,gBAAiB,AACjB,eAAgB,AAChB,gBAAkB,CACnB,AAEH,gCACI,gBAAkB,CACnB,AAEH,gCACE,kBAAmB,AACnB,gBAAkB,CACnB","file":"ViewMore.css","sourcesContent":[".viewMore{\n    color: #2949BC;\n    font-weight: 600;\n    font-size: 13px;\n    line-height: 18px;\n  }\n  \n.viewMore img{\n    margin-right: 5px;\n  }\n\n.storyHeading {\n  text-align: center;\n  padding-top: 10px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"viewMore": "ViewMore__viewMore___3VGEc",
	"storyHeading": "ViewMore__storyHeading___ed2gh"
};