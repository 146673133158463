import React from 'react';
import { useIntl } from 'react-intl';

const DataRequestNotImplemented = () => {
  const intl = useIntl();

  return <div className="text-center page-header">
    {intl.formatMessage({ id: 'Dashboard.Projects.AlertMessage.DataRequestNotImplemented' })}
  </div>;
};

export default DataRequestNotImplemented;
