import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import styles from './SessionWizard.css';
import ITimeBlock from 'src/app/data/calendar/interfaces/ITimeBlock';
import { ShortScenarioInfo } from './components';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import TimeBlock
  from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/components/TimeBlock/TimeBlock';
import { getTimeBlockName } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/components/TimeBlock/utils';
import TimeRangeSlider
  from 'src/components/TimeRangeSlider/TimeRangeSlider';
import moment, { Moment } from 'moment-timezone';
import { getDayFormat, getTimeFormat } from 'src/app/data/common/utils/dateUtil';
import cn from 'classnames';
import Toggle from 'react-switch';
import ReactDOM from 'react-dom';
import Tooltip, { TooltipPlacement } from 'src/components/Tooltip/Tooltip';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import { useIntl } from 'react-intl';
import TranslateMessage from 'src/i18n/TranslateMessage';
import ITimeInterval from 'src/app/data/common/interfaces/ITimeInterval';
import { isTimeBlockRestricted } from 'src/app/data/calendar/utils/simulationSchedulingUtil';

interface ITimeStepProps {
  allocating: boolean;
  notificationContainer: HTMLElement | null;
  showAllControlContainer: HTMLElement | null;
  canShowAll: (maxSelectedTime: number) => boolean;
  timeBlocks: ITimeBlock[];
  calendarTimezoneId: string;
  selectedTimeBlock: ITimeBlock | null;
  defaultData: {
    showOtherBlocks: boolean,
    rangeStart?: Moment | null,
    rangeEnd?: Moment | null,
  };
  deliveryMode: SessionType;
  sessionLength: number;
  date: Moment;
  onValueChange: (data: any, names: any, defaultData: any) => void;
  restrictions?: ITimeInterval[];
  practiceSession?: boolean;
  className?: string;
}

const TimeStep = (props: ITimeStepProps) => {
  const {
    allocating,
    notificationContainer,
    showAllControlContainer,
    timeBlocks,
    onValueChange,
    selectedTimeBlock,
    calendarTimezoneId,
    deliveryMode,
    sessionLength,
    date,
    defaultData,
    canShowAll,
    restrictions,
    practiceSession,
    className,
  } = props;
  const isDstChangeDate = timeBlocks.length > 2
    && timeBlocks[0].start.isDST() !== timeBlocks[timeBlocks.length - 1].start.isDST();
  const convertedDate = moment.tz(date, calendarTimezoneId);
  const intl = useIntl();

  const defaultRange = {
    start: defaultData?.rangeStart || convertedDate.clone().startOf('d').hours(8),
    end: defaultData?.rangeEnd || convertedDate.clone().startOf('d').hours(20),
  };

  const [range, setRange] = useState<{ start: Moment, end: Moment }>(defaultRange);
  const [showOtherBlocks, setShowOtherBlocks] = useState(defaultData.showOtherBlocks);

  const renderTimeBlock = useCallback((timeBlock: ITimeBlock, index: number) => {
    const isSelected = !!selectedTimeBlock
      && selectedTimeBlock.start.isSame(timeBlock.start)
      && selectedTimeBlock.end.isSame(timeBlock.end);

    const onClick = (tb: ITimeBlock) => () => !allocating && onSelectTimeBlock(tb);

    const handleStepOnKeyPress = (timeB: any) => (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
      if (keyboardEvent.key === 'Enter') {
        onClick(timeB)();
      }
    };
    
    const isTBRestricted = practiceSession ? false : isTimeBlockRestricted(restrictions || [], { startTime: timeBlock.start, endTime: timeBlock.end});
    
    if(isTBRestricted) {
      return;
    } else {
    return (
      <div
        key={'time-block-' + index}
        className={cn(styles.timeBlockListItem, allocating && styles.timBlockDisabled)}
        onClick={onClick(timeBlock)}
        onKeyDown={handleStepOnKeyPress(timeBlock)}
      >
        <TimeBlock
          timeBlock={timeBlock}
          selected={isSelected}
          available={practiceSession || timeBlock.recommended}
          isDstChangeDate={isDstChangeDate}
          tzId={calendarTimezoneId}
          practiceSession={practiceSession}
        />
      </div>
    );}
  }, [
    allocating,
    selectedTimeBlock?.start.valueOf(),
    selectedTimeBlock?.end.valueOf(),
    isDstChangeDate,
    calendarTimezoneId,
    restrictions,
  ]);

  const onSelectTimeBlock = (timeBlock: ITimeBlock) => {
    const name = getTimeBlockName(timeBlock, calendarTimezoneId, false);

    onValueChange(timeBlock, name, {
      showOtherBlocks,
      rangeStart: range.start,
      rangeEnd: range.end,
    });
  };

  const onChangeRange = (startRange: Moment, endRange: Moment) => {
    setRange({
      start: startRange,
      end: endRange,
    });
  };

  const onToggleOtherBlocks = () => {
    setShowOtherBlocks(!showOtherBlocks);

    onValueChange(null, name, {
      showOtherBlocks,
      rangeStart: range.start,
      rangeEnd: range.end,
    });
  };

  const timeBlocksInRange = useMemo(() =>
    practiceSession ? timeBlocks : timeBlocks
      .filter((tb) =>
        tb.start.isSameOrAfter(range.start) &&
        tb.end.isSameOrBefore(range.end)
      )
    , [timeBlocks, range, practiceSession]);

  const renderAllPossibleTimeBlocks = () => {

    if (!timeBlocksInRange.length) {
      return (
        <div className={styles.emptyTimeBlock}>
          {timeBlocks.length ? intl.formatMessage({ id: 'Calendar.Text.NoTimeBlocksInSelectedRange' }) : intl.formatMessage({ id: 'Calendar.Text.NoAvailableTimeBlocks' })}
        </div>
      );
    }

    return (
      <div className={styles.timeBlocksList}>
        {timeBlocksInRange.map(renderTimeBlock)}
      </div>
    );
  };

  const renderRecommendedTimeBlocks = () => {
    const recommendedTimeBlocks = timeBlocks.filter(tb => tb.recommended);
    const recommendedTimeBlocksInRange = timeBlocksInRange.filter(tb => tb.recommended);

    if (!recommendedTimeBlocksInRange.length) {
      return (
        <div className={styles.emptyTimeBlock}>
           {recommendedTimeBlocks.length ? intl.formatMessage({ id: 'Calendar.Text.NoTimeBlocksInSelectedRange' }) : intl.formatMessage({ id: 'Calendar.Text.NoAvailableTimeBlocks' })}
        </div>
      );
    }

    return (
      <div className={styles.timeBlocksList}>
        {recommendedTimeBlocksInRange.map(renderTimeBlock)}
      </div>
    );
  };

const getTooltipText = () => {
  if (showOtherBlocks) {
    return intl.formatMessage({ id: 'Calendar.ToolTip.SlideToggleOff' });
  }

  return intl.formatMessage({ id: 'Calendar.ToolTip.SlideToggleOn' });
};

const renderTimeBlockSection = () => {
  if (showOtherBlocks) {
    return renderAllPossibleTimeBlocks();
  }

  return renderRecommendedTimeBlocks();
};

  return (
    <LoadingOverlay active={allocating} spinner={true} className={cn(styles.overlayWrapper, className)}>
      { 
        practiceSession ? renderAllPossibleTimeBlocks() : 
      <div className={styles.timeStepContainer}>
        <div className={styles.shortInfo}>
          <div className={styles.dateInfo}>{date.format(getDayFormat())}</div>
          <ShortScenarioInfo deliveryMode={deliveryMode} sessionLength={sessionLength}/>
        </div>
        <div>
          <div className={styles.rangeLabelWrapper}>
            <i className={cn('far', 'fa-clock', styles.rangeLabelIcon)}/>
            <div className={styles.rangeLabel}>{range.start.format(getTimeFormat())} - {range.end.format(getTimeFormat())}</div>
          </div>

          <TimeRangeSlider
            min={convertedDate.clone().startOf('d')}
            max={convertedDate.clone().add(1, 'd').startOf('d')}
            defaultStart={defaultRange.start}
            defaultEnd={defaultRange.end}
            tzId={calendarTimezoneId}
            onChange={onChangeRange}
          />
          {
            canShowAll(range.end.valueOf()) &&

            notificationContainer &&
            ReactDOM.createPortal(
              <>
                {
                  selectedTimeBlock &&
                  !selectedTimeBlock.recommended &&
                  <div className={styles.footerNotification}>
                    {TranslateMessage('Calendar.Text.SelectingThisTimeBlock')}
                  </div>
                }
              </>, notificationContainer)
          }
          {
            canShowAll(range.end.valueOf()) &&
            showAllControlContainer &&
            ReactDOM.createPortal(
              <>
                <Tooltip
                  placement={TooltipPlacement.BOTTOM}
                  text={getTooltipText()}
                >
                  <label className={styles.toggleOthersLabel}>
                    <Toggle
                      onChange={onToggleOtherBlocks}
                      checked={showOtherBlocks}
                      onColor={'#93D2AB'}
                      height={20}
                      width={40}
                    />
                    <span className={styles.toggleOthersLabelText}>
                      {TranslateMessage('Calendar.Label.SelectAnyTimeBlock')}
                <i className={cn('fas fa-info-circle', styles.infoIcon)}/>
              </span>

                  </label>
                </Tooltip>
              </>,
              showAllControlContainer
            )
          }
        </div>
         {renderTimeBlockSection()}
      </div> 
      }
    </LoadingOverlay>
  );
};

export default TimeStep;
