import React, { FunctionComponent } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { defaultDemandBasedTimeSlots } from "src/layouts/common/Projects/components/ProjectCard/components/NewProjectForm/components/ProjectCreateStep/ProjectCreateStep";
import cn from "classnames";
import styles from "../../SimulationScheduling.css";
import { getTitle, ITimeBlock } from "src/app/data/calendar/utils/simulationSchedulingUtil";
import { ISlot } from "src/layouts/common/SimulationScheduling/SimulationScheduling";

interface IDemandBasedTimeSlotsProps {
  demandBasedSlotsCount: number;
  timeSlots: ISlot[];
  selectedTime: ITimeBlock | null;
  setSelectedTime: (tb: ITimeBlock | null) => void;
  setTimeSlots: (slots: ISlot[]) => void;
  selectedDate: moment.Moment;
  setErrorMessage: (msg: string | null) => void;
  calendarDivRef: any;
}

const DemandBasedTimeSlots: FunctionComponent<any> = (props: IDemandBasedTimeSlotsProps) => {
  const {
    demandBasedSlotsCount,
    timeSlots,
    selectedTime,
    setSelectedTime,
    setTimeSlots,
    selectedDate,
    setErrorMessage,
    calendarDivRef,
  } = props;

  const intl = useIntl();

  const handleTimeSlots = (index: number, action: "set" | "clear") => () => {
    if (!enableSetButtons(index)) {
      const slot = [...timeSlots];
      if (action === "set") {
        slot[index] = { ...selectedTime };
      } else if (action === "clear") {
        slot[index] = { startTime: null, endTime: null };
      }
      setSelectedTime(null);
      setTimeSlots(slot);
    }
  };

  const enableSetButtons = (btnIndex: number): boolean => {
    const enabledButtons: number[] = [];
    const disabledButtons: number[] = [];
    timeSlots.forEach((slot: ISlot, i: number) => {
      if (slot.startTime && slot.endTime) {
        enabledButtons.push(i);
      } else {
        disabledButtons.push(i);
      }
    });
    if (selectedDate && selectedTime && !selectedTime?.isAvailable) {
      enabledButtons.push(disabledButtons[0]);
    }
    return !enabledButtons.includes(btnIndex);
  };

  const handleKeyPressOnSetBtn = (index: number) => (e: any) => {
    setErrorMessage(null);
    if (index < timeSlots.length - 1) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        e.target.click();
        const dates = Array.from(calendarDivRef?.current.getElementsByClassName("react-datepicker__day--selected"));
        const selectableDates: any = dates.filter((selectableDate: HTMLElement) => {
          return selectableDate?.getAttribute("aria-disabled") === "false";
        });
        if (selectableDates[0]) {
          selectableDates[0].focus();
        }
      }
    }
  };

  return (
    <div
      className={cn(styles.slotsWrap, demandBasedSlotsCount !== defaultDemandBasedTimeSlots && styles.slotsWrapCenter)}
    >
      {timeSlots.map((slot: ISlot, i: number) => {
        return (
          i < (demandBasedSlotsCount ?? defaultDemandBasedTimeSlots) && (
            <div className={cn(styles.slot, slot.startTime && slot.endTime && styles.slotSelected)} key={"slots" + i}>
              <div>
                <span className={styles.title}>
                  {intl.formatMessage({ id: "MursionPortal.DemandBasedScheduling.Slot" }, { count: i + 1 })}
                </span>
                {!(slot.startTime && slot.endTime) && (
                  <div className={styles.pickdateText}>
                    {intl.formatMessage({
                      id: "MursionPortal.DemandBasedScheduling.PickDateAndTime",
                    })}
                  </div>
                )}
              </div>
              {slot.startTime && slot.endTime && (
                <div className={styles.slotDate}>
                  <div>{moment(slot.startTime).format("dddd")}</div>
                  <div>{moment(slot.endTime).format("MMM D")}</div>
                  <div>{getTitle(slot as ITimeBlock)}</div>
                </div>
              )}
              <div className={styles.setClearBtnWrap}>
                <button
                  className={cn(
                    styles.timeSlotButton,
                    enableSetButtons(i)
                      ? styles.timeSlotButtonDisabled
                      : !(slot.startTime && slot.endTime) && styles.timeSlotSelected
                  )}
                  onClick={handleTimeSlots(i, slot.startTime && slot.endTime ? "clear" : "set")}
                  onKeyDown={handleKeyPressOnSetBtn(i)}
                >
                  {slot.startTime && slot.endTime
                    ? intl.formatMessage({ id: "MursionPortal.DemandBasedScheduling.Button.Clear" })
                    : intl.formatMessage({ id: "MursionPortal.DemandBasedScheduling.Button.Set" })}
                </button>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default DemandBasedTimeSlots;
