import moment from 'moment-timezone';
import { getTimeFormat } from 'src/app/data/common/utils/dateUtil';
import ITimeBlock from 'src/app/data/calendar/interfaces/ITimeBlock';

export const getTimeBlockName = (timeBlock: ITimeBlock, tzId: string, showOffsetInfo: boolean) => {
  const tbStart = moment.tz(timeBlock.start, tzId || '');
  const tbEnd = moment.tz(timeBlock.end, tzId || '');
  const offsetStart = ` GMT(${tbStart.tz(tzId).format('Z')})`;
  const offsetEnd = ` GMT(${tbEnd.tz(tzId).format('Z')})`;

  const formattedStart = `${tbStart.format(getTimeFormat())} ${showOffsetInfo ? offsetStart : ''}`;
  const formattedEnd = `${tbEnd.format(getTimeFormat())} ${showOffsetInfo ? offsetEnd : ''}`;

  return `${formattedStart} - ${formattedEnd}`;
};