import React, { FunctionComponent } from "react";
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { Pills } from 'src/components/pills';
import styles from 'src/layouts/common/Dashboard/components/styles/Dashboard.css';
import { IUserRoleExtended } from 'src/app/data/profile/interfaces';
import RoleID from "src/app/data/common/interfaces/RoleID";

interface IDashboardHeaderPills {
    pillSelectedAll: boolean | undefined;
    pillValues?: INamedEntry[] | null;
    pillMaxWidth?: number;
    showPill: boolean | undefined;
    userRole: IUserRoleExtended;
}

const DashboardHeaderPills: FunctionComponent<IDashboardHeaderPills> = ({ 
    pillSelectedAll,
    pillValues,
    pillMaxWidth,
    showPill, 
    userRole
}) => {

    const showClientPills = showPill && !(userRole?.id && [
        RoleID.BUYER,
        RoleID.ACC_MANAGER,
    ].includes(userRole?.id));

    return (
       <>
            {showClientPills ? (
                <div className={styles.pillContainer}>
                <Pills
                    selectedAll={pillSelectedAll}
                    values={pillValues || []}
                    allPlaceholder={'Filters.AllClients'}
                    morePlaceholder={'Filters.MoreClients'}
                    countingPlaceholder={'MursionPortal.Clients'}
                    maxWidth={pillMaxWidth}
                />
                </div>
            ): null}
       </>
    );
};

export default DashboardHeaderPills;