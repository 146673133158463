import LoadChunk from 'src/app/services/LoadChunk/LoadChunk';

const ClientCalendar = LoadChunk(() => import('./ClientCalendar/ClientCalendar'));
const Projects = LoadChunk(() => import('./Projects'));
const Teams = LoadChunk(() => import('./Teams'));

const Users = LoadChunk(() => import('./Users'));
const Reports = LoadChunk(() => import('./Reports'));
const ClientSettings = LoadChunk(() => import('./ClientSettings'));

export default {
  ClientCalendar,
  Projects,
  Teams,
  Users,
  Reports,
  ClientSettings
};
