import * as React from 'react';
import { Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import { IAssetSettingBase } from 'src/app/data/client/interfaces/IAsset';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import IRestCollectionInfo from 'src/app/data/common/interfaces/IRestCollectionInfo';
import IUser from 'src/app/data/common/interfaces/IUser';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import IScenario, { IScenarioTemplate } from 'src/app/data/projects/interfaces/IScenario';
import {
  ISession,
  SessionScenarioType,
} from 'src/app/data/session/interfaces/ISession';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import selectors from 'src/app/redux/selectors';
import actions from 'src/app/redux/store/actions';
import { IAppState } from 'src/app/redux/store/reducers';
import AssetSettingField from 'src/components/AssetSettingField/AssetSettingField';
import LearnersPicker from 'src/components/LearnersPicker';
import Separator from 'src/components/Separator';
import Notes from 'src/layouts/common/Sessions/SessionsTable/components/Notes';
import SessionsHistoryTable
  from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/SessionHistoryTable/SessionHistoryTable';
import styles from './SessionEditForm.css';
import ITeam from 'src/app/data/client/interfaces/ITeam';
import ITeamClientView from 'src/app/data/client/interfaces/ITeamClientView';
import SessionInfo from './SessionInfo';
import IProject, { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import AssetViewV2 from 'src/components/ScenarioInfo/AssetViewV2/AssetViewV2';
import { convertScenarioDraftToUpdate } from 'src/app/data/projects/utils/scenarioConverter';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import { ICompany } from 'src/app/data/licensee/interfaces/ICompany';
import SsLearnersPicker from 'src/components/SsLearnersPicker';
import SessionSimSpecialistField
  from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/SessionSimSpecialistField/SessionSimSpecialistField';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import { ISessionInfoEditConfig } from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/SessionInfo/SessionInfo';
import IClientConfig from 'src/app/data/clientConfig/interfaces/IClientConfig';
import Checkbox from 'src/components/Checkbox/Checkbox';
import canCreateSessionWithoutLearners from 'src/app/data/session/utils/canCreateSessionWithoutLearners';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import LazyImage from 'src/components/LazyImage';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Radio, RadioGroup } from 'src/components/RadioGroup/RadioGroup';
import moment from 'moment';
import { getDateAwareTimezoneTitle } from 'src/app/data/common/utils/dateUtil';
import cn from 'classnames';
import { isWeekEnd } from 'src/app/data/common/utils/calendarUtils';
import InfoTooltip from 'src/components/InfoTooltip/InfoTooltip';
import Tooltip, { TooltipPlacement, TooltipTheme } from 'src/components/Tooltip/Tooltip';
import Button, { ButtonSize, ButtonType } from 'src/components/Button';
import { isCurrentUserLearner, isCurrentUserPSorOps } from 'src/app/data/common/utils/userRoleUtils';
import { RouteComponentProps, withRouter } from 'react-router';
import ROUTE_PATHS from 'src/routing/paths';
import { canSessionBeCancelled, manageSessionAttendance, getUpcomingBeforeStart } from 'src/app/data/session/utils/sessionActionUtils';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import { MAX_FIELD_LENGTH, MINUTES_JOIN_BEFORE } from 'src/app/data/common/constants';
import { toast } from 'react-toastify';
import { getTitle, IReschedulingEnabledArgs, isReschedulingEnabled } from 'src/app/data/calendar/utils/simulationSchedulingUtil';
import { handleSurveyAndSocialRedirection } from 'src/app/data/common/utils/launchSimulationUtils';
import UseCopyToClipboard from 'src/hooks/useCopyToClipboard/useCopyToClipboard';
import { isPracticeSession } from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/utils';

export interface ISessionEditFormProps extends WrappedComponentProps, RouteComponentProps{
  canAddToCalendar: boolean;
  companyConfig: ICompanyConfig | null;
  clientConfig: IClientConfig | null;
  configIsLoading: boolean;
  companyData: ICompany | null;
  disableLearnersSelect?: boolean;
  disableAssetSettingsSelect?: boolean;
  displayLearners: boolean;
  displayNotes: boolean;
  editNotes: boolean;
  displaySimSpecialist: boolean;
  displayHistory: boolean;
  editSessionDateTime: boolean;
  enableSimSpecSelect?: boolean;
  learnersInfo: IRestCollectionInfo<ILearnerExtended>;
  projectTeamsInfo: IRestCollectionInfo<ITeamClientView>;
  scenario: Partial<IScenario & IScenarioTemplate>;
  selectedLearners: IUser[];
  session: ISession;
  setSelectedLearners: (learners: IUser[]) => void;
  ssLearnersInfo: IRestCollectionInfo<ICompanyUser>;
  viewMode: boolean;
  timezoneId: string;
  userRole: IUserRoleExtended;
  fetchIcs: (sessionId: string) => Promise<string>;
  fetchTrainingIcs: (sessionId: string) => Promise<string>;
  googleLinkGetter: (session: ISession) => Promise<string>;
  onChange: (session: ISession) => void;
  onAssignSS: (simspecialistId: string, demandSlot?: any) => void;
  setDemandTimeSlot: (timeSlot: IDemandSlots) => void;
  projectInfo: IProject | null;
  userProfile: IUserProfile | null;
  showAttendButton: boolean;
  triggerRescheduleEmail: (sessionId:string) => Promise<string>;
  refreshSession: () => void;
  deleteSession: (sessionId:string, reason: string, doNotSendCancellationEmail: boolean) => Promise<ISession>;
  isCurrentSimDeclinedSession:boolean;
  logToSession: (userId: string, sessionId: string) => void;
  fetchClientGlobalConfig: (projectId: string, clientId: string, licenseeId: string, configType: string) => Promise<any>;
}

export interface IAvailabilityInfo {
  id?: string;
  simSpecialist: ICompanyUser;
}
export interface IDemandSlots {
  startDate: number | null;
  endDate: number | null;
}

export const MAX_NOTES_LENGTH_DISPLAY = 200;

export const SESSION_STATUS_SHOW_USER_ATTENDANCE = [
  SessionStatusType.PENDING,
  SessionStatusType.COMPLETED,
  SessionStatusType.RUNNING,
  SessionStatusType.MISSED,
  SessionStatusType.ERROR,
  SessionStatusType.CANCELLED,
  SessionStatusType.INCOMPLETE,
];

interface ISessionEditFormState {
  datePickerFocused: boolean;
  showHistory: boolean;
  demandTimeSlots: IDemandSlots;
  selectedTimeSlot: string | null;
}
class SessionEditForm extends React.Component<ISessionEditFormProps, ISessionEditFormState> {

  public state: ISessionEditFormState = {
    datePickerFocused: false,
    showHistory: false,
    demandTimeSlots:{
      startDate: null, endDate: null
    },
    selectedTimeSlot: null,
  };

  public componentWillUnmount() {
    this.props.setSelectedLearners([]);
  }

  public componentDidMount() {
    if ( this.props.displaySimSpecialist && this.props.session.timeSlots?.length && !this.props.session.simspecialist) {
      const index = this.props.session.timeSlots.findIndex((slot: any) => {
        return (moment(slot.startDate).isAfter(moment()));
      });
      if (index > -1) {
        this.onDemandTimeSlotChange(index.toString());
      }
    }
  }

  public render() { // nosonar
    const {
      canAddToCalendar,
      companyConfig,
      clientConfig,
      displayLearners,
      displayNotes,
      displaySimSpecialist,
      displayHistory,
      editSessionDateTime,
      session,
      scenario,
      timezoneId,
      viewMode,
      userRole,
      showAttendButton,
      projectInfo,
      userProfile,
      isCurrentSimDeclinedSession,
    } = this.props;

    const scenarioId = scenario.id || '';
    const isBooked = (session.status === SessionStatusType.BOOKED);
    const scenarioVersion = ((scenario as IScenario).draft || (scenario as IScenarioTemplate)).scenarioVersion || '';

    const startDate = scenario.planning ? scenario.planning.startDate : undefined;
    const endDate = scenario.planning ? scenario.planning.endDate : undefined;

    let editingInfoConfig: ISessionInfoEditConfig | null = null;

    if (editSessionDateTime && companyConfig && clientConfig) {
      const sessionLength = (scenario.draft ? scenario.draft.sessionLength : scenario.sessionLength) || 0;

      editingInfoConfig = {
        selectedScenarioData: {
          id: scenario.id || '',
          isBankScenario: session.scenarioType === SessionScenarioType.TEMPLATE,
          startDate,
          endDate,
        },
        sessionLength,
        companyConfig,
        clientConfig,
      };
    }

    const minutesBeforeJoin = companyConfig?.session?.upcomingBeforeStart ? getUpcomingBeforeStart(companyConfig.session.upcomingBeforeStart) : MINUTES_JOIN_BEFORE;
    const isSessionCancellable = canSessionBeCancelled(session, userRole) && !session.training;
    const rescheduleParams: IReschedulingEnabledArgs = {
      status: session?.status,
      newRescheduled: session.newRescheduled,
      sameDayRescheduling: projectInfo?.sameDayRescheduling,
      endDate: scenario.planning?.endDate,
      sessionLength: scenario.draft?.sessionLength,
      reschedulingEnabled: projectInfo?.reschedulingEnabled,
      simspecialist: session?.simspecialist,
      type: session.type,
      userRole,
      isSessionCancellable,
      completionRateFulfilled: !!session?.completionRateFulfilled,
      schedulingRateFulfilled: !!session?.schedulingRateFulfilled,
    };
    const renderRescheduleButton = (projectInfo ? isReschedulingEnabled(rescheduleParams) : false); 
    const hideReScheduleButtonLearner = isCurrentUserLearner(userRole) && scenario?.schedulingRateFulfilled && scenario?.completionRateFulfilled && !!scenario.draft && (scenario.draft.deliveryMode === SessionType.ONE_TO_ONE);
    const isSessionConfirmed = !!session.simspecialist && (session.status !== SessionStatusType.MISSED) && (session.status !== SessionStatusType.COMPLETED) && (session.status !== SessionStatusType.ERROR);
    const renderManageAttendance = manageSessionAttendance(session, userProfile, timezoneId);
    const redirectSessionLink = () => {
      const redirectInfo = {
        sessionStatus: showAttendButton,
        sessionLink: session.link,
        sessionId: session.id,
        clientConfig
      };
      handleSurveyAndSocialRedirection(redirectInfo);
      if(session.id && isCurrentUserLearner(userRole))
      {
        this.props.logToSession(userRole.id,session.id);
      }
    };

    return (
      <div className={styles.sessionWrap}>
        <LoadingOverlay active={this.props.configIsLoading} spinner={true}>
          {
            isCurrentSimDeclinedSession && 
            <label>{TranslateMessage('Requests.Table.Declined')}</label>

          }
          {
             displaySimSpecialist && !!session.timeSlots?.length && !session.simspecialist && !isCurrentSimDeclinedSession &&
            <div>
              <label>{TranslateMessage('Session.Edit.Modal.DemandBasedTimeSlots')}</label>
            <RadioGroup
              name={'TimeSlot'}
              onChange={this.onDemandTimeSlotChange}
              selectedValue={this.state.selectedTimeSlot}
            >
              <div className={styles.timeSlotsContainer}>
                {session.timeSlots.map((timeSlot,i) => {
                  return <div className={cn(styles.timeSlots, this.state.selectedTimeSlot === i.toString() && styles.selected)} key={'timeSlot' + i}>
                    <div className={cn(isWeekEnd(timeSlot.startDate, timezoneId) && styles.weekendTimeSlots)}>
                      <div className={styles.dFlex}>
                      <Radio value={i.toString()} disabled={moment(timeSlot.startDate).isBefore(moment())} label={this.props.intl.formatMessage({id:'MursionPortal.DemandBasedScheduling.Slot'},{count: i+1})} />
                      {isWeekEnd(timeSlot.startDate, timezoneId) && <InfoTooltip text={moment(timeSlot.startDate).tz(timezoneId).format('dddd')} placement={TooltipPlacement.BOTTOM} classNames={styles.tooltipAlignment}/>}  
                        </div>
                      <div className={styles.slotTextWrap}>
                        <div className={styles.slotText}>
                          <strong>{moment(timeSlot.startDate).tz(timezoneId).format('dddd')}</strong>
                          <br />
                          <strong>{this.props.intl.formatDate(timeSlot.startDate, {
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit',
                            timeZone: timezoneId
                          })}</strong>
                        </div>
                        <div className={styles.slotText}>
                          <strong>{`${getTitle({ startTime: moment(timeSlot.startDate).tz(timezoneId), endTime: moment(timeSlot.endDate).tz(timezoneId) })} ${getDateAwareTimezoneTitle(timezoneId, timeSlot.startDate)}`}
                          </strong>
                        </div>
                      </div>
                  </div>
                  </div>;
                })}
              </div>
            </RadioGroup>
            </div>}
            <div className={styles.rescheduleWrap}>
              <div>
                  <SessionInfo
                    hideDateTime={displaySimSpecialist && !!session.timeSlots?.length && !session.simspecialist}
                    calendarTimezoneId={timezoneId}
                    editingConfig={editingInfoConfig}
                    session={session}
                    onChange={this.onChange}
            />
            {
              !!session.id &&
              <UseCopyToClipboard
                value={session.id}
                messageTitle={this.props.intl.formatMessage({ id: 'Dashboard.Learners.Column.SimulationId' })}
                autoClose={3000}
              >              
                {
                  ({ onClickToCopy }) => (
                      <Button btnSize={ButtonSize.MEDIUM} 
                        onClick={onClickToCopy}
                        btnType={ButtonType.BLUE_SECONDARY}
                        title={this.props.intl.formatMessage({ id: 'Dashboard.Learners.Column.SimulationId.CopyHover' })}
                        aria-label={this.props.intl.formatMessage({ id: 'Dashboard.Learners.Column.SimulationId.CopyHover' })}
                      >
                        {this.props.intl.formatMessage({ id: 'Dashboard.Learners.Column.SimulationId.CopyHover' })}
                      </Button>
                  )
                }
              </UseCopyToClipboard>
            }
            </div>
            <div className={styles.sessionBtnWrap}>
            {!hideReScheduleButtonLearner && renderRescheduleButton &&
              <Button
                btnType={showAttendButton ? ButtonType.BLUE_SECONDARY :ButtonType.BLUE}
                onClick={this.onReschedule}
              >
              {TranslateMessage('Session.Edit.Modal.Reschedule')}
              </Button>
            }
            {
              ((isCurrentUserLearner(userRole) && isSessionConfirmed) || showAttendButton) &&
              <Tooltip
                placement={TooltipPlacement.TOP}
                text={showAttendButton ? '' : this.props.intl.formatMessage({ id: 'Session.Edit.Modal.JoinSimulationBtnTooltip' }, {
                  code: (word: string) => <strong> {word} </strong>,
                         minutesBeforeJoin})}
                theme={TooltipTheme.COBALT}
                className={styles.joinButtonTooltip}
              >
                <a className={cn(isSessionConfirmed && styles.joinButton, showAttendButton && styles.enable, !showAttendButton && styles.disablePointer)} onClick={redirectSessionLink} href={'#'} aria-describedby="sessionEditModalJoinSessionBtnTooltip">{TranslateMessage('Session.Edit.Modal.JoinSession')}
                </a>
              </Tooltip>
            }
            </div>
          </div>
        </LoadingOverlay>
        {
          canAddToCalendar && (
            <>
              <Separator/>
              <div className={styles.addToCalendar}>
                <label>{TranslateMessage('Session.Edit.Modal.AddToCalendar')}</label>
                <div className={styles.toggleButtonGroup}>
                  <div className={styles.tooltipWrap} tabIndex={0} aria-describedby="sessionEditModalGoogleTooltipDescription">
                    <Button
                      onClick={this.onGoogleClick}
                      btnSize={ButtonSize.MEDIUM}
                      tabIndex={-1}
                    >
                      {TranslateMessage('MursionPortal.Button.Google')}
                    </Button>
                    <span className={styles.tooltip} id={"sessionEditModalGoogleTooltipDescription"}><span>{TranslateMessage('Session.Edit.Modal.ToolTip.Google')}</span></span>
                  </div>
                  <div className={styles.tooltipWrap} tabIndex={0} aria-describedby="sessionEditModalOutlookIcalOtherTooltipDescription">
                      <Button
                        onClick={this.onIcsClick}
                        btnSize={ButtonSize.MEDIUM}
                        tabIndex={-1}
                      >
                        {TranslateMessage('MursionPortal.Button.Outlook')}
                      </Button>
                    <span className={styles.tooltip} id={"sessionEditModalOutlookIcalOtherTooltipDescription"}><span>{TranslateMessage('Session.Edit.Modal.ToolTip.OutlookIcalOther')}</span></span>
                  </div>
                </div>
              </div>
            </>
          )
        }
        {

          scenarioId &&
          <>
            <Separator/>
            {
              scenarioVersion !== ScenarioVersion.V2 && session.assetSettings &&
              <>
                {
                  viewMode || this.props.disableAssetSettingsSelect
                    ? this.renderAssetSettings(session.assetSettings)
                    : (
                      <AssetSettingField
                        aliasNames={scenario && scenario.draft ? scenario.draft.assetSettingsAliases : null}
                        required={true}
                        singleColumn={true}
                        label={'Content Bundle'}
                        scenarioId={scenarioId}
                        viewMode={isBooked}
                        selectedAssetSettingId={session.assetSettings.id}
                        onChange={this.onAssetSettingsChange}
                      />
                    )
                }
              </>
            }
            {
              scenarioVersion === ScenarioVersion.V2 &&
              <AssetViewV2
                industryId={''}
                scenarioDraft={convertScenarioDraftToUpdate((scenario as IScenario).draft)}
                viewMode={true}
              />
            }

          </>

        }

        {!isPracticeSession(session) && displayLearners && (
            <>
              <Separator/>
              <div className={styles.participantsWrapper}>
                <div className={styles.participants}>
                  <label>{session.training ? TranslateMessage('Session.Edit.Modal.Trainees') : TranslateMessage('Session.Table.Column.Learners')}</label>
                  <div className={styles.trainingLearnerPickerWrap}>
                    {this.renderLearnersPicker()}
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          displaySimSpecialist && (
            <>
              <Separator/>
              <div className={cn(styles.participantsWrapper, styles.simSpecialistParticipantWrap)}>
                <div className={styles.participants}>
                  <label>{session.training ? TranslateMessage('Session.Edit.Modal.Trainer') : TranslateMessage('Session.Edit.Modal.SimSpecialistAssigned')}</label>
                  {
                    this.renderSimSpecialistField()
                  }
                </div>
              </div>
            </>
          )
        }

        {
          displayNotes && (
            <>
              <Separator/>
              <div className={styles.notesWrapper}>
                <label>{TranslateMessage('Session.Edit.Modal.Notes')}</label>
                {this.renderSessionNotes()}
              </div>
            </>
          )
        }
        {
          !!session.clientNote && (
            <>
              <Separator/>
              <div className={styles.notesWrapper}>
                <label>{TranslateMessage('Session.Edit.Modal.ClientNotes')}</label>
                <p className={styles.fortattedText}>{session.clientNote}</p>
              </div>
            </>
          )
        }
        {renderManageAttendance &&
          <div className={styles.manageAttendance}>
         <Button btnType={ButtonType.SPECIAL_RED} btnSize={ButtonSize.MEDIUM} onClick={this.onMarkAttendance}>
            {
              session.attendanceAdded  ?
              TranslateMessage('Session.Edit.Modal.EditAttendance'):
              TranslateMessage('Session.Edit.Modal.CompleteAttendance') 
            } 
         </Button>
          </div>
        }
        {
          displayHistory && (
            <>
              <Separator/>
              <Collapse in={this.state.showHistory}>
                <div>
                  {
                    this.state.showHistory && this.renderHistoryTable()
                  }
                </div>
              </Collapse>
              <a
                onClick={this.toggleShowHistory} className={styles.showHideBtn}>{!this.state.showHistory ? TranslateMessage('Session.Edit.Modal.ShowHistory') : TranslateMessage('Session.Edit.Modal.HideHistory')}</a>
            </>
          )
        }
      </div>
    );
  }

  private isGroupType = () => {
    return this.props.session.type === SessionType.GROUP;
  };
  private onDemandTimeSlotChange = (index: string) => {
    if (this.props.session.timeSlots) {
      const timeSlot = { startDate: this.props.session.timeSlots[parseInt(index, 10)].startDate, endDate: this.props.session.timeSlots[parseInt(index, 10)].endDate };
      this.setState({ demandTimeSlots: timeSlot, selectedTimeSlot: index});      
      this.props.setDemandTimeSlot(timeSlot);
    }
  };

  private onMarkAttendance = () => {
    const { history,session } = this.props;
    history.push(`${ROUTE_PATHS.MARK_ATTENDANCE}/?sessionId=${session.id}`);
  };

  private handleRescheduleEmailToast=(Message:any)=>{
    toast(Message, { autoClose:3000,
    bodyClassName: styles.notification});
}
  
  private onReschedule = () => {
    const { history, scenario, session, userRole, userProfile } = this.props;

    if (isCurrentUserLearner(userRole)) {
      history.push(`${ROUTE_PATHS.RESCHEDULING}?projectId=${scenario.draft?.project.id}&scenarioId=${scenario.id}&sessionId=${session.id}`);
    }
    else if (isCurrentUserPSorOps(userRole)) {
      this.handleRescheduleEmailToast(this.props.intl.formatMessage({ id: 'Session.Rescheduling.Email.Reminder.Msg' }));
     this.props.triggerRescheduleEmail(session?.id || "").then(() => {
        const currentDate = new Date();
        // Won't internationalize as this value is to be stored in DB
        this.props.deleteSession(session?.id || "", "Cancelled by PS("+userProfile?.firstName +" "+userProfile?.lastName+") through the Reschedule button on "+moment(currentDate).format("LLLL"),
         true).then(() => {
          this.props.refreshSession();
        });
      }).catch(() => {
        this.handleRescheduleEmailToast(this.props.intl.formatMessage({ id: 'MursionPortal.ErrorMessage.SomethingMustBeBroken' }));
      });
    }
  };

  private onIcsClick = async () => {
    const { session, companyData } = this.props;
    const sessionId = session.id;
    if (!sessionId) {
      return;
    }

    const companyName = !!companyData ? companyData.name.toLowerCase() : 'mursion';
    const fetcher = session.training
      ? this.props.fetchTrainingIcs
      : this.props.fetchIcs;
    const data = await fetcher(sessionId);
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8;' });
    const fileName = `${companyName}-event.ics`;
    const element = document.createElement('a');
    const url = window.URL.createObjectURL(blob);

    element.setAttribute('href', url);
    element.setAttribute('download', fileName);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  private onGoogleClick = async () => {
    const link = await this.props.googleLinkGetter(this.props.session);

    window.open(link, '_blank');
  };

  private toggleShowHistory = () => this.setState({ showHistory: !this.state.showHistory });


  private renderHistoryTable = () => {

    return (
      <div>
        <SessionsHistoryTable
          sessionId={this.props.session.id || ''}
          tzId={this.props.timezoneId}
        />
      </div>
    );
  };

  private renderAssetSettings = (assetSettings: IAssetSettingBase) => {
    const { description, picture, id } = assetSettings;
    const { scenario } = this.props;

    const name = scenario.draft && scenario.draft.assetSettingsAliases[id]
      ? scenario.draft.assetSettingsAliases[id]
      : assetSettings.name;

    return (
      <>
        <label>{TranslateMessage('MursionPortal.Label.ArtProject')}</label>
        <div className={styles.simulationContentWrap}>
          <div className={styles.imageWrap}>
            <div className={styles.assetSetting}>
              <div className={styles.assetSettingLabel}>
                {TranslateMessage('Session.Edit.Modal.SimulationContent.Image')}
              </div>
              <div className={styles.assetSettingValue}>
                {
                  picture
                    ? <LazyImage src={picture} alt={''} />
                    : <div>-</div>
                }
              </div>
            </div>
          </div>
          <div className={styles.nameDescriptionWrap}>
            <div className={cn(styles.assetSetting, styles.simulationContentWrap)}>
              <div className={styles.assetSettingLabel}>
                {TranslateMessage('Session.Edit.Modal.SimulationContent.Name')}
              </div>
              <div className={styles.assetSettingValue}>
                {name || '-'}
              </div>
            </div>
            <div className={styles.assetSetting}>
              <div className={styles.assetSettingLabel}>
                {TranslateMessage('Session.Edit.Modal.SimulationContent.Description')}
              </div>
              <div className={cn(styles.assetSettingValue, styles.description)}>
                {description || '-'}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  private renderSessionNotes() {
    // TODO: notes will we improved later
    // currently they can be created only by a sim specialist
    // and view only by the creator
    const { editNotes, session } = this.props;
    const { intl } = this.props;
    const onNotesChange = (notes: string[]) => {
      this.onChange({
        ...session,
        notes,
      });
    };
    const canEditNotes = editNotes && session.status !== SessionStatusType.SWAP;

    return (
      <Notes
        emptyMessage={intl.formatMessage({ id: 'Session.Edit.Modal.Notes.EmptyMessage' })}
        maxNoteLengthDisplay={MAX_NOTES_LENGTH_DISPLAY}
        notes={session.notes}
        onChange={onNotesChange}
        viewMode={!canEditNotes}
        maxLength={MAX_FIELD_LENGTH}
      />
    );
  }

  private renderSimSpecialistField() {

    const { session, enableSimSpecSelect, userRole } = this.props;
    const demandBasedTimeSlots = this.state.demandTimeSlots;

    return (
      <SessionSimSpecialistField
        disabled={!enableSimSpecSelect}
        training={!!session.training}
        learners={session.learners}
        simSpecialist={session.simspecialist}
        startDate={demandBasedTimeSlots.startDate ? demandBasedTimeSlots.startDate : session.startDate}
        endDate={demandBasedTimeSlots.endDate ? demandBasedTimeSlots.endDate : session.endDate}
        scenarioId={session.scenarioId}
        isScenarioTemplate={session.scenarioType === SessionScenarioType.TEMPLATE}
        onSimSpecChange={this.onSimSpecChange}
        userRole={userRole}
        sessionInfo={session}
        displaySimSpecialistStatus={this.displayAttendanceTable()}
      />
    );
  }

  private displayAttendanceTable = () => {
    const { userRole, session } = this.props;
    const displayAttendance = userRole?.id !== RoleID.LEARNER && session.status && SESSION_STATUS_SHOW_USER_ATTENDANCE.includes(session.status);
    return !!displayAttendance;
  };

  private onSimSpecChange = (simspecialist: ICompanyUser | null) => {

    this.onChange({
      ...this.props.session,
      simspecialist: simspecialist || undefined,
    });

    const updatedSS = simspecialist ? simspecialist.id : '';
    this.props.onAssignSS(updatedSS || '');
  };

  private onAssetSettingsChange = (selectedAssetSettingsId: string) => {
    const { assetSettings } = this.props.session;

    this.onChange({
        ...this.props.session,
        assetSettings: assetSettings
          ? {
            ...assetSettings,
            id: selectedAssetSettingsId
          }
          : null
      }
    );
  };

  private getTeams(): ITeam[] {
    const { projectTeamsInfo, scenario } = this.props;

    if (!(scenario as IScenario).draft) {
      return [];
    }

    return (scenario as IScenario).planning.teams
      .filter(team => projectTeamsInfo.items.findIndex(t => t.id === team.id) > -1);
  }

  private renderLearnersPicker() {
    const {
      disableLearnersSelect,
      scenario,
      session,
      learnersInfo,
      ssLearnersInfo,
      viewMode,
      userRole
    } = this.props;
    const teams = this.getTeams();
    const maxLearners = ((scenario as IScenario).draft || scenario as IScenarioTemplate).sessionSize;
    const showExternalLearnersOption = !!scenario.draft && canCreateSessionWithoutLearners(userRole, scenario.draft.deliveryMode, scenario.draft.scenarioVersion);

    return session.training ? (
      <SsLearnersPicker
        disabled={disableLearnersSelect}
        learnersInfo={{
          ...ssLearnersInfo,
          items: ssLearnersInfo.items.filter(learner => !session.simspecialist || learner.id !== session.simspecialist.id)
        }}
        maxLearners={maxLearners}
        selectedLearners={session.learners || []}
        onChange={this.onSsLearnersChange}
      />
    ) : (
      <>
        {
          showExternalLearnersOption && (disableLearnersSelect && session.externalLearnersOnly || !disableLearnersSelect) && (
            <Checkbox
              checked={session.externalLearnersOnly}
              disabled={viewMode || disableLearnersSelect}
              id={'all-learners-are-external'}
              onChange={this.onExternalLearnerOptionChange}
            >
              <div>{TranslateMessage('Session.Edit.Modal.Learners.UnauthenticatedCheckbox')}</div>
            </Checkbox>
          )
        }
        <LearnersPicker
          disabled={viewMode || disableLearnersSelect || this.isGroupType()}
          teams={teams}
          selectedLearners={session.learners || []}
          learnersInfo={learnersInfo}
          maxLearners={maxLearners}
          onChange={this.onLearnersChange}
          session={this.props.session}
          displayLearnerStatus={this.displayAttendanceTable()}
        />
      </>
    );
  }

  private onExternalLearnerOptionChange = () => {
    this.onChange({
      ...this.props.session,
      externalLearnersOnly: !this.props.session.externalLearnersOnly,
    });
  };

  private onSsLearnersChange = (learners: IUser[]) => {
    this.onChange({
      ...this.props.session,
      learners,
    });
  };

  private onLearnersChange = (learners: IUser[]) => {
    this.onChange({
      ...this.props.session,
      learners: learners.map(lrn => ({
        ...lrn,
        teams: [],
      })),
    });
    this.props.setSelectedLearners(learners);
  };

  private onChange = (session: ISession) => {

    if (this.isGroupType()) {
      this.props.onChange({
        ...session,
        learners: session.learners || [],
      });
      return;
    }

    this.props.onChange(session);
  };
}

export default injectIntl(withRouter(connect(
  (state: IAppState) => ({
    companyConfig: selectors.companyConfig.getCompanyConfig(state),
    clientConfig: selectors.clientsConfig.getClientConfig(state),
    configIsLoading: selectors.companyConfig.isCompanyConfigFetching(state),
    selectedLearners: selectors.calendar.getSelectedLearners(state),
    companyData: selectors.company.getCompanyData(state),
    userRole: selectors.profile.getCurrentUserRole(state),
    userProfile: selectors.profile.getUserProfile(state),
  }),
  {
    fetchIcs: actions.session.fetchIcs,
    fetchTrainingIcs: actions.session.fetchTrainingIcs,
    setSelectedLearners: actions.calendar.setSelectedLearners,
    triggerRescheduleEmail: actions.session.triggerRescheduleEmail,
    deleteSession: actions.session.deleteSession,
    logToSession: actions.session.logToSession,
    fetchClientGlobalConfig: actions.projects.fetchClientGlobalConfig,
  }
)(SessionEditForm)));
