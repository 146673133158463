import React, { useEffect, useMemo, useState, FunctionComponent, useCallback } from "react";
import Button, { ButtonSize, ButtonType } from "src/components/Button";
import Page from "src/components/Page";
import { Radio, RadioGroup } from "src/components/RadioGroup/RadioGroup";
import actions from "src/app/redux/store/actions";
import styles from "./SimAttendance.css";
import { useSelector, useDispatch } from "react-redux";
import selectors from "src/app/redux/selectors";
import { isCurrentUserSimSpec, isCurrentUserSupportAdmin } from "src/app/data/common/utils/userRoleUtils";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getDateAwareTimezoneTitle } from "src/app/data/common/utils/dateUtil";
import A11yModal from "src/components/A11yModal";
import { Modal, Col, Row } from "react-bootstrap";
import cn from "classnames";
import getUrlParamValue from "src/app/data/common/utils/queryParamsAccessor";
import ROUTE_PATHS from "src/routing/paths";
import {
  ILearnerAttendanceStatus,
  ISessionAttendanceUpdate,
  ISessionWithHistoryInfo,
  SessionUserStatus
} from "src/app/data/session/interfaces/ISession";
import { getLearnerAttendanceStatus, isBeforeSimsSessionNextDayMidnight } from "src/app/data/session/utils/sessionActionUtils";
import ErrorMessage from "src/components/ErrorMessage";
import { useIntl } from "react-intl";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import RoleID from "src/app/data/common/interfaces/RoleID";
import getUserName from "src/app/data/common/utils/getUserName";
import { getButtonTheme } from "src/layouts/common/InviteToSchedulePage/InviteToScheduleUtils";
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';
import Checkbox from 'src/components/Checkbox/Checkbox';
import FieldGroup from 'src/components/FieldGroup/FieldGroup';
import { SelectorTheme } from "src/components/Selector";
import { LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH } from "src/app/data/common/constants";

export enum PARAM {
  REDIRECTED_FROM = 'redirectedFrom',
  SESSION_ID = 'sessionId',
}

export enum PAGE {
  DASHBOARD = 'dashboard',
}

export enum ErrorTracking {
  SIM_LATE_OR_NO_SHOW = 1,
  SESSION_STARTED_TOO_EARLY = 2,
  MOUTH_LIP_SYNC_ISSUE_DURING_SESSION = 3,
  AUDIO_VIDEO_ISSUE_FOR_SIM = 4,
  AUDIO_VIDEO_ISSUE_FOR_LEARNER = 5,
  OTHER_SIM_HARDWARE_ISSUES = 6,
  LEARNER_AND_SIM_BOTH_JOINED = 7,
  SIM_MOMENT_ISSUE = 8,
  LEARNER_WIFI = 9,
  SIM_WIFI = 10,
  OTHER_ISSUES = 11,
}

const SimAttendance: FunctionComponent<any> = () => {// nosonar
  const [statusState, setStatusState] = useState<ILearnerAttendanceStatus[]>([]);
  const [attendeeFirstName, setFirstName] = useState<string>("");
  const [attendeeLastName, setLastName] = useState<string>("");
  const [showAddInput, setAddInput] = useState<boolean>(false);
  const [isAttendanceFormEditable, setAttendanceFormEditable] = useState<boolean>(true);
  const [submittingForm, setSubmittingForm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSessionAttendance, setSessionAttendance] = useState<ISessionWithHistoryInfo | null>(null);
  const [hasSimRole, setSimRole] = useState<boolean>(false);
  const [sessionAndSimStatusMissed, setSessionAndSimStatusMissed] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<ErrorTracking[]>([]);

  const [otherIssues, setOtherIssues] = useState<string>('');
  const [isCheckedSimLateOrNoShow, setIsCheckedSimLateOrNoShow] = useState<boolean>(false);
  const [isCheckedSessionStartedTooEarly, setIsCheckedSessionStartedTooEarly] = useState<boolean>(false);
  const [isCheckedMouthLipSyncIssue, setIsCheckedMouthLipSyncIssue] = useState<boolean>(false);
  const [isCheckedAudioVideoIssueForSim, setIsCheckedAudioVideoIssueForSim] = useState<boolean>(false);
  const [isCheckedAudioVideoIssueForLearner, setIsCheckedAudioVideoIssueForLearner] = useState<boolean>(false);
  const [isCheckedOtherSimHardwareIssues, setIsCheckedOtherSimHardwareIssues] = useState<boolean>(false);
  const [isCheckedLearnerAndSimBothJoined, setIsCheckedLearnerAndSimBothJoined] = useState<boolean>(false);
  const [isCheckedSimMomentIssue, setIsCheckedSimMomentIssue] = useState<boolean>(false);
  const [isCheckedLearnerWifi, setIsCheckedLearnerWifi] = useState<boolean>(false);
  const [isCheckedSimWifi, setIsCheckedSimWifi] = useState<boolean>(false);

  const allAcceptedStatus = [
    SessionUserStatus.COMPLETED,
    SessionUserStatus.MISSED,
    SessionUserStatus.ERROR,
    SessionUserStatus.SIM_ERROR,
    SessionUserStatus.LATE,
    SessionUserStatus.LEFT,
  ];

  const { location } = useHistory();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  const userProfile = useSelector(selectors.profile.getUserProfile);
  const simTimezoneId = useSelector(selectors.profile.getUserTimeZoneId);
  const paramSessionId = useMemo(() => {
    return getUrlParamValue("sessionId", location);
  }, [location]);
  const redirectToHome = () => {
    history.push(ROUTE_PATHS.HOME);
  };

  const redirectToDashboard = () => {
    history.push(ROUTE_PATHS.DASHBOARD);
  };

  const updateSessionState = (sessionInfo: ISessionWithHistoryInfo) => {
    const { status, simspecialist } = sessionInfo;
    const isSessionAndSimStatusMissed = status === SessionStatusType.MISSED && !simspecialist?.status;
    if (isSessionAndSimStatusMissed) {
      setAttendanceFormEditable(false);
      setSessionAndSimStatusMissed(true);
    }
  };

  const fetchSessionDetails = async () => {
    if (paramSessionId) {
      setLoading(true);
      const sessionAttendance = await dispatch(actions.session.fetchSession(paramSessionId));
      setLoading(false);
      setSessionAttendance(sessionAttendance);
      if (sessionAttendance.otherIssues) {
        setOtherIssues(sessionAttendance.otherIssues);
      }
      if (sessionAttendance.simReportError) {
        setSelectedItems(sessionAttendance.simReportError);
      }
      const learnersData: ILearnerAttendanceStatus[] = sessionAttendance.learners.map(learner => ({
        userId: learner.id,
        firstName: learner.firstName,
        lastName: learner.lastName,
        email: learner.email,
        isRegisteredUser: true,
        status: getLearnerAttendanceStatus(learner.status),
      }));

      updateSessionState(sessionAttendance);

      if (isCurrentUserSupportAdmin(userRole)) {
        setStatusState(learnersData);
      }
      else if (sessionAttendance.simspecialist?.id !== userProfile?.id) {
        redirectToHome();
      } else {
        if (!isBeforeSimsSessionNextDayMidnight(simTimezoneId, sessionAttendance.endDate)) {
          setAttendanceFormEditable(false);
        }
        setStatusState(learnersData);
      }
    }
  };

  const authorizeUserAndFetchDetails = useCallback(() => {
    const isAuthorizedRole = isCurrentUserSimSpec(userRole) || isCurrentUserSupportAdmin(userRole);
    if (!isAuthorizedRole || !paramSessionId) {
      const hasSimSpecRole = userProfile?.roles.some(role => role.id === RoleID.SIM_SPECIALIST);
      if (hasSimSpecRole) {
        setSimRole(true);
      } else {
        redirectToHome();
      }
    }
    else {
      fetchSessionDetails();
    }

  }, [userRole, paramSessionId]);

  useEffect(() => {
    authorizeUserAndFetchDetails();
  }, [authorizeUserAndFetchDetails]);

  const onChange = (index: number) => async (value: SessionUserStatus) => {
    const newStatus = [...statusState];
    newStatus[index].status = value;
    setStatusState(newStatus);
  };

  const onFirstNameChange = (e: any) => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = (e: any) => {
    setLastName(e.target.value);
  };

  const addAttendee = (e: any) => {
    e.preventDefault();
    setErrorMessage(null);
    if (attendeeFirstName && attendeeLastName) {
      setStatusState([{ status: SessionUserStatus.COMPLETED, firstName: attendeeFirstName, userId: null, isRegisteredUser: false, lastName: attendeeLastName }, ...statusState]);
      setAddInput(false);
      setFirstName("");
      setLastName("");
    }
  };

  const onAddAttendeeClick = () => {
    setErrorMessage(null);
    setAddInput(true);
  };

  const onSubmit = async () => {
    setErrorMessage(null);
    setSubmittingForm(true);
    setLoading(true);
    const registeredUsers = statusState.filter(user => user.isRegisteredUser).map(regUser => ({ status: regUser.status, userId: regUser.userId }));
    const unregisteredUsers = statusState.filter(user => !user.isRegisteredUser).map(unRegUser => ({ status: unRegUser.status, firstName: unRegUser.firstName, lastName: unRegUser.lastName }));
    const attendeeData: ISessionAttendanceUpdate = { attendeeStatus: registeredUsers };
    if (unregisteredUsers.length) {
      attendeeData.addAttendees = unregisteredUsers;
    }
    attendeeData.simReportError = selectedItems;
    attendeeData.otherIssues = otherIssues;
    const submitAttendance = await dispatch(
      actions.session.updateSessionAttendance(attendeeData, paramSessionId)
    );
    setLoading(false);
    if (submitAttendance) {
      if (getUrlParamValue(PARAM.REDIRECTED_FROM, location) === PAGE.DASHBOARD) {
        redirectToDashboard();
      } else {
        redirectToHome();
      }
    } else {
      setErrorMessage(intl.formatMessage({ id: "MursionPortal.Error.SomethingWentWrong" }));
    }
  };
  const isSubmitButtonDisabled = () => {
    if (otherIssues.length > LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH) {
      return !otherIssues ||
        otherIssues.length > LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH;
    }
    return statusState.length < 1 || submittingForm || statusState.some(user => !allAcceptedStatus.includes(user.status ?? SessionUserStatus.MISSED));
  };

  const getModalText = () => {
    if (sessionAndSimStatusMissed) {
      return intl.formatMessage({ id: 'MursionPortal.SimAttendance.MissedSimulation.Message' });
    }

    return `${intl.formatMessage({ id: 'MursionPortal.SimAttendance.Midnight' })}${' '}`;
  };

  const handleOtherIssueText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setOtherIssues(value);
    if (value.trim() !== '' || value.length > LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH) {
      setSelectedItems(prevSelectedItems => {
        if (!prevSelectedItems.includes(ErrorTracking.OTHER_ISSUES)) {
          return [...prevSelectedItems, ErrorTracking.OTHER_ISSUES];
        }
        return prevSelectedItems;
      });
    } else {
      setSelectedItems(prevSelectedItems => prevSelectedItems.filter(item => item !== ErrorTracking.OTHER_ISSUES));
    }
    sortSelectedItems();
  };

  const onCheckItem = (id: ErrorTracking, checked: boolean) => {
    setSelectedItems(prevSelectedItems => {
      if (checked && !prevSelectedItems.includes(id)) {
        return [...prevSelectedItems, id];
      } else if (!checked && prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter(item => item !== id);
      }
      return prevSelectedItems;
    });

    sortSelectedItems();
  };

  const sortSelectedItems = () => {
    setSelectedItems(prevSelectedItems => {
      return [...prevSelectedItems].map(item => item).sort((a, b) => a - b);
    });
  };

  const getErrorCheckboxWithLabel = (labelId: ErrorTracking, text: string) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckboxState(labelId, e.target.checked);
      onCheckItem(labelId, e.target.checked);
    };

    return (
      <div>
        <Checkbox
          checked={getCheckboxState(labelId)}
          themeType={SelectorTheme.Cobalt}
          className={styles.simReportCheckbox}
          onChange={handleChange}
        >
          {text}
        </Checkbox>
      </div>
    );
  };


  const getCheckboxState = (id: ErrorTracking) => {
    const isSelectedItems = selectedItems.includes(id);
    if (isSelectedItems) {
      return true;
    }
    switch (id) {
      case ErrorTracking.SIM_LATE_OR_NO_SHOW:
        return isCheckedSimLateOrNoShow;
      case ErrorTracking.SESSION_STARTED_TOO_EARLY:
        return isCheckedSessionStartedTooEarly;
      case ErrorTracking.MOUTH_LIP_SYNC_ISSUE_DURING_SESSION:
        return isCheckedMouthLipSyncIssue;
      case ErrorTracking.AUDIO_VIDEO_ISSUE_FOR_SIM:
        return isCheckedAudioVideoIssueForSim;
      case ErrorTracking.AUDIO_VIDEO_ISSUE_FOR_LEARNER:
        return isCheckedAudioVideoIssueForLearner;
      case ErrorTracking.OTHER_SIM_HARDWARE_ISSUES:
        return isCheckedOtherSimHardwareIssues;
      case ErrorTracking.LEARNER_AND_SIM_BOTH_JOINED:
        return isCheckedLearnerAndSimBothJoined;
      case ErrorTracking.SIM_MOMENT_ISSUE:
        return isCheckedSimMomentIssue;
      case ErrorTracking.LEARNER_WIFI:
        return isCheckedLearnerWifi;
      case ErrorTracking.SIM_WIFI:
        return isCheckedSimWifi;
      default:
        return false;
    }
  };

  const setCheckboxState = (id: ErrorTracking, checked: boolean) => {
    switch (id) {
      case ErrorTracking.SIM_LATE_OR_NO_SHOW:
        setIsCheckedSimLateOrNoShow(checked);
        break;
      case ErrorTracking.SESSION_STARTED_TOO_EARLY:
        setIsCheckedSessionStartedTooEarly(checked);
        break;
      case ErrorTracking.MOUTH_LIP_SYNC_ISSUE_DURING_SESSION:
        setIsCheckedMouthLipSyncIssue(checked);
        break;
      case ErrorTracking.AUDIO_VIDEO_ISSUE_FOR_SIM:
        setIsCheckedAudioVideoIssueForSim(checked);
        break;
      case ErrorTracking.AUDIO_VIDEO_ISSUE_FOR_LEARNER:
        setIsCheckedAudioVideoIssueForLearner(checked);
        break;
      case ErrorTracking.OTHER_SIM_HARDWARE_ISSUES:
        setIsCheckedOtherSimHardwareIssues(checked);
        break;
      case ErrorTracking.LEARNER_AND_SIM_BOTH_JOINED:
        setIsCheckedLearnerAndSimBothJoined(checked);
        break;
      case ErrorTracking.SIM_MOMENT_ISSUE:
        setIsCheckedSimMomentIssue(checked);
        break;
      case ErrorTracking.LEARNER_WIFI:
        setIsCheckedLearnerWifi(checked);
        break;
      case ErrorTracking.SIM_WIFI:
        setIsCheckedSimWifi(checked);
        break;
      default:
        break;
    }
  };

  const getErrorRow = (
    columnText: string,
    labelId: ErrorTracking,
    optionText: string,
    labelId2?: ErrorTracking,
    optionText2?: string,
    labelId3?: ErrorTracking,
    optionText3?: string
  ) => {
    return (
      <Row className={styles.simReportErrorRow}>
        <Col className={styles.simReportErrorLabel}>
          {columnText}
        </Col>
        <Col className={styles.simReportErrorOptions}>
          {getErrorCheckboxWithLabel(labelId, optionText)}
          {optionText2 && labelId2 && getErrorCheckboxWithLabel(labelId2, optionText2)}
          {optionText3 && labelId3 && getErrorCheckboxWithLabel(labelId3, optionText3)}
        </Col>
      </Row>
    );
  };

  return (
    <Page bgColor={"white"} className={styles.simAttendanceWrap}>
      <A11yModal show={!isAttendanceFormEditable} onHide={redirectToHome} size={"sm"}>
        <Modal.Header className={styles.modalHeader}>
          <h4>{intl.formatMessage({ id: 'Mursion.Portal.Status.Error' })}!</h4>
          <button className={styles.close} onClick={redirectToHome}><span aria-hidden="true">×</span></button>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          {sessionAndSimStatusMissed ? null : <p className={styles.textRed}>{intl.formatMessage({ id: 'MursionPortal.SimAttendance.NoLongerAvailable' })}</p>}
          <p>{getModalText()}</p>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button btnType={ButtonType.SPECIAL_RED} btnSize={ButtonSize.MEDIUM} onClick={redirectToHome}>
            {intl.formatMessage({ id: 'MursionPortal.Button.Continue' })}
          </Button>
        </Modal.Footer>
      </A11yModal>
      {hasSimRole ?
        <div className={styles.switchRole}>Please switch role to Sim Specialist to view the page!</div>
        :
        <LoadingOverlay active={isLoading} spinner={true}>
          {isSessionAttendance && (
            <div>
              <div>
                <h1>{intl.formatMessage({ id: 'MursionPortal.SimAttendance.SimulationAttendance' })}</h1>
                <h2>{isSessionAttendance.scenarioName}</h2>
              </div>
              <div className={styles.leadershipCoaching}>
                <div className={cn(styles.dateTimeWrap, styles.leadershipCoachingColumns)}>
                  <div>
                    <label>{intl.formatMessage({ id: 'Session.Edit.Modal.Date' })}:</label>{" "}
                    <span>{moment(isSessionAttendance.startDate).tz(simTimezoneId).format("LL")}</span>
                  </div>
                  <div>
                    <label>{intl.formatMessage({ id: 'Session.Edit.Modal.Time' })}:</label>{" "}
                    <span>
                      {moment(isSessionAttendance.startDate).tz(simTimezoneId).format("LT")} -{" "}
                      {moment(isSessionAttendance.endDate).format("LT")}{" "}
                      {getDateAwareTimezoneTitle(simTimezoneId, new Date().getTime())}
                    </span>
                  </div>
                </div>
                <div className={styles.simulationContentWrap}>
                  <div className={styles.nameDesWrap}>
                    <div>
                      <label>{intl.formatMessage({ id: 'MursionPortal.Scenario.Name' })}:</label> <div className={styles.scenarioNameText}>{isSessionAttendance.scenarioName || ''}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.instructionWrap}>
                  <h3>{intl.formatMessage({ id: 'MursionPortal.SimAttendance.Instructions' })}:</h3>
                  <div className={styles.instructions}>
                    <p>{intl.formatMessage({ id: 'MursionPortal.SimAttendance.Instructions.LabelText' })}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={styles.tableResponsive}>
            <div className={styles.attendeeTableWrap}>
              <div className={styles.attendeeList}>
                <div className={styles.attendee}>
                  <strong>{intl.formatMessage({ id: 'MursionPortal.SimAttendance.Attendee' })}</strong>
                </div>
                <div className={styles.status}>
                  <strong>{intl.formatMessage({ id: 'MursionPortal.Table.ColumnHeader.LearnerStatus' })}</strong>
                </div>
              </div>
              {statusState.length > 0 &&
                statusState.map((learnerInfo, index) => (
                  <div key={index}>
                    <RadioGroup name={index.toString()} onChange={onChange(index)} selectedValue={getLearnerAttendanceStatus(statusState[index].status)}>
                      <div className={styles.statusRadio}>
                        <div className={styles.attendee}>{getUserName(learnerInfo)}</div>
                        <div className={cn(styles.status, styles.statusRadioWrap)}>
                          <div className={cn(styles.posRel, styles.completed)}>
                            <div
                              className={styles.tooltipWrap}
                              tabIndex={0}
                              aria-describedby="simAttendanceLearnerCompletedTooltipText"
                            >
                              <Radio value={SessionUserStatus.COMPLETED} label={intl.formatMessage({ id: 'MursionPortal.ProjectStatus.Completed' })} />
                              <div className={styles.tooltip}>
                                <div className={styles.tooltipContent} id="simAttendanceLearnerCompletedTooltipText">
                                  {intl.formatMessage({ id: 'MursionPortal.SimAttendance.Tooltip.Completed' })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={cn(styles.posRel, styles.missed)}>
                            <div
                              className={styles.tooltipWrap}
                              tabIndex={0}
                              aria-describedby="simAttendanceLearnerMissedTooltipText"
                            >
                              <Radio value={SessionUserStatus.MISSED} label={intl.formatMessage({ id: 'Mursion.Portal.Status.No.Show' })} />
                              <div className={styles.tooltip}>
                                <div className={styles.tooltipContent} id="simAttendanceLearnerMissedTooltipText">
                                  {intl.formatMessage({ id: 'MursionPortal.SimAttendance.Tooltip.Missed' })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={cn(styles.posRel, styles.error)}>
                            <div
                              className={styles.tooltipWrap}
                              tabIndex={0}
                              aria-describedby="LearnerAttendanceLearnerErrorTooltipText"
                            >
                              <Radio value={SessionUserStatus.ERROR} label={intl.formatMessage({ id: 'Mursion.Portal.Status.LearnerTechnicalError' })} />
                              <div className={styles.tooltip}>
                                <div className={styles.tooltipContent} id="LearnerAttendanceLearnerErrorTooltipText">
                                  {intl.formatMessage({ id: 'MursionPortal.SimAttendance.Tooltip.Error' })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={cn(styles.posRel, styles.error)}>
                            <div
                              className={styles.tooltipWrap}
                              tabIndex={0}
                              aria-describedby="simAttendanceLearnerErrorTooltipText"
                            >
                              <Radio value={SessionUserStatus.SIM_ERROR} label={intl.formatMessage({ id: 'Mursion.Portal.Status.SimTechnicalError' })} />
                              <div className={styles.tooltip}>
                                <div className={styles.tooltipContent} id="simAttendanceLearnerErrorTooltipText">
                                  {intl.formatMessage({ id: 'MursionPortal.SimAttendance.Sim.Tooltip.Error' })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={cn(styles.posRel, styles.late)}>
                            <div
                              className={styles.tooltipWrap}
                              tabIndex={0}
                              aria-describedby="simAttendanceLearnerLateTooltipText"
                            >
                              <Radio value={SessionUserStatus.LATE} label={intl.formatMessage({ id: 'Mursion.Portal.Status.TooLateToComplete' })} />
                              <div className={styles.tooltip}>
                                <div className={styles.tooltipContent} id="simAttendanceLearnerLateTooltipText">
                                  {intl.formatMessage({ id: 'MursionPortal.SimAttendance.Tooltip.Late' })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={cn(styles.posRel, styles.left)}>
                            <div
                              className={styles.tooltipWrap}
                              tabIndex={0}
                              aria-describedby="simAttendanceLearnerLeftTooltipText"
                            >
                              <Radio value={SessionUserStatus.LEFT} label={intl.formatMessage({ id: 'Mursion.Portal.Status.LearnerElectedToLeave' })} />
                              <div className={styles.tooltip}>
                                <div className={styles.tooltipContent} id="simAttendanceLearnerLeftTooltipText">
                                  {intl.formatMessage({ id: 'Mursion.Portal.Status.Unprepared.Tooltip' })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </RadioGroup>
                  </div>
                ))}
            </div>
          </div>
          {!showAddInput ? (
            <div className={styles.addAttendeeWrap}>
              <button onClick={onAddAttendeeClick} >{intl.formatMessage({ id: 'MursionPortal.SimAttendance.Add.Attendee' })}</button>
            </div>
          ) : (
            <form className={styles.nameInputWrap}>
              <label htmlFor="first-name">{intl.formatMessage({ id: 'MursionPortal.SimAttendance.Add.Attendee' })}</label>
              <input
                id="first-name"
                type="text"
                placeholder={intl.formatMessage({ id: 'MursionPortal.FirstName' })}
                value={attendeeFirstName}
                name={"attendeeFirstName"}
                onChange={onFirstNameChange}
              />
              <input
                id="last-name"
                type="text"
                placeholder={intl.formatMessage({ id: 'MursionPortal.LastName' })}
                value={attendeeLastName}
                name={"attendeeName"}
                onChange={onLastNameChange}
              />
              <Button disabled={!attendeeFirstName || !attendeeLastName} type="submit" onClick={addAttendee}>
                {intl.formatMessage({ id: 'MursionPortal.Add' })}
              </Button>
            </form>
          )}
          <div className={styles.simReportErrorWrap}>
            <div className={styles.simReportErrorTitle}>{intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.SimReportedError' })}</div>
            {getErrorRow(
              intl.formatMessage({ id: 'MursionPortal.Label.Session' }),
              ErrorTracking.SIM_LATE_OR_NO_SHOW,
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.SimLateOrNoShow' }),
              ErrorTracking.SESSION_STARTED_TOO_EARLY,
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' })
            )}
            {getErrorRow(
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.AvatarIssue' }),
              ErrorTracking.MOUTH_LIP_SYNC_ISSUE_DURING_SESSION,
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' })
            )}
            {getErrorRow(
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.Hardware' }),
              ErrorTracking.AUDIO_VIDEO_ISSUE_FOR_SIM,
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' }),
              ErrorTracking.AUDIO_VIDEO_ISSUE_FOR_LEARNER,
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' }),
              ErrorTracking.OTHER_SIM_HARDWARE_ISSUES,
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' }),
            )}
            {getErrorRow(
              intl.formatMessage({ id: 'Dashboard.SimulationTable.Column.SoftwareVersion' }),
              ErrorTracking.LEARNER_AND_SIM_BOTH_JOINED,
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' }),
              ErrorTracking.SIM_MOMENT_ISSUE,
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.SimMomentIssue' })
            )}
            {getErrorRow(
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.WifiIssue' }),
              ErrorTracking.LEARNER_WIFI,
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.LearnerWifi' }),
              ErrorTracking.SIM_WIFI,
              intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.SimWifi' })
            )}
            <Row className={styles.simReportErrorRow}>
              <Col className={styles.simReportErrorLabel}>
                {intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.AdditionalDetails' })}
              </Col>
              <Col className={styles.simReportErrorOptions}>
                <div className={styles.scenarioOptionsDescription}>
                  <div className={styles.maxLengthText}>{intl.formatMessage({ id: 'MursionPortal.Scenario.CharacterLimit.Message' }, { maxLength: LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH })}</div>
                  <FieldGroup
                    id={`other-issue`}
                    as={'textarea'}
                    value={otherIssues}
                    csvProtected={true}
                    placeholder={intl.formatMessage({ id: 'MursionPortal.SimAttendance.Error.DescribeYourError' })}
                    maxLength={LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH}
                    isCobalt={true}
                    onChange={handleOtherIssueText}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <ErrorMessage message={errorMessage} focusOnChange={true} />
          <Button
            onClick={onSubmit}
            className={cn(styles.submitBtn, 'mb-3')}
            disabled={isSubmitButtonDisabled()}
            btnType={getButtonTheme(isSubmitButtonDisabled())}
            aria-label={intl.formatMessage({ id: 'MursionPortal.Button.Submit' })}>
            {intl.formatMessage({ id: 'MursionPortal.Button.Submit' })}
          </Button>
        </LoadingOverlay>}
    </Page >
  );
};

export default SimAttendance;
