import React, { FunctionComponent, useState } from "react";
import ArrowDown from "src/layouts/common/Projects/components/ProjectCard/components/ScenariosContainer/components/ScenarioCard/components/ScenarioInfo/Images/arrowDown.svg";
import styles from "src/layouts/common/Projects/components/ProjectCard/components/common/ScenarioEditForm/ViewMore/ViewMore.css";
import { useIntl } from "react-intl";
import { getPlainText } from "src/components/TinyMceEditor/TinyMceEditor";
import cn from 'classnames';

export interface IViewMoreProps {
  text: string;
  maxVisibleLength: number;
  className?: string;
  isTextWithHtml?: boolean;
  btnClassName?: string;
}

const ViewMore: FunctionComponent<IViewMoreProps> = (props) => {
  const { text, maxVisibleLength, className, isTextWithHtml, btnClassName } = props;
  const intl = useIntl();
  const [isViewMore, setIsViewMore] = useState(true);

  const toggleViewMore = () => {
    setIsViewMore(!isViewMore);
  };
  
  const getTextToShow = () => {
    if (isViewMore) {
      return text.slice(0, maxVisibleLength);
    }

    return text;
  };

  return (
    <>
      {
        isTextWithHtml ? (
          getPlainText(text)
        ) : (
          <>
            <div className={className}>{getTextToShow()}</div>
            {text.length > maxVisibleLength && isViewMore && (
              <button
                className={cn(styles.viewMore, btnClassName)}
                onClick={toggleViewMore}
                aria-label={intl.formatMessage({ id: 'MursionPortal.AriaLabel.ViewMore' })}
              >
                <img src={ArrowDown} alt={intl.formatMessage({ id: 'MursionPortal.AriaLabel.ViewMore' })} />
                {intl.formatMessage({ id: 'MursionPortal.ScenarioCard.ViewMore.Button' })}
              </button>
            )}
          </>
        )
      }
    </>
  );
};
export default ViewMore;
