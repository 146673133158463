import React, { FunctionComponent, useMemo, useState } from 'react';
import styles from 'src/layouts/common/ClientUsers/components/ClientUsersExportDialog/ClientUsersExportDialog.css';
import { useDispatch } from 'react-redux';
import Button, { ButtonSize, ButtonType } from 'src/components/Button';
import { Modal } from 'react-bootstrap';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import Tokenizer from 'src/components/Tokenizer';
import Separator from 'src/components/Separator';
import actions from 'src/app/redux/store/actions';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getListPageDataFromUrl from 'src/app/data/common/utils/getListPageDataFromUrl';
import ErrorMessage from 'src/components/ErrorMessage';
import { CLIENTS_ROLES } from 'src/app/data/common/roles';
import IUserRole from 'src/app/data/common/interfaces/IUserRole';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { useIntl } from 'react-intl';
import { getLearnerCompletionFilterValue, getMissedLateActivityFilterValue } from 'src/app/data/common/utils/scenarioLearnerActivityUtils';
import { LearnerCompletionType, MissedLateActivityType } from 'src/app/data/projects/interfaces/IScenario';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { ILearnerLockedOutExportQuery, ILearnerLockedOutQuery } from 'src/app/redux/modules/learner/rest';
import { ISearchParams } from 'src/layouts/common/Dashboard/components/layout/DashboardLayout/DashboardLayout';
import { getButtonThemeByType, getDisableButtonStyle } from 'src/layouts/common/InviteToSchedulePage/InviteToScheduleUtils';
import { IListDataExport } from 'src/app/data/common/interfaces/fetch';

export interface IClientsUserExportDialog {
  show: boolean | undefined;
  tableFilters: {
    clients: INamedEntry[] | null;
    projects: INamedEntry[] | null;
    scenarios: INamedEntry[] | null;
    teams: INamedEntry[] | null;
    roleIds?: string[] | null | undefined;
    accepted?: true | false | null | undefined;
    filter?: string | null,
    learnerCompletion?: LearnerCompletionType[] | null;
    missedLateActivity?: MissedLateActivityType[] | null;
  };
  isTeam?: boolean;
  themeType?: SelectorTheme;
  isPageLearnerSchedulingLocked?: boolean; 
  exportAction?: IListDataExport<ILearnerLockedOutExportQuery>;
  query?: ILearnerLockedOutQuery;
  searchParams?: ISearchParams;
  fetchLearnerActivity?: boolean;
}

const ClientsUserExportDialog: FunctionComponent<IClientsUserExportDialog> = (props) => {
  const {
    show,
    tableFilters,
    isTeam = false,
    isPageLearnerSchedulingLocked,
    exportAction,
    themeType,
    query,
    searchParams,
    fetchLearnerActivity = null
  } = props;
  const intl  = useIntl();

  if (!show) {
    return null;
  }

  const dispatch = useDispatch();
  const [exporting, setExporting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const exportQuery = useMemo<ILearnerLockedOutExportQuery>(() => ({
    ...query,
    order: searchParams?.order || 'startDate',
    asc: searchParams?.asc || true,
    archive: 'all',
  }), [query, searchParams]);

  let controller = new AbortController();

  const onExportClick = async () => {
    setExporting(true);

    controller.abort();
    controller = new AbortController();

    const queryPageClientData: IListPageData = getListPageDataFromUrl(
      location,
      false,
      {
        order: 'firstName', // order by start date by default
        archive: 'all',
      },
    );

    const queryPageTeamsData: IListPageData = getListPageDataFromUrl(
      location,
      false,
      {
        archive: 'active',
        asc: true,
        order: "id",
      },
    );

    const defaultQuery = isTeam ? queryPageTeamsData : queryPageClientData;
    const queryPageData = {
      ...defaultQuery,
      page: 0,
      size: 10000,
    };

    const clientUsersQuery = {
      clientIds: !!tableFilters.clients ? tableFilters.clients.map(c => c.id) : null,
      projectIds: !!tableFilters.projects ? tableFilters.projects.map(p => p.id) : null,
      scenarioIds: !!tableFilters.scenarios ? tableFilters.scenarios.map(s => s.id) : null,
      teamIds: !!tableFilters.teams ? tableFilters.teams.map(t => t.id) : null,
      roleIds: tableFilters.roleIds,
      accepted: tableFilters.accepted,
      filter: tableFilters?.filter,
      learnerCompletion: getLearnerCompletionFilterValue(tableFilters.learnerCompletion),
      missedLateActivity: getMissedLateActivityFilterValue(tableFilters.missedLateActivity),
      fetchLearnerActivity,
    };

    try {
      const fetchAPI = isTeam ? actions.clients.users.exportClientTeamExtWithOptions : actions.clients.users.exportClientUsersExtWithOptions;
      let action = fetchAPI({
        pageData: queryPageData,
        clientUsersQuery,
        signal: controller.signal,
      });

      let fileName = isTeam ? 'edit_user_team_memberships.xlsx' : 'client_users.xlsx';

      if(isPageLearnerSchedulingLocked && exportAction && exportQuery) {
        action = exportAction({
          query: exportQuery,
          signal: controller.signal,      
        });
        fileName = `locked_learners.xlsx`;
      }

      const usersData = await dispatch(action);
      const data = new Blob([usersData], { type: 'application/vnd.openxmlformats-officedocument' });
      const element = document.createElement('a');
      const url = window.URL.createObjectURL(data);
      element.setAttribute('href', url);
      element.setAttribute('download', fileName);

      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setExporting(false);
      setError(null);
      setExportDialog(false);
    } catch (e) {
      setError(e.message);
      setExporting(false);
    }
  };

  const [showExportDialog, setExportDialog] = useState(false);
  const openDialog = () => {
    setExportDialog(true);
    setError(null);
  };

  const closeDialog = () => {
    if (exporting) {
      return;
    }
    setError(null);
    setExportDialog(false);
  };

  const clientLists = tableFilters?.clients;
  const projectsList = tableFilters?.projects;
  const scenariosList = tableFilters?.scenarios;
  const teamsList = tableFilters?.teams;
  const rolesList: INamedEntry[] = Object.values(CLIENTS_ROLES)
    .filter((role: IUserRole) => !!tableFilters.roleIds ? tableFilters.roleIds.includes(role.id) : true)
    .map((role: IUserRole) => ({ id: role.id, name: role.shortName }));

  const isButtonDisabled = () => isPageLearnerSchedulingLocked ? !query || exporting : exporting;

  const acceptedText = tableFilters.accepted ? intl.formatMessage({ id: 'Users.ExportButton.TextYes' }) : intl.formatMessage({ id: 'Users.ExportButton.TextNo' });
  const learnerCompletionText = tableFilters.learnerCompletion ? tableFilters.learnerCompletion : intl.formatMessage({ id: 'Filters.All' });
  const missedLateActivityText = tableFilters.missedLateActivity ? tableFilters.missedLateActivity : intl.formatMessage({ id: 'Filters.All' });

  return (
    <>
      <Button
        btnSize={ButtonSize.MEDIUM}
        btnType={getButtonThemeByType(themeType === SelectorTheme.Cobalt, isButtonDisabled())}
        onClick={openDialog}
        disabled={isButtonDisabled()}
        spinner={true}
        loading={exporting}
        aria-label={intl.formatMessage({ id: 'Users.Button.Export' })}
        className={getDisableButtonStyle(themeType === SelectorTheme.Cobalt, isButtonDisabled(), styles.exportBtn)}
      >
        {TranslateMessage('Users.Button.Export')}
      </Button>
      <Modal
        className={styles.modalDialog}
        show={showExportDialog}
        onHide={closeDialog}
        bsSize='sm'
        tabIndex={0}
        enforceFocus={false}
      >
        <Modal.Header className={styles.header}>
          <h4>
            {TranslateMessage('Users.heading.ExportContentFilteredBy')}
          </h4>
        </Modal.Header>
        <Modal.Body className={styles.container} tabIndex={1}>
          <LoadingOverlay active={exporting} spinner={true}>
            {!!clientLists && !!clientLists.length &&
            <>
              <Tokenizer label={intl.formatMessage({ id: 'Users.Labels.Clients' })} items={clientLists}
                         color={'#93D2AB'}/>
              <Separator/>
            </>
            }
            {!!projectsList && !!projectsList.length &&
            <>
              <Tokenizer label={intl.formatMessage({ id: 'Users.Labels.Projects' })} items={projectsList}
                         color={'#FFD670'}/>
              <Separator/>
            </>
            }
            {!!scenariosList && !!scenariosList.length &&
            <>
              <Tokenizer label={intl.formatMessage({ id: 'Users.Labels.Scenarios' })} items={scenariosList}
                         color={'#BF70BF'}/>
              <Separator/>
            </>
            }
            {!!teamsList && !!teamsList.length &&
            <>
              <Tokenizer label={intl.formatMessage({ id: 'Users.Labels.Teams' })} items={teamsList} color={'#6E4AFF'}/>
              <Separator/>
            </>
            }
          {!isPageLearnerSchedulingLocked ? <>
            <Tokenizer label={intl.formatMessage({ id: 'Users.Labels.Roles' })} items={rolesList} color={'#4A6173'}/>
            <Separator/>
            <div className={styles.acceptedField}>
              <p> {TranslateMessage('Users.Text.OnlyAccepted')}<strong>{acceptedText}</strong>
              </p>
              {!!tableFilters.learnerCompletion &&
                <p> {TranslateMessage('MursionPortal.Learners.Filters.LearnerCompletion')}<strong>{learnerCompletionText}</strong>
              </p>
              }
              {!!tableFilters.missedLateActivity &&
                <p> {TranslateMessage('MursionPortal.Learners.Filters.MissedLateActivity')}<strong>{missedLateActivityText}</strong>
              </p>
              }
            </div>
          </> : null}
          </LoadingOverlay>
        </Modal.Body>
        {error &&
        <div className={styles.error}>
          <ErrorMessage message={error}/>
        </div>
        }
        <Modal.Footer className={styles.footer}>
          <Button
            onClick={closeDialog}
            disabled={exporting}
            aria-label={intl.formatMessage({ id: 'Users.Button.Cancel' })}
          >
            {TranslateMessage('Users.Button.Cancel')}
          </Button>
          <Button
            onClick={onExportClick}
            btnType={ButtonType.PURPLE}
            disabled={exporting}
            aria-label={intl.formatMessage({ id: 'Users.Button.Confirm' })}
          >
            {TranslateMessage('Users.Button.Confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientsUserExportDialog;



