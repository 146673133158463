exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ShortScenarioInfo__container___Klu6Z{border:1px solid #f3f1f0;border-radius:5px;background-color:#f5f5f5;padding:9px 16px}.ShortScenarioInfo__block___3Ua63{margin-right:15px}.ShortScenarioInfo__label___8hQB_{letter-spacing:0;font-weight:400;font-size:13px}.ShortScenarioInfo__value___7-yS7{font-size:13px}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/components/ShortScenarioInfo/ShortScenarioInfo.css"],"names":[],"mappings":"AAAA,sCACE,yBAA0B,AAC1B,kBAAmB,AACnB,yBAA0B,AAC1B,gBAAkB,CACnB,AAED,kCACE,iBAAmB,CACpB,AAED,kCACE,iBAAkB,AAClB,gBAAoB,AACpB,cAAgB,CACjB,AAED,kCACE,cAAgB,CACjB","file":"ShortScenarioInfo.css","sourcesContent":[".container {\n  border: 1px solid #F3F1F0;\n  border-radius: 5px;\n  background-color: #F5F5F5;\n  padding: 9px 16px;\n}\n\n.block {\n  margin-right: 15px;\n}\n\n.label {\n  letter-spacing: 0;\n  font-weight: normal;\n  font-size: 13px;\n}\n\n.value {\n  font-size: 13px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "ShortScenarioInfo__container___Klu6Z",
	"block": "ShortScenarioInfo__block___3Ua63",
	"label": "ShortScenarioInfo__label___8hQB_",
	"value": "ShortScenarioInfo__value___7-yS7"
};