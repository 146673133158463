import {
  hasPermissionToAssignSimSpecialist,
  hasPermissionToEditSelfCreatedSession
} from 'src/app/data/permissions/sessionPermissionUtils';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import { ISession, SessionUserStatus, USER_NO_STATUS, USER_STATUS_MISSED, ISessionForList, USER_STATUS_INCOMPLETE, USER_STATUS_NO_SHOW } from 'src/app/data/session/interfaces/ISession';
import SessionStatusType, { learnerStatusMapping } from 'src/app/data/session/interfaces/SessionStatusType';
import { canSimSpecBeAssignedToSession, isSessionAndSimStatusMissed } from 'src/app/data/session/utils/sessionActionUtils';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import { ScenarioStatus } from 'src/app/data/projects/interfaces/IScenario';
import {
  isCurrentUserBorF,
  isCurrentUserBuyer,
  isCurrentUserClientUser,
  isCurrentUserLearner,
  isCurrentUserPSorOps,
  isCurrentUserPSorAMorOps,
  isCurrentUserSimSpec,
  isCurrentUserSimDesigner
} from 'src/app/data/common/utils/userRoleUtils';
import { isGlobalUserRole } from 'src/app/data/profile/utils/isGlobalUser';
import { SESSION_STATUS_SHOW_USER_ATTENDANCE } from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/SessionEditForm';
import { intl } from 'src/i18n/createIntl';
import { statusFormatter} from 'src/app/data/session/utils/statusFormatter';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import { ScenarioVersion, SoftwareType } from 'src/app/data/projects/interfaces/IProject';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import moment from 'moment';
import { SimSpecialistStatusType } from 'src/app/data/session/interfaces/SimSpecialistStatusType';
import capitalize from 'src/app/data/common/utils/capitalize';
import IUser from 'src/app/data/common/interfaces/IUser';

interface ILearnerSessionInfo extends IUser {
  timeSpent?: number;
}

const LEARNERS_DISPLAY_STATUSES: SessionStatusType[] = [
  SessionStatusType.UNDEFINED,
  SessionStatusType.ORPHAN,
  SessionStatusType.RESERVED,
  SessionStatusType.BOOKED,
  SessionStatusType.SWAP,
  SessionStatusType.WAIF,
  SessionStatusType.UPCOMING,
  SessionStatusType.PENDING,
  SessionStatusType.RUNNING,
  SessionStatusType.COMPLETED,
  SessionStatusType.MISSED,
  SessionStatusType.ERROR,
  SessionStatusType.CANCELLED,
  SessionStatusType.LATE_CANCELLED,
  SessionStatusType.LICENSEE_CANCELLED,
  SessionStatusType.EARLY_CANCELLED,
  SessionStatusType.INCOMPLETE,
];

const NO_TIME_STATUSES = [
  SessionUserStatus.ERROR,
  SessionUserStatus.LATE,
  SessionUserStatus.DECLINED,
];

function isSessionDateTimeEditable(session: ISession | null) {
  return !!session && (
    session.status === SessionStatusType.UNDEFINED ||
    session.status === SessionStatusType.ORPHAN ||
    session.status === SessionStatusType.RESERVED ||
    session.status === SessionStatusType.WAIF
  );
}

function canDisplayTrainees(userProfile: IUserProfile | null, session: ISession | null) {
  if (!userProfile || !session || !session.status || !LEARNERS_DISPLAY_STATUSES.includes(session.status)) {
    return false;
  }
  const isCurrentUserSS = userProfile.currentRoleId === RoleID.SIM_SPECIALIST;
  return !isCurrentUserSS || canEditTrainees(userProfile, session);
}

function canDisplayLearners(userRole: IUserRoleExtended | null, session: ISession | null) {
  if (!session
    || !session.status
    || !LEARNERS_DISPLAY_STATUSES.includes(session.status)
  ) {
    return false;
  }
  return isCurrentUserSimSpec(userRole) || isGlobalUserRole(userRole) || isCurrentUserBorF(userRole) || isCurrentUserPSorAMorOps(userRole);
}

function canEditTrainees(userProfile: IUserProfile | null, session: ISession | null) {
  if (!userProfile || !session) {
    return false;
  }
  const isCurrentUserSS = userProfile.currentRoleId === RoleID.SIM_SPECIALIST;
  return !isCurrentUserSS
    || !!session.createdBy && session.createdBy.profileId === userProfile.id && !session.learners.some(l => l.id === userProfile.id);
}

function canEditLearners(userRole: IUserRoleExtended | null, session: ISession | null) {
  if (!session) {
    return false;
  }
  switch (session.status) {
    case SessionStatusType.UNDEFINED:
    case SessionStatusType.ORPHAN:
    case SessionStatusType.RESERVED:
    case SessionStatusType.BOOKED:
    case SessionStatusType.SWAP:
    case SessionStatusType.WAIF:
      return isCurrentUserBorF(userRole) || isCurrentUserLearner(userRole);
    default:
      return false;
  }
}

function canChangeSimSpecialist(userRole: IUserRoleExtended | null, session: ISession | null) {
  return hasPermissionToAssignSimSpecialist(userRole)
    && !!session && canSimSpecBeAssignedToSession(session);
}

function canEditAssetSettings(userRole: IUserRoleExtended | null, session: ISession | null) {
  return isCurrentUserBuyer(userRole)
    && !!session && session.status === SessionStatusType.RESERVED;

}

// TODO add additional permissions
function canEditSessionDateTime(userRole: IUserRoleExtended | null, session: ISession | null, selfCreated: boolean) {
  return isSessionDateTimeEditable(session) &&
    (
      isCurrentUserBorF(userRole) ||
      selfCreated && hasPermissionToEditSelfCreatedSession(userRole)
    );
}

function isSelfCreated(userProfile: IUserProfile | null, session: ISession | null) {
  if (!userProfile || !userProfile.currentRoleId || !session) {
    return false;
  }

  return userProfile.id === session.createdBy.profileId &&
    userProfile.currentRoleId === session.createdBy.roleId;
}

function isSessionBookedReservedSwap(session: ISession | null) {
  return !!session && (
    session.status === SessionStatusType.BOOKED ||
    session.status === SessionStatusType.RESERVED ||
    session.status === SessionStatusType.SWAP
  );
}

function isSessionFinished(session: ISession | null) {
  return !!session && session.status === SessionStatusType.COMPLETED;
}

const canAttendSession = (userProfile: IUserProfile | null, session: ISession | null): boolean => {
  if (!session || !userProfile) {
    return false;
  }
  const isSessionSS = !!session.simspecialist && session.simspecialist.id === userProfile.id && userProfile.currentRoleId === RoleID.SIM_SPECIALIST;
  const isSessionLearner = session.learners.some(learners => learners.id === userProfile.id);
  const isSessionStatusAllowed = session.status === SessionStatusType.UPCOMING
    || session.status === SessionStatusType.RUNNING
    || session.status === SessionStatusType.PENDING;

  return ((!session.training && isSessionLearner && userProfile.currentRoleId === RoleID.LEARNER) // learner session and current user is participant
    || (session.training && isSessionLearner && userProfile.currentRoleId === RoleID.SIM_SPECIALIST) // training session and ss participant
    || isSessionSS) // both types of session and current user is SS in session
    && isSessionStatusAllowed;
};

const canEditCertifiedSS = (isArchived: boolean, scenarioStatus: ScenarioStatus, userRole: IUserRoleExtended | null): boolean => {
  if (!userRole || !scenarioStatus || isArchived) {
    return false;
  }

  const allowedStatuses: ScenarioStatus[] = [
    ScenarioStatus.STARTED,
    ScenarioStatus.UPCOMING
  ];

  return allowedStatuses.includes(scenarioStatus) && (isCurrentUserPSorOps(userRole) || isCurrentUserSimDesigner(userRole));
};

const isCurentUserTrainee = (userProfile: IUserProfile | null, session: ISession | null) => {
  if (!session || !userProfile) {
    return false;
  }

  return session.training && session.learners.some(l => l.id === userProfile.id);
};

const isCurrentUserSessionSS = (userProfile: IUserProfile | null, session: ISession | null): boolean => {
  if (!session || !userProfile) {
    return false;
  }

  return !!session.simspecialist && session.simspecialist.id === userProfile.id;
};

const getSessionUserAttendanceStatus = (session: ISession | null, userStatus?: SessionUserStatus | null) => {

  if (!session?.status || !SESSION_STATUS_SHOW_USER_ATTENDANCE.includes(session.status)) {
    return '';
  }

  switch (session.status) {
    case SessionStatusType.PENDING:
    case SessionStatusType.RUNNING:
      return userStatus || intl().formatMessage({ id: USER_NO_STATUS });
    case SessionStatusType.MISSED:
      return userStatus || intl().formatMessage({ id: USER_STATUS_MISSED });
      case SessionStatusType.ERROR:
        return userStatus || intl().formatMessage({ id: USER_STATUS_MISSED });
    case SessionStatusType.CANCELLED:
      return userStatus || intl().formatMessage({ id: USER_STATUS_MISSED });
    case SessionStatusType.INCOMPLETE:
      return userStatus || intl().formatMessage({ id: USER_STATUS_INCOMPLETE });
    default:
      return '';
  }
};

const timeFormatter = (value?: number) => {
  return value ? moment.duration(value).format('mm:ss') : '';
};

const getSessionAttendanceDuration = (user: any) => {
  return !user.status || NO_TIME_STATUSES.includes(user.status)
    ? statusFormatter(user.status || intl().formatMessage({ id: USER_STATUS_MISSED }))
    : (timeFormatter(user.timeSpent) || statusFormatter(user.status));
};

const getAttendanceColumnValue = (session: ISessionForList, companyConfig: ICompanyConfig | null, onRedirectToCompleteAttendance:() => JSX.Element, getAttendanceCompleteValue:() => JSX.Element) => {
  const attendanceAdded = session.attendanceAdded;
  const mursionSocialAttendanceLiscence = companyConfig?.ml3SocialAttendance;
  const ml3SocialAttendanceClient = session.client?.ml3SocialAttendance;
  const isAttendanceAllowed = mursionSocialAttendanceLiscence && ml3SocialAttendanceClient;
  const scenarioVersion = session?.project?.scenarioVersion;
  const isMursionSocialScenario = scenarioVersion === ScenarioVersion.V3z || scenarioVersion === ScenarioVersion.V3meet;
  const isCancelledStatus = session.status === (SessionStatusType.CANCELLED ||SessionStatusType.LATE_CANCELLED ||SessionStatusType.LICENSEE_CANCELLED ||SessionStatusType.EARLY_CANCELLED);
  const isAttendanceRequired = session.status === SessionStatusType.BOOKED || session.status === SessionStatusType.UPCOMING || session.status === SessionStatusType.PENDING;
  const isCompletedMissedOrError = session.status === SessionStatusType.COMPLETED || session.status === SessionStatusType.MISSED || session.status === SessionStatusType.ERROR;
  if ((!(isAttendanceAllowed && isMursionSocialScenario) && session.project.scenarioVersion !== ScenarioVersion.V3) || isCancelledStatus || isSessionAndSimStatusMissed(session)) {
    return intl().formatMessage({ id: 'Session.Table.Column.Value.NotApplicable' });
  }
  if(isAttendanceRequired) {
    return intl().formatMessage({ id: 'Session.Table.Column.Value.AttendanceRequired' });
  }
  if(isCompletedMissedOrError) {
    if(attendanceAdded) {
      return getAttendanceCompleteValue();
    }
    return onRedirectToCompleteAttendance();
  }
  return intl().formatMessage({ id: 'Session.Table.Column.Value.NotApplicable' });
};

const completeGoogleFormText = () => {
  return intl().formatMessage({ id: 'Session.Table.Column.Value.CompleteGoogleForm' });
};

const getSimPostSimulationSurveyColumnValue = (session: ISessionForList, onRedirectToPostSimulationSurvey: (simPostSimulationSurvey: boolean | undefined) => JSX.Element, licenseeId: string | undefined) => {
  const simPostSimulationSurveyCompleted = session.simPostSimulationSurveyCompleted;
  const simPostSimulationSurveyOneToOne = session.project?.simPostSimulationSurveyOneToOne;
  const simPostSimulationSurveyWorkshop = session.project?.simPostSimulationSurveyWorkshop;
  const sessionDeliveryMode = session?.scenario?.deliveryMode;
  const sessionStatusTypes = session.status === SessionStatusType.UPCOMING || session.status === SessionStatusType.PENDING || session.status === SessionStatusType.COMPLETED || session.status === SessionStatusType.RUNNING;

  if(licenseeId !== 'mursion') {
    return intl().formatMessage({ id: 'Session.Table.Column.Value.NotApplicable' });
  }
  if((sessionDeliveryMode === SessionType.ONE_TO_ONE && !simPostSimulationSurveyOneToOne)) {
    return completeGoogleFormText();
  } else if((sessionDeliveryMode !== SessionType.ONE_TO_ONE && !simPostSimulationSurveyWorkshop)) {
    return completeGoogleFormText();
  }
  if(sessionStatusTypes) {
    return onRedirectToPostSimulationSurvey(simPostSimulationSurveyCompleted);
  }
  return intl().formatMessage({ id: 'Session.Table.Column.Value.NotApplicable' });
};

const getSessionTableColumnsWidth = (nextProps: any, showSimspecialist: boolean, showAttendance:boolean, showSurvey: boolean, showScenarioVersion: boolean) => {
  return (!nextProps.showTimeUntilStart ? 95 : 85) /
  (10 -
    ((!nextProps.showClient ? 1 : 0) +
      (!nextProps.showProject ? 1 : 0) +
      (!nextProps.showContractId ? 1 : 0) +
      (!showScenarioVersion ? 1 : 0) +
      (!nextProps.showScenario ? 1 : 0) +
      (!showSimspecialist ? 1 : 0) +
      (!nextProps.showTeams ? 1 : 0) +
      // + (!nextProps.showStatus ? 1 : 0)
      (!nextProps.showCreateInfo ? 1 : 0) +
      (!nextProps.showCancelInfo ? 1 : 0) +
      (!nextProps.showLearners ? 1 : 0) +
      (!nextProps.showAvailibility ? 1 : 0) +
      (!showAttendance ? 1 : 0) +
      (!showSurvey ? 1 : 0) +
      (!nextProps.showSubmitRequest ? 1 : 0)));
};

const isScenarioOfMagicType = (scenarioVersion: ScenarioVersion, softwareType: SoftwareType | undefined): boolean => {
  return (scenarioVersion === ScenarioVersion.V3 && softwareType === SoftwareType.WEB);
};

const isJoinNowActionColumn = (isCurrentUserPSRole: boolean, showJoinNow: boolean | undefined) => {
  return isCurrentUserPSRole ? true : !showJoinNow;
};

const isPracticeSession = (session: ISession) => {
  return session && session.training && session.learners.some((l) => l.id === session.simspecialist?.id);
};

const getLearnersList = (externalLearnersOnly: boolean | undefined, session: ISession) => {
  return externalLearnersOnly ? [] : session.learners?.map((learner) => learner.id || '');
};

const getSimSpecialistSessionStatus = (simSpecialistStatusType: SimSpecialistStatusType | null | undefined, simSpecialistSessionStatus: string) => {
  switch (simSpecialistStatusType) {
    case SimSpecialistStatusType.MISSED:
    case null:
      return intl().formatMessage({ id: 'Mursion.Portal.Status.No.Show' });
    case SimSpecialistStatusType.SIM_TECHNICAL_ERROR:
      return intl().formatMessage({ id: 'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label' });
    default:
      return simSpecialistSessionStatus;
  }
};

const getDisplayStatus = (
  displaySimSpecialistStatus: boolean,
  sessionInfo: ISession | null,
  itemStatus: SessionUserStatus | null | undefined,
) => {
  return displaySimSpecialistStatus
    ? getSimSpecialistSessionStatus(
        sessionInfo?.simspecialist?.status,
        statusFormatter(getSessionUserAttendanceStatus(sessionInfo, itemStatus))
      )
    : `(${intl().formatMessage({ id: 'Mursion.Portal.Status.Pending' })})`;
};

const sessionStatusFormatter = (status?: string) => {
  return status ? capitalize(status.replace(/_/g, ' ')) : '';
};

const getLearnerStatus = (userStatus: string) => {
  const status = sessionStatusFormatter(userStatus);
  return learnerStatusMapping[status] || status;
};

const getSessionAttendanceDurationForLearnerStatus = (user: ILearnerSessionInfo) => {
  const learnerStatus = getLearnerStatus(user.status);
  return !learnerStatus || NO_TIME_STATUSES.includes(user.status)
    ? learnerStatus || intl().formatMessage({ id: USER_STATUS_NO_SHOW })
    : (timeFormatter(user.timeSpent) || learnerStatus);
};

const getSessionUserAttendanceLearnerStatus = (session: ISession | null, userStatus?: string | null) => {

  if (!session?.status || !SESSION_STATUS_SHOW_USER_ATTENDANCE.includes(session.status)) {
    return '';
  }

  switch (session.status) {
    case SessionStatusType.PENDING:
    case SessionStatusType.RUNNING:
      return userStatus || capitalize(intl().formatMessage({ id: USER_NO_STATUS }));
    case SessionStatusType.MISSED:
      return userStatus || intl().formatMessage({ id: USER_STATUS_NO_SHOW });
    case SessionStatusType.ERROR:
      return userStatus || intl().formatMessage({ id: USER_STATUS_NO_SHOW });
    case SessionStatusType.CANCELLED:
      return userStatus || intl().formatMessage({ id: USER_STATUS_NO_SHOW });
    case SessionStatusType.INCOMPLETE:
      return userStatus || capitalize(intl().formatMessage({ id: USER_STATUS_INCOMPLETE }));
    default:
      return '';
  }
};

export {
  canChangeSimSpecialist,
  canDisplayLearners,
  canDisplayTrainees,
  canEditLearners,
  canEditTrainees,
  canEditSessionDateTime,
  canEditAssetSettings,
  isCurrentUserLearner,
  isCurrentUserClientUser,
  isCurrentUserPSorAMorOps,
  isCurrentUserBorF,
  isCurrentUserSimSpec,
  isSelfCreated,
  isSessionBookedReservedSwap,
  isSessionFinished,
  canAttendSession,
  canEditCertifiedSS,
  isCurentUserTrainee,
  isCurrentUserSessionSS,
  getSessionUserAttendanceStatus,
  getSessionAttendanceDuration,
  getAttendanceColumnValue,
  getSimPostSimulationSurveyColumnValue,
  getSessionTableColumnsWidth,
  isScenarioOfMagicType,
  isJoinNowActionColumn,
  isPracticeSession,
  getLearnersList,
  getDisplayStatus,
  getSessionAttendanceDurationForLearnerStatus,
  getLearnerStatus,
  getSessionUserAttendanceLearnerStatus,
};
