import moment from 'moment-timezone';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IScenario from 'src/app/data/projects/interfaces/IScenario';
import selectors from 'src/app/redux/selectors';
import actions from 'src/app/redux/store/actions';
import Page from 'src/components/Page';
import { ISelectorOption } from 'src/components/Selector/Selector';
import styles from 'src/layouts/common/SimulationScheduling/SimulationScheduling.css';
import cn from 'classnames';
import IProject, { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import getUrlParamValue, { updateUrlParamsValue } from 'src/app/data/common/utils/queryParamsAccessor';
import { useHistory } from 'react-router';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import ROUTE_PATHS from 'src/routing/paths';
import {
  isCurrentUserBorF,
  isCurrentUserClientUser,
  isCurrentUserPSorOps,
} from 'src/app/data/common/utils/userRoleUtils';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import {
  allDbsTimeSlotsFilled,
  IReschedulingEnabledArgs,
  isClientGroupDelivery,
  isReschedulingEnabled,
  isSchedulingTypeNotDemandBased,
  ITimeBlock,
  SchedulingType,
} from 'src/app/data/calendar/utils/simulationSchedulingUtil';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import SubmitPage from 'src/layouts/common/SimulationScheduling/components/SubmitPage/SubmitPage';
import SchedulingFlow from 'src/layouts/common/SimulationScheduling/components/SchedulingFlow/SchedulingFlow';
import WarningModal from 'src/layouts/common/SimulationScheduling/components/WarningModal/index';
import {
  IDemandBasedTimeSlots,
  IEmergencySessionCreate,
  ISessionCreate,
} from 'src/app/data/session/interfaces/ISession';
import { ITimeAllocationResponse } from 'src/app/redux/modules/sessionWizard/rest';
import { defaultDemandBasedTimeSlots } from 'src/layouts/common/Projects/components/ProjectCard/components/NewProjectForm/components/ProjectCreateStep/ProjectCreateStep';
import { SessionStatusSubType } from 'src/app/data/session/interfaces/SessionStatusType';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import { getScenarioRedirectionLinkForClientUser } from 'src/app/data/common/utils/getScenarioRedirectionLinkForClientUser';
import { getUrlParamData } from 'src/app/data/common/utils/getListPageDataFromUrl';
import { getScenarioNameForClientUsers } from 'src/app/data/common/utils/scenarioTemplateUtils';
import { isNonCurrentPortalVersionForLearner } from 'src/app/data/common/portalVersion';
import { redirectionOnPortal } from 'src/app/data/common/utils/redirectionOnPortal';

export interface ISlot {
  startTime?: moment.Moment | null;
  endTime?: moment.Moment | null;
}

export type SchedulingStepTab = 'dateAndTimeStep' | 'simSelectionStep' | 'learnerSelectionStep';

const SimulationScheduling: FunctionComponent<any> = () => {
  const learnerId = useSelector(selectors.profile.getUserId);
  const userTimezoneId = useSelector(selectors.profile.getUserTimeZoneId);
  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = history;

  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [projectInfo, setProjectInfo] = useState<IProject | null>(null);
  const [scenarioInfo, setScenarioInfo] = useState<IScenario | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [timeSlots, setTimeSlots] = useState<ISlot[]>([{}, {}, {}]);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [selectedTime, setSelectedTime] = useState<ITimeBlock | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSecondPossibleDay, setSecondPossibleDay] = useState<moment.Moment | null>(null);
  const [isUnschedulable, setUnschedulable] = useState<boolean>(false);
  const [isSelectedSlotUnavailable, setSelectedSlotUnavailable] = useState<boolean>(false);
  const [learners, setLearners] = useState<ILearnerExtended[]>([]);
  const [isSimSpecialist, setSimSpecialist] = useState<ICompanyUser | null>(null);
  const [onlyUnauthenticatedLearners, setOnlyUnauthenticatedLearners] = useState(false);
  const [isCurrentTab, setCurrentTab] = useState<SchedulingStepTab>('dateAndTimeStep');
  const [redirectedFrom, setRedirectedFrom] = useState<string>('calendar');
  const [selectedTimezone, setSelectedTimezone] = useState<string>(userTimezoneId);
  const [codeOfConductSelected, setCodeOfConductSelected] = useState(false);
  const [clientName, setClientName] = useState<string>('');
  const [emergencySessionId, setEmergencySessionId] = useState<string>('');
  const [schedulingType, setSchedulingType] = useState<SchedulingType>();
  const [showRequestSlots, setShowRequestSlots] = useState(true);
  const [scheduledSessionId, setScheduledSessionId] = useState<string>('');
  const [sessionID, setSessionID] = useState<string | null>(null);
  const [disableDropDowns, setDisableDropDowns] = useState<boolean>(false);
  const [areSlotsCalculated, setAreSlotsCalculated] = useState<boolean>(true);
  const disableSelectors = sessionStorage.getItem('disableDropDowns');
  const authData = useSelector(selectors.auth.getAuthData);
  
  const switchRole = useCallback(async () => {
    const userRoleInLink = getUrlParamValue('userRole', location);
    if (userRoleInLink && userRole?.id !== userRoleInLink) {
      updateUrlParamsValue({ userRole: undefined }, location, history);
      await dispatch(actions.profile.clearUserData());
      await dispatch(actions.auth.switchRole(userRoleInLink));
      await dispatch(actions.profile.getUserProfile());
    } else {
      updateUrlParamsValue({ userRole: undefined }, location, history);
    }
  }, [userRole, location]);

  const handleSchedulingType = () => {
    if (isCurrentUserPSorOps(userRole)) {
      setSchedulingType(SchedulingType.EMERGENCY);
    } else if (location.pathname === ROUTE_PATHS.RESCHEDULING) {
      setSchedulingType(SchedulingType.RESCHEDULING);
    } else {
      setSchedulingType(SchedulingType.DEMAND_BASED);
    }
  };

  useEffect(() => {
    switchRole();
    handleSchedulingType();
    const redirectLoc = getUrlParamValue('redirect', location);
    if (redirectLoc) {
      setRedirectedFrom(redirectLoc);
    }
  }, []);

  useEffect(() => {
    if (isNonCurrentPortalVersionForLearner(authData?.portal_version, userRole?.id)) {
      redirectionOnPortal();
      dispatch(actions.auth.logOut());
    }
  }, [authData]);

  useEffect(() => {
    if (disableSelectors === 'true') {
      setDisableDropDowns(true);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (disableSelectors) {
        sessionStorage.removeItem('disableDropDowns');
      }
    };
  }, []);

  useEffect(() => {
    if (schedulingType === SchedulingType.RESCHEDULING) {
      const paramProjectId = getUrlParamValue('projectId', location);
      const paramScenarioId = getUrlParamValue('scenarioId', location);
      const sessionId = getUrlParamValue('sessionId', location);
      setSessionID(sessionId);
      if (paramProjectId && paramScenarioId && sessionId) {
        onProjectSelect({ value: paramProjectId, label: '' });
        onScenarioSelect({ value: paramScenarioId, label: '' });
      } else {
        setUnschedulable(true);
      }
    }
  }, [schedulingType]);

  useEffect(() => {
    if (projectInfo && scenarioInfo && schedulingType === SchedulingType.RESCHEDULING) {
      const sessionId = getUrlParamValue('sessionId', location);
      dispatch(actions.session.fetchSession(sessionId)).then((sessionInfo) => {
        const rescheduleParams: IReschedulingEnabledArgs = {
          status: sessionInfo?.status,
          newRescheduled: sessionInfo.newRescheduled,
          sameDayRescheduling: projectInfo?.sameDayRescheduling,
          endDate: scenarioInfo.planning.endDate,
          sessionLength: scenarioInfo.draft?.sessionLength,
          reschedulingEnabled: projectInfo?.reschedulingEnabled,
          simspecialist: sessionInfo?.simspecialist,
          type: sessionInfo.type,
          userRole,
          completionRateFulfilled: !!sessionInfo?.completionRateFulfilled,
          schedulingRateFulfilled: !!sessionInfo?.schedulingRateFulfilled,
        };
        setUnschedulable(!isReschedulingEnabled(rescheduleParams));
      });
    }
    if (isCurrentUserBorF(userRole) && scenarioInfo && scenarioInfo?.draft.deliveryMode === SessionType.GROUP) {
      setSchedulingType(SchedulingType.DEMAND_BASED);
    }
  }, [projectInfo, scenarioInfo, schedulingType]);

  useEffect(() => {
    if (disableDropDowns) {
      const paramProjectId = getUrlParamValue('projectId', location);
      const paramScenarioId = getUrlParamValue('scenarioId', location);

      if (paramProjectId && paramScenarioId) {
        onProjectSelect({ value: paramProjectId, label: '' });
        onScenarioSelect({ value: paramScenarioId, label: '' });
      }
    }
  }, [disableDropDowns]);

  const getAssetId = async (): Promise<string> => {
    if (projectInfo?.scenarioVersion !== ScenarioVersion.V2 && scenarioInfo) {
      const assetSettings = await dispatch(actions.assets.fetchAssetSettingsList(scenarioInfo.id));
      return assetSettings[0].id;
    } else {
      return '';
    }
  };

  function getLearners(isGroupScenario: boolean) {
    return isGroupScenario
      ? []
      : learners.map((l) => l.user.id || '');
  }

  function getSessionPayloadForEmergencySession() {
    let session: IEmergencySessionCreate = {
      endDate: selectedTime?.endTime.valueOf() || 0,
      learners: learners.map((l) => l.user.id || ''),
      notes: [],
      scenarioId: scenarioInfo?.id || '',
      startDate: selectedTime?.startTime.valueOf() || 0,
      assetSettingsId: '',
      reason: 'emergencySession',
      simspecialistId: isSimSpecialist?.id || '',
      externalLearnersOnly: onlyUnauthenticatedLearners,
    };

    if (projectInfo?.joinNow) {
      session = {
        ...session,
        subType: SessionStatusSubType.JOIN_NOW_REQUESTS,
        timeSlots: [
          {
            preference: 1,
            startDate: selectedTime?.startTime.valueOf() || 0,
            endDate: selectedTime?.endTime.valueOf() || 0,
          },
        ],
      };
    }

    return session;
  }

  function getRequestPayloadForDemandBasedSession(request: ISessionCreate) {
    const slots: IDemandBasedTimeSlots[] = timeSlots
      .slice(0, demandBasedSlotsCount)
      .map((slot: ISlot, i: number) => {
        return {
          preference: i + 1,
          startDate: slot.startTime?.valueOf() || 0,
          endDate: slot.endTime?.valueOf() || 0,
        };
      });

    request = {
      ...request,
      timeSlots: slots,
      startDate: slots[0].startDate,
      endDate: slots[0].endDate,
      subType: SessionStatusSubType.DEMAND_BASED,
    };

    return request;
  }

  function getErrorMessage(err: IErrorState) {
    return err?.validations
      ? err.validations[0].message
      : err.message;
  }

  function isScenarioOfGroupType() {
    return !!scenarioInfo && scenarioInfo?.draft.deliveryMode === SessionType.GROUP;
  }

  function getLearnersForSessionRequest(isGroupScenario: boolean) {
    return isCurrentUserBorF(userRole)
      ? getLearners(isGroupScenario)
      : [learnerId];
  }

  function getRequestPayloadForInstantBookingSession(request: ISessionCreate) {
    if (schedulingType === SchedulingType.RESCHEDULING) {
      const paramSessionId = getUrlParamValue('sessionId', location);
      request = {
        ...request,
        rescheduled: true,
        previousSessionId: paramSessionId,
      };
    } else {
      request = {
        ...request,
        instantBooking: true,
        timeSlots: [
          {
            preference: 1,
            startDate: selectedTime?.startTime.valueOf() || 0,
            endDate: selectedTime?.endTime.valueOf() || 0,
          },
        ],
        subType: SessionStatusSubType.DEMAND_BASED,
      };
    }

    request = {
      ...request,
      startDate: selectedTime?.startTime.valueOf() || 0,
      endDate: selectedTime?.endTime.valueOf() || 0,
    };

    return request;
  }

  function updateRequestWithDelayedEventId(request: ISessionCreate) {
    const delayedEventId = getUrlParamData('delayedEventId');
    if (delayedEventId?.length) {
      request = { ...request, delayedEventId };
    }
    
    return request;
  }

  function modifyAssetSettingsIdInRequestPayload(request: ISessionCreate, assetSettingsId: string) {
    if (assetSettingsId) {
      request = { ...request, assetSettingsId };
    }

    return request;
  }

  function modifyExternalLearnersOnlyFieldInRequestPayload(request: ISessionCreate) {
    if (isCurrentUserBorF(userRole)) {
      request = { ...request, externalLearnersOnly: onlyUnauthenticatedLearners };
    }

    return request;
  }

  const onSubmit = async () => {
    setLoading(true);
    if (schedulingType === SchedulingType.EMERGENCY) {
      let session = getSessionPayloadForEmergencySession();

      const assetSettingsId = await getAssetId();
      if (assetSettingsId) {
        session = { ...session, assetSettingsId };
      }
      return dispatch(actions.session.createEmergencySession(session))
        .then((res) => {
          setEmergencySessionId(res.id as string);
          setSubmit(true);
        })
        .catch((err) => {
          setErrorMessage(getErrorMessage(err));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const isGroupScenario = isScenarioOfGroupType();
      let request: ISessionCreate = {
        scenarioId: scenarioInfo?.id as string,
        assetSettingsId: '',
        clientNote: '',
        endDate: moment().valueOf(),
        startDate: moment().valueOf(),
        learners: getLearnersForSessionRequest(isGroupScenario),
      };

      request = updateRequestWithDelayedEventId(request);

      if (selectedTime?.isAvailable) {
        if (scenarioInfo?.id) {            
          const req = {
            scenarioId: scenarioInfo.id,
            interval: {
              startDate: selectedTime.startTime.valueOf(),
              endDate: selectedTime.endTime.valueOf(),
            },
          };
          const allocateResult: ITimeAllocationResponse | null = await dispatch(
            actions.sessionWizard.allocateAvailability(req)
          );
          if (allocateResult?.success) {
            request = getRequestPayloadForInstantBookingSession(request);
          } else {
            setSelectedTime(null);
            setSelectedSlotUnavailable(true);
            setSelectedTimezone(JSON.stringify(selectedTimezone));
            setLoading(false);
            return;
          }
        }
      } else {
        request = getRequestPayloadForDemandBasedSession(request);
      }
      const assetSettingsId = await getAssetId();
      request = modifyAssetSettingsIdInRequestPayload(request, assetSettingsId);
      request = modifyExternalLearnersOnlyFieldInRequestPayload(request);

      dispatch(actions.session.createSession(request))
        .then((res) => {
          setScheduledSessionId(res.id as string);
          setSubmit(true);
        })
        .catch((err) => {
          setErrorMessage(getErrorMessage(err));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const refreshState = () => {
    setErrorMessage(null);
    setScenarioInfo(null);
    setSelectedDate(null);
    updateUrlParamsValue(
      {
        date: undefined,
      },
      location,
      history
    );
    setSelectedTime(null);
    setSecondPossibleDay(null);
    setProjectInfo(null);
    setTimeSlots([{}, {}, {}]);
    setCurrentTab('dateAndTimeStep');
    setOnlyUnauthenticatedLearners(false);
    setSimSpecialist(null);
    setLearners([]);
  };

  const onProjectSelect = (project: ISelectorOption<any>) => {
    setLoading(true);
    if (schedulingType !== SchedulingType.RESCHEDULING || !disableDropDowns) {
      refreshState();
    }
    dispatch(actions.demandBasedSession.fetchProjectInfo(project.value)).then((response) => {
      if (isCurrentUserClientUser(userRole)) {
        if (schedulingType !== SchedulingType.RESCHEDULING) {
          if (response.demandBasedSlotsCount === 0) {
            setSchedulingType(SchedulingType.INSTANT);
          } else {
            setSchedulingType(SchedulingType.DEMAND_BASED);
          }
        }
      }
      setProjectInfo(response);
      setLoading(false);
    });
  };

  const onScenarioSelect = (scenario: ISelectorOption<any>) => {
    updateUrlParamsValue(
      {
        scenarioId: scenario.value,
      },
      location,
      history
    );
    setLoading(true);
    if (schedulingType !== SchedulingType.RESCHEDULING || !disableDropDowns) {
      setSecondPossibleDay(null);
      setErrorMessage(null);
      setSelectedDate(null);
      updateUrlParamsValue(
        {
          date: undefined,
        },
        location,
        history
      );
      setSelectedTime(null);
      setScenarioInfo(null);
      setTimeSlots([{}, {}, {}]);
      setCurrentTab('dateAndTimeStep');
      setOnlyUnauthenticatedLearners(false);
      setSimSpecialist(null);
      setLearners([]);
    }
    dispatch(actions.demandBasedSession.fetchScenario(scenario.value)).then((res: IScenario) => {
      setScenarioInfo(res);
      setLoading(false);
    });
  };

  const onDateChange = (date: moment.Moment) => {
    setErrorMessage(null);
    const enabledDateSelection = timeSlots.slice(0, demandBasedSlotsCount).some((slot: ISlot) => {
      return !(slot.startTime && slot.endTime);
    });
    if (schedulingType === SchedulingType.DEMAND_BASED ? enabledDateSelection : true) {
      /* Reset 'Learner' & 'SS' on date change  */
      resetLearnerAndSS();
      setSelectedDate(date);
      setSelectedTime(null);
      updateUrlParamsValue(
        {
          date: date.valueOf(),
        },
        location,
        history
      );
    }
  };

  const setTime = (time: ITimeBlock | null) => {
    if (!time) {
      setSelectedTime(null);
      return;
    }
    setErrorMessage(null);
    const enabledTimeSelection = timeSlots.slice(0, demandBasedSlotsCount).some((slot: ISlot) => {
      return !(slot.startTime && slot.endTime);
    });
    if (schedulingType === SchedulingType.DEMAND_BASED ? enabledTimeSelection : true) {
      if (selectedTime?.startTime.valueOf() !== time.startTime.valueOf()) {
        /* Reset 'Learner' & 'SS' on time select */
        resetLearnerAndSS();
      }
      setSelectedTime(time);
    }
  };

  const resetLearnerAndSS = () => {
    /* Reset 'Learner' & 'SS' */
    setLearners([]);
    setSimSpecialist(null);
  };

  const handleModalClose = () => {
    if (isUnschedulable) {
      return history.push(ROUTE_PATHS.DASHBOARD);
    } else {
      return setSelectedSlotUnavailable(false);
    }
  };

  const getScenarioLink = (): string => {
    const paramClientId = getUrlParamValue('clientId', location);
    const paramProjectId = getUrlParamValue('projectId', location);
    const paramScenarioId = getUrlParamValue('scenarioId', location);
    const generationType = scenarioInfo?.generationType;
    const scenarioUrl = generationType === 1 ? 'nextGenScenarios' : 'scenarios';

    if (paramClientId) {
      return `/clients/${paramClientId}/projects/${paramProjectId}/${scenarioUrl}/${paramScenarioId}`;
    } else {
      return getScenarioRedirectionLinkForClientUser(
        paramProjectId,
        paramScenarioId,
        generationType
      );
    }
  };

  const onTimeZoneChange = (timeZone: string) => {
    updateUrlParamsValue(
      {
        date: undefined,
      },
      location,
      history
    );
    setSelectedDate(null);
    setSelectedTime(null);
    setSecondPossibleDay(null);
    setTimeSlots([{}, {}, {}]);
    setLearners([]);
    setSelectedTimezone(timeZone);
    setCurrentTab('dateAndTimeStep');
    setOnlyUnauthenticatedLearners(false);
    setSimSpecialist(null);
  };

  const onSchedule = () => {
    handleSchedulingType();
    setScenarioInfo(null);
    setProjectInfo(null);
    setSubmit(false);
    setSelectedTime(null);
    setCurrentTab('dateAndTimeStep');
    setSimSpecialist(null);
    setLearners([]);
    setTimeSlots([{}, {}, {}]);
    setOnlyUnauthenticatedLearners(false);
    setSelectedDate(null);
    updateUrlParamsValue(
      {
        date: undefined,
      },
      location,
      history
    );
    updateUrlParamsValue(
      {
        clientName,
      },
      location,
      history
    );
  };

  const demandBasedSlotsCount = useMemo(
    (): number =>
      isClientGroupDelivery(userRole, scenarioInfo?.draft.deliveryMode)
        ? 1
        : projectInfo?.demandBasedSlotsCount ?? defaultDemandBasedTimeSlots,
    [scenarioInfo?.draft.deliveryMode, projectInfo?.demandBasedSlotsCount, schedulingType]
  );

  const dbsSlotsFilled = useMemo(
    () => allDbsTimeSlotsFilled(timeSlots, demandBasedSlotsCount, schedulingType),
    [timeSlots, demandBasedSlotsCount, schedulingType]
  );

  const scenarioTitle = getScenarioNameForClientUsers(userRole, scenarioInfo?.draft?.nameCustomized, scenarioInfo?.name);

  const submitPageProps = {
    emergencySessionId,
    selectedDate,
    learners,
    onlyUnauthenticatedLearners,
    isSimSpecialist,
    scenarioName: scenarioTitle,
    projectName: projectInfo?.name,
    clientName,
    isRescheduling: schedulingType === SchedulingType.RESCHEDULING,
    onSchedule,
    getScenarioLink,
    selectedTime: {
      startTime: dbsSlotsFilled
        ? timeSlots[0].startTime || moment()
        : selectedTime?.startTime || moment(),
      endTime: dbsSlotsFilled
        ? timeSlots[0].endTime || moment()
        : selectedTime?.endTime || moment(),
      isAvailable: selectedTime?.isAvailable,
    },
    schedulingType,
    scheduledSessionId,
  };

  const schedulingFlowProps = {
    redirectedFrom,
    onProjectSelect,
    isLoading,
    setLoading,
    onScenarioSelect,
    projectInfo,
    scenarioInfo,
    refreshState,
    setClientName,
    getScenarioLink,
    timeSlots,
    selectedTime,
    setSelectedTime,
    setTimeSlots,
    selectedDate,
    codeOfConductSelected,
    isSimSpecialist,
    learners,
    setCurrentTab,
    isCurrentTab,
    setErrorMessage,
    onDateChange,
    selectedTimezone,
    errorMessage,
    onSubmit,
    setCodeOfConductSelected,
    onTimeZoneChange,
    isSecondPossibleDay,
    setTime,
    setLearners,
    setSimSpecialist,
    onlyUnauthenticatedLearners,
    setOnlyUnauthenticatedLearners,
    schedulingType,
    setShowRequestSlots,
    showRequestSlots,
    setSecondPossibleDay,
    dbsSlotsFilled,
    demandBasedSlotsCount,
    sessionID,
    disableDropDowns,
    setAreSlotsCalculated,
  };

  return (
    <Page
      bgColor={'white'}
      className={cn(isSubmit && styles.slotsSubmitted, schedulingType === SchedulingType.EMERGENCY && styles.emergencySession)}
    >
      <LoadingOverlay active={isLoading || !areSlotsCalculated} spinner={true}>
        <div
          className={cn(
            styles.container,
            isSchedulingTypeNotDemandBased(schedulingType) && styles.reschedulingWrap
          )}
        >
          {isSubmit ? <SubmitPage {...submitPageProps} /> : <SchedulingFlow {...schedulingFlowProps} />}
        </div>
      </LoadingOverlay>
      <WarningModal
        isUnschedulable={isUnschedulable}
        isSelectedSlotUnavailable={isSelectedSlotUnavailable}
        onClose={handleModalClose}
      />
    </Page>
  );
};

export default SimulationScheduling;