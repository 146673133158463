import { IntlShape } from 'react-intl/lib';
import { DeliveryWeekDaysField } from 'src/app/data/common/utils/dateUtil';
import IProject from 'src/app/data/projects/interfaces/IProject';
import moment from 'moment-timezone';
import { intl as intlShape } from 'src/i18n/createIntl';

export const TIMEZONE_ID_EST = 'America/New_York';
export const HOURS_VALUE_IN_DEFAULT_DELIVERY_HOURS_START_TIME_IN_EST = 8; // for 8 am
export const HOURS_VALUE_IN_DEFAULT_DELIVERY_HOURS_END_TIME_IN_EST = 0; // for 12 am

export type ProjectSettingsType = boolean | undefined;

export function getDeliveryWeekDaysCheckboxesDetails(project: IProject, intl: IntlShape) {
    return [
        {
            label: intl.formatMessage({ id: 'MursionPortal.Label.Monday.ShortForm' }),
            value: project.deliveryWeekDays.deliveryOnMonday,
            field: DeliveryWeekDaysField.MONDAY,
        },
        {
            label: intl.formatMessage({ id: 'MursionPortal.Label.Tuesday.ShortForm' }),
            value: project.deliveryWeekDays.deliveryOnTuesday,
            field: DeliveryWeekDaysField.TUESDAY,
        },
        {
            label: intl.formatMessage({ id: 'MursionPortal.Label.Wednesday.ShortForm' }),
            value: project.deliveryWeekDays.deliveryOnWednesday,
            field: DeliveryWeekDaysField.WEDNESDAY,
        },
        {
            label: intl.formatMessage({ id: 'MursionPortal.Label.Thursday.ShortForm' }),
            value: project.deliveryWeekDays.deliveryOnThursday,
            field: DeliveryWeekDaysField.THURSDAY,
        },
        {
            label: intl.formatMessage({ id: 'MursionPortal.Label.Friday.ShortForm' }),
            value: project.deliveryWeekDays.deliveryOnFriday,
            field: DeliveryWeekDaysField.FRIDAY,
        },
        {
            label: intl.formatMessage({ id: 'MursionPortal.Label.Saturday.ShortForm' }),
            value: project.deliveryWeekDays.deliveryOnSaturday,
            field: DeliveryWeekDaysField.SATURDAY,
        },
        {
            label: intl.formatMessage({ id: 'MursionPortal.Label.Sunday.ShortForm' }),
            value: project.deliveryWeekDays.deliveryOnSunday,
            field: DeliveryWeekDaysField.SUNDAY,
        }
    ];
}

/**
 * converts 'hours' of one timezone to another timezone and returns in HH:mm format
 * eg- 8 am of EST would be 17:30 of IST
 */
export function getHoursInDifferentTz(from: string, to: string, hoursValue: number) {
    const date = moment().tz(from).startOf('day').hour(hoursValue);

    return moment(date).tz(to).format('HH:mm');
}

/**
 * converts 8 am and 12 am EST to target timezone and returns converted values
 * @param from - source timezone
 * @param to - target timezone
 */
export function getDeliveryHoursDefaultTime(from: string, to: string) {
    const startTime = getHoursInDifferentTz(from, to, HOURS_VALUE_IN_DEFAULT_DELIVERY_HOURS_START_TIME_IN_EST);
    const endTime = getHoursInDifferentTz(from, to, HOURS_VALUE_IN_DEFAULT_DELIVERY_HOURS_END_TIME_IN_EST);

    return {
        startTime,
        endTime,
    };
}

export function getPlaceholderText(deliveryHoursTime: string | null) {
    return deliveryHoursTime ? '' : intlShape().formatMessage({ id: 'MursionPortal.AriaLabel.SelectTime' });
}

export function getDeliveryHoursTime(deliveryHoursTime: string | null, defaultTime: moment.Moment) {
    return deliveryHoursTime ? moment(deliveryHoursTime, 'HH:mm') : defaultTime;
}

export function getMinTime(deliveryHoursTime: string | null) {
    return deliveryHoursTime ? moment(deliveryHoursTime, 'HH:mm').add('30', 'minutes') : moment().startOf('d');
}

export function getProjectSettigsValue(virtualSimOnly: ProjectSettingsType, surveyValue: ProjectSettingsType) {
    return virtualSimOnly ? false : surveyValue;
}

export function isVirtualSimOnlyChange(currentStateVirtualSimOnly: ProjectSettingsType, prevStateVirtualSimOnly: ProjectSettingsType) {
    return currentStateVirtualSimOnly !== prevStateVirtualSimOnly;
}

export function getSessionTimeStep(sessionTimeStep: number | null | undefined) {
    return sessionTimeStep ?? null;
}