import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import styles from 'src/layouts/common/SimulationScheduling/components/TimeRangeBlocks/TimeRangeBlocks.css';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import Tooltip, { TooltipPlacement, TooltipTheme } from 'src/components/Tooltip/Tooltip';
import { checkUserRoleByRoleID, isCurrentUserBorF, isCurrentUserClientUser, isCurrentUserLearner } from 'src/app/data/common/utils/userRoleUtils';
import { useSelector } from 'react-redux';
import { selectors } from 'src/app/redux';
import {
  getDateAvailability,
  getTitle,
  isTimeBlockBooked,
  ITimeBlock,
  ITimeBlocksOnDays,
  SchedulingType,
  showRequestTimeSlots,
} from 'src/app/data/calendar/utils/simulationSchedulingUtil';
import ScrollBar from 'src/components/ScrollBar';
import ITimeInterval from 'src/app/data/common/interfaces/ITimeInterval';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { ISlot } from 'src/layouts/common/SimulationScheduling/SimulationScheduling';
import LoggingService from 'src/app/services/logging/LoggingService';
import IScenario from 'src/app/data/projects/interfaces/IScenario';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import UnavailableSlotsError from 'src/components/UnavailableSlots/UnavailableSlotsError';
import { v4 as uuid } from 'uuid';

export type TimeBlocksSection = 'instantBooking' | 'demandSlots';


export interface ITimeBlockProps {
  scheduleType: SchedulingType;
  timeBlocks: ITimeBlocksOnDays[];
  learnerAvailability: ITimeInterval[] | null;
  selectedDate: Moment | null;
  timeSlots: ISlot[];
  selectedTime: ITimeBlock | null;
  setTime: (time: ITimeBlock) => void;
  userTimezoneId: string;
  setShowRequestSlots: (value: boolean) => void;
  isResponse: boolean;
  scenarioInfo?: IScenario | null;
  sessionID?: string;
  showAvailability?: number; // from project setting
  finalShowAvailabilityEndDate?: moment.Moment | null;
  setAreSlotsAvailable?: (val: boolean) => void;
  actualUpcomingAvailabilityPeriod?: number;
}

const TimeRangeBlocks: FunctionComponent<ITimeBlockProps> = (props) => {
  const {
    scheduleType,
    timeBlocks,
    selectedDate,
    learnerAvailability,
    timeSlots,
    selectedTime,
    setTime,
    userTimezoneId,
    setShowRequestSlots,
    isResponse,
    scenarioInfo,
    sessionID,
    showAvailability,
    finalShowAvailabilityEndDate,
    setAreSlotsAvailable,
    actualUpcomingAvailabilityPeriod,
  } = props;
  const userName = useSelector(selectors.profile.getUserName);
  const clientName = useSelector(selectors.profile.getClientName);
  const allRolesOfUser = useSelector(selectors.profile.getAllRolesOfUser);
  const prevSelectedDate = useRef<moment.Moment>(moment().subtract(1, 'day'));
  const [timeBlocksInSelectedDate, setTimeBlocksInSelectedDate] = useState<ITimeBlocksOnDays | undefined>(undefined);
  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  const [requestTimeSlots, setRequestTimeSlots] = isCurrentUserBorF(userRole) ? useState<ITimeBlock[] | null>(null) : useState<ITimeBlock[]>([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<ITimeBlock[] | null>(null);
  const [hasAvailableTimeSlotsBeenSet, setHasAvailableTimeSlotsBeenSet] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<TimeBlocksSection>('instantBooking');
  const scrollRef = useRef<any>();
  const intl = useIntl();
  const [upcomingSlots, setUpcomingSlots] = useState<ITimeBlock[]>([]);
  let showUpcomingSlots = false;

  if (showAvailability) {
    showUpcomingSlots = scheduleType === SchedulingType.INSTANT;
  }

  useEffect(() => {
    const dayTimeBlocks = timeBlocks.find((timeBlock: ITimeBlocksOnDays) => timeBlock.date === selectedDate?.valueOf());
    setTimeBlocksInSelectedDate(dayTimeBlocks);
    if (isCurrentUserClientUser(userRole) && scheduleType === SchedulingType.DEMAND_BASED && !isResponse) {
      const requestSlots: ITimeBlock[] = [];
      const availableSlots: ITimeBlock[] = [];
      dayTimeBlocks?.timeSlots.forEach((timeBlock: ITimeBlock) => {
        if (timeBlock.isAvailable) {
          availableSlots.push(timeBlock);
        } else {
          requestSlots.push(timeBlock);
        }
      });
      setAvailableTimeSlots(availableSlots);
      setRequestTimeSlots(requestSlots);
    } else {
      setAvailableTimeSlots(dayTimeBlocks?.timeSlots || []);
    }
  }, [selectedDate, timeBlocks]);

  useEffect(() => {
    if (selectedDate && scheduleType === SchedulingType.DEMAND_BASED) {
      setActiveSection('instantBooking');
    }
  }, [selectedDate, scheduleType]);

  useEffect(() => { 
    if (isResponse && isCurrentUserClientUser(userRole)) {
      setRequestTimeSlots([]);
    }
    if (isResponse) {
      if (isCurrentUserLearner(userRole)) {
        setAvailableTimeSlots([]);
      } else {
        setAvailableTimeSlots(null);
      }
    }
  },[isResponse]);

  const logInstantBookingInfo = async () => {
    const sessionIdForLogging = sessionID ? sessionID : 'New'; 

    if (timeBlocksInSelectedDate !== undefined && scenarioInfo && selectedDate && prevSelectedDate.current.valueOf() !== selectedDate?.valueOf()) {
      const instantBookingInfo = `InstantBookingSlotsAvailability, USER, ${userName}, CLIENT, ${clientName}, DATE_TIME_OF_BOOKING, ${moment().utc().format('MMMM Do YYYY HH:mm:ss:SSS')}, SLOT_DATE, ${selectedDate?.format('MMMM Do YYYY')}, SLOTS_AVAILABLE, ${availableTimeSlots?.length}, SESSION_ID, ${sessionIdForLogging}, SCENARIO, ${scenarioInfo.name}`;

      await LoggingService.logInfo(instantBookingInfo);
      prevSelectedDate.current = selectedDate?.clone();
    }
  };

  useEffect(() => {
    if (scheduleType === SchedulingType.INSTANT || scheduleType === SchedulingType.RESCHEDULING) {
      logInstantBookingInfo();
    }
  }, [availableTimeSlots]);

  const showRequestAndIntantBookingSlotsSections = useMemo(() => {
    return scheduleType === SchedulingType.DEMAND_BASED && availableTimeSlots && availableTimeSlots.length;
  }, [scheduleType, availableTimeSlots]);

  const handleSectionChange = (section: TimeBlocksSection, top?: number) => () => {
    if (scrollRef.current) {
      scrollRef.current.setState({ topPosition: top || 0 });
    }
    if (activeSection !== section) {
      setActiveSection(section);
    }
  };

  const timeSlotOnClick = (timeBlock: ITimeBlock) => () => {
    setTime(timeBlock);
  };

  const handleTimeSlotOnKeyDown = (timeBlock: ITimeBlock) => (e: any) => {
    if (e.keyCode === 13) {
      timeSlotOnClick(timeBlock);
    }
  };

  const renderTimeSlots = useMemo(() => {
    if(!isResponse && availableTimeSlots && requestTimeSlots) {
      const showRequestSlot = showRequestTimeSlots(
        scheduleType,
        activeSection,
        !!requestTimeSlots.length,
        !!availableTimeSlots.length
      );
      if (isCurrentUserClientUser(userRole)) {
        setShowRequestSlots(showRequestSlot);
      }
      if (showRequestSlot) {
        setHasAvailableTimeSlotsBeenSet(true);
        return requestTimeSlots;
      }
      setHasAvailableTimeSlotsBeenSet(true);
      if (showUpcomingSlots) {
        setUpcomingSlots([]);
        const upcomingSlotsStartTime = finalShowAvailabilityEndDate?.clone().subtract(actualUpcomingAvailabilityPeriod, 's');

        return availableTimeSlots.filter((timeSlot) => {
          if (timeSlot.endTime.isAfter(upcomingSlotsStartTime)) {
            setUpcomingSlots((prevUpcomingSlots) => ([...prevUpcomingSlots, timeSlot]));
            return false;
          }
          return true;
        });
      }

      return availableTimeSlots;
    } else{
      setHasAvailableTimeSlotsBeenSet(false);
      return [];
    }
  }, [scheduleType, activeSection, requestTimeSlots, availableTimeSlots, isResponse]);

  useEffect(() => {
    if (setAreSlotsAvailable && renderTimeSlots.length) {
      setAreSlotsAvailable(true);
    }
  }, [renderTimeSlots]);

  const getToolTipText = (tb: ITimeBlock, showReservedText: boolean): string => {
    let tooltipStr = '';
    if (selectedDate) {
      if (scheduleType === SchedulingType.DEMAND_BASED) {
        if (tb.isAvailable) {
          tooltipStr = intl.formatMessage({ id: 'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg' });
        } else if (showReservedText) {
          tooltipStr = intl.formatMessage({
            id: 'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText',
          });
        }
      } else if (scheduleType === SchedulingType.EMERGENCY && tb.timeTitle) {
        tooltipStr = tb.timeTitle;
      }
    }
    return tooltipStr;
  };

  const isAlreadySelectedOrLearnerUnavailable = (tb: ITimeBlock): boolean => {
    let isTimeBlockReserved = false;
    if (scheduleType === SchedulingType.DEMAND_BASED && selectedDate) {
      timeSlots.forEach((slot: ISlot) => {
        if (slot?.startTime && slot?.startTime.isSame(tb.startTime)) {
          isTimeBlockReserved = true;
        }
      });
      if(!isTimeBlockReserved) {
        /**
         * learner availability is coming empty from api in the case when user with AO/F roles don't have a learner role because of which they are not able to schedule sessions
         * we would be handling this from FE and enable all slots for that user
         */
        if (isCurrentUserBorF(userRole) && (!checkUserRoleByRoleID(allRolesOfUser, RoleID.LEARNER) || scenarioInfo?.draft?.deliveryMode === SessionType.ONE_TO_ONE)) {
          isTimeBlockReserved = false;
        } else if (learnerAvailability && selectedDate) {
          const learnerDateAvailability = getDateAvailability(learnerAvailability, selectedDate, userTimezoneId);
          isTimeBlockReserved = isTimeBlockBooked(learnerDateAvailability, tb);
        }
      }
    }
    return isTimeBlockReserved;
  };

  function getMessageBasedOnSlots() {
    if (upcomingSlots.length !== 0) {
      let text;

      if (renderTimeSlots.length === 0) {
        text = (
          <>
            {intl.formatMessage({ id: 'MursionPortal.BookItUI.BusyText' })}
            < br />
            {intl.formatMessage({ id: 'MursionPortal.BookItUI.TryLaterText' })}
          </>
        );
      } else {
        text = (
          <>
            {intl.formatMessage({ id: 'MursionPortal.BookItUI.MoreSimulationsText' })}
            < br />
            {intl.formatMessage({ id: 'MursionPortal.BookItUI.ComeBackText' })}
          </>
        );
      }

      return (
        <div className={styles.timeBlocktext}>
          {text}
        </div>
      );
    }

    return '';
  }

  return (
    <div
      className={cn(
        styles.timeBlocksMainWrap,
        showRequestAndIntantBookingSlotsSections && styles.instantBookingTabVisible
      )}
    >
       {!renderTimeSlots.length && !upcomingSlots.length && requestTimeSlots && !requestTimeSlots.length && hasAvailableTimeSlotsBeenSet && !isResponse &&(
        <UnavailableSlotsError />
      )}
      {!!showRequestAndIntantBookingSlotsSections && requestTimeSlots && (
        <TimeBlocksSection
          activeSection={activeSection}
          handleSectionChange={handleSectionChange}
          showDemandSlotBtn={!!requestTimeSlots.length}
        />
      )}
      {(!!renderTimeSlots.length || !!upcomingSlots.length) && <ScrollBar
        scrollClass={styles.timeBlocksWrap}
        contentClass={styles.container}
        horizontal={false}
        scrollRef={scrollRef}
      >
        <div className={cn(styles.timeBlocksInnerContent, 'timeRangeWrapInnerValues')}>
          {renderTimeSlots?.map((timeBlock: ITimeBlock, i: number) => {
            {
              const isReserved = isAlreadySelectedOrLearnerUnavailable(timeBlock);
              return (
                <Tooltip
                  placement={TooltipPlacement.TOP}
                  text={getToolTipText(timeBlock, isReserved)}
                  theme={TooltipTheme.COBALT}
                  onFocusViewTooltip={true}
                  key={i}
                >
                  <div
                    className={cn(
                      styles.timeBlock,
                      (timeBlock.isAvailable && scheduleType === SchedulingType.DEMAND_BASED)&& styles.simAvailableTimeBlock,
                      timeBlock.startTime.isSame(selectedTime?.startTime) && styles.selectedTimeBlock,
                      isReserved && styles.disabledTimeBlock
                    )}
                    tabIndex={0}
                    onClick={!isReserved ? timeSlotOnClick(timeBlock) : undefined}
                    onKeyDown={!isReserved ? handleTimeSlotOnKeyDown(timeBlock) : undefined}
                  >
                    {getTitle(timeBlock)}
                  </div>
                </Tooltip>
              );
            }
          })}
          {getMessageBasedOnSlots()}
          {
            upcomingSlots.map((tb) => {
              return <div key={uuid()} className={cn(styles.timeBlock, styles.timeBlockdisabled)}>{getTitle(tb)}</div>;
            })
          }
        </div>
      </ScrollBar>}
    </div>
  );
};

interface ITimeBlocksSectionProps {
  activeSection: TimeBlocksSection;
  handleSectionChange: (section: TimeBlocksSection) => (e: any) => void;
  showDemandSlotBtn: boolean;
}

const TimeBlocksSection = (props: ITimeBlocksSectionProps) => {
  const intl = useIntl();
  const { activeSection, handleSectionChange, showDemandSlotBtn } = props;

  return (
    <div className={cn(styles.instantBookingTabWrap, styles.simOnlySlots)}>
      <button
        className={cn((activeSection === 'instantBooking' || !showDemandSlotBtn) && styles.active)}
        onClick={handleSectionChange('instantBooking')}
        aria-label={intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.InstantBooking' })}
      >
        <div className={styles.title}>
          {intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.InstantBooking' })}
        </div>
      </button>
      {showDemandSlotBtn && (
        <button
          className={cn(activeSection === 'demandSlots' && styles.active)}
          onClick={handleSectionChange('demandSlots')}
          aria-label={intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.AllSlots' })}
        >
          <div className={styles.title}>
            {intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.AllSlots' })}
          </div>
          <Tooltip
            placement={TooltipPlacement.TOP}
            text={intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.ScheduleYourScenario' })}
            theme={TooltipTheme.COBALT}
            onFocusViewTooltip={true}
          >
            <div className={cn(styles.timeBlock)} tabIndex={0}>
              ?
            </div>
          </Tooltip>
        </button>
      )}
    </div>
  );
};

export default TimeRangeBlocks;