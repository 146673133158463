import React, { useEffect, useState } from "react";
import Checkbox from 'src/components/Checkbox/Checkbox';
import _isEmpty from 'lodash/isEmpty';
import styles from './ProjectInfo.css';
import CLIENT_SURVEY_CONFIG from 'src/layouts/common/ClientSurveyPage/clientSurveyConfig';

interface ISurveyConfig {
    SURVEY_TYPE: string;
    ENABLED: boolean;
    isVirtualSimOnlyEnable?: boolean;
}
const ProjectSurveyControl = (props: any) => {

    const { intl, editMode, setSurveyConfig, enableGlobalConfigCall,isSaveProjectSuccess, isVirtualSimOnlyEnable } = props;
    const [preSimulation, setPreSimulation] = useState(false);
    const [initialPreSimulation, setInitialPreSimulation] = useState(false);

    useEffect(() => {
        if (props.projectId) { // new project creation getCallbackGlobalConfig is not required
            getCallbackGlobalConfig(props.projectId, props.clientId, props.licenseeId);
        }
    }, [props.projectId]);

    useEffect(() => {
        setSurveyConfig({ enablePreSimulation: preSimulation });
    }, [preSimulation]);

    useEffect(() => {
        const preSimulationValue = isVirtualSimOnlyEnable ? false : preSimulation;
        setPreSimulation(preSimulationValue);
    }, [isVirtualSimOnlyEnable]);

    useEffect(() => {
        if (isSaveProjectSuccess) {
            setInitialPreSimulation(preSimulation);
        }
        else if (!editMode) {
            setPreSimulation(initialPreSimulation);
        }
    }, [editMode, isSaveProjectSuccess]);

    const getCallbackGlobalConfig = async (projectId: string, clientId: string, licenseeId: string) => {
        try {
            const fetcherSurveyCnfig = props.fetchClientGlobalConfig;
            const SURVEY_CONFIGS = await fetcherSurveyCnfig(projectId, clientId, licenseeId, CLIENT_SURVEY_CONFIG.survey_configs); // projectId or clientId or licenseeId or configType
            const surveyConfigs: [] = _isEmpty(SURVEY_CONFIGS) ? [] : SURVEY_CONFIGS;

            if (surveyConfigs?.length > 0) {
                surveyConfigs.map((item: ISurveyConfig) => {
                    if (item.SURVEY_TYPE === CLIENT_SURVEY_CONFIG.pre_simulation_survey) {
                        setPreSimulation(item.ENABLED);
                        setInitialPreSimulation(item.ENABLED);
                    }
                });
            }

        } catch (err) {
            // do nothing
        }
    };

    const enableGlobalConfigCallAPI = () => {
        if(enableGlobalConfigCall) {
            enableGlobalConfigCall(true);
        }
    };
    
    const onPreSimulationSurvey = (event: any) => {
        setPreSimulation(event.target.checked);
        enableGlobalConfigCallAPI();
    };


    return (
        <>
            {
                editMode
                    ? (
                        <div className={styles.field}>
                            <div>
                                <Checkbox
                                    checked={preSimulation}
                                    onChange={onPreSimulationSurvey}
                                    disabled={isVirtualSimOnlyEnable}
                                >
                                    {intl.formatMessage({ id: 'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey' })}
                                </Checkbox>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label className={styles.label}>
                                {intl.formatMessage({ id: 'Projects.ProjectInfo.Checkbox.SurveyEnabled' })}:
                            </label>
                            <div className={styles.value}>
                                <strong>
                                    {preSimulation && intl.formatMessage({ id: 'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey' })} <br />
                                </strong>
                            </div>
                        </>
                    )
            }
        </>
    );
};

export default ProjectSurveyControl;