import classNames from 'classnames';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import Button, { ButtonFont, ButtonSize } from 'src/components/Button';
import RemovableLabel from 'src/components/RemovableLabel';
import styles from './Tags.css';
import FieldGroup from 'src/components/FieldGroup';
import { MAX_TAG_LENGTH } from 'src/app/data/common/constants';
import ErrorMessage from 'src/components/ErrorMessage';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { isInjected } from 'src/app/data/common/utils/csvInjectionProtector';

export interface ITagsProps extends WrappedComponentProps {
  tagClassName?: string;
  tags: string[];
  addTagButtonTitle?: string;
  label?: string;
  emptyMessage?: string;
  viewMode?: boolean;
  error: string | null;
  onAddNewTag?: (tag: string) => void;
  onRemoveTag?: (index: number) => void;
}

interface ITagsState {
  addTagDisabled: boolean;
  userInput: string;
}

class Tags extends React.Component<ITagsProps, ITagsState> {

  public state: ITagsState = {
    addTagDisabled: false,
    userInput: '',
  };

  public render() {
    const { intl } = this.props;
    return (
      <>
        <Form.Label bsClass={styles.controlLabel}>{this.props.label}</Form.Label>
        <div className={styles.container}>
          {!this.props.viewMode && this.renderUserInput()}
          <div className={styles.tagsContainer}>
            {this.props.tags.length === 0 &&
            <span>{this.props.emptyMessage || intl.formatMessage({ id: 'MursionPortal.NoTags' })}</span>}
            {
              this.props.tags.map((tag, index) => {
                const props = {
                  className: classNames([
                    styles.addedTag,
                    this.props.tagClassName,
                    { [styles.removable]: !this.props.viewMode },
                  ]),
                  onRemove: this.removeTag,
                  removeArgument: index,
                  showRemoveButton: !this.props.viewMode,
                  title: tag,
                };

                return (
                  <RemovableLabel  key={`tag-${tag}-${index}`} {...props}>
                    <i className={classNames('fas', 'fa-tag', styles.labelIcon)}/>
                    <span className={styles.tagText}>{tag}</span>
                  </RemovableLabel>
                );
              })
            }
          </div>
          <ErrorMessage message={this.props.error}/>
        </div>
      </>
    );
  }

  private renderUserInput() {
    const { userInput, addTagDisabled } = this.state;
    const { intl } = this.props;
    const canBeAdded =
      !!userInput.trim()
      && !addTagDisabled
      && userInput.length <= MAX_TAG_LENGTH
      && !isInjected(userInput);

    return (
      <div className={styles.inputContainer}>
        <FieldGroup
          id='tag-input'
          type='text'
          csvProtected={true}
          value={this.state.userInput}
          onChange={this.onUserInputChange}
          maxLength={MAX_TAG_LENGTH}
          className={styles.input}
          ariaLabel={intl.formatMessage({ id: 'MursionPortal.AriaLabel.EnterTagName' })}
        />
        <Button
          className={styles.addTagBtn}
          btnSize={ButtonSize.SMALL}
          btnFont={ButtonFont.LIGHT}
          disabled={!canBeAdded}
          onClick={this.addNewTag}
        >
          {this.props.addTagButtonTitle || intl.formatMessage({ id: 'MursionPortal.AddTag' })}
        </Button>
      </div>
    );
  }

  private addNewTag = (event: any) => {
    event.preventDefault();

    const { userInput } = this.state;

    if (this.props.onAddNewTag) {
      this.props.onAddNewTag(userInput);
    }

    this.setState({
      userInput: '',
    });
  };

  private removeTag = (index: number) => {
    if (this.props.onRemoveTag) {
      this.props.onRemoveTag(index);
    }
  };

  private onUserInputChange = (event: any) => {
    const userInput = event.target.value;

    this.setState({
      addTagDisabled: !!this.props.tags.find(tag => tag.trim().toLowerCase() === (userInput || '').trim().toLowerCase()),
      userInput,
    });
  };
}

export default injectIntl(Tags);
