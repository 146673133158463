import React, { FunctionComponent, useMemo } from 'react';
import cn from 'classnames';
import styles from 'src/layouts/common/SimulationScheduling/SimulationScheduling.css';
import moment from 'moment';
import IScenario from 'src/app/data/projects/interfaces/IScenario';
import { getPlainText } from 'src/components/TinyMceEditor/TinyMceEditor';
import { getScenarioNameForClientUsers } from 'src/app/data/common/utils/scenarioTemplateUtils';
import { useSelector } from 'react-redux';
import { selectors } from 'src/app/redux';
interface IScenarioDetailsProps {
  scenarioInfo: IScenario | null;
  getScenarioLink?: () => string;
}

const ScenarioDetails: FunctionComponent<any> = (props: IScenarioDetailsProps) => {
  const { scenarioInfo } = props;
  const userRole = useSelector(selectors.profile.getCurrentUserRole);

  const sessionLength = useMemo(() => {
    return moment.duration(scenarioInfo?.draft.sessionLength, "ms").format({
      template: "m _",
      trim: "all",
      trunc: true,
    });
  }, [scenarioInfo?.draft.sessionLength]);

  const getSessionDetails = () => {
    return scenarioInfo?.draft.vignette ? getPlainText(scenarioInfo.draft.vignette) : '';
  };

  const scenarioTitle = getScenarioNameForClientUsers(
    userRole,
    scenarioInfo?.draft?.nameCustomized,
    scenarioInfo?.draft.name
  );
  
  return (
    <div className={styles.borderTop}>
      <div className={cn(styles.detailsDescriptionWrap, styles.dFlex)}>
        <div className={styles.scenarioInfo}>
          <div className={cn(styles.description, styles.bold)}>{scenarioTitle}</div>
          <div className={cn(styles.description, styles.sessionLenght)}>{sessionLength}</div>
          {getSessionDetails()}
        </div>
      </div>
    </div>
  );
};

export default ScenarioDetails;
