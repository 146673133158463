const CLIENT_SURVEY_CONFIG = {
    pre_simulation_survey: 'PRE_SIMULATION_SURVEY',
    post_simulation_survey: 'POST_SIMULATION_SURVEY',
    post_simfeedback_onetoone_survey: 'POST_SIMFEEDBACK_ONETOONE_SURVEY',
    post_simfeedback_workshop_survey: 'POST_SIMFEEDBACK_WORKSHOP_SURVEY',
    LEARNER_FEEDBACK_SURVEY: 'LEARNER_FEEDBACK_SURVEY',
    NEXTGEN_SIM_FEEDBACK_SURVEY: 'NEXTGEN_SIM_FEEDBACK_SURVEY',
    NEXTGEN_POST_SIMULATION_SURVEY: 'NEXTGEN_POST_SIMULATION_SURVEY',
    survey_configs: 'SURVEY_CONFIGS',
    surveyTypeParams: 'surveyTypeId',
    surveyTypeConfigured: 'surveyConfig',
    postSimFeedBackConfigList: ['postSimFeedback'],
    preSimulationConfigList: ['presimulation'],
    postSimulationConfigList: ['postSimulation'],
    qualtricsSDKID: 'ZN_cI1I6LpZClRVRyu',
    providerId: 'providerId',
    userId: 'userId',
    providerName: 'providerName',
    clientName: 'clientName',
    projectName:'projectName',
    projectId: 'projectId',
    sowId: 'sowId',
    sessionId: 'mursionSessionId',
    scenarioId: 'scenarioId',
    joinSessionLink: 'joinSessionLink',
    surveyId: 'surveyId',
    webPortal: 'webPortal',
    clientId: 'clientId',
    scenarioName: 'scenarioName',
    scenarioOutcome: 'scenarioOutcome',
    scenarioStrategy1: 'scenarioStrategy1',
    scenarioStrategy2: 'scenarioStrategy2',
    scenarioStrategy3: 'scenarioStrategy3',
    softwareVersion: 'softwareVersion',
    learner1Name: 'learner1Name',
    learner2Name: 'learner2Name',
    learner3Name: 'learner3Name',
    learner4Name: 'learner4Name',
    learner1UserId: 'learner1UserId',
    learner2UserId: 'learner2UserId',
    learner3UserId: 'learner3UserId',
    learner4UserId: 'learner4UserId',
    deliveryMode: 'deliveryMode',
    sessionAttendanceCount: 'sessionAttendanceCount',
    preSimulationSurvey: 'preSimulationSurvey',
    postSimulationSurvey: 'postSimulationSurvey',
    Q_Language: 'Q_Language',
    hostName: 'hostName',
    fromEmail: 'fromEmail',
    SURVEY_ROUTE:'/survey/session'
};

export default CLIENT_SURVEY_CONFIG;