import React, { FunctionComponent, useState, useEffect, useMemo }from 'react';
import {Container, Row, Col } from 'react-bootstrap';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { getDashboardFilterCache } from 'src/layouts/common/Dashboard/components/layout';
import styles from './DashboardReportFilter.css';
import { useLocation } from 'react-router';
import { IDashboardReportFilterProps, IFilterStore } from 'src/app/data/dashboardReport/interfaces/IDashboardReport';
import { mappedScheduleFilters } from '../../utils/viewSelectedFilters';
import DashboardReportTabs from './DashboardReportTabs';

const DashboardReportFilter: FunctionComponent<IDashboardReportFilterProps> = ({ storageKey, clientName, fetchReportDataResponse, userRoleId, isEditOrView, isLoading, isSendReport }) => {
    const [selectedFilterStore, setSelectedFilterStore] = useState<IFilterStore[]>([]);
    const { search } = useLocation();
    const [activeKey, setActiveKey] = useState('provider');
    const getHeaderTitle = useMemo(() => new URLSearchParams(search).get("title") || '', [search]);
    const filterHeading = TranslateMessage('Dashboard.Report.Filter.Title');

    useEffect(() => {
        if(clientName){
            let cacheValueForScheduleReport = getDashboardFilterCache(storageKey);
            if(!isSendReport){
                cacheValueForScheduleReport = fetchReportDataResponse || cacheValueForScheduleReport;
            }
            const result = mappedScheduleFilters(cacheValueForScheduleReport, userRoleId, storageKey);
            if(result.length){
                setActiveKey(result[0].key);
                setSelectedFilterStore(result);
            }
        }
    }, [fetchReportDataResponse, clientName]);

    const setActiveHandler = (event: any) => {
        setActiveKey(event);
    };

    return (
        <Container className={styles.container}>
            <Row>
                <Col sm={2}>
                    <p className={styles.boldText} title={clientName}>{clientName}: </p> 
                </Col>
                <Col sm={10} >
                    <p className={styles.filterSelected} >{filterHeading}</p>
                </Col>
            </Row>
            <Row className={styles.marginRow}>
                <Col sm={2}>
                    <p className={styles.boldText} title={getHeaderTitle}>{getHeaderTitle}</p>
                </Col>
                <Col sm={10}>
                    <DashboardReportTabs 
                        setActiveHandler={setActiveHandler} 
                        activeKey={activeKey} 
                        selectedFilterStore={selectedFilterStore} 
                        isEditOrView={isEditOrView}
                        isLoading={isLoading}
                        isSendReport={isSendReport}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default DashboardReportFilter;