import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import DatePicker from "react-datepicker";
import { injectIntl } from 'react-intl';
import moment from 'moment';
import {useSelector} from 'react-redux';
import { selectors } from 'src/app/redux';
import { convertMomentToDate } from 'src/app/data/common/utils/dateUtil';
import {Container, Row, Col, InputGroup, Form} from 'react-bootstrap';
import cn from 'classnames';
import TranslateMessage from 'src/i18n/TranslateMessage';
import Selector from 'src/components/Selector';
import { SelectAccountSelector } from 'src/components/EntitySelectors';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import questionMarkIcon from 'src/images/questionMark.svg';
import TooltipComponent from 'src/components/Tooltip';
import { TooltipPlacement, TooltipTheme } from 'src/components/Tooltip/Tooltip';
import { IOptionType, IReportDropdownProps } from 'src/app/data/dashboardReport/interfaces/IDashboardReport';
import { fetchFrequencyOptions, fetchSessionOptions, fetchSessionIncludedSelector, mappedReportFilterResponse, ReportSession } from 'src/app/data/dashboardReport/utils';
import { ReportSessionType } from 'src/app/data/dashboardReport/interfaces/ReportFiltersType';
import { checkEmailValidation } from '../ReportUtils';
import styles from './DashboardReportDropdownFilter.css';
import { REPORT_PAGE_ID } from 'src/app/data/dashboardReport/interfaces/IReportPageId';

const MAX_RECIPIENT_LIMIT = 30;

const DashboardReportDropdownFilter:FunctionComponent<IReportDropdownProps> = (props) => {

    const { 
        intl,  
        isSendReport,
        recipients, 
        setRecipients,
        handleChangeReportFrequency,
        selectedReportFrequency,
        setSession, 
        session,
        setReportStartDate,
        reportStartDate,
        selectedFilters,
        getPageId,
        showViewReport,
        clientID,
        isShowSelectSession,
    } = props;
    const [ frequencyOptions ] =  useState<IOptionType[]>(fetchFrequencyOptions);
    const [ sessionOptions, setSessionOptions ] =  useState<IOptionType[]>([]);
    const [ accountValue, setAccountValue ] = useState<INamedEntry[]>([]);
    const [ email, setEmail ] =  useState<string>('');
    const [ storeEmails, setStoreEmails ] = useState<INamedEntry[]>([]);
    const [ emailErrorMessage, setEmailErrorMessage ] = useState<string | null>();
    const [ optionsEmails, setOptionsEmails ] = useState<string[]>([]);
    const [ isMaximumEmailLimit, setIsMaximumEmailLimit ] = useState<boolean>(false);
    const [ checkIsFrequencyChange, setCheckIsFrequencyChange ] = useState<boolean>(false);
    const isSessionSelectorIncluded = useMemo(() =>  fetchSessionIncludedSelector(getPageId), [getPageId]);
    const userTimeZoneId = useSelector(selectors.profile.getUserTimeZoneId);
    const tomorrow = convertMomentToDate(moment.tz(userTimeZoneId).add(1, 'd').startOf('d'));
    const getReportType = getPageId?.replace(" ", "") || '';
	const isPageLearnerActivity = REPORT_PAGE_ID[getReportType] === REPORT_PAGE_ID.LearnerActivities;

    useEffect(() => {
        if(Array.isArray(accountValue) && Array.isArray(storeEmails)){
            const allEmails = [...accountValue, ...storeEmails];
            setRecipients(allEmails);
        }
    }, [accountValue, storeEmails]);

    useEffect(() => {
        if(!!selectedFilters && !isSendReport){
            const accountEmailData: INamedEntry[] = [];
            const recipientEmailData: INamedEntry[] = [];
            const { getReportFrequency, getReportFrequencyType, getStartDate, getRecipientsEmail } = mappedReportFilterResponse(selectedFilters);
            handleChangeReportFrequency(getReportFrequency);
            const fqType = getReportFrequencyType ? {...getReportFrequencyType, label: ReportSession[ReportSessionType[getReportFrequencyType.value]]} : {label: '', value: ''};
            onSessionChange(fqType);
            onDateChange(getStartDate);
            setRecipients(getRecipientsEmail);
            if(optionsEmails.length && getRecipientsEmail.length){
                getRecipientsEmail.forEach((item: INamedEntry) => {
                    if(optionsEmails.indexOf(item.id) !== -1){
                        accountEmailData.push(item);
                    }else{
                        recipientEmailData.push(item);
                    }
                });
                setAccountValue(accountEmailData);
                setStoreEmails(recipientEmailData);
            }
        }
    }, [selectedFilters, optionsEmails]); 

    const checkRecipientsEmailLimit = () => {
        const len = Array.isArray(recipients) ? recipients.length : 0;
        if((len + 1) > MAX_RECIPIENT_LIMIT){
            setIsMaximumEmailLimit(true);
            return true;
        }
        setIsMaximumEmailLimit(false);
        return false;
    };

    const handleFrequencyChange = (selectedValue: IOptionType) => {
        setSessionOptions(fetchSessionOptions(selectedValue.value, isPageLearnerActivity));
        handleChangeReportFrequency(selectedValue);
        const getReportFrequency = selectedFilters?.reportFrequency;
        const { value } = selectedValue;
        if (session && getReportFrequency !== value || checkIsFrequencyChange) {
            setSession(undefined);
            setCheckIsFrequencyChange(true);
        }
    };

    const onSessionChange = (sessionValue:IOptionType) =>{
        setSession(sessionValue);
    };

    const onDateChange = (value:Date) => {
        setReportStartDate(value);
    };

    const getSelectedValue = (selectedValue: INamedEntry[]) => {
        if(selectedValue){
            return selectedValue;
        }
        if(selectedFilters){
            return accountValue;
        }
        return [];
    };

    const onChangeAccountHandler = (selectedValue: INamedEntry[]) => {
        if(!checkRecipientsEmailLimit()){
            setAccountValue(getSelectedValue(selectedValue));
        }
    };

    const onFetchAccountData = ( data:INamedEntry[] ) => {
        setOptionsEmails(data.map(v => v.id));
    };

    const checkEmailIsExists = (newEmail: string) => {
        const isExists = recipients.findIndex(v => v.id.toLowerCase() === newEmail.toLowerCase());
        return checkEmailValidation(newEmail, isExists, intl);
    };

    const onEmailChange = (event:any) => {
        setEmail(event.target.value);
        setEmailErrorMessage(checkEmailIsExists(event.target.value));
    };

    const updateRecipient = () => {
        const tmpEmailStore = Array.isArray(storeEmails) ? [...storeEmails] : [];
        const emailError = checkEmailIsExists(email);
        if(!emailError && !checkRecipientsEmailLimit()){
            setStoreEmails([...tmpEmailStore, ...[{id: email, name: email}]]);
            setEmail('');
        }
        return emailError;
    };

    const onAddEmail = () => {
        setEmailErrorMessage(updateRecipient());
    };

    const handleRecipientsEmailsIds = (data: INamedEntry[], checkEmail: string, setData: (storeData: INamedEntry[]) => void): boolean => {
        if(Array.isArray(data)){
            const store = [...data];
            const getIndex = store.findIndex(d => d.id === checkEmail);
            if(getIndex !== -1){
                store.splice(getIndex, 1);
                setData(store);
                setIsMaximumEmailLimit(false);
                return true;
            }
        }
        return false;
    };

    const emailRemoveHandler = (event: any) => {
        const getEventID = event.target.id;
        if(getEventID){
            const response = handleRecipientsEmailsIds(storeEmails, getEventID, setStoreEmails);
            if(!response){
                handleRecipientsEmailsIds(accountValue, getEventID, setAccountValue);
            }
        }
    };

    const isSelectedSessionAll = useMemo(()=>{
        return session?.value === ReportSessionType.ALL;
    }, [session]);

    return (
        <Container className={styles.container}>
            <Row>
                {!isSendReport && <Col sm={4}>
                    <Row>
                        <Col sm={12}>
                            <p className={styles.label}>{TranslateMessage('Dashboard.Report.SelectReportFrequency.Label')}</p>
                            <Selector
                            isMulti={false}
                            value={selectedReportFrequency}
                            placeholder={intl.formatMessage({ id: 'Dashboard.Report.Dropdown.Select.Placeholder' })}
                            onChange={handleFrequencyChange}
                            options={frequencyOptions}
                            required={'required'}
                            classNamePrefix={"frequency"}
                            ariaLabel={intl.formatMessage({ id: 'Dashboard.Report.SelectReportFrequency.Label' })}
                            disabled={showViewReport}
                        />
                    </Col>
                </Row>
                {isSessionSelectorIncluded && isShowSelectSession && <Row className={styles.marginRow}>
                    <Col sm={12}>
                        <p className={styles.label}>{TranslateMessage('Dashboard.Report.SelectSessions.Label')}</p>
                        <Selector
                            isMulti={false}
                            value={session || null}
                            placeholder={intl.formatMessage({ id: 'Dashboard.Report.Dropdown.Select.Placeholder' })}
                            onChange={onSessionChange}
                            options={sessionOptions}
                            required={'required'}
                            classNamePrefix={"frequency"}
                            ariaLabel={intl.formatMessage({ id: 'Dashboard.Report.SelectSessions.Label' })}
                            disabled={showViewReport || typeof selectedReportFrequency === 'undefined'}
                            hasClearRef={'Ref'}
                        />
                    </Col>
                </Row>}
                <Row className={styles.marginRow}>
                    <Col sm={12}>
                        <p className={styles.label}>{TranslateMessage('Dashboard.Report.SelectReportStartDate.Label')}</p>
                        <TooltipComponent
                            text={intl.formatMessage({ id: 'Dashboard.Report.SelectReportStartDate.Tooltip' })}
                            placement={TooltipPlacement.TOP}
                            theme={TooltipTheme.COBALT}>
                            <img 
                                alt={intl.formatMessage({ id: 'Dashboard.Report.SelectReportStartDate.Tooltip'})} 
                                tabIndex={0} 
                                src={questionMarkIcon} 
                                className={styles.questionMarkIcon}/>
                        </TooltipComponent>
                        <DatePicker 
                            id="reportStartDateInput"
                            selected={reportStartDate} 
                            minDate={tomorrow}
                            onChange={onDateChange} 
                            calendarClassName="customCalendar"
                            placeholderText={intl.formatMessage({ id: 'Dashboard.Report.Frequency.Date.Select.Placeholder' })}
                            disabled={showViewReport || isSelectedSessionAll}
                        />
                    </Col>
                </Row>
            </Col>}

            <Col sm={4}>
                    <Row>
                        <Col sm={12}>
                            <p className={styles.label}>{TranslateMessage('Dashboard.Report.SelectAccountOwner.Label')}</p>
                            <SelectAccountSelector
                            onChange={onChangeAccountHandler}
                            active={true}
                            archive={'all'}
                            value={accountValue}
                            className={styles.accountSelector}
                            classNamePrefix={"multiSelect"}
                            aria-label={intl.formatMessage({ id: 'Dashboard.Report.SelectAccountOwner.Label'})}
                            clientIds={[clientID]}
                            disabled={showViewReport}
                            onFetchAccountData={onFetchAccountData}
                        />
                    </Col>
                </Row>
                <Row className={styles.manuallRecipients}>
                    <Col sm={12}>
                        <p className={styles.label}>{TranslateMessage('Dashboard.Report.ManuallyAddRecipients.Label')}</p>
                        <InputGroup>
                            <Form.Control
                                id="createEmail"
                                type="text"
                                placeholder={intl.formatMessage({ id: 'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder'})}
                                disabled={showViewReport}
                                value={email}
                                onChange={onEmailChange}
                                className={styles.recipientInput}
                                aria-label={intl.formatMessage({ id: 'Dashboard.Report.ManuallyAddRecipients.Label'})}
                                aria-describedby="btnGroupAddon"
                            />
                            <InputGroup.Text id="btnGroupAddon" className={showViewReport ? styles.disableButton : styles.inputAddon}>
                                <button 
                                    aria-label={intl.formatMessage({id: 'MursionPortal.Add'})} 
                                    className={styles.addButton} 
                                    onClick={onAddEmail}/>
                            </InputGroup.Text>
                        </InputGroup>
                        <p className={styles.emailError}>{emailErrorMessage}</p>
                    </Col>
                </Row>
                </Col>

                <Col sm={isSendReport ? 8 : 4}>
                    <Row>
                        <Col sm={12}>
                            <p className={styles.recipientLabel}>{TranslateMessage('Dashboard.Report.RecipientsBox.Label')}</p>
                            {isMaximumEmailLimit && <p className={styles.emailError}>{TranslateMessage('Dashboard.Report.RecipientsBox.RecipientMaxLimit')}</p>} 
                            <div className={cn(styles.customScrollBarWrap, showViewReport && styles.disableRecipients)}>
                                <Row >
                                    {Array.isArray(recipients) && recipients.map((account, key) => {
                                        return (
                                            <Col key={"selected-learners" + key} sm={isSendReport ? 4 : 12}>
                                                <div tabIndex={0} className={styles.selectedValues}>
                                                    <span className={styles.textEllipses}>
                                                        {account.id}
                                                    </span>
                                                    {<button id={account.id} onClick={emailRemoveHandler} />}
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        </Col>
                    </Row>
            </Col>
            </Row>
        </Container>
    );
};

export default injectIntl(DashboardReportDropdownFilter);
