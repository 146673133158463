import * as React from 'react';
import { connect } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import { IAppState } from 'src/app/redux/store/reducers';
import EntityTable, { longValueFormatter } from 'src/components/EntityTable/EntityTable';
import IEntityHistory, { ISessionHistory } from 'src/app/data/common/interfaces/IEntityHistory';
import moment from 'moment-timezone';
import actions from 'src/app/redux/store/actions';
import IRestError from 'src/app/data/common/interfaces/IRestError';
import Pagination from 'src/components/Pagination/Pagination';
import { DEFAULT_TABLE_PAGE_DATA } from 'src/app/data/common/utils/getListPageDataFromUrl';
import { getRoleById } from 'src/app/data/common/roles';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { getSessionStatusTypeI18nId } from 'src/app/data/session/interfaces/SessionStatusType';

export interface ISessionsHistoryTableProps extends WrappedComponentProps {
  sessionId: string;
  tzId?: string;

  sessionHistory: Array<IEntityHistory<ISessionHistory>>;
  fetched: boolean;
  fetching: boolean;
  fetchError: IRestError | null;

  page: number;
  sizePerPage: number;
  totalSize: number;

  fetchEntityHistoryListBySession: (sessionId: string, page: number, size: number) => void;
}

class SessionsHistoryTable extends React.Component<ISessionsHistoryTableProps> {

  public componentDidMount(): void {
    this.refresh();
  }

  public render() {

    const { fetching, page, sizePerPage, totalSize } = this.props;

    const columns: any[] = [
      {
        dataField: 'date',
        style: { width: '20%' },
        text: TranslateMessage('Session.Edit.Modal.Table.Column.DateTime'),
        formatter: longValueFormatter(),
      },
      {
        dataField: 'author',
        style: { width: '20%' },
        text: TranslateMessage('Session.Edit.Modal.Table.Column.UserRole'),
        formatter: longValueFormatter(),
      },
      {
        dataField: 'action',
        style: { width: '30%' },
        text: TranslateMessage('Session.Edit.Modal.Table.Column.Change'),
      },
      {
        dataField: 'status',
        style: { width: '10%' },
        text: TranslateMessage('Session.Edit.Modal.Table.Column.SessionStatus'),
      },
      {
        dataField: 'reason',
        style: { width: '20%' },
        text: TranslateMessage('Session.Edit.Modal.Table.Column.Reason'),
      },
    ];
    const { intl } = this.props;
    return (
      <>
        <EntityTable
          columns={columns}
          entitiesInfo={{
            fetchError: this.props.fetchError,
            fetched: this.props.fetched,
            fetching: this.props.fetching,
            items: this.props.sessionHistory,
          }}
          entityIdExtractor={this.idExtractor}
          emptyMessage={intl.formatMessage({ id:'Session.Edit.Modal.Table.HistoryNotFoundMessage'})}
          entityToDataMapper={this.entityToDataMapper}
        />

        <Pagination
          disabled={fetching}
          dropUp={'up'}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          onChange={this.onChangePagination}
        />
      </>
    );
  }

  private idExtractor = (entity: IEntityHistory<ISessionHistory>) => (entity.id || '');
  private entityToDataMapper = (historyItem: IEntityHistory<ISessionHistory>) => {
    const tz = this.props.tzId || moment.tz.guess();
    const { date, author, object, action, reason } = historyItem;
    let rescheduleMessage = '';
    if(object?.newRescheduled){
      rescheduleMessage = ' (Session is rescheduled by ' + author?.firstName + ' ' + author?.lastName + ' on ' + moment(object.rescheduledDate).format("LLLL") + ')';
    }
    const status = object ? this.props.intl.formatMessage({ id: getSessionStatusTypeI18nId(object.status) }) : null;

    let hAuthor = 'System Matching';

    if (author) {
      const { firstName, lastName, roleId } = author;
      const authorName = firstName + ' ' + lastName.substring(0, 1).toUpperCase() + '.';
      const role = getRoleById(roleId);
      hAuthor = authorName + (role && ` (${role.shortName})`);
    }
    const hDate = this.props.intl.formatDate(date, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: tz
    });
    const hAction = action || '-';
    const hStatus = status || '-';
    const hReason = reason ? (reason + rescheduleMessage) : '-';

    return {
      id: historyItem.id,
      date: hDate,
      author: hAuthor,
      action: hAction,
      status: hStatus,
      reason: hReason,
    };
  }

  private onChangePagination = (page: number, size: number) => {
    this.props.fetchEntityHistoryListBySession(this.props.sessionId, page, size);
  }

  private refresh = () => {
    this.props.fetchEntityHistoryListBySession(this.props.sessionId, DEFAULT_TABLE_PAGE_DATA.page, DEFAULT_TABLE_PAGE_DATA.size);
  }
}

export default injectIntl(connect((state: IAppState) => ({
    sessionHistory: selectors.entityHistory.getListBySession(state),
    fetched: selectors.entityHistory.isListBySessionFetched(state),
    fetching: selectors.entityHistory.isListBySessionRefreshing(state),
    fetchError: selectors.entityHistory.getListBySessionRefreshError(state),

    page: selectors.entityHistory.getListBySessionPage(state),
    sizePerPage: selectors.entityHistory.getListBySessionSizePerPage(state),
    totalSize: selectors.entityHistory.getListBySessionTotalSize(state),
  }), {
    fetchEntityHistoryListBySession: actions.entityHistory.fetchEntityHistoryListBySession,
  }
)(SessionsHistoryTable));
