
import { DEFAULT_TABS_FILTERS_CONFIGURATIONS } from 'src/app/data/dashboardReport/interfaces/DefaultTabFiltersConfigurations';
import { IFilterStore } from 'src/app/data/dashboardReport/interfaces/IDashboardReport';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { getRegistrationStatusLabel, getBooleanLabel, getDeliveryModeLabel } from '../../utils/getFilterLabels';
import getScenarioVersionLabel from 'src/app/data/projects/utils/getScenarioVersionLabel';
import { SessionSummariesType } from 'src/app/data/dashboardReport/interfaces/ReportFiltersType';

function isEmpty(val: any){
    return (val === undefined || val == null || val.length <= 0);
}
const roleSort = (a1: string, a2: string) => {
    if ( a1 < a2 ) {
      return -1;
    }
    if (a1 > a2) {
      return 1;
    }
    return 0;
  };

function isObjectData(data: any){
    return !!(data && typeof data === 'object' && data.constructor === Object);
}

function handleObjectOrString(resultValue: any){
    if(isObjectData(resultValue)){
        return resultValue.hasOwnProperty("value") && Array.isArray(resultValue.value) ? resultValue.value.sort(roleSort).join(", ") : resultValue?.name || resultValue?.id;
    }
    if(typeof resultValue === 'string'){
        return resultValue;
    }
    if(Array.isArray(resultValue)){
        return resultValue.map((item) => isObjectData(item) ? item?.name || item?.id : item).sort(roleSort).join(", ");
    }
    return resultValue;
}

export const mappedScheduleFilters = (cacheValue: object, userRoleId: string, storageKey: string) => {

    const fetchName = (dataItem: IFilterStore): string => {
        if(!!storageKey && storageKey.includes(SessionSummariesType.SessionSummaries)){
            const { key, name } = dataItem;
            switch(key){
                case 'recorded':
                    return SessionSummariesType.SimulationRecorded;
                case 'simulationStatuses':
                    return SessionSummariesType.SessionStatus;
                default:
                    return name;
            }
        }
        return dataItem.name;
    };
    const updateObjectValue = (accessKey: string) => {
        const value = cacheValue[accessKey];
        if(!isEmpty(value)){
            switch(accessKey){
                case 'deliveryModes':
                case 'deliveryMode':
                    if(Array.isArray(value)){
                        return value.map((item: SessionType) => getDeliveryModeLabel(item)).sort(roleSort).join(", ");
                    }
                    return null;
                case 'standardized':
                case 'recorded': 
                case 'simulationRecorded':
                case 'billable': 
                case 'lateReschedule':
                    return getBooleanLabel(value);
                case 'simulationStatus':
                case 'simulationStatuses':
                    return value.sort(roleSort).join(", ");
                case 'registrationStatus':
                    return getRegistrationStatusLabel(value);
                case 'scenarioVersion':
                case 'softwareVersion':
                    return value.map((val: ScenarioVersion) => getScenarioVersionLabel(val)).sort(roleSort).join(", ");
                default: 
                    return handleObjectOrString(value);
            }
        }
        return value;
    };
    const fetchResponse = Object.keys(cacheValue).reduce((accumulator, key) => ({...accumulator, [key]: updateObjectValue(key)}), {});
    const getFilterData = DEFAULT_TABS_FILTERS_CONFIGURATIONS.filter((item: IFilterStore) => !!cacheValue[item.key] || cacheValue[item.key] === false);
    const fetchResult = getFilterData.map((dataValue: IFilterStore) => ({...dataValue, filters: fetchResponse[dataValue.key], name: fetchName(dataValue)}));
    return fetchResult.filter((dataVal) => {
        if(dataVal.hasOwnProperty("roles") && Array.isArray(dataVal.roles)){
            return dataVal.roles.includes(userRoleId);
        }
        return true;
    });
};
