import * as React from 'react';
import styles from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/components/ScenarioStepInfo/ScenarioStepInfo.css';
import { ShortScenarioInfo } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/components/index';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { useIntl } from 'react-intl';
import { getDescriptionText } from 'src/components/ScenarioOverview/DescriptionField/DescriptionField';
import { getPlainText } from 'src/components/TinyMceEditor/TinyMceEditor';
export interface IScenarioStepInfo {
  name: string;
  vignette: string | null;
  goals: string[];
  deliveryMode: SessionType;
  sessionLength: number;
}

interface IScenarioStepInfoProps {
  info: IScenarioStepInfo;
}

const ScenarioStepInfo = (props: IScenarioStepInfoProps) => {
  const { vignette, goals, deliveryMode, sessionLength } = props.info;
  const isStraetegiesEmpty = !goals.slice(1).some(goal => !!goal);
  const intl = useIntl();

  return (
    <div className={styles.infoContainer}>
      <div className={styles.shortInfo}>
        <ShortScenarioInfo deliveryMode={deliveryMode} sessionLength={sessionLength}/>
      </div>
      <label> {intl.formatMessage({ id: 'MursionPortal.Label.Description' })} </label>
      <div className={styles.scenarioStepText}> {getDescriptionText(vignette) || intl.formatMessage({ id: 'MursionPortal.Label.NoDescriptionProvided' })} </div>
      <label> {intl.formatMessage({ id: 'MursionPortal.Label.Outcome' })} </label>
      <div className={styles.scenarioStepText}> {getPlainText(goals[0]) || intl.formatMessage({ id: 'MursionPortal.Label.NoOutcomeProvided' })} </div>
      <label> {intl.formatMessage({ id: 'MursionPortal.Label.Strategies' })} </label>
      <div>
        {isStraetegiesEmpty ? intl.formatMessage({ id: 'MursionPortal.Label.NoStrategiesProvided' })
          : goals.slice(1).filter((goal: string) => goal.length).map((goal: string, i: number) => (
            <div key={'goal' + i} className={styles.goal}>
              <strong className={styles.goalIndex}> {i + 1} </strong>
              <div className={styles.scenarioStepText}>{goal}</div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default ScenarioStepInfo;
