import IClientConfig from 'src/app/data/clientConfig/interfaces/IClientConfig';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import ROUTE_PATHS from 'src/routing/paths';

export function getRoutesPath(clientConfig: IClientConfig | null, isUserOnboarded: boolean | undefined, companyConfig: ICompanyConfig | null) {
  if (companyConfig?.learnerOnboardingPage && clientConfig?.learnerOnboardingPage && !isUserOnboarded) {
    return { path: ROUTE_PATHS.ONBOARDING, isOnboardingTabEnable: true };
  } else {
    return { path: ROUTE_PATHS.DASHBOARD, isOnboardingTabEnable: companyConfig?.learnerOnboardingPage && clientConfig?.learnerOnboardingPage };
  }
}