import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import cn from "classnames";
import styles from "../../SimulationScheduling.css";
import { Checkbox } from "src/components/Checkbox";
import { SelectorTheme } from "src/components/Selector";
import TranslateMessage from "src/i18n/TranslateMessage";

const CODE_OF_CONDUCT_URL = "https://support.mursion.com/knowledge/mursions-code-of-conduct";

interface ISubmitSectionProps {
  onCodeOfConductChecked: (e: any) => void;
  codeOfConductSelected: boolean;
  enableSubmitButton: () => boolean;
  onSubmit: () => void;
}

const SubmitSection: FunctionComponent<any> = (props: ISubmitSectionProps) => {
  const { onCodeOfConductChecked, codeOfConductSelected, enableSubmitButton, onSubmit } = props;
  const intl = useIntl();
  return (
    <>
      <p className={styles.codeOfConductText}>
        <Checkbox
          className={cn(styles.checkbox)}
          themeType={SelectorTheme.Cobalt}
          checked={codeOfConductSelected}
          onChange={onCodeOfConductChecked}
        >
          {TranslateMessage("MursionPortal.CodeOfConduct.SchedulingFlow.Text", {
            code: (word: string) => (
              <a href={CODE_OF_CONDUCT_URL} target="_blank">
                {word}
              </a>
            ),
          })}
        </Checkbox>
      </p>
      <div className={styles.submitBtnWrap}>
        <button disabled={!enableSubmitButton()} onClick={onSubmit} className={styles.submitBtn}>
          {" "}
          {intl.formatMessage({ id: "MursionPortal.Submit" })}
        </button>
      </div>
    </>
  );
};

export default SubmitSection;
