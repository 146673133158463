import * as React from 'react';
import { useEffect, useState } from 'react';
import sessionWizardHooks, { useRawDataFetching }
  from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { ISessionWizardStepProps } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/SessionWizardList';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import { SessionWizardDropdownList, ScenarioStepInfo } from './components';
import actions from 'src/app/redux/store/actions';

interface ISessionWizardScenarioBankStepProps extends ISessionWizardStepProps {
  projectId: number | string;
  userProfile: IUserProfile;
}

const ScenarioBankStep = (props: ISessionWizardScenarioBankStepProps) => {
  const { selectedItemId, userProfile,} = props;
  const listProps = sessionWizardHooks.useActiveScenarioTemplates(userProfile.licenseeId || '', ScenarioVersion.V3)({ page: 0, size: 50 });

  const [active, setHookStatus] = useState(false);
  const { item: scenario } = useRawDataFetching(actions.scenarios.fetchScenarioTemplate)(selectedItemId)(active);
   useEffect(() => {
    if (!!selectedItemId) {
      setHookStatus(true);
    }
  }, [selectedItemId]);

  const info = scenario && {
    name: scenario.name,
    vignette: scenario.vignette,
    goals: scenario.goals,
    deliveryMode: scenario.deliveryMode,
    sessionLength: scenario.sessionLength,
  };

  return (
    <>
      <SessionWizardDropdownList {...props} {...listProps}/>
      {!!info && <ScenarioStepInfo info={info} />
      }
    </>
  );
};

export default ScenarioBankStep;
