import * as React from 'react';
import { useEffect, useRef } from 'react';
import cn from 'classnames';
import { getTimeFormat } from 'src/app/data/common/utils/dateUtil';
import styles from './SessionWizard.css';
import moment from 'moment-timezone';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import ITimeBlock from 'src/app/data/calendar/interfaces/ITimeBlock';
import TranslateMessage from 'src/i18n/TranslateMessage';

interface ISessionTimeStepProps {
  timeBlocks: ITimeBlock[];
  calendarTimezoneId: string;
  selectedTimeBlock: ITimeBlock | null;
  onValueChange: (data: any, names: any) => void;
}

const LIST_ITEM_HEIGHT = 30;

/**
 * TODO: remove in favor of TimeStep component
 */
const SessionTimeStep = (props: ISessionTimeStepProps) => {
  const { timeBlocks, onValueChange, selectedTimeBlock, calendarTimezoneId } = props;
  const listRef = useRef<HTMLElement>();
  const isDstChangeDate = timeBlocks.length > 2 && timeBlocks[0].start.isDST() !== timeBlocks[timeBlocks.length - 1].start.isDST();

  // auto-select time block if there is only one
  useEffect(() => {
    if (timeBlocks.length === 1 && !selectedTimeBlock) {
      onSelectTimeBlock(timeBlocks[0]);
    }
  }, [timeBlocks, selectedTimeBlock]);

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.scrollTop = selectedTimeBlock ? selectedTimeBlock.index * LIST_ITEM_HEIGHT : 0;
    }
  }, [JSON.stringify(timeBlocks.map(tb => ({ start: tb.start.valueOf(), end: tb.end.valueOf() })))]);

  const renderTimeBlock = (timeBlock: ITimeBlock, index: number) => {
    const isSelected = selectedTimeBlock
      && selectedTimeBlock.start.isSame(timeBlock.start)
      && selectedTimeBlock.end.isSame(timeBlock.end);

    const cls = cn([
      styles.timeBlock,
      isSelected && styles.selected,
    ]);

    let showOffsetInfo = false;
    if (isDstChangeDate && (timeBlock.start.isDST() !== timeBlock.start.clone().subtract(1, 'hour').isDST())
      || (timeBlock.start.isDST() !== timeBlock.start.clone().add(1, 'hour').isDST())
    ) {
      showOffsetInfo = true;
    }

    const name = formatTimeBlock(timeBlock, calendarTimezoneId, showOffsetInfo);

    return (
      <>
        <div className={cls} key={`session-time-block-${index}`} onClick={onSelectTimeBlock(timeBlock)}>
          <span className={styles.timeLabel}> {name} </span>
        </div>
      </>
    );
  };

  const onSelectTimeBlock = (timeBlock: ITimeBlock) => () => {
    const name = formatTimeBlock(timeBlock, calendarTimezoneId, false);

    onValueChange(timeBlock, name);
  };

  const formatTimeBlock = (timeBlock: ITimeBlock, tzId: string, showOffset: boolean) => {
    const tbStart = moment.tz(timeBlock.start, tzId || '');
    const tbEnd = moment.tz(timeBlock.end, tzId || '');
    const offsetStart = ` GMT(${tbStart.tz(tzId).format('Z')})`;
    const offsetEnd = ` GMT(${tbEnd.tz(tzId).format('Z')})`;

    return `${tbStart.format(getTimeFormat())} ${showOffset ? offsetStart : ''} - ${tbEnd.format(getTimeFormat())} ${showOffset ? offsetEnd : ''}`;
  };

  const Row = ({ index, style }: { index: number, style: any }) => {
    const item = timeBlocks[index];

    if (!item) {
      return null;
    }

    return (
      <div style={style}>
        {renderTimeBlock(item, index)}
      </div>
    );
  };

  return timeBlocks.length
    ? (
      <AutoSizer>
        {({ height, width }) => (
          <List
            outerRef={listRef}
            itemCount={timeBlocks.length}
            itemSize={LIST_ITEM_HEIGHT}
            height={height}
            width={width}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    )
    : <div className={styles.emptyTimeBlock}>{TranslateMessage('Calendar.Text.NoAvailableTimeBlocks')}</div>;
};

export default SessionTimeStep;
