import LoadChunk from 'src/app/services/LoadChunk/LoadChunk';

const Dashboard = LoadChunk(() => import('./Dashboard'));
const Downloads = LoadChunk(() => import('./Downloads'));
const LaunchSimulation = LoadChunk(() => import('src/layouts/common/LaunchSimulation/LaunchSimulation'));
const ArtDesignerPage = LoadChunk(() => import('./ArtDesignerPage'));
const ClientSurveyPage = LoadChunk(() => import('./ClientSurveyPage'));

const Tutorial = LoadChunk(() => import('./Tutorial'));
const NotFound = LoadChunk(() => import('./NotFound'));
const FillProfile = LoadChunk(() => import('./FillProfile'));

const OAuth = LoadChunk(() => import('./OAuth'));
const ChangePassword = LoadChunk(() => import('./ChangePassword'));
const SessionReportContainer = LoadChunk(() => import('src/layouts/common/Sessions/SessionsContainer'));
const JoinNow = LoadChunk(() => import('src/layouts/common/JoinNow'));
const LearnerOnboardingPage = LoadChunk(() => import('src/layouts/common/LearnerOnboarding/LearnerOnboarding'));
const EventTemplate = LoadChunk(() => import('src/layouts/mursion/ScenarioBankContainer/components/ItemBankEvent'));

const LearnerMaterials = LoadChunk(() => import('src/layouts/common/LearnerMaterials'));
const SimMaterials = LoadChunk(() => import('src/layouts/common/SIMMaterials/SIMMaterials'));
const Engagement = LoadChunk(() => import('src/layouts/common/Dashboard'));
const CommunicationKey = LoadChunk(() => import('src/layouts/common/Engagement/components/CommunicationKey/CommunicationKey'));
const NextGenLearnerSessionReportLayout = LoadChunk(() => import('src/layouts/mursion/NextGenLearnerSessionReport/NextGenLearnerSessionReportLayout'));
const InviteTeamLink = LoadChunk(() => import('src/layouts/common/InviteTeamLink/InviteTeamLink'));
const InviteToSchedule =  LoadChunk(() => import('src/layouts/common/Engagement/components/InviteToSchedule/InviteToSchedule'));
const CustomizeEmailSettings = LoadChunk(() => import('src/layouts/common/Engagement/components/EmailSettings/CustomizeEmailSettings'));
const PracticeSession = LoadChunk(() => import('src/layouts/common/PracticeSession/PracticeSession'));

export default {
  Dashboard,
  Downloads,
  FillProfile,
  NotFound,
  Tutorial,
  LaunchSimulation,
  OAuth,
  ArtDesignerPage,
  ClientSurveyPage,
  ChangePassword,
  SessionReportContainer,
  JoinNow,
  LearnerOnboardingPage,
  LearnerMaterials,
  EventTemplate,
  SimMaterials,
  Engagement,
  CommunicationKey,
  NextGenLearnerSessionReportLayout,
  InviteTeamLink,
  InviteToSchedule,
  CustomizeEmailSettings,
  PracticeSession,
};
