import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import actions from 'src/app/redux/store/actions';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import useDeepCompareEffect from 'use-deep-compare-effect';
import useDebounce from 'src/hooks/useDebounce';
import _isEqual from 'lodash/isEqual';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';


export interface IPagedDataHookResult<U> {
  items: U[];
  filter: string;
  setPagedDataCallback: (pagedData?: Partial<IListPageData>) => void;
  total: number;
  refreshing: boolean;
  error: any;
}

export interface IAggregatedPagedDataHookResult<U> extends IPagedDataHookResult<U> {
  loadMoreCallback: () => void;
  currentPage: number;
  hasMore: boolean;
}

export interface IPagedListDataHookResult<T, U> {
  items: U[];
  total: number;
  debouncing: boolean;
  refreshing: boolean;
  error: any;
  onRefresh: (...params: T[]) => void;
}

export interface IRawDataHookResult<T, U> {
  item: U;
  debouncing: boolean;
  refreshing: boolean;
  error: any;
  onRefresh: (...params: T[]) => void;
}

export interface IRawListDataHookResult<T, U> {
  items: U[];
  debouncing: boolean;
  refreshing: boolean;
  error: any;
  onRefresh: (...params: T[]) => void;
}

// TODO: add abort & debouncer remove after added all raws
export const usePagedDataFetching = <T extends any[], U extends any>(
  action: (pagedData: IListPageData, ...params: T) => Promise<IListDataResponse<U>>,
  compareFn: (itemA: U, itemB: U) => boolean = _isEqual,
) =>
  (...params: T) =>
    (defaultPagedData: IListPageData, isActive: boolean = true): IAggregatedPagedDataHookResult<U> => {
      const [items, setItems] = useState<U[]>([]);
      const [page, setPage] = useState<number>(0);
      const [hasMore, setHasMore] = useState<boolean>(false);
      const [total, setTotal] = useState<number>(0);
      const [refreshing, setRefreshing] = useState<boolean>(true);
      const [error, setError] = useState<any>(null);
      const [pagedData, setPagedData] = useState(defaultPagedData);

      const dispatch = useDispatch();

      const fetchItems = async (newPagedData: IListPageData, newPage: number) => {
        setRefreshing(true);

        try {
          const loadedItems = await dispatch(action({ ...newPagedData, page: newPage }, ...params)) as any;
          const newItems = loadedItems.content.filter((loadedItem: U) => !items.some((item: U) => compareFn(loadedItem, item)));

          setItems([...items, ...newItems]);
          setHasMore(!loadedItems.last);
          setTotal(loadedItems.totalElements);

        } catch (e) {
          setError(e);
        }
        setRefreshing(false);
      };

      useEffect(() => {
        if (isActive) {
          fetchItems(pagedData, page);
        }
      }, [isActive]);

      // on load more callback
      const loadMoreCallback = useCallback(
        () => {
          const newPage = page + 1;

          setPage(newPage);

          fetchItems(pagedData, newPage);
        },
        [dispatch, pagedData, page, items]
      );

      // on change filter callback
      const setPagedDataCallback = useCallback(
        (newPagedData) => {
          setItems([]);
          setPagedData({ ...pagedData, ...newPagedData });
          setPage(0);

          fetchItems({ ...pagedData, ...newPagedData }, 0);
        },
        [dispatch, pagedData]
      );

      return {
        items,
        loadMoreCallback,
        setPagedDataCallback,
        filter: pagedData.filter || '',
        currentPage: page,
        hasMore,
        total,
        refreshing,
        error,
      };
    };

export const usePagedListFetching = <T extends any, U extends any>(
  action: (pageData: IListPageData, ...defaultParams: T[]) => Promise<IListDataResponse<U>>,
) =>
  (pageData: IListPageData, ...defaultParams: T[]) =>
    (isActive: boolean = true, debounceDelay: number = 300): IPagedListDataHookResult<T, U> => {
      const [items, setItems] = useState<U[]>([]);
      const [refreshing, setRefreshing] = useState<boolean>(false);
      const [error, setError] = useState<any>(null);
      const [total, setTotal] = useState<number>(0);
      const dispatch = useDispatch();
      let controller = new AbortController();
      const [tempParams, setTempParams] = useState<any[]>([pageData, ...defaultParams]);
      const { value: debounceParams, debouncing } = useDebounce(tempParams, debounceDelay);

      const fetchItems = async () => {
        if (refreshing) {
          controller.abort();
          controller = new AbortController();
        }
        setRefreshing(true);
        try {
          const loadedItems = await dispatch(action(tempParams[0], ...tempParams.slice(1), controller.signal as any)) as any;
          setItems(loadedItems.content || []);
          setTotal(loadedItems.totalElements);
        } catch (e) {
          setError(e);
        }
        setRefreshing(false);
      };

      useEffect(() => {
        return () => controller.abort();
      }, []);

      useDeepCompareEffect(() => {
        if (isActive) {
          if (_isEqual(tempParams, debounceParams)) {
            fetchItems();
          }
        }
      }, [debounceParams, isActive, action]);

      useDeepCompareEffect(() => {
        setTempParams([pageData, ...defaultParams]);
      }, [pageData, defaultParams]);

      // on change filter callback
      const onRefresh = useCallback(
        () => {
          fetchItems();
        },
        [debounceParams]
      );

      return {
        items,
        total,
        debouncing,
        refreshing,
        error,
        onRefresh,
      };
    };

export const useRawListDataFetching = <T extends any, U extends any>(
  action: (...defaultParams: T[]) => Promise<U[]>
) =>
  (...defaultParams: T[]) =>
    (isActive: boolean = true, debounceDelay: number = 300): IRawListDataHookResult<T, U> => {
      const [items, setItems] = useState<U[]>([]);
      const [refreshing, setRefreshing] = useState<boolean>(false);
      const [error, setError] = useState<any>(null);
      const dispatch = useDispatch();
      let controller = new AbortController();
      const [tempParams, setTempParams] = useState<T[]>(defaultParams);
      const { value: debounceParams, debouncing } = useDebounce(tempParams, debounceDelay);

      const fetchItems = async () => {
        if (refreshing) {
          controller.abort();
          controller = new AbortController();
        }
        setRefreshing(true);
        try {
          const loadedItems = await dispatch(action(...tempParams, controller.signal as any)) as any;
          setItems(loadedItems);
        } catch (e) {
          setError(e);
        }
        setRefreshing(false);
      };

      useEffect(() => {
        return () => controller.abort();
      }, []);

      useDeepCompareEffect(() => {
        if (isActive) {
          if (_isEqual(tempParams, debounceParams)) {
            fetchItems();
          }
        }
      }, [debounceParams, isActive]);

      useDeepCompareEffect(() => {
        setTempParams(defaultParams);
      }, [defaultParams]);

      // on change filter callback
      const onRefresh = useCallback(
        () => {
          fetchItems();
        },
        [debounceParams]
      );

      return {
        items,
        debouncing,
        refreshing,
        error,
        onRefresh,
      };
    };

export const useRawDataFetching = <T, U extends any>(
  action: (...defaultParams: T[]) => Promise<U>
) =>
  (...params: T[]) =>
    (isActive: boolean = true, debounceDelay: number = 300): IRawDataHookResult<T, U | null> => {
      const [item, setItem] = useState<U | null>(null);
      const [refreshing, setRefreshing] = useState<boolean>(false);
      const [error, setError] = useState<any>(null);
      const dispatch = useDispatch();
      let controller = new AbortController();
      const { value: debounceParams, debouncing } = useDebounce(params, debounceDelay);

      const fetchItem = async () => {
        if (refreshing) {
          controller.abort();
          controller = new AbortController();
        }
        setRefreshing(true);
        try {
          const loadedItem = await dispatch(action(...debounceParams, controller.signal as any)) as any;
          setItem(loadedItem);
        } catch (e) {
          setError(e);
        }
        setRefreshing(false);
      };

      useEffect(() => {
        return () => controller.abort();
      }, []);

      const paramsReady = useMemo(() =>
          _isEqual(debounceParams, params) && isActive,
        [debounceParams, params, isActive]
      );

      useEffect(() => {
        if (paramsReady) {
          fetchItem();
        }
      }, [paramsReady]);

      // on change filter callback
      const onRefresh = useCallback(fetchItem, [debounceParams]);

      return {
        item,
        debouncing,
        refreshing,
        error,
        onRefresh,
      };
    };

// TODO: convert all requests to requests with options ================================================
export const useAggregatedPagedDataFetchingWithOptions = <T, U extends { id?: any }>(
  action: (params: IPageRequestParams & T) => Promise<IListDataResponse<U>>,
  compareFn: (itemA: U, itemB: U) => boolean = _isEqual,
) =>
  (params?: Omit<IPageRequestParams & T, keyof IPageRequestParams>) =>
    (defaultPagedData: IListPageData, isActive: boolean = true): IAggregatedPagedDataHookResult<U> => {
      const [items, setItems] = useState<U[]>([]);
      const [page, setPage] = useState<number>(0);
      const [hasMore, setHasMore] = useState<boolean>(false);
      const [total, setTotal] = useState<number>(0);
      const [refreshing, setRefreshing] = useState<boolean>(true);
      const [error, setError] = useState<any>(null);
      const [pagedData, setPagedData] = useState(defaultPagedData);
      let controller = new AbortController();

      const dispatch = useDispatch();

      const fetchItems = async (newPagedData: IListPageData, newPage: number) => {
        if (refreshing) {
          controller.abort();
          controller = new AbortController();
        }

        setRefreshing(true);

        // TODO: figure out how to fix any
        const actionParams: any = {
          ...params,
          pageData: { ...newPagedData, page: newPage },
          signal: controller.signal
        };

        try {
          const loadedItems = await dispatch(
            action(actionParams)
          ) as any;
          const newItems = loadedItems.content.filter((loadedItem: U) => !items.some((item: U) => compareFn(loadedItem, item)));

          setItems([...items, ...newItems]);
          setHasMore(!loadedItems.last);
          setTotal(loadedItems.totalElements);

        } catch (e) {
          setError(e);
        }
        setRefreshing(false);
      };

      useEffect(() => {
        return () => controller.abort();
      }, []);

      useEffect(() => {
        if (isActive) {
          fetchItems(pagedData, page);
        }
      }, [isActive]);

      // on load more callback
      const loadMoreCallback = useCallback(
        () => {
          const newPage = page + 1;

          setPage(newPage);

          fetchItems(pagedData, newPage);
        },
        [dispatch, pagedData, page, items]
      );

      // on change filter callback
      const setPagedDataCallback = useCallback(
        (newPagedData) => {
          setItems([]);
          setPagedData({ ...pagedData, ...newPagedData });
          setPage(0);

          fetchItems({ ...pagedData, ...newPagedData }, 0);
        },
        [dispatch, pagedData]
      );

      return {
        items,
        loadMoreCallback,
        setPagedDataCallback,
        filter: pagedData.filter || '',
        currentPage: page,
        hasMore,
        total,
        refreshing,
        error,
      };
    };


export const usePagedListFetchingWithOptions = <T, U extends any>(
  action: (params: IPageRequestParams & T) => Promise<IListDataResponse<U>>,
) =>
  (params?: Omit<IPageRequestParams & T, keyof IPageRequestParams>) =>
    (defaultPagedData: IListPageData, debounceDelay: number = 300, isActive: boolean = true): IPagedDataHookResult<U> => {
      const [items, setItems] = useState<U[]>([]);
      const [total, setTotal] = useState<number>(0);
      const [refreshing, setRefreshing] = useState<boolean>(true);
      const [error, setError] = useState<any>(null);
      let controller = new AbortController();
      const searchParams = params || {};
      const { value: debounceParams } = useDebounce({ ...defaultPagedData, ...searchParams }, debounceDelay);

      const dispatch = useDispatch();

      const fetchItems = async () => {
        if (refreshing) {
          controller.abort();
          controller = new AbortController();
        }

        setRefreshing(true);

        // TODO: figure out how to fix any
        const actionParams: any = {
          ...debounceParams,
          pageData: defaultPagedData,
          signal: controller.signal
        };

        try {
          const loadedItems = await dispatch(action(actionParams)) as any;
          setItems(loadedItems.content);
          setTotal(loadedItems.totalElements);
        } catch (e) {
          setError(e);
        }
        setRefreshing(false);
      };

      const paramsReady = useMemo(() =>
          _isEqual(debounceParams, { ...defaultPagedData, ...searchParams }) && isActive,
        [debounceParams, defaultPagedData, searchParams, isActive]
      );

      useEffect(() => {
        return () => controller.abort();
      }, []);

      useEffect(() => {
        // setting default state for refreshing when paramsReady update and
        // refreshing value is updated in fetchItems based on condition.
        setRefreshing(true);
        if (paramsReady) {
          fetchItems();
        }
      }, [paramsReady]);

      // on change filter callback
      const setPagedDataCallback = useCallback(fetchItems, [dispatch, debounceParams, defaultPagedData, controller]);

      return {
        items,
        setPagedDataCallback,
        filter: defaultPagedData.filter || '',
        total,
        refreshing,
        error,
      };
    };

export const useRawListDataFetchingWithOptions =
  <T extends any, U extends any>(action: (defaultParams: IAbortableRequestParams & T) => Promise<U[]>) =>
    (defaultParams?: Omit<IAbortableRequestParams & T, keyof IPageRequestParams>) =>
      (isActive: boolean = true, debounceDelay: number = 300): IRawListDataHookResult<T, U> => {
        const [items, setItems] = useState<U[]>([]);
        const [refreshing, setRefreshing] = useState<boolean>(false);
        const [error, setError] = useState<any>(null);
        const dispatch = useDispatch();
        let controller = new AbortController();
        const [tempParams, setTempParams] = useState(defaultParams as any);
        const { value: debounceParams, debouncing } = useDebounce(tempParams, debounceDelay);

        const fetchItems = async () => {
          if (refreshing) {
            controller.abort();
            controller = new AbortController();
          }
          setRefreshing(true);
          try {
            const loadedItems = await dispatch(action({ ...tempParams, signal: controller.signal })) as any;
            setItems(loadedItems);
          } catch (e) {
            setError(e);
          }
          setRefreshing(false);
        };

        useEffect(() => {
          return () => controller.abort();
        }, []);

        useEffect(() => {
          if (isActive) {
            if (_isEqual(tempParams, debounceParams)) {
              fetchItems();
            }
          }
        }, [JSON.stringify(debounceParams), isActive]);

        useEffect(() => {
          setTempParams(defaultParams);
        }, [JSON.stringify(debounceParams)]);

        // on change filter callback
        const onRefresh = useCallback(
          () => {
            fetchItems();
          },
          [debounceParams]
        );

        return {
          items,
          debouncing,
          refreshing,
          error,
          onRefresh,
        };
      };

export const useRawDataFetchingWithOptions = <T extends any, U extends any>(
  action: (defaultParams: IAbortableRequestParams & T) => Promise<U>
) =>
  (defaultParams?: Omit<IAbortableRequestParams & T, keyof IPageRequestParams>) =>
    (isActive: boolean = true, debounceDelay: number = 300): IRawDataHookResult<T, U | null> => {
      const [item, setItem] = useState<U | null>(null);
      const [refreshing, setRefreshing] = useState<boolean>(false);
      const [error, setError] = useState<any>(null);
      const dispatch = useDispatch();
      let controller = new AbortController();
      const [tempParams, setTempParams] = useState(defaultParams as any);
      const { value: debounceParams, debouncing } = useDebounce(tempParams, debounceDelay);

      const fetchItem = async () => {
        if (refreshing) {
          controller.abort();
          controller = new AbortController();
        }
        setRefreshing(true);
        try {
          const loadedItem = await dispatch(action({ ...tempParams, signal: controller.signal })) as any;
          setItem(loadedItem);
        } catch (e) {
          setError(e);
        }
        setRefreshing(false);
      };

      useEffect(() => {
        return () => controller.abort();
      }, []);

      useDeepCompareEffect(() => {
        if (isActive) {
          if (_isEqual(tempParams, debounceParams)) {
            fetchItem();
          }
        }
      }, [debounceParams, isActive]);

      useDeepCompareEffect(() => {
        setTempParams(defaultParams);
      }, [defaultParams]);

      // on change filter callback
      const onRefresh = useCallback(
        () => {
          fetchItem();
        },
        [debounceParams]
      );

      return {
        item,
        debouncing,
        refreshing,
        error,
        onRefresh,
      };
    };

export default {
  useActiveClients:
    usePagedDataFetching(
      actions.sessionWizard.fetchClientsWithActiveScenarios,
    ),
  useActiveProjects:
    usePagedDataFetching(
      actions.sessionWizard.fetchProjectsWithActiveScenarios,
    ),
  useActiveScenarios:
    usePagedDataFetching(
      actions.sessionWizard.fetchSessionWizardActiveScenarios,
    ),
  useActiveSimSpecialistsForScenario:
    usePagedDataFetching(
      actions.sessionWizard.fetchSimSpecsByScenarioId,
    ),
  useActiveSimSpecialistsForScenarioTemplate:
    usePagedDataFetching(
      actions.sessionWizard.fetchSimSpecsByScenarioTemplateId,
    ),
  useActiveLearners:
    usePagedDataFetching(
      actions.sessionWizard.fetchAvailableLearners,
    ),
  useActiveSsLearners:
    usePagedDataFetching(
      actions.sessionWizard.fetchAvailableSsLearners,
    ),
  useScenarioAvailableTimeBlocks:
    useRawDataFetching(
      actions.sessionWizard.fetchScenarioAvailableTimeBlocks,
    ),
    usePracticeScenarioAvailableTimeBlocks:
    useRawDataFetching(
      actions.practiceSession.fetchPracticeScenarioAvailableTimeBlocks,
    ),
  useScenarioTemplateAvailableTimeBlocks:
    useRawDataFetching(
      actions.sessionWizard.fetchScenarioTemplateAvailableTimeBlocks,
    ),
  useCalendarViewData:
    useRawDataFetching(
      actions.calendarEvents.fetchCalendarViewData,
    ),
  useSession:
    useRawDataFetching(
      actions.session.fetchSession,
    ),
  useTrainingSession:
    useRawDataFetching(
      actions.session.fetchTrainingSession,
    ),
  useCalendarEvents:
    useRawListDataFetching(
      actions.calendarEvents.fetchEvents,
    ),
  useCalendarEventsWithFilters:
    useRawListDataFetching(
      actions.calendarEvents.fetchEventsWithFilters,
    ),
  useActiveScenarioTemplates:
    usePagedDataFetching(
      actions.sessionWizard.fetchActiveScenarioTemplates,
    ),
};
