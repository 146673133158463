import React, { useEffect, useRef, useState } from 'react';
import styles from 'src/layouts/common/EULA/EULA.css';
import Button, { ButtonSize, ButtonType } from 'src/components/Button';
import logo from 'src/layouts/common/NavigationBar/images/mursion-navbar-logo.png';
import { connect, useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import actions from 'src/app/redux/store/actions';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import { IUserProfile } from 'src/app/data/profile/interfaces';
import cn from 'classnames';
import LocaleDropdown from 'src/components/LocaleDropdown';
import { createIntl, createIntlCache } from 'react-intl';
import dropdownStyles from 'src/layouts/common/FillProfile/FillProfile.css';
import languages from 'src/i18n/languages';
import { LOCALES } from 'src/i18n/locales';

const intlWithLocale = (lang: string) => {
  let localeLang = lang;
  const supportedLanguages = Object.values(LOCALES);
  const index = supportedLanguages.indexOf(localeLang);
  if (index > -1) {
    localeLang = supportedLanguages[index];
  } else {
    localeLang = supportedLanguages[0];
  }
  const cache = createIntlCache();
  return createIntl({ locale: localeLang, messages: languages[localeLang], }, cache);
};

export interface IEulaOption {
  id: number;
  name: string;
}

export interface IEULAProps {
  disabled?: boolean;
  onCancel: () => void;
  onApply: () => void;
  fetchEulaContent: (userProfile: IUserProfile | null, language: string) => Promise<string>;
}

export const EULA = (props: IEULAProps) => {
  const { onCancel, onApply, disabled, fetchEulaContent } = props;
  const userProfile = useSelector(selectors.profile.getUserProfile);
  const userLang = useSelector(selectors.profile.getUserLanguage);

  const [isCodeOfConductChecked, setCodeOfConductChecked] = useState<boolean>(false);
  const [eulaContent, setEulaContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const eulaContainerRef = useRef<HTMLInputElement>(null);
  const isUserLangEnUS = !!userLang.length ? userLang === 'en-US': true;
  const defaultUserLang = 'en-US';
  const [selectedLanguage, setSelectedLanguage] = useState<string>(defaultUserLang);
  
  useEffect(() => {
    fetchEulaContentDetails();
  }, [userProfile]);

  const fetchEulaContentDetails = () => {
    getEulaContent(defaultUserLang);
  };

  const getEulaContent = async (language: string) => {
    try {
      setLoading(true);
      setSelectedLanguage(language);
      const eulaContentResponse = await fetchEulaContent(userProfile, language);
      setEulaContent(eulaContentResponse);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleInputChange = (event: Event) => {
      toggleCodeOfConduct(event);
    };

    const checkBoxInputElement = eulaContainerRef.current?.querySelector('input[type="checkbox"]');
    if (checkBoxInputElement) {
      setCodeOfConductChecked(false);
      // Attach event listener to element for capturing change event
      checkBoxInputElement?.addEventListener('change', handleInputChange);
    } else {
      setCodeOfConductChecked(true);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      checkBoxInputElement?.removeEventListener('change', handleInputChange);
    };
  }, [eulaContent]);

  const toggleCodeOfConduct = (event: any) => {
    setCodeOfConductChecked(event.target.checked);
  };

  const onLanguageChange = (language: string) => {
    getEulaContent(language);
  };

  const renderLanguage = () => {
    return (
      <div className={cn(styles.languageDropdown, dropdownStyles.profileEditorWrap)}>
        <div className={styles.languageDropdownLabel}>{`${intlWithLocale(selectedLanguage).formatMessage({ id: 'MyProfile.Label.Language' })}:`}</div>
        <div className={styles.languageDropdownWrap}>
          <LocaleDropdown
            onChange={onLanguageChange}
            ariaLabel={intlWithLocale(selectedLanguage).formatMessage({ id: 'MyProfile.Label.Language' })}
            defaultValue={defaultUserLang}
          />
        </div>
      </div>
    );
  };

  return (
    <LoadingOverlay active={loading} spinner={true}>
      <div className={styles.header}>
        <img src={logo} alt={''}/>
      </div>
      <div className={cn(styles.eulaContainer, isUserLangEnUS && styles.withLink)}>
        {isUserLangEnUS ? renderLanguage() : null}
        <div
          className={styles.agreementList}
          ref={eulaContainerRef}
          dangerouslySetInnerHTML={{ __html: eulaContent }}
          tabIndex={0}
        />
        {
          !disabled && (
            <div className={styles.navPanel}>
              <Button
                btnSize={ButtonSize.NORMAL}
                btnType={ButtonType.LIGHT_BLUE}
                onClick={onCancel}
                className={styles.cancelBtn}
              >
                {intlWithLocale(selectedLanguage).formatMessage({ id: 'MursionPortal.Button.Cancel' })}
              </Button>
              <Button
                className={styles.acceptBtn}
                btnSize={ButtonSize.NORMAL}
                btnType={ButtonType.BLUE}
                onClick={onApply}
                disabled={!isCodeOfConductChecked}
              >
                {intlWithLocale(selectedLanguage).formatMessage({ id: 'MursionPortal.Button.Accept' })}
              </Button>
            </div>
          )
        }

      </div>
    </LoadingOverlay>
  );
};

export default connect(() => ({}), {
  fetchEulaContent: actions.clients.fetchEulaContent,
})(EULA);
