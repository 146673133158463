import * as React from 'react';
import { FormControl, FormControlProps } from 'react-bootstrap';
import { connect } from 'react-redux';
import IEnvironment from 'src/app/data/client/interfaces/IEnvironment';
import selectors from 'src/app/redux/selectors';
import actions from 'src/app/redux/store/actions';
import { IAppState } from 'src/app/redux/store/reducers';
import styles from '../IScenarioEditFormStyles.css';
import cn from 'classnames';
import LazyImage from 'src/components/LazyImage';
import IRestError from 'src/app/data/common/interfaces/IRestError';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import getLabelForOption from 'src/app/data/common/utils/getLabelForOption';

export interface IEnvironmentFieldProps extends WrappedComponentProps {
  industryId: string;
  environmentId: string;
  viewMode?: boolean;
  onChange: (environmentId: string, needsRefresh?: boolean) => void
;
  currentEnvironment: IEnvironment;
  environments: IEnvironment[];
  fetched: boolean;
  fetching: boolean;
  fetchError: IRestError;
  fetchEnvironmentById: (environmentId: string) => void;
  fetchEnvironmentsListByIndustry: (industryId: string) => void;
}

/**
 * check if environment is set
 *    if it is set - preload others and select it
 *    if it is NOT set
 *       - show message that an industry should be set
 *       - wait until user selects industry
 *       - preload environments by industry and select the first one
 */

class EnvironmentField extends React.Component<IEnvironmentFieldProps> {

  public componentDidMount() {
    if (this.props.viewMode && this.props.environmentId) {
      this.props.fetchEnvironmentById(this.props.environmentId);
      return;
    }

    if (this.props.industryId) {
      return this.props.fetchEnvironmentsListByIndustry(this.props.industryId);
    }
  }

  public componentDidUpdate(prevProps: IEnvironmentFieldProps) {
    const setEditMode = prevProps.viewMode && !this.props.viewMode;
    const setViewMode = !prevProps.viewMode && this.props.viewMode;
    const changedEnvironmentId = this.props.environmentId && prevProps.environmentId !== this.props.environmentId;

    const oldIndustryId = prevProps.industryId;
    const newIndustryId = this.props.industryId;

    if (setEditMode || newIndustryId && oldIndustryId !== newIndustryId) {
      this.props.fetchEnvironmentsListByIndustry(newIndustryId);
    }

    if (setViewMode && this.props.environmentId || changedEnvironmentId) {
      this.props.fetchEnvironmentById(this.props.environmentId);
    }

  }

  public render() {
    const {
      industryId,
      environmentId,
      viewMode,
      currentEnvironment,
      environments,
      fetching,
      intl
    } = this.props;

    let message = '';

    if (!industryId) {
      message = intl.formatMessage({ id: 'Projects.Message.SelectIndustryFirst' });
    } else if (fetching) {
      message = intl.formatMessage({ id: 'Projects.Message.LoadingEnvironmentsList' });
    } else if (!environments.length) {
      message = intl.formatMessage({ id: 'Projects.Message.NoAssignedEnvironments' });
    }

    const className = cn({
      [styles.formGroup]: true,
    });
    const props = {
      as: 'select' as React.ElementType,
      id: 'scenario-environment',
      onChange: this.onChange,
      disabled: viewMode,
      value: environmentId,
    };

    const picture = currentEnvironment && currentEnvironment.picture;
    const environmentName = currentEnvironment && currentEnvironment.name || intl.formatMessage({ id: 'Projects.Message.ScenarioHasNoAssignedEnvironment' });
    const isDisabled = viewMode
      || !industryId
      || fetching
      || !environments.length;

    return (
      <div className={className}>
        {
          viewMode && currentEnvironment &&
          <>
            <div className={styles.environmentName}>
              <i className={cn('fas', 'fa-image')}/> {environmentName}
            </div>
            {
              picture &&
              <div className={styles.imageContainer}>
                <LazyImage
                  src={picture}
                  alt={intl.formatMessage({ id: 'Projects.AltText.EnvironmentImage' })}
                />
              </div>
            }
          </>
        }
        {
          !viewMode &&
          <>
            {
              message
                ? <div className={styles.message}>{message}</div>
                : (
                  <FormControl {...props as FormControlProps} disabled={isDisabled}>
                    {this.getEnvironmentOptions()}
                  </FormControl>
                )
            }
          </>
        }
      </div>
    );
  }

  private onChange = (event: any) => {
    const environmentId = event.target.value || '';

    this.props.onChange(environmentId, true);
  }

  private getEnvironmentOptions = () => {
    const { intl } = this.props;
    const options: any[] = [
      <option key={`environment-empty`} value=""
              disabled={true}>{intl.formatMessage({ id: 'Projects.Placeholder.SelectAnEnviroment' })}</option>,
    ];

    const items = this.props.environments
      .map((env: IEnvironment) => (
        <option
          key={`environment-${env.id}`}
          title={env.name ? env.name : ''}
          value={env.id || ''}
        >
          {env.name ? getLabelForOption(env.name) : ''}
        </option>
      ));

    return options.concat(items);
  }
}

export default injectIntl(connect(
  (state: IAppState) => ({
    currentEnvironment: selectors.environments.getCurrentEnvironent(state),
    environments: selectors.environments.getListByIndustry(state),
    fetched: selectors.environments.isListByIndustryFetched(state),
    fetching: selectors.environments.isRefreshing(state),
    fetchError: selectors.environments.getListByIndustryRefreshError(state),
  }),
  {
    fetchEnvironmentById: actions.environments.fetchEnvironmentById,
    fetchEnvironmentsListByIndustry: actions.environments.fetchEnvironmentsListByIndustry,
  },
)(EnvironmentField));
