import React, { FunctionComponent, useRef, useEffect } from "react";
import styles from 'src/layouts/common/Dashboard/components/styles/Dashboard.css';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Image } from 'react-bootstrap';

interface IDashboardHeaderSearch {
    search: string | undefined;
    onSearchChange: (val: string) => void;
    isHeaderSearchEnabled?: boolean;
    placeholder?: string;
}

const DashboardHeaderSearch: FunctionComponent<IDashboardHeaderSearch> = ({ 
    search,
    onSearchChange,
    isHeaderSearchEnabled = true,
    placeholder,
}) => {
    const intl = useIntl();
    const onSearchClear = () => onSearchChange('');
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if(inputRef && inputRef.current && inputRef.current.value){
            inputRef.current.value = search ? search : '';
        }
    }, [search]);

    return (
        <div className={styles.searchContainer}>
            {isHeaderSearchEnabled && (
                <>
                    <input
                        className={cn(styles.searchInput, search && styles.searchActive)}
                        ref={inputRef}
                        onChange={onSearchInputChange}
                        aria-label={intl.formatMessage({ id: 'MursionPortal.AriaLabel.SearchInput' })}
                        placeholder={placeholder || intl.formatMessage({ id: 'MursionPortal.Search' })}
                    />
                    {search ? (
                    <span className={styles.clearIcon} onClick={onSearchClear}>
                        <i className={'fa fa-times-circle'} />
                    </span>
                    ) : (
                        <Image className={styles.searchIcon} src={require('src/images/search-icon.svg')} alt={intl.formatMessage({ id: 'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder' })}/>
                    )}  
                </>
            )}
    </div>
    );
};

export default DashboardHeaderSearch;