import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { DASHBOARD_CHILD_PAGE, DASHBOARD_PAGE } from 'src/layouts/common/Dashboard';
import { clientExtraDataInitValue } from 'src/layouts/common/Dashboard/components/layout';
import { useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import { LEARNER_PAGE } from 'src/layouts/common/LearnerOnboarding/components/useActiveSectionOnScroll';

export function getDashboardFilterCache(storageKey: string): { [k: string]: any | null } {
  const json = localStorage.getItem(storageKey);
  return json ? JSON.parse(json) : {};
}

export function setDashboardFilterCache(storageKey: string, value: object): void {
  localStorage.setItem(storageKey, JSON.stringify(value));
}

export const useCachingFilter = (
  id: DASHBOARD_CHILD_PAGE | DASHBOARD_PAGE | LEARNER_PAGE,
  states: Array<[string, any, Dispatch<SetStateAction<any | undefined>>]>
) => {
  const userProfileId = useSelector(selectors.profile.getUserId) || 'UndefinedId';
  const userRole = useSelector(selectors.profile.getCurrentUserRole)?.name || 'UndefinedRole';

  const storageKey = useMemo(() => `Filter[${id}][${userRole}][${userProfileId}]`, [userProfileId, userRole, id]);
  useEffect(() => {
    const cacheValue = getDashboardFilterCache(storageKey);
    states.forEach(([name, , setState]) => {
      const defaultValue = (name === 'clientExtraData') ? clientExtraDataInitValue : null;
      let extractedCacheValue;
      if(cacheValue[name] === null || typeof cacheValue[name] === 'undefined') {
        extractedCacheValue = defaultValue;
      }
      else {
        extractedCacheValue = cacheValue[name]; 
      }
      // don't commit null date range
      if (name.toLowerCase().includes('date') && !extractedCacheValue) {
        return;
      }
      setState(extractedCacheValue);
    });
  }, []);

  useEffect(() => {
    const cacheValue = states.reduce((prev, [name, state]) => {
      return { ...prev, [name]: state };
    }, {});
    setDashboardFilterCache(storageKey, cacheValue);
  }, [states]);
};

export function removeItemsCache(storageKey: string): void {
  localStorage.removeItem(storageKey);
}