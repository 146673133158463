import * as React from "react";
import { FunctionComponent } from "react";
import styles from "./WarningModal.css";
import { useIntl } from "react-intl";
import A11yModal from "src/components/A11yModal";
import Button, { ButtonType } from "src/components/Button";
import { Modal } from "react-bootstrap";

export interface IWarningModalProps {
  isUnschedulable: boolean;
  isSelectedSlotUnavailable: boolean;
  onClose: () => void;
}

const WarningModal: FunctionComponent<IWarningModalProps> = (props) => {
  const { isUnschedulable, isSelectedSlotUnavailable, onClose } = props;
  const intl = useIntl();

  return (
    <A11yModal
      show={isUnschedulable || isSelectedSlotUnavailable}
      size={"sm"}
      onHide={onClose}
      bodyOpenClassName={styles.unscheduleModalWrap}
    >
      <Modal.Header className={styles.modalHeader} closeButton={true}>
        <h4>
          <strong>{intl.formatMessage({ id: "MursionPortal.Modal.Header.Sorry" })}</strong>
        </h4>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <p>
          {isUnschedulable
            ? intl.formatMessage({ id: "MursionPortal.Modal.UnschedulableSessionMsg" })
            : intl.formatMessage({ id: "MursionPortal.Modal.UnavailableSlotMsg" })}
        </p>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button btnType={ButtonType.BLUE} onClick={onClose}>
          {isUnschedulable
            ? intl.formatMessage({ id: "MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard" })
            : intl.formatMessage({ id: "MursionPortal.Button.Exclamation.GotIt" })}
        </Button>
      </Modal.Footer>
    </A11yModal>
  );
};

export default WarningModal;
