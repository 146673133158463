exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SsoLink__modal___uLSqr{top:30%}.SsoLink__title___G2-9i{margin-bottom:5px;font-size:1.2em;padding:5px;text-align:center}.SsoLink__modal___uLSqr .modal-dialog{max-width:500px}.SsoLink__modal___uLSqr .modal-body{text-align:center}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/layouts/common/SsoLink/SsoLink.css"],"names":[],"mappings":"AAAA,wBACE,OAAS,CACV,AAED,wBACE,kBAAmB,AACnB,gBAAiB,AACjB,YAAa,AACb,iBAAmB,CACpB,AAED,sCACE,eAAiB,CAClB,AAED,oCACE,iBAAmB,CACpB","file":"SsoLink.css","sourcesContent":[".modal {\n  top: 30%;\n}\n\n.title {\n  margin-bottom: 5px;\n  font-size: 1.2em;\n  padding: 5px;\n  text-align: center;\n}\n\n.modal :global(.modal-dialog) {\n  max-width: 500px;\n}\n\n.modal :global(.modal-body) {\n  text-align: center;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"modal": "SsoLink__modal___uLSqr",
	"title": "SsoLink__title___G2-9i"
};