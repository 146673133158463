import * as React from 'react';
import {Row, Col} from 'react-bootstrap';
import getUserName from 'src/app/data/common/utils/getUserName';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import { IItemPickerListItemProps } from 'src/components/ItemPicker/ItemPicker';

class UsersPickerComponent extends React.Component<IItemPickerListItemProps> {

  public render() {
    const userInfo: ICompanyUser = this.props.item.data;
    return (
      <Row>
        <Col xs={6}>
          {getUserName(userInfo)}
        </Col>
        <Col xs={6}>
          {userInfo.departament}
        </Col>
      </Row>);
  }
}

export default UsersPickerComponent;
