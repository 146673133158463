import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { isCurrentUserBorF} from "src/app/data/common/utils/userRoleUtils";
import { actions, selectors } from "src/app/redux";
import { ProjectSelector, ScenarioSelector, TeamSelector } from "src/components/EntitySelectors";
import Page from "src/components/Page";
import styles from "./InstructorScenarioTeamMapping.css";
import ROUTE_PATHS from "src/routing/paths";
import INamedEntry from "src/app/data/common/interfaces/INamedEntry";
import crossImage from 'src/layouts/common/Projects/components/ProjectCard/components/ProjectInfo/images/cross.png';
import Dialog from "src/components/Dialog";
import { compareInstructorMapping, getDisabledValue, getIds, getPreSelectedValue, getValueForActiveProp, isDataNull, setIds } from "src/app/data/client/utils/instructorMappingUtils";
import { IInstructorMapping } from "src/app/redux/modules/integrations/lti/rest";

const InstructorScenarioTeamMapping = () => {
  const intl = useIntl();
  const [projectId, setProjectId] = useState<string | null>(null);
  const [scenarioId, setScenarioId] = useState<string | null>(null);
  const [teamId, setTeamId] = useState<string | null>(null);
  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  const history = useHistory();
  const params = useParams<{contextId: string}>();
  const dispatch = useDispatch();
  const contextId = useRef<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hasScenarioChanged, setHasScenarioChanged] = useState<boolean>(false);
  const [mappingPresentForContextId, setMappingPresentForContextId] = useState<boolean>(false);
  const [mappingForContextId, setMappingForContextId] = useState<IInstructorMapping[]>([]);

  useEffect(() => {
    if (!isCurrentUserBorF(userRole)) {
      history.push(ROUTE_PATHS.CALENDAR);
    } else {
    contextId.current = params.contextId;
      dispatch(actions.integrations.fetchInstructorMapping(contextId.current)).then( (res) => {
        if (!isDataNull([res.scenarioId, res.projectId, res.teamId])) {
          setMappingPresentForContextId(true);
          setProjectId(res.projectId || '');
          setScenarioId(res.scenarioId);
          setTeamId(res.teamId);
          setMappingForContextId([res]);
        }
      });
    }
  }, []);

  const handleTeamChange = (selection: any) => {
    if (setIds(selection.id, mappingPresentForContextId)) {
      setTeamId(selection?.id);
    }
  };

  const handleScenarioChange = (selection: INamedEntry) => {
    if (setIds(selection.id, mappingPresentForContextId)) {
      setScenarioId(selection?.id);
      setTeamId(null);
      setHasScenarioChanged(true);
    } else {
      setMappingPresentForContextId(false);
    }
  };

  const handleProjectChange = (selection: INamedEntry) => {
    if (setIds(selection.id, mappingPresentForContextId)) {
      setProjectId(selection?.id);
      setScenarioId(null);
      setTeamId(null);
    }
  };

  const createMapping = () => {
    if (scenarioId && teamId) {
      dispatch(actions.integrations.createInstructorMapping({ contextId: contextId.current, scenarioId, teamId })).then(() => {
        history.push(ROUTE_PATHS.PROJECTS);
      });
    }
    if (showModal) {
      setShowModal(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = () => {
    if (scenarioId && teamId) {
      dispatch(actions.integrations.checkInstructorMapping({ scenarioId, teamId })).then((res) => {
        if (compareInstructorMapping(res, scenarioId, teamId)) {
          setShowModal(true);
        } else {
          createMapping();
        }
      });
    }
  };

  const getModalTitle = (): React.ReactNode => {
    return (
      <button type="button" className={styles.closeIcon} onClick={closeModal}>
        <img alt={intl.formatMessage({ id: 'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon' })} src={crossImage} />
      </button>
    );
  };

  return (
    <Page bgColor={"white"}>
      <h1 className={styles.mapperPageTitle}>{intl.formatMessage({ id: 'MursionPortal.InstructoScenarioTeamMapping.Header' })}</h1>
      <div className={styles.selectorsContainer}>
        <div className={styles.selector}>
          <span className="selectorLabel">{intl.formatMessage({ id: "Filters.Project" })}</span>
          <ProjectSelector
            preSelectedValue={getPreSelectedValue(projectId)}
            isMulti={false}
            isSearchable={true}
            onChange={handleProjectChange}
            clientIds={null}
            active={true}
            archive={"active"}
          />
        </div>
        <div className={styles.selector}>
          <span className="selectorLabel">{intl.formatMessage({ id: "Filters.Scenario" })}</span>
          <ScenarioSelector
            preSelectedValue={getPreSelectedValue(scenarioId)}
            clientIds={null}
            projectIds={getIds(projectId)}
            onChange={handleScenarioChange}
            active={!!projectId}
            archive={"active"}
            isMulti={false}
            clearOnOptionsChange={true}
          />
        </div>
        <div className={styles.selector}>
          <span className="selectorLabel">{intl.formatMessage({ id: "Filters.Team" })}</span>
          <TeamSelector
            preSelectedValue={getPreSelectedValue(teamId)}
            isMulti={false}
            clientIds={null}
            projectIds={getIds(projectId)}
            scenarioIds={getIds(scenarioId)}
            onChange={handleTeamChange}
            active={getValueForActiveProp(projectId, scenarioId)}
            archive={"active"}
            clearOnOptionsChange={true}
            hasScenarioChanged={hasScenarioChanged}
            setHasScenarioChanged={setHasScenarioChanged}
            ignoreCachedValue={true}
          />
        </div>
      </div>
      <div className={styles.dFlex}>
        <button onClick={handleSubmit} disabled={getDisabledValue(projectId, scenarioId, teamId, mappingForContextId)} className={styles.submitCta}>
          {intl.formatMessage({ id: "MursionPortal.Button.Submit" })}
        </button>
      </div>
      <Dialog
        show={showModal}
        onHide={closeModal}
        title={getModalTitle()}
        modalBodyHeading={''}
        modalBody={intl.formatMessage({ id: 'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage' })}
        label={intl.formatMessage({ id: 'Users.Button.OK' })}
        onClose={createMapping}
        className={styles.mappingModal}
      />
    </Page>
  );
};

export default InstructorScenarioTeamMapping;
