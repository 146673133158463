import * as React from 'react';
import { connect } from 'react-redux';
import IAvatar from 'src/app/data/client/interfaces/IAvatar';
import ITeam from 'src/app/data/client/interfaces/ITeam';
import IRestError from 'src/app/data/common/interfaces/IRestError';
import selectors from 'src/app/redux/selectors';
import actions from 'src/app/redux/store/actions';
import { IAppState } from 'src/app/redux/store/reducers';
import Button, { ButtonFont, ButtonSize } from 'src/components/Button';
import ItemPicker, { IItemPickerListItemProps, IPickerItem } from 'src/components/ItemPicker/ItemPicker';
import styles from './AvatarsField.css';
import { DEFAULT_USER_ICON_PATH } from 'src/app/data/client/data';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import TranslateMessage from 'src/i18n/TranslateMessage';

export interface IAvatarsFieldProps extends WrappedComponentProps {
  environmentId: string;
  avatarIds: string[];
  viewMode?: boolean;
  onChange: (avatarIds: string[], needsRefresh?: boolean) => void;

  listClassName?: string;

  avatars: IAvatar[];
  fetched: boolean;
  fetching: boolean;
  fetchError: IRestError;
  fetchAvatarsListByEnvironment: (environmentId: string) => void;
}

class AvatarsField extends React.Component<IAvatarsFieldProps> {

  public componentDidMount() {

    if (this.props.environmentId) {
      return this.props.fetchAvatarsListByEnvironment(this.props.environmentId);
    }
  }

  public componentDidUpdate(prevProps: IAvatarsFieldProps) {
    const setEditMode = prevProps.viewMode && !this.props.viewMode;

    const oldEnvironmentId = prevProps.environmentId;
    const newEnvironmentId = this.props.environmentId;

    if (setEditMode || newEnvironmentId && oldEnvironmentId !== newEnvironmentId) {
      return this.props.fetchAvatarsListByEnvironment(newEnvironmentId);
    }
  }

  public render() {
    const {
      environmentId,
      listClassName,
      avatars,
      fetched,
      fetching,
      fetchError,
      viewMode,
      intl
    } = this.props;

    let message = '';

    if (!environmentId) {
      message = intl.formatMessage({ id: 'Projects.Message.SelectEnvironmentFirst' });
    } else if (fetching) {
      message = intl.formatMessage({ id: 'Projects.Message.LoadingAvatarsList' });
    } else if (!avatars.length) {
      message = intl.formatMessage({ id: 'Projects.Message.NoAssignedAvatars' });
    }

    return (
      viewMode
        ? this.renderAvatarsViewMode()
        : (
          !message && avatars.length
            ? <ItemPicker
              listClassName={listClassName}
              ItemComponent={this.avatarPickerItemRenderer}
              items={this.wrapAvatars()}
              itemsFetchError={fetchError}
              itemsFetched={fetched}
              itemsFetching={fetching}
              emptyListMessage={intl.formatMessage({ id: 'Projetcs.EmptyMessage.NoSelectedAvatars' })}
              keyExtractor={this.extractKey}
              selectedItemTitleExtractor={this.extractTeamTitle}
              onChange={this.onAvatarsChange}
            />
            : <span>{message}</span>
        )
    );
  }

  private renderAvatarsViewMode() {
    const avatars = this.props.avatars.filter(ava => this.props.avatarIds.includes(ava.id));

    if (avatars.length === 0) {
      return <span>{TranslateMessage('Projects.Avatars.NoAvatarsSpecified')}</span>;
    }

    return (
      <div className={styles.avatars}>
        {
          avatars.map(avatar =>
            <Button
              key={`avatar-item-${avatar.id}`}
              btnSize={ButtonSize.MEDIUM}
              btnFont={ButtonFont.LIGHT}
              className={styles.avatarCard}
              title={avatar.name}
            >
              <div className={styles.avatarPic} style={{
                backgroundImage: `url(${avatar.picture || DEFAULT_USER_ICON_PATH})`,
              }}/>
              <span className={styles.ellipsis}>{avatar.name}</span>
            </Button>
          )
        }
      </div>
    );
  }

  private avatarPickerItemRenderer = (props: IItemPickerListItemProps) => {
    const avatarInfo: ITeam = props.item.data;

    return <span>{avatarInfo.name}</span>;
  }

  private onAvatarsChange = (item: IPickerItem) => {
    const avatarIds = [...this.props.avatarIds];

    const avatarIndex = avatarIds.findIndex(avaId => item.id === avaId);

    if (item.selected && avatarIndex === -1) {
      avatarIds.push(item.id);
    } else if (!item.selected && avatarIndex > -1) {
      avatarIds.splice(avatarIndex, 1);
    }

    this.props.onChange(avatarIds, true);
  }

  private extractKey = (item: IPickerItem) => {
    return item.data.id;
  }

  private extractTeamTitle = (item: IPickerItem) => {
    return item.data.name;
  }

  private wrapAvatars = (): IPickerItem[] => {
    const { avatars } = this.props;
    const selectedAvatarIds = this.props.avatarIds;

    return avatars.map((avatar: IAvatar) => ({
      data: avatar,
      id: avatar.id || '',
      selected: selectedAvatarIds.some((selectedAvatarId) => selectedAvatarId === avatar.id),
    }));
  }
}

export default injectIntl(connect(
  (state: IAppState) => ({
    avatars: selectors.avatars.getListByEnvironment(state),
    fetched: selectors.avatars.isListByEnvironmentFetched(state),
    fetching: selectors.avatars.isListByEnvironmentRefreshing(state),
    fetchError: selectors.avatars.getListByEnvironmentRefreshError(state),
  }),
  {
    fetchAvatarsListByEnvironment: actions.avatars.fetchAvatarsListByEnvironment,
  },
)(AvatarsField));
