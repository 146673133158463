import React, {FunctionComponent, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import {Container, Row, Col} from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import actions from 'src/app/redux/store/actions';
import styles from './DashboardReportLayout.css';
import Separator from 'src/components/Separator';
import backIcon from "src/components/CompletedSimulations/images/back.svg";
import DashboardReportFilter from '../DashboardReportFilter/DashboardReportFilter';
import DashboardReportDropdownFilter from 'src/layouts/common/Dashboard/components/Reports/components/DashboardReportDropdownFilter/DashboardReportDropdownFilter';
import { CtaButton } from 'src/components';
import TranslateMessage from 'src/i18n/TranslateMessage';
import Dialog from 'src/components/Dialog';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { IDashboardReportLayoutProps, IDashboardScheduleReports, IOptionType, IReportScheduleAndSendData, IDashboardReportQueryParams } from 'src/app/data/dashboardReport/interfaces/IDashboardReport';
import { REPORT_FORM_PERMISSION, REPORT_PAGE_ID } from 'src/app/data/dashboardReport/interfaces/IReportPageId';
import { injectIntl } from 'react-intl';
import { requestUrl, dashboardReportButtonConditions, commonPayload, dashboardReportQueryParams, dashboardReportTitle, ScheduleReportUpdatePayload } from 'src/layouts/common/Dashboard/components/Reports/components/ReportUtils';
import { hasPermissionToUpdateDashboardReport } from 'src/app/data/permissions/dashboardReportPermissionUtils';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { ReportSessionType } from 'src/app/data/dashboardReport/interfaces/ReportFiltersType';

const DashboardReportLayout: FunctionComponent<IDashboardReportLayoutProps> = ({
	redirectDashboard, 
	isSendReport, 
	storageKey, 
	clientName,
	clientID,
	getPageId,
	intl,
	userId,
	userRoleId,
	query
}) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const [modalTitle, setModalTitle] = useState<string>('');
	const [modalBodyHeading, setModalBodyHeading] = useState<string>('');
	const [modalBody, setModalBody] = useState<string>('');
  	const [dialogButtonLabel, setDialogButtonLabel] = useState<string>('');
	const [showSendReportDialog, setShowReportDialog] = useState<boolean>(false);
	// FORM State
	const [recipients, setRecipients] = useState<INamedEntry[]>([]);
	const [selectedReportFrequency, setSelectedReportFrequency] = useState<IOptionType | undefined>();
	const [session, setSession] = useState<IOptionType | undefined>();
	const [reportStartDate, setReportStartDate] = useState<Date>();
	const [fetchEditScheduleReport, setFetchEditScheduleReport] = useState<IReportScheduleAndSendData | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showLoader, setShowLoader] = useState<boolean>(false);
	const [showViewReport, setShowViewReport] = useState<boolean>(false);
	const [fetchReportDataResponse, setFetchReportDataResponse] = useState<null | object>(null);
	// Delete dialog stages
	const dialogStage = {
		initialState: 'INITIAL_STATE',
		finalState: 'FINAL_STATE'
	};
	const [dialogStep, setDialogStep] = useState<string>(dialogStage.initialState);
	const [isScheduleDeleteEnable, setIsScheduleDeleteEnable] = useState<boolean>(false);
	const deleteButton = TranslateMessage('Dashboard.Report.DeleteSchedule');
	const clientConfig= useSelector(selectors.clientsConfig.getClientConfig);
	const getReportType = getPageId?.replace(" ", "") || '';
	const getReportPageId = REPORT_PAGE_ID[getReportType];

	const isShowSelectSession = useMemo(() =>  !!REPORT_FORM_PERMISSION[getReportPageId], [getReportPageId]);

	useEffect(() => {
		if(clientID && getReportPageId){
			setIsLoading(true);
		  	getScheduleReport(getReportPageId, clientID);
		}else if(!clientID){
			setShowViewReport(false);
		  }
	}, [clientID]);
	
	const getScheduleReport = async (pageId: string, clientId: string) => {
		try {
		  const response = await dispatch(actions.dashboardReport.getDashboardScheduleReport(pageId, clientId));
		  if(response && response.clientId){
			setFetchEditScheduleReport(response);
			const { scheduledByRole, reportFilters } = response;
			const facilitatorLearnersListType  = clientConfig?.facilitatorLearnersListType;
        	const checkPermission = hasPermissionToUpdateDashboardReport(userRoleId, scheduledByRole, facilitatorLearnersListType);
			filtersValues(reportFilters);
			const checkSendReportEnable = isSendReport ? false : !checkPermission;
			setShowViewReport(checkSendReportEnable);
		  }
		  setIsLoading(false);
		}catch(err){
		  setIsLoading(false);
		  setShowViewReport(false);
		}
	};

	const filtersValues = (reportFilters: string) => {
		if(!!reportFilters){
			const fetchFilterReportData = typeof reportFilters === 'string' ? JSON.parse(reportFilters): reportFilters; 
			setFetchReportDataResponse(fetchFilterReportData);
		}
	};

	const isButtonDisabled = ()=>{
		const disableProps:IDashboardScheduleReports = {
			recipients, 
			fetchEditScheduleReport, 
			selectedReportFrequencyVal: session?.value, 
			sessionVal: selectedReportFrequency?.value, 
			reportStartDate, 
			isSendReport,
			isShowSelectSession,
		};
		return dashboardReportButtonConditions(disableProps);
	};

	function hideReportDialog(keyboardEvent?:KeyboardEvent) {
		if (keyboardEvent?.type === 'keydown' && keyboardEvent?.key === 'Escape') {
			return;
		}
		setModalTitle('');
		setDialogButtonLabel('');
		setShowReportDialog(false);
	}

	const reportDialog = () => {
		setModalTitle(isSendReport ? intl.formatMessage({id: 'Dashboard.Report.SendReport'}) : intl.formatMessage({id: 'Dashboard.Report.ScheduleReport'}));
		setModalBodyHeading(isSendReport ? '' : intl.formatMessage({id: 'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText'}));
		setModalBody(isSendReport ? intl.formatMessage({id: 'Dashboard.Report.SendReport.ConfirmationModal.Text'}) : intl.formatMessage({id: 'Dashboard.Report.ScheduleReport.ConfirmationModal.Text'}, {strong: (word: string) => <b className={styles.bold}>{word}</b>}));
		setDialogButtonLabel(intl.formatMessage({id: 'Dashboard.Report.ConfirmationModal.Button'}));
		setShowReportDialog(true);
	};

	const deleteDialog = () => {
		setModalTitle(intl.formatMessage({id: 'Dashboard.Report.DeleteSchedule'}));
		setModalBodyHeading('');
		setModalBody(intl.formatMessage({id: 'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText'}));
		setDialogButtonLabel(intl.formatMessage({id: 'Dashboard.Report.DeleteSchedule.ConfirmationButton'}));
		setShowReportDialog(true);
		setDialogStep(dialogStage.initialState);
		setIsScheduleDeleteEnable(true);
	};

	const editDialog = () => {
		setModalTitle(intl.formatMessage({id: 'Dashboard.Report.EditSchedule'}));
		setModalBodyHeading(intl.formatMessage({id: 'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText'}));
		setModalBody(intl.formatMessage({id: 'Dashboard.Report.EditSchedule.ConfirmationModal.Text'}, {strong: (word: string) => <b className={styles.bold}>{word}</b>}));
		setDialogButtonLabel(intl.formatMessage({id: 'Dashboard.Report.ConfirmationModal.Button'}));
		setShowReportDialog(true);
	};

	

	const onDeleteReport = async () => {
		if(getPageId && clientID){
			try{
				setShowLoader(true);
				await dispatch(actions.dashboardReport.deleteDashboardReport(getReportPageId, clientID));
				setShowLoader(false);
				setIsScheduleDeleteEnable(true);
				setModalBody(intl.formatMessage({id: 'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text'}));
				setDialogButtonLabel(intl.formatMessage({id: 'Dashboard.Report.ConfirmationModal.Button'}));
				setDialogStep(dialogStage.finalState);
			}catch(e){
				console.error(e);
			}
		}
	};

	const handleDeleteReportClose = () => {
		if(dialogStep === dialogStage.initialState) {
			onDeleteReport();
			return;
		}
		hideReportDialog();
		setDialogStep(dialogStage.initialState);
		history.goBack();
	};

	const handleReportClose = () => {
		if(isScheduleDeleteEnable) {
			handleDeleteReportClose();
			return;
		}
		hideReportDialog();
		history.goBack();
	};

	const handleChange = (frequency: IOptionType) => {
        setSelectedReportFrequency(frequency);
    };

	const onSubmitReport = async () => {
		if(getPageId && clientID){
			const reportId = getPageId?.replace(" ", "");
			const params:IDashboardReportQueryParams = {
							isSendReport,
							clientID,
							recipients,
							getReportType: reportId,
							selectedReportFrequencyValue : selectedReportFrequency?.value,
							sessionValue: session?.value,
							scheduledBy: userId,
							scheduledByRole: userRoleId,
						};

			if(session?.value!==ReportSessionType.ALL){
				params.reportStartDate = reportStartDate;
			}

			const createQueryParams = dashboardReportQueryParams(params);

			const request = requestUrl(isSendReport);
			const payload: IReportScheduleAndSendData = {...commonPayload(query, isSendReport), ...createQueryParams};

			try{
				await dispatch(request(payload));
				reportDialog();
			}catch(e){
				console.error(e);
			}
		}
	};

	const onUpdateScheduleReport = async () => {
		if(getPageId && clientID){
			const reportId = getPageId?.replace(" ", "");
			const scheduleReportId = fetchEditScheduleReport?.id;

			const updatePayload = ScheduleReportUpdatePayload(
									scheduleReportId,
									clientID,
									recipients,
									selectedReportFrequency?.value,
									session?.value,
									reportId,
									reportStartDate
								);

			const payload: IReportScheduleAndSendData = {...commonPayload(query, isSendReport), ...updatePayload};

			try{
				await dispatch(actions.dashboardReport.updateDashboardScheduleReport(payload));
				editDialog();
			}catch(e){
				console.error(e);
			}
		}
	};

	const fetchReportTitle = (isButton?:boolean) => {
		return dashboardReportTitle(isSendReport, isButton, !!fetchEditScheduleReport, showViewReport);
	};

    return(
		<LoadingOverlay active={isLoading} className={styles.loading} spinner={true}>
        <Container className={styles.container}>
			<Row>
				<div className={styles.backButton}>
					<Link to={redirectDashboard}>
						<img alt={intl.formatMessage({id: 'Dashboard.Back'})} src={backIcon} className={styles.backIcon} />
						<span>{TranslateMessage('MursionPortal.Dashboard.BackToDashboard')}</span>
					</Link>
				</div>
				
				<Col xs={12}>
					<h2 className={styles.heading}>{fetchReportTitle()}</h2>
				</Col>
			</Row>
			<Row>
				<Separator />
			</Row>
			{ !isSendReport && !!fetchEditScheduleReport && !showViewReport &&
				<Row className={styles.deleteButton}>
					<CtaButton onClick={deleteDialog}>
						{deleteButton}
					</CtaButton>
				</Row>
			}
			<Row>
				{clientName && (
					<DashboardReportFilter 
						storageKey={storageKey} 
						clientName={clientName}
						fetchReportDataResponse={fetchReportDataResponse}
						userRoleId={userRoleId}
						isEditOrView={!!fetchEditScheduleReport}
						isLoading={isLoading}
						isSendReport={isSendReport}
					/>
				)}
				
			</Row>
			{clientID && <Row>
				<DashboardReportDropdownFilter 
					isSendReport={isSendReport}
					clientID={clientID}
					recipients={recipients}
					setRecipients={setRecipients}
					handleChangeReportFrequency={handleChange}
					selectedReportFrequency={selectedReportFrequency}
					setSession={setSession}
					session={session}
					setReportStartDate={setReportStartDate}
					reportStartDate={reportStartDate}
					selectedFilters={fetchEditScheduleReport}
					getPageId={getPageId}
					showViewReport={showViewReport}
					isShowSelectSession={isShowSelectSession}
				/>
			</Row>}
			
			<Row className={styles.reportButton} >
				{!showViewReport && <CtaButton onClick={!isSendReport && !!fetchEditScheduleReport ? onUpdateScheduleReport : onSubmitReport} disabled={isButtonDisabled()}>
					{fetchReportTitle(true)}
				</CtaButton>}
			</Row>
		</Container>
		<div>
			<Dialog 
				isLoading={showLoader}
				show={showSendReportDialog}
				onHide={hideReportDialog}
				title={modalTitle}
				modalBodyHeading={modalBodyHeading}
				modalBody={modalBody}
				label={dialogButtonLabel}
				onClose={handleReportClose}
				shouldCloseOnOverlayClick={false}
      		/>
		</div>
		</LoadingOverlay>
    );
};

export default injectIntl(DashboardReportLayout);