import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import useDebounce from 'src/hooks/useDebounce';
import _noop from 'lodash/noop';
import cn from 'classnames';
import IScenario from 'src/app/data/projects/interfaces/IScenario';
import getUserName from 'src/app/data/common/utils/getUserName';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import styles from './SessionWizard.css';
import SelectListItemPlain from 'src/components/SelectList/SelectListItemPlain';
import { DEFAULT_USER_ICON_PATH } from 'src/app/data/client/data';
import Separator from 'src/components/Separator';
import FieldGroup from 'src/components/FieldGroup';
import ErrorMessage from 'src/components/ErrorMessage';
import Button, { ButtonSize } from 'src/components/Button';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import sessionWizardHooks, { IAggregatedPagedDataHookResult } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { hasPermissionToCreateEmergencySession } from 'src/app/data/permissions/sessionPermissionUtils';
import { isCurrentUserBorF, isCurrentUserLearner } from 'src/app/data/common/utils/userRoleUtils';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import IUser from 'src/app/data/common/interfaces/IUser';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import { sortListByField } from 'src/app/data/common/utils/sortListByField';
import Checkbox from 'src/components/Checkbox/Checkbox';
import canCreateSessionWithoutLearners from 'src/app/data/session/utils/canCreateSessionWithoutLearners';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';

interface ILearnersStepProps {
  footerContainer: HTMLElement | null;
  selectedLearners: ILearnerExtended[];
  onValueChange: (selectedLearners: ILearnerExtended[], selectedItemName: string, allLearnersAreExternal: boolean) => void;

  userProfile: IUserProfile;
  userRole: IUserRoleExtended;
  selectedScenario: IScenario;
  startDate: number;
  endDate: number;
  handleFocus?: any;
}

const LearnersStep = (props: ILearnersStepProps) => {
  const {
    footerContainer,
    selectedLearners,
    onValueChange,
    userRole,
    userProfile,
    selectedScenario,
    startDate,
    endDate
  } = props;

  const { items: activeLearners, refreshing, setPagedDataCallback }: IAggregatedPagedDataHookResult<ILearnerExtended> =
    sessionWizardHooks.useActiveLearners(selectedScenario.id, startDate, endDate)({ page: 0, size: 9999 });
  const intl = useIntl();
  const [teams, setTeams] = useState<INamedEntry[]>([]);
  const [selectedTeamId, setSelectedTeamId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [nonSelectedLearners, setNonSelectedLearners] = useState<ILearnerExtended[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [autoSelectUser, setAutoSelectUser] = useState<boolean>(false);
  const [allLearnersAreExternal, setAllLearnersAreExternal] = useState<boolean>(false);

  // const noTeamsMessage = 'No Available Teams';
  // const noLearnersMessage = 'No Available Learners';

  // learners auto-picked if L create 1v1, or F/AO crate GroupSession
  const maxLearners = selectedScenario.draft.sessionSize;
  const currentTeam = selectedTeamId ? teams.find(team => team.id === selectedTeamId) || null : null;
  const canAddTeam = currentTeam && (!maxLearners || maxLearners - selectedLearners.length >= nonSelectedLearners.length);

  const isEmergency = hasPermissionToCreateEmergencySession(userRole);
  const isGroupType = selectedScenario.draft.deliveryMode === SessionType.GROUP;
  const isOneToOne = selectedScenario.draft.deliveryMode === SessionType.ONE_TO_ONE;
  const isAOorF = isCurrentUserBorF(userRole);
  const hasLearnerRole = userProfile.roles.some(role => role.id === RoleID.LEARNER);
  const isLearner = isCurrentUserLearner(userRole);
  const isInTeam = selectedScenario.planning.teams.some(team => team.learners.some(teamLearner => teamLearner.id === userProfile.id));
  const shouldIgnoreTeams = isEmergency && isGroupType;
  const userName = getUserName(userProfile);
  const [filter, setFilter] = useState<string>('');
  const { value: debouncedFilter } = useDebounce(filter, 500);

  const showAllLearnersAreExternalCheckbox = useMemo(() => {
    return canCreateSessionWithoutLearners(userRole, selectedScenario.draft.deliveryMode, selectedScenario.draft.scenarioVersion);
  }, [isLearner, isGroupType, selectedScenario.draft.scenarioVersion]);

  // keep only the teams that have some active learners
  useEffect(() => {
    if (!teams.length) {
      const availableTeams = selectedScenario.planning.teams
        .filter(team =>
          activeLearners.some(learner =>
            learner.teams.some((learnerTeam: any) =>
              learnerTeam.id === team.id)
          )
        );
      setTeams(sortListByField(availableTeams, 'name', 'asc') as INamedEntry[]);
    }

  }, [activeLearners, selectedScenario.planning.teams]);

  useEffect(() => {
    setPagedDataCallback({ filter: debouncedFilter });
  }, [debouncedFilter]);

  useEffect(() => {

    if (!isEmergency && userProfile.id) {
      if (
        isGroupType && isAOorF && hasLearnerRole // AO or F with L role creating group type session
        || isOneToOne && isLearner && isInTeam   // L in the team creating 1 to 1 type session
      ) {
        setDisabled(true);
        setAutoSelectUser(true);

        const learnerSelf: ILearnerExtended | null = activeLearners.find(l => l.user.id === userProfile.id) || null;

        if (!learnerSelf) {
          return;
        }

        onValueChange([learnerSelf], userName, false);
      }
    }

  }, [
    isEmergency,
    isGroupType,
    isOneToOne,
    isAOorF,
    isLearner,
    isInTeam,
    userProfile.id,
    userName,
    activeLearners,
  ]);

  useEffect(() => {
    setNonSelectedLearners(
      activeLearners
        // show only users which are not selected
        .filter(al => al.user.id && !selectedLearners.some(l => l.user.id === al.user.id))
        // filter by team if a team is selected
        .filter(al => selectedTeamId ? al.teams.some(team => team.id === selectedTeamId) : true)
    );
  }, [activeLearners, selectedLearners, selectedTeamId]);

  const onChangeSelectedTeam = (e: any) => setSelectedTeamId(e.target.value);
  const onChangeFilter = (e: any) => setFilter(e.target.value);

  const updateSelectedLearners = (updatedLearners: ILearnerExtended[]) => {
    const names = updatedLearners.map(updatedLearner => {
      const learner = activeLearners.find(activeLearner => activeLearner.user.id === updatedLearner.user.id);
      if (!learner) {
        return '';
      }

      return getUserName(learner.user);
    }).join(', ');
    onValueChange([...updatedLearners], names, allLearnersAreExternal);
  };

  const addLearner = (learner: ILearnerExtended) => () => {
    if (learner && !learner.user.id) {
      return;
    }

    if (maxLearners && selectedLearners.length >= maxLearners) {
      setErrorMessage(intl.formatMessage({ id: maxLearners > 1 ? 'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners' : 'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner' }, { maxLearners }));
      return;
    }

    updateSelectedLearners([...selectedLearners, learner]);
  };

  const removeLearner = (learnerId: string) => () => {
    const updatedLearners = selectedLearners.filter(selectedLearner => selectedLearner.user.id !== learnerId);

    setErrorMessage('');
    updateSelectedLearners(updatedLearners);
  };

  const addTeam = () => {
    const learners = activeLearners
      .filter(learner =>
        learner.teams.some(team => team.id === selectedTeamId)
        && !selectedLearners.some(selectedLearner => selectedLearner.user.id === learner.user.id));

    if (maxLearners && selectedLearners.length >= maxLearners) {
      setErrorMessage(intl.formatMessage({ id: maxLearners > 1 ? 'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners' : 'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner' }, { maxLearners }));
      return;
    }

    updateSelectedLearners([...selectedLearners, ...learners]);
  };

  const renderSelectedLearner = (user: IUser) => {
    const selectedLearnerName = getUserName(user);
    const { id: learnerId, picture } = user;

    return (
      <SelectListItemPlain
        title={selectedLearnerName}
        onClick={!disabled ? removeLearner(learnerId || '') : _noop}
        key={`selected-items-${learnerId}`}
        selected={true}
        imageSrc={picture || ''}
        fallback={DEFAULT_USER_ICON_PATH}
        circle={true}
        imageClassName={styles.userImageContainer}
      >
        <div className={styles.selectedLearnerWrapper} title={selectedLearnerName}>
          <span className={styles.listText}>{selectedLearnerName}</span>
          {
            !disabled &&
            <button className={styles.learnerRemoveButton} onClick={removeLearner(learnerId || '')}>
              <i className='fas fa-times-circle'/>
            </button>
          }
        </div>
      </SelectListItemPlain>
    );
  };

  const NonSelectedLearnerRow = ({ index, style }: { index: number, style: any }) => {
    const learner = nonSelectedLearners[index];
    const learnerName = getUserName(learner.user);
    const learnerTeamList = learner.teams.map(team => team.name).join(', ');

    return (
      <div style={style}>
        <SelectListItemPlain
          title={learnerName}
          onClick={addLearner(learner)}
          key={`ss-item-${learner.user.id}`}
          selected={false}
          imageSrc={learner.user.picture || ''}
          fallback={DEFAULT_USER_ICON_PATH}
          circle={true}
          imageClassName={styles.userImageContainer}
        >
          <div className={styles.learnerWrapper} title={`${learnerName}\n${learnerTeamList}`}>
            <span className={styles.listText}>{learnerName}</span>
            <span className={styles.lernerTeamName}>{learnerTeamList}</span>
          </div>
        </SelectListItemPlain>
      </div>
    );
  };

  const renderSessionWizardSearch = () => (
    <div className={styles.searchContainer}>
      <input
        className={styles.search}
        placeholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.Search' })}
        type="search"
        value={filter}
        onChange={onChangeFilter}
      />
    </div>
  );

  const onChangeAllLearnersAreExternal = (e: any) => {
    const checked = e.target.checked;
    onValueChange([], checked ? 'Unauthenticated only' : '', checked);

    setAllLearnersAreExternal(checked);

  };

  const showLearnersSelect = showAllLearnersAreExternalCheckbox ? !allLearnersAreExternal : true;

  return (
    <>
      {
        showLearnersSelect &&
        <>
          <div style={{
            minHeight: '50px',
            maxHeight: '200px',
            overflow: 'auto'
          }}>

            {
              !!selectedLearners.length
                ? selectedLearners
                  .map(
                    (learner: ILearnerExtended) => renderSelectedLearner(learner.user)
                  )
                : autoSelectUser
                ? renderSelectedLearner(userProfile)
                : <span
                  className={styles.emptyMessage}> {intl.formatMessage({ id: 'MursionPortal.LearnersStep.Label.NoUsersSelected' })} </span>
            }
          </div>
          <ErrorMessage message={errorMessage} className={styles.emptyMessage}/>
          {
            !disabled &&
            <div className={cn(styles.listContainer, styles.learnersWrapper)} style={{ flex: '1' }}>
              <Separator/>
              <div className={styles.teamWrapper}>

                {
                  !shouldIgnoreTeams &&
                  <FieldGroup
                    id={'team-selector'}
                    as={'select'}
                    className={styles.teamFilter}
                    disabled={disabled}
                    value={selectedTeamId}
                    onChange={onChangeSelectedTeam}
                  >
                    <option key={'team-selector-default'} value={''} disabled={false}>
                      {intl.formatMessage({ id: 'MursionPortal.LearnersStep.Option.AllTeams' })}
                    </option>
                    {
                      teams
                        .map((team: INamedEntry, i: number) => (
                          <option value={team.id} key={`team-selector-option-${i}`}>
                            {team.name}
                          </option>
                        ))
                    }
                  </FieldGroup>
                }
                {
                  !!selectedTeamId &&
                  <Button
                    btnSize={ButtonSize.MEDIUM}
                    className={styles.teamAddBtn}
                    disabled={!canAddTeam}
                    onClick={addTeam}
                    title={intl.formatMessage({ id: 'MursionPortal.LearnersStep.Button.AddEntireTeam' })}
                  >
                    <i className={cn('fas', 'fa-plus', styles.addBtnIcon)}/>
                  </Button>
                }
              </div>
              {
                !canAddTeam
                && (
                  <div className={styles.noteWrapper}>
                    <i className={cn('fas', 'fa-exclamation-circle', styles.noteIcon)}/>
                    <i className={styles.noteText}>
                      {intl.formatMessage({ id: 'MursionPortal.LearnersStep.Note.AllowedSessionSIze' }, { maxLearners })}
                    </i>
                  </div>
                )
              }
              <div className={styles.learnerFilter}>
                {
                  renderSessionWizardSearch()
                }
              </div>
              <div style={{ minHeight: 200, flexGrow: 1 }}>
                {
                  !disabled && (
                    nonSelectedLearners.length
                      ? (
                        <AutoSizer>
                          {({ height, width }) => (
                            <List
                              itemCount={nonSelectedLearners.length}
                              itemSize={45}
                              height={height}
                              width={width}
                            >
                              {NonSelectedLearnerRow}
                            </List>
                          )}
                        </AutoSizer>
                      )
                      : refreshing
                      ? (
                        <div>
                          <SelectListItemPlain refreshing={true}/>
                          <SelectListItemPlain refreshing={true}/>
                          <SelectListItemPlain refreshing={true}/>
                        </div>
                      )
                      : <div
                        className={styles.emptyTimeBlock}>{intl.formatMessage({ id: 'MursionPortal.LearnersStep.Label.NoLearnersToSelect' })}</div>
                  )
                }
              </div>


            </div>
          }
        </>
      }

      {
        footerContainer &&
        showAllLearnersAreExternalCheckbox &&
        ReactDOM.createPortal(
          <>
            <Checkbox
              className={styles.checkbox}
              checked={allLearnersAreExternal}
              id={'all-learners-are-external'}
              onChange={onChangeAllLearnersAreExternal}
              onKeyDown={props.handleFocus}
            >
              <div>{intl.formatMessage({ id: 'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers' })}</div>
            </Checkbox>
          </>, footerContainer)
      }
    </>
  );
};

export default LearnersStep;
