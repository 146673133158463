import moment, { Moment } from 'moment';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isCurrentUserClientUser, isCurrentUserPSorOps } from 'src/app/data/common/utils/userRoleUtils';
import IProject from 'src/app/data/projects/interfaces/IProject';
import IScenario from 'src/app/data/projects/interfaces/IScenario';
import { actions, selectors } from 'src/app/redux';
import {
  defaultTimeStep,
  getShowAvailabilityEndDateExcludingRestrictions,
  getTimeSlotsForDays,
  ITimeBlocksOnDays,
  SchedulingType,
  timeFormat,
} from 'src/app/data/calendar/utils/simulationSchedulingUtil';
import ITimeInterval from 'src/app/data/common/interfaces/ITimeInterval';

interface IFetchTimeBlocksHook {
  timeBlocks: ITimeBlocksOnDays[];
  learnerAvailability: ITimeInterval[] | null;
  excludedDates: Date[];
  isResponse: boolean;
}

interface IFetchTimeBlocksHookParams {
  startDate?: Moment;
  endDate?: Moment;
  timezone: string;
  scenario: IScenario | null;
  project: IProject | null;
  setLoading: (value: boolean) => void;
  schedulingType: SchedulingType;
  selectedDate: Moment | null;
  secondPossibleDay: Moment | null;
  showAvailabilityEndDateExcludingWeekends: Moment;
  minStartDate: Moment;
  setActualUpcomingAvailabilityPeriod: (val: number) => void;
  finalShowAvailabilityEndDate: moment.Moment | null;
  setFinalShowAvailabilityEndDate: (val: moment.Moment | null) => void;
  scheduleType: SchedulingType;
  setAreSlotsCalculated: (val: boolean) => void;
}

interface IQueryData {
  startDate?: Moment;
  endDate?: Moment;
  scenarioId?: string;
  timezone: string;
}

const useFetchTimeBlocks = (params: IFetchTimeBlocksHookParams): IFetchTimeBlocksHook => {
  const { startDate,
    endDate,
    timezone,
    scenario,
    project,
    setLoading,
    schedulingType,
    selectedDate,
    secondPossibleDay,
    showAvailabilityEndDateExcludingWeekends,
    minStartDate,
    setActualUpcomingAvailabilityPeriod,
    finalShowAvailabilityEndDate,
    setFinalShowAvailabilityEndDate,
    scheduleType,
    setAreSlotsCalculated,
  } = params;
  const [simAvailability, setSimAvailability] = useState<ITimeInterval[] | null>(null);
  const [learnerAvailability, setLearnerAvailability] = useState<ITimeInterval[] | null>(null);
  const [restrictionTimeBlocks, setRestrictionTimeBlocks] = useState<ITimeInterval[] | null>(null);
  const [cachedTimeBlocks, setCachedTimeBlocks] = useState<ITimeBlocksOnDays[]>([]);
  const [excludedDates, setExcludedDates] = useState<Date[]>([]);
  const [timeBlocks, setTimeBlocks] = useState<ITimeBlocksOnDays[]>([]);
  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  const dispatch = useDispatch();
  const companyData = useSelector(selectors.company.getCompanyData);
  const companyConfig = useSelector(selectors.companyConfig.getCompanyConfig);
  const userProfile = useSelector(selectors.profile.getUserProfile);
  const [isResponse, setResponse] = useState<boolean>(true);
  const showUpcomingSlots = scheduleType === SchedulingType.INSTANT;
  const queryData = useRef<IQueryData | undefined>();

  useEffect(() => {
    if (!companyConfig) {
      dispatch(actions.companyConfig.fetchConfig(userProfile?.licenseeId || ""));
    }
  }, [companyConfig]);

  const getTimeStep = useMemo(() => {
    if (companyConfig || project?.sessionTimeStep) {
      return moment.duration(project?.sessionTimeStep ? project.sessionTimeStep : companyConfig?.session.timeStep, "ms").asMinutes();
    }
    return defaultTimeStep;
  }, [companyConfig, project?.sessionTimeStep]);

  useEffect(() => {
    setCachedTimeBlocks([]);
  }, [scenario?.id]);

  const getEndDate = (showAvailabilityEndDateExcludingRestrictions: moment.Moment): number => {
    if (showAvailabilityEndDateExcludingRestrictions.isAfter(endDate) && endDate) {
      return endDate.valueOf();
    }

    return showAvailabilityEndDateExcludingRestrictions.valueOf();
  };

  function getRestrictionTBs(restrictionTimeSlots?: ITimeInterval[]) {
    return restrictionTimeSlots ? restrictionTimeSlots : [];
  }

  function getValueForSimAvailabilityOnly() {
    return isCurrentUserPSorOps(userRole) ? undefined : true;
  }

  function shouldSetRestrictionTimeBlocks(){
    return isCurrentUserPSorOps(userRole) && !restrictionTimeBlocks;
  }

  const fetchScenarioAvailableTimeBlocks = (restrictionTimeSlots?: ITimeInterval[]) => {
    let showAvailabilityEndDateExcludingRestrictions;
    const restrictionTBs = getRestrictionTBs(restrictionTimeSlots);

    if (isCurrentUserClientUser(userRole)) {
      const { upcomingAvailability } = project as IProject;

      showAvailabilityEndDateExcludingRestrictions = getShowAvailabilityEndDateExcludingRestrictions(showAvailabilityEndDateExcludingWeekends, [...restrictionTBs], timezone);

      // only show upcoming slots for first available date for scheduling
      if (minStartDate.isSame(showAvailabilityEndDateExcludingRestrictions, 'd') && showUpcomingSlots) {

        // show upcoming slots only till the end of first available date for scheduling
        if (showAvailabilityEndDateExcludingRestrictions.clone().add(upcomingAvailability, 's').isSame(showAvailabilityEndDateExcludingRestrictions, 'd')) {
          showAvailabilityEndDateExcludingRestrictions.add(upcomingAvailability, 's');
          setActualUpcomingAvailabilityPeriod(upcomingAvailability);
        } else {
          const actualUpcomingAvailability = showAvailabilityEndDateExcludingRestrictions.clone().endOf('d').diff(showAvailabilityEndDateExcludingRestrictions, 's');
          setActualUpcomingAvailabilityPeriod(actualUpcomingAvailability);
          showAvailabilityEndDateExcludingRestrictions.endOf('d');
        }
      }
    } else {
      showAvailabilityEndDateExcludingRestrictions = showAvailabilityEndDateExcludingWeekends.clone();
    }

    setFinalShowAvailabilityEndDate(showAvailabilityEndDateExcludingRestrictions);

    if (startDate && scenario?.id && showAvailabilityEndDateExcludingRestrictions.isAfter(startDate)) {
      setResponse(true);
      const timeBlockOptions = {
        scenarioId: scenario?.id,
        isTraining: false,
        startTime: startDate.valueOf(),
        endTime: getEndDate(showAvailabilityEndDateExcludingRestrictions),
        timezone,
        signal: null,
        simAvailabilityOnly: getValueForSimAvailabilityOnly(),
      };

      dispatch(
        actions.sessionWizard.fetchScenarioAvailableTimeBlocks(timeBlockOptions)
      )
        .then((res) => {
          if (shouldSetRestrictionTimeBlocks()) {
            setRestrictionTimeBlocks([]);
          }
          setSimAvailability(res.startDates);
          setResponse(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSimAvailability([]);
      setResponse(false);
      setLoading(false);
    }
  };

  const getStartDateForFetchLearnerAvailability = () => {
    return selectedDate?.isBefore(startDate)
      ? selectedDate.valueOf()
      : startDate?.valueOf() as number;
  };

  function hasQueryDataChanged(oldQueryData: MutableRefObject<IQueryData | undefined>, newQueryData: IQueryData) {
    return oldQueryData.current?.startDate?.valueOf() !== newQueryData.startDate?.valueOf()
      || oldQueryData.current?.endDate?.valueOf() !== newQueryData.endDate?.valueOf()
      || oldQueryData.current?.scenarioId !== newQueryData.scenarioId
      || oldQueryData.current?.timezone !== newQueryData.timezone;
  }

  useEffect(() => {
    const newQueryData: IQueryData = {
      startDate,
      endDate,
      scenarioId: scenario?.id,
      timezone,
    };

    if (startDate && endDate && scenario?.id && timezone && hasQueryDataChanged(queryData, newQueryData)) {
      queryData.current = newQueryData;
      setLoading(true);
      setAreSlotsCalculated(false);
      setSimAvailability(null);
      if (isCurrentUserClientUser(userRole)) {
        dispatch(
          actions.demandBasedSession.fetchLearnerAvailability(
            getStartDateForFetchLearnerAvailability(),
            endDate.valueOf()
          )
        ).then((learnerAvailabilitySlots) => {
          setLearnerAvailability(learnerAvailabilitySlots);
        });

        dispatch(actions.session.getRestrictions(companyData?.id || "", minStartDate.valueOf(), endDate.valueOf()))
          .then((restrictionTimeSlots) => {
            if (project?.showAvailability) {
              fetchScenarioAvailableTimeBlocks(restrictionTimeSlots);
            }

            setRestrictionTimeBlocks(restrictionTimeSlots);
          });
      }

      if (isCurrentUserPSorOps(userRole) || !project?.showAvailability) {
        fetchScenarioAvailableTimeBlocks();
      }
    }
  }, [startDate, endDate, scenario?.id]);

  useEffect(() => {
    if (restrictionTimeBlocks && simAvailability) {
      const cachedTimeBlocksForSelectedDate: ITimeBlocksOnDays[] = [];
      if (selectedDate) {
        const timeBlock = timeBlocks.find((tb) => tb.date === selectedDate.valueOf());
        if (timeBlock) {
          cachedTimeBlocksForSelectedDate.push(timeBlock);
        }
      }
      if (secondPossibleDay) {
        const timeBlock = timeBlocks.find((tb) => tb.date === secondPossibleDay.valueOf());
        if (timeBlock) {
          cachedTimeBlocksForSelectedDate.push(timeBlock);
        }
      }
      setCachedTimeBlocks([...cachedTimeBlocksForSelectedDate]);
    }
  }, [startDate, endDate]);
  
  const getScenarioEndDay = () => {
    const scenarioPlanningEndDate = scenario?.planning.endDate || 0;

    if (!isCurrentUserClientUser(userRole) || !project?.showAvailability) {
      return scenarioPlanningEndDate;
    }

    if (finalShowAvailabilityEndDate && finalShowAvailabilityEndDate.valueOf() > scenarioPlanningEndDate) {
      return scenarioPlanningEndDate;
    }

    return finalShowAvailabilityEndDate && finalShowAvailabilityEndDate.valueOf() || 0;
  };

  useEffect(() => {
    if (restrictionTimeBlocks && simAvailability) {
      const getSlotsParams = {
        projectTz: project?.timezoneId || "",
        userTz: timezone,
        startTimeStr: project?.demandBasedSchedulingStartTime || moment().startOf('d').format(timeFormat),
        endTimeStr: project?.demandBasedSchedulingEndTime || moment().endOf('d').format(timeFormat),
        selectedMonthStart: startDate || moment(),
        selectedMonthEnd: endDate || moment(),
        stepper: getTimeStep,
        sessionLength: moment.duration(scenario?.draft.sessionLength, "ms").asMinutes(),
        isCurrentUserClient: isCurrentUserClientUser(userRole),
        scenarioEndDay: getScenarioEndDay(),
        restrictionTimeSlots: restrictionTimeBlocks,
        availability: simAvailability,
        schedulingType,
      };
      const excludedDatesAndTimeBlocks = getTimeSlotsForDays(getSlotsParams);
      setTimeBlocks([...cachedTimeBlocks, ...excludedDatesAndTimeBlocks.timeBlocksOnDays]);
      setExcludedDates(excludedDatesAndTimeBlocks.excludedDates);
      setAreSlotsCalculated(true);
    }
  }, [restrictionTimeBlocks, simAvailability, scenario?.draft.sessionLength, project?.timezoneId]);

  return { timeBlocks, learnerAvailability, excludedDates, isResponse };
};
export default useFetchTimeBlocks;
