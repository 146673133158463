import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

export interface IAllocationCounterProps {
  show: boolean;
  duration: number; // milliseconds
  message: string;
  expiredMessage: string;
  step?: number; // milliseconds, 1000 by default
  onExpire: () => void;
}

const AllocationCounter: FunctionComponent<IAllocationCounterProps> = (props) => {

  const {
    duration,
    show,
    message,
    expiredMessage,
    onExpire,
  } = props;

  const [timeLeft, setTimeLeft] = useState<number>(duration);

  const step = useMemo(() => props.step || 1000, [props.step]);

  useEffect(() => {
    if (duration <= 1000) {
      return;
    }

    setTimeLeft(duration);
  }, [show, duration]);

  useEffect(() => {
    if (!show) {
      return;
    }

    const time = setTimeout(() => {
      setTimeLeft(timeLeft < step ? 0 : timeLeft - step);
    }, step);

    if (timeLeft <= 0) {
      onExpire();
      clearTimeout(time);
    }

    return () => {
      clearTimeout(time);
    };
  }, [show, timeLeft, step]);

  if (!show) {
    return null;
  }

  const counterTime = moment.duration(timeLeft).format({ template: 'm _ s _', trim: 'all', trunc: true });

  return (
    <div>
      {
        timeLeft
          ? <span><strong>{counterTime}</strong> {message}</span>
          : <span>{expiredMessage}</span>
      }
    </div>
  );
};

export default AllocationCounter;