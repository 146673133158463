import * as React from "react";
import { FunctionComponent, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "src/app/redux/store/actions";
import styles from "./LearnerSimSelector.css";
import cn from "classnames";
import { ILearnerExtended } from "src/app/data/client/interfaces/ILearner";
import ICompanyUser from "src/app/data/licensee/users/interfaces/ICompanyUser";
import useDebounce from "src/hooks/useDebounce";
import { Checkbox } from "src/components/Checkbox";
import ITimeInterval from "src/app/data/common/interfaces/ITimeInterval";
import { useIntl } from "react-intl";
import { Radio, RadioGroup } from "src/components/RadioGroup/RadioGroup";
import { SelectorTheme } from "src/components/Selector/themes";
import moment from "moment";
import { isCurrentUserPSorOps } from "src/app/data/common/utils/userRoleUtils";
import { selectors } from "src/app/redux";
import WarningModal from "../WarningModal";
import { SchedulingType } from "src/app/data/calendar/utils/simulationSchedulingUtil";

export interface ILearnerSimSelectorProps {
  selectedLearners: ILearnerExtended[];
  setSelectedLearners: (learner: ILearnerExtended[]) => void;
  selectedSimSpecialist: null | ICompanyUser;
  setSimSpecialist: (simSpecialist: ICompanyUser | null) => void;
  onlyUnauthenticatedLearners: boolean;
  setOnlyUnauthenticatedLearners: (value: boolean) => void;
  sessionStartAndEndDate: ITimeInterval;
  sessionSize: number;
  showAllLearnersAreExternalCheckbox: boolean;
  currentTab: "dateAndTimeStep" | "simSelectionStep" | "learnerSelectionStep";
  setLoading: (isLoading: boolean) => void;
  scenarioId: string;
  handleSchedulingStepChange: (value: "dateAndTimeStep" | "simSelectionStep" | "learnerSelectionStep") => void;
  schedulingType: SchedulingType;
  isClientGroupDelivery: boolean;
}

const LearnerSimSelector: FunctionComponent<ILearnerSimSelectorProps> = (props) => {
  // nosonar

  const {
    selectedLearners,
    setSelectedLearners,
    selectedSimSpecialist,
    setSimSpecialist,
    onlyUnauthenticatedLearners,
    setOnlyUnauthenticatedLearners,
    sessionStartAndEndDate,
    sessionSize,
    showAllLearnersAreExternalCheckbox,
    currentTab,
    setLoading,
    scenarioId,
    handleSchedulingStepChange,
    schedulingType,
    isClientGroupDelivery,
  } = props;
  const dispatch = useDispatch();
  const [isSimsList, setSimsList] = useState<ICompanyUser[]>([]);
  const [isLearnersList, setLearnersList] = useState<ILearnerExtended[]>([]);

  const [filter, setFilter] = useState("");
  const { value: debouncedFilter } = useDebounce(filter, 500);
  const userRole = useSelector(selectors.profile.getCurrentUserRole);

  const [isAvailableSims, setIsUnavailableSims] = useState(true);
  const [isSelectedSlotUnavailable, setSelectedSlotUnavailable] = useState(false);
  const toolTipRef = useRef<HTMLSpanElement>(null);
  const intl = useIntl();

  const getSimsList = () => {
    const isCurrentSlotAvailable = sessionStartAndEndDate.startDate > moment().valueOf();
    if (isCurrentSlotAvailable) {
      setLoading(true);
      if (!isAvailableSims) {
        dispatch(
          actions.sessionWizard.fetchSimSpecsByScenarioId(
            { page: 0, size: 50, filter: debouncedFilter },
            scenarioId,
            sessionStartAndEndDate.startDate,
            sessionStartAndEndDate.endDate,
            false,
            null,
            false
          )
        ).then((unavailableSims) => {
          dispatch(
            actions.sessionWizard.fetchSimSpecsByScenarioId(
              { page: 0, size: 50, filter: debouncedFilter },
              scenarioId,
              sessionStartAndEndDate.startDate,
              sessionStartAndEndDate.endDate,
              false,
              null,
              true
            )
          ).then((availableSims) => {
            const sims = unavailableSims.content.filter((unavailableSim) => {
              return !availableSims.content.find((availableSim) => availableSim.id === unavailableSim.id);
            });
            setSimsList(sims);
            setLoading(false);
          });
        });
      } else {
        dispatch(
          actions.sessionWizard.fetchSimSpecsByScenarioId(
            { page: 0, size: 50, filter: debouncedFilter },
            scenarioId,
            sessionStartAndEndDate.startDate,
            sessionStartAndEndDate.endDate,
            false,
            null,
            true
          )
        ).then((res) => {
          setSimsList(res.content);
          setLoading(false);
        });
      }
    } else {
      setSelectedSlotUnavailable(true);
    }
  };

  const getLearnersList = () => {
    setLoading(true);
    dispatch(
      actions.sessionWizard.fetchAvailableLearners(
        { page: 0, size: 9999, filter: debouncedFilter },
        scenarioId,
        sessionStartAndEndDate.startDate,
        sessionStartAndEndDate.endDate
      )
    ).then((res) => {
      setLearnersList(res.content);
      setLoading(false);
    });
  };

  useEffect(() => {
    getLearnersList();
    if (isCurrentUserPSorOps(userRole)) {
      getSimsList();
    }
  }, [debouncedFilter]);

  useEffect(() => {
    if (filter) {
      setFilter("");
    }
  }, [currentTab]);

  useEffect(() => {
    if (isCurrentUserPSorOps(userRole)) {
      getSimsList();
    }
  }, [isAvailableSims]);

  useEffect(() => {
    if (isClientGroupDelivery) {
      setSelectedLearners(isLearnersList);
    }
  }, [schedulingType, isLearnersList]);

  const onSearch = (e: any) => {
    setFilter(e.target.value);
  };

  const handleSimSelection = (simSpecialistId: string) => {
    setSimSpecialist(isSimsList.find((sim) => sim.id === simSpecialistId) || null);
  };

  const unselectSimSpec = () => {
    setSimSpecialist(null);
  };

  const isLearnerSelected = (learner: ILearnerExtended): boolean => {
    return !!selectedLearners.find((selectedLearner: ILearnerExtended) => {
      return selectedLearner.user.id === learner.user.id;
    });
  };

  const handleModalClose = () => {
    handleSchedulingStepChange("dateAndTimeStep");
  };

  const handleLearnerSelection = (learner: ILearnerExtended) => (e: any) => {
    if (e.target.checked) {
      if (selectedLearners.length < sessionSize) {
        setSelectedLearners([...selectedLearners, learner]);
      } else {
        toolTipRef.current?.focus();
      }
    } else {
      const isLearners = selectedLearners.filter((user: ILearnerExtended) => {
        return user.user.id !== learner.user.id;
      });
      setSelectedLearners(isLearners);
    }
  };

  const handleUnauthenticatedLearnersOnly = (e: any) => {
    setOnlyUnauthenticatedLearners(e.target.checked);
    if (e.target.checked) {
      setSelectedLearners([]);
    }
  };

  const handleCertified = () => {
    setIsUnavailableSims(!isAvailableSims);
  };


  return (
    <div className={styles.learnerSimSchedulingTabWrap}>
      <div className={styles.tabWrap}>
        {!isClientGroupDelivery && <div className={styles.searchSelectLearnerWrap}>
          {currentTab === "learnerSelectionStep" && (
            <label>
              {intl.formatMessage({ id: "MursionPortal.LearnerSimSelector.Tab.Search.Learner" })}
              <span
                ref={toolTipRef}
                className={cn(styles.tooltipWrap, styles.liftTooltip)}
                tabIndex={0}
                aria-describedby="allSlotsForScheduling"
              >
                ?
                <div className={styles.tooltip}>
                  <span id="allSlotsForScheduling">
                    {intl.formatMessage({ id: "Projects.Modal.Text.LearnerSelected" }, { maxLearners: sessionSize })}
                  </span>
                </div>
              </span>
            </label>
          )}
          {currentTab === "simSelectionStep" && (
            <label> {intl.formatMessage({ id: "MursionPortal.LearnerSimSelector.Tab.Search.Sim" })}</label>
          )}
          <input type="text" value={filter} onChange={onSearch} className={styles.searchLearner} />
          <div className={styles.learnerList}>
            <div
              className={cn(
                styles.onlyUnauthenticatedUserTextWrap,
                currentTab === "simSelectionStep" && styles.borderBtm
              )}
            >
              {currentTab === "learnerSelectionStep" && (
                <label>{intl.formatMessage({ id: "MursionPortal.LearnerSimSelector.Learner" })}</label>
              )}
              {currentTab === "simSelectionStep" && (
                <label>{intl.formatMessage({ id: "MursionPortal.Label.FilterSIMsBy" })}</label>
              )}
              {showAllLearnersAreExternalCheckbox && currentTab === "learnerSelectionStep" && (
                <label className={styles.checkBoxWrap}>
                  <Checkbox
                    onChange={handleUnauthenticatedLearnersOnly}
                    checked={onlyUnauthenticatedLearners}
                    themeType={SelectorTheme.Cobalt}
                  >
                    {intl.formatMessage({ id: "Session.Edit.Modal.Learners.UnauthenticatedCheckbox" })}
                  </Checkbox>
                  <span className={styles.checkmark} />
                </label>
              )}
              {currentTab === "simSelectionStep" && (
                <RadioGroup name="sim" onChange={handleCertified} selectedValue={isAvailableSims}>
                  <Radio
                    value={true}
                    themeType={SelectorTheme.Cobalt}
                    label={intl.formatMessage({ id: "MursionPortal.LearnerSimSelector.Sim.Available" })}
                    tooltipText={intl.formatMessage({ id: "MursionPortal.LearnerSimSelector.Sim.Available.ToolTip" })}
                  />
                  <Radio
                    value={false}
                    themeType={SelectorTheme.Cobalt}
                    label={intl.formatMessage({ id: "MursionPortal.LearnerSimSelector.Sim.Unavailable" })}
                    tooltipText={intl.formatMessage({ id: "MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip" })}
                  />
                </RadioGroup>
              )}
            </div>
            <div>
              <div className={styles.customScrollBarWrap}>
                {currentTab === "learnerSelectionStep" && !onlyUnauthenticatedLearners && (
                  <>
                    {isLearnersList.map((learner: ILearnerExtended, key) => {
                      return (
                        <label key={"learner-list" + key} className={cn(styles.checkBoxWrap, styles.listing)}>
                          <Checkbox
                            onChange={handleLearnerSelection(learner)}
                            checked={isLearnerSelected(learner)}
                            themeType={SelectorTheme.Cobalt}
                          >
                            {learner.user.firstName} {learner.user.lastName}
                            {!learner.user.firstName && !learner.user.lastName && <>{learner.user.email}</>}
                          </Checkbox>
                        </label>
                      );
                    })}{" "}
                  </>
                )}
                {currentTab === "simSelectionStep" && (
                  <>
                    {isSimsList.length ? (
                      <RadioGroup
                        name="simSpec"
                        onChange={handleSimSelection}
                        selectedValue={selectedSimSpecialist?.id}
                      >
                        {isSimsList.map((sim: ICompanyUser, key) => {
                          return (
                            <Radio
                              key={"sim" + key}
                              value={sim.id}
                              themeType={SelectorTheme.Cobalt}
                              label={`${sim.firstName} ${sim.lastName} ${
                                !sim.firstName && !sim.lastName ? sim.email : ""
                              }`}
                            />
                          );
                        })}
                      </RadioGroup>
                    ) : (
                      <div className={styles.noSimsAvailableTitle}>
                        {intl.formatMessage({ id: "MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist" })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>}
        <div className={cn(styles.selectedLearnerWrap, isClientGroupDelivery && styles.removePaddingLeft)}>
          {currentTab === "learnerSelectionStep" && (
            <label>{intl.formatMessage({ id: "MursionPortal.LearnerSimSelector.Learner.Selected" })}</label>
          )}
          {currentTab === "simSelectionStep" && (
            <label>{intl.formatMessage({ id: "MursionPortal.LearnerSimSelector.Sim.Selected" })}</label>
          )}

          <div className={styles.customScrollBarWrap}>
            {currentTab === "learnerSelectionStep" && (
              <>
                {selectedLearners.map((learner, key) => {
                  return (
                    <div key={"selected-learners" + key}>
                      <div className={cn(styles.selectedValues, isClientGroupDelivery && styles.equalPadding)} tabIndex={0}>
                        <span className={styles.textEllipses}>
                          {learner.user.firstName} {learner.user.lastName}{" "}
                          {!learner.user.firstName && !learner.user.lastName && <>{learner.user.email}</>}{" "}
                        </span>
                        {!isClientGroupDelivery && <button onClick={handleLearnerSelection(learner)} />}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {currentTab === "simSelectionStep" && selectedSimSpecialist && (
              <div>
                <div className={styles.selectedValues} tabIndex={0}>
                  <span className={styles.textEllipses}>
                    {selectedSimSpecialist.firstName} {selectedSimSpecialist.lastName}{" "}
                    {!selectedSimSpecialist.firstName && !selectedSimSpecialist.lastName && (
                      <>{selectedSimSpecialist.email}</>
                    )}
                  </span>
                  <button onClick={unselectSimSpec} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <WarningModal
        isUnschedulable={false}
        isSelectedSlotUnavailable={isSelectedSlotUnavailable}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default LearnerSimSelector;
