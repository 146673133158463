import * as React from 'react';
import Cookies from 'js-cookie';
import LoadingOverlayWrapper from "react-loading-overlay";
import styles from './SsoCheck.css';
import { connect } from 'react-redux';
import { IAppState } from 'src/app/redux/store/reducers';
import selectors from 'src/app/redux/selectors';
import { RouteComponentProps, withRouter } from 'react-router';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import _noop from 'lodash/noop';
import { Modal } from 'react-bootstrap';
import Button, { ButtonSize, ButtonType } from 'src/components/Button';
import getUserName from 'src/app/data/common/utils/getUserName';
import {
  IExternalLoginData,
  LOGOUT_TIMEOUT,
  PARAM_LOGIN_EXTERNAL,
  PARAM_LOGIN_FAILED
} from 'src/app/redux/modules/auth/interfaces/IAuthData';
import AuthService from 'src/app/services/auth/AuthService';
import openPopupWindow from 'src/app/data/common/utils/openPopupWindow';
import getUrlParamValue from 'src/app/data/common/utils/queryParamsAccessor';
import RestService from 'src/app/services/rest/RestService/RestService';
import ROUTE_PATHS from "src/routing/paths";
import { injectIntl, WrappedComponentProps } from 'react-intl';
import TranslateMessage from 'src/i18n/TranslateMessage';

interface ISsoCheckProps extends RouteComponentProps<any>, WrappedComponentProps {
  externalLoginData: IExternalLoginData;
  redirectUrl?: string;
  userProfile: IUserProfile | null;
}

interface ISsoCheckState {
  showModal: boolean;
  loading: boolean;
}

class SsoCheck extends React.Component<ISsoCheckProps, ISsoCheckState> {

  public state: ISsoCheckState = {
    showModal: false,
    loading: false,
  };

  public async componentDidMount() {
    const { location, externalLoginData, userProfile } = this.props;

    if (getUrlParamValue(PARAM_LOGIN_FAILED, location)) {
      await this.externalLogout();
      this.cleanUp();
      return;
    }

    if (userProfile && (externalLoginData.email || '').toLowerCase() !== (userProfile.email || '').toLowerCase()) {
      this.setState({
        showModal: true,
      });
    } else {
      this.cleanUp();
    }
  }

  public render() {
    const { externalLoginData, userProfile } = this.props;
    const { showModal, loading } = this.state;

    if (!userProfile) {
      return null;
    }

    const loginUrl = this.getLoginUrl();
    const logoutUrl = this.getLogoutUrl();
    const { intl } = this.props;

    return showModal ? (
      <Modal className={styles.modal} show={true} onHide={_noop}>
        <Modal.Title>
          <h5 className={styles.title}>{TranslateMessage('MursionPortal.Header.HowWouldYouProceed')}</h5>
        </Modal.Title>
        <Modal.Body>
          <div>{TranslateMessage('MursionPortal.Message.YouAreSignedIn',
            {
              code: (word: string) => <b> {word} </b>,
              userName:getUserName(userProfile),
              userProfile:userProfile.email
            }
          )}</div>
          <div>{TranslateMessage('MursionPortal.Text.ToContinue',
            {
              code: (word: string) => <b> {word} </b>,
              loginData:externalLoginData.email
            }
          )}</div>
        </Modal.Body>
        <Modal.Footer>
          <a href={logoutUrl || ''} onClick={this.switchUser(loginUrl, logoutUrl)} target="_blank">
            <Button
              btnSize={ButtonSize.MEDIUM}
              disabled={loading}
            >
              {TranslateMessage('MursionPoral.Button.SwitchUser')}
            </Button>
          </a>
          <Button
            btnSize={ButtonSize.MEDIUM}
            btnType={ButtonType.SPECIAL_PURPLE}
            disabled={loading}
            title={intl.formatMessage({ id: 'MursionPortal.Title.ContinueAs' }, { userName: getUserName(this.props.userProfile) })}
            onClick={this.continueAsCurrent}
          >
            {TranslateMessage('MursionPortal.Button.ContinueAs',
              {
                code: (word: string) => <b> {word} </b>,
                userName:getUserName(this.props.userProfile, true)
              }
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    ) : (
      <LoadingOverlayWrapper active={true} className={styles.loadingSpinner} spinner={true}/>
    );
  }

  private getLoginUrl = () => {
    let url = `${RestService.SSO_LOGIN_URL}/${this.props.externalLoginData.id}`;

    if (this.props.redirectUrl) {
      url += `?redirect_url=${this.props.redirectUrl}`;
    }

    return url;
  }

  private getLogoutUrl = () => {
    return `${RestService.SSO_LOGOUT_URL}/${this.props.externalLoginData.id}`;
  }

  private continueAsCurrent = (e: any) => {
    e.preventDefault();
    this.cleanUp();
  }

  private switchUser = (loginUrl: string, logoutUrl: string | null) => async (e: any) => {
    e.preventDefault();

    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    if (logoutUrl) {
      const currentWindow = window;
      const logoutWindow = openPopupWindow(decodeURIComponent(logoutUrl), `LogOut:${Date.now()}`);

      if (logoutWindow) {
        setTimeout(() => {
          logoutWindow.close();
          currentWindow.location.replace(loginUrl);
        }, LOGOUT_TIMEOUT);
      }
    } else {
      this.cleanUp();
    }

    AuthService.clearToken();
  }

  private externalLogout = async () => {
    const logoutUrl = this.getLogoutUrl();
    const logoutWindow = openPopupWindow(decodeURIComponent(logoutUrl), `LogOut:${Date.now()}`);

    if (logoutWindow) {
      await this.timeout(LOGOUT_TIMEOUT);
      logoutWindow.close();
    }
  }

  private cleanUp = () => {
    Cookies.remove(PARAM_LOGIN_EXTERNAL);
    const { history, redirectUrl } = this.props;

    history.replace(redirectUrl || ROUTE_PATHS.HOME, { from: { pathname: ROUTE_PATHS.LOGIN } });
  }

  private timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

export default injectIntl(withRouter(connect(
  (state: IAppState) => ({
    userProfile: selectors.profile.getUserProfile(state),
  }),
)(SsoCheck)));
